$white: #fff;
$black: #000;

$blue-light: #9fc9e8;
$blue: #589bcc;
$blue-active: #2fa4e7;
$blue-dark: #546e7a;

$olive: #afc403;
$lime-dark: #afc403;
$lime-darker: #97a900;
$lime: #bed600;
$lime-light: #dae69b;
$purple: #5e5f8a;
$red-bright: #ff3939;
$green-bright: #71c019;

$red-lightest: #ffc1c1;
$red-lighter: #ff9f9f;
$red-light: #e85151;
$red: #ff0000;

$gray-light: #eeeeee;
$gray-darker: #dadada;
$gray-border: #e6e6e6;
$slate: #c9c9c9;
$gray-font: #757575;
$gray: #9e9e9e;
$gray-dark: #454444;
$gray-darkest: #424242;

$yellow: rgb(225, 191, 0);
$orange: #fb702b;

// in case we need to use them outside stitches

$pt-blue100: #dff3ff;
$pt-blue200: #bfe7ff;
$pt-blue300: #9fdbff;
$pt-blue400: #7fcfff;
$pt-blue500: #5fc5fc;
$pt-blue600: #47a5d6;
$pt-blue700: #3083af;
$pt-blue800: #196188;
$pt-blue900: #023f61;

$pt-yellow100: #fbf5d5;
$pt-yellow200: #f7ebab;
$pt-yellow300: #f3e181;
$pt-yellow400: #efd757;
$pt-yellow500: #e9c82c;
$pt-yellow600: #c9aa25;
$pt-yellow700: #aa8c1c;
$pt-yellow800: #8b6e13;
$pt-yellow900: #6c500a;

$pt-green050: #f6f8e9;
$pt-green100: #edf1d3;
$pt-green200: #dbe3a7;
$pt-green300: #c9d57b;
$pt-green400: #b7c74f;
$pt-green500: #a2b624;
$pt-green600: #899b21;
$pt-green700: #6f811c;
$pt-green800: #556717;
$pt-green900: #3b4d12;

$pt-grey050: #f8f8fa;
$pt-grey100: #f8f8f9;
$pt-grey200: #f6f6f8;
$pt-grey300: #f4f4f7;
$pt-grey400: #f2f2f6;
$pt-grey500: #e3e3e7;
$pt-grey600: #d4d4d8;
$pt-grey700: #c5c5c9;
$pt-grey800: #b6b6ba;
$pt-grey900: #a7a7ab;

$pt-black100: #afaeae;
$pt-black200: #999999;
$pt-black300: #858585;
$pt-black400: #717171;
$pt-black500: #5d5d5d;
$pt-black600: #494949;
$pt-black700: #353535;
$pt-black800: #212121;
$pt-black900: #0d0d0d;
