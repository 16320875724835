@import "../../../base/colors";

.pt-pi-savings-bar {
  display: block;
  padding-left: 120px;
  padding-right: 120px;
  min-width: 350px;

  &__bar {
    display: block;
    position: relative;
    height: 18px;
  }

  &__limits-line,
  &__pot-savings-line,
  &__ach-savings-line {
    display: inline-block;
    position: absolute;
    top: 0;
    height: 18px;
  }
  &__limits-line {
    background-color: #e2e2e2;
  }
  &__pot-savings-line {
    background-color: rgba(#ff7c7c, 0.7);
  }
  &__ach-savings-line {
    background-color: rgba(#50d28c, 0.7);
  }
  &__bar:hover &__pot-savings-line {
    background-color: #ff7c7c;
  }
  &__bar:hover &__ach-savings-line {
    background-color: #50d28c;
  }

  &__value-tick,
  &__min-value-tick,
  &__max-value-tick {
    display: inline-block;
    position: absolute;
    width: 1px;
    height: 23px;
    background-color: gray;
  }

  &__relative-wrapper {
    position: relative;
  }
  &__value-centerer {
    position: relative;
    width: 200px;
    text-align: center;

    & > div {
      margin-left: 50%;
      width: 100%;
    }
  }
  &__value-min,
  &__value-max,
  &__value-bottom {
    position: absolute;
    line-height: 14px;
    font-size: 14px;
    text-align: center;

    & > * {
      white-space: nowrap;
    }
  }
  &__value-min,
  &__value-max {
    top: -15px;
  }
  &__value-min {
    right: 1px;
  }
  &__value-max {
    left: 1px;
  }
  &__value-bottom {
    right: 0;
    bottom: -36px;
  }

  /* modifiers */

  &--small {
    padding-left: 80px;
    padding-right: 80px;
    min-width: 250px;
  }
  &--small &__bar {
    height: 9px;
  }
  &--small &__limits-line,
  &--small &__pot-savings-line,
  &--small &__ach-savings-line {
    height: 9px;
  }
  &--small &__value-tick,
  &--small &__min-value-tick,
  &--small &__max-value-tick {
    height: 12px;
  }
  &--small &__value-min,
  &--small &__value-max,
  &--small &__value-bottom {
    line-height: 11px;
    font-size: 11px;
  }
  &--small &__value-min,
  &--small &__value-max {
    top: -11px;
  }
  &--small &__value-bottom {
    bottom: -23px;
  }
}
