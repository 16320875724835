@import "../base/colors";

// labor index header

.labor-index__header-row {
  margin-bottom: 25px;
}

.labor-index__header {
  background-color: #2b3239;
}

.labor-index__header-link,
.labor-index__header-link:hover {
  text-decoration: none;
}

.labor-index__header-link {
  h1 {
    color: #fff;
    display: inline;
  }

  h2 {
    color: #90a927;
    display: inline;
    text-transform: uppercase;
  }
}

// labor index chart old impl

.pt-rate-type-btns {
  .btn:focus {
    outline: none;
  }
}

// indeces list

.industry-list .fa {
  margin-right: 10px;
}

.industry-list {
  .list-group-item {
    color: #333 !important;

    &.active {
      color: #fff !important;
      background-color: $blue-active;
      border-color: $blue-active;

      .badge {
        color: $blue-active;
      }
    }
  }

  .badge {
    background-color: $blue-active;
  }
}

.index-icon {
  color: #90a927;
}

// ask question

.ask-question-panel {
  color: #444;
}

.ask-question-panel.open {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.ask-question-panel .title {
  color: #fff;
  background-color: #5e5e8b;
  padding: 10px 40px;
  font-size: 16px;
  text-transform: uppercase;
  cursor: pointer;
  text-align: center;
  margin-bottom: 0;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.ask-question-panel .content h1 {
  font-size: 20px;
  text-align: center;
  margin-top: 20px;
}

.ask-question-panel h1.title {
  font-size: 16px;
  padding: 15px 20px;
}

.ask-question-panel h2 {
  font-size: 14px;
  border-bottom: 1px solid #eee;
  padding-bottom: 15px;
}

.ask-question-panel .content {
  min-height: 390px;
  background-color: #fff;
  position: relative;
  z-index: 10;
  padding: 0 20px 15px 20px;
  border: 1px solid #e6e6e6;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.ask-question-panel .form-control {
  border: 2px solid #777;
  font-size: 12px;
  padding: 5px 10px;
}

.ask-question-panel input[type="text"] {
  height: 36px;
}

.ask-question-panel textarea {
  height: 100px;
  resize: vertical;
}

.ask-question-panel input[type="submit"] {
  color: #fff;
  background-color: #5e5e8b;
  padding: 5px 20px;
}

.ask-question-panel input[type="submit"]:hover {
  background-color: #3b3a5f;
}

.ask-question-panel .group {
  margin-bottom: 15px;
}

.ask-question-panel .error label {
  color: #d70000;
}

.ask-question-panel .error .form-control {
  border-color: #d70000;
}

.ask-question-panel .group {
  position: relative;
}

.pt-checkbox {
  padding-left: 25px;
  position: relative;
}

.pt-checkbox input[type="checkbox"] {
  opacity: 0;
  position: absolute;
}

.pt-checkbox span {
  display: block;
}

.pt-checkbox span:before {
  width: 18px;
  height: 18px;
  content: "\f00c";
  position: absolute;
  border: 2px solid #777;
  top: 1px;
  left: 0;
  display: block;
  border-radius: 3px;
  font-family: "FontAwesome";
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #fff;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
}

.pt-checkbox input[type="checkbox"]:checked + span:before {
  color: #555;
}

.error .pt-checkbox input[type="checkbox"]:checked + span:before,
.pt-checkbox.error input[type="checkbox"]:checked + span:before {
  color: #d70000;
  border-color: #d70000;
}
