.pt-running-processings-alert {
  &__progress-line {
    line-height: 22px;
    vertical-align: middle;
    white-space: nowrap;
    margin-bottom: 5px;
    margin-top: 5px;

    label {
      margin-right: 15px;
    }
  }
}
