.saved-searches-list .info-box {
  font-size: 15px;
  height: 80px;
  width: 80px;
  line-height: 90px;
}
.saved-searches-list .info-box span {
  // display: flex;
  // justify-content: center;
  // flex-direction: column;
  // text-align: center;
  // height: 100%;
  //flex-wrap: wrap;
  width: 80%;
  margin: auto;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.saved-searches-list .rate_box {
  width: 80px;
  padding-top: 0px;
  padding-bottom: 0px;
}
.saved-searches-list .plus {
  color: #009e42;
  font-size: 15px;
}
.saved-searches-list .min {
  color: #cf2a3c;
  font-size: 15px;
}
.info_head {
  padding: 25px 0;
}
.saved-search-details {
  .info-box {
    width: 80px;
    height: 80px;
    float: unset;
    margin: 0 auto;
    svg {
      margin: -10px auto 6px;
    }
  }

  .rates-summary {
    display: flex;
    justify-content: center;
  }
}
.saved-search-details .info-box span {
  font-size: 61%;
}
.saved-search-details .rate_box small {
  font-size: 13px;
}
.saved-search-details .rate_box {
  width: 38%;
}
.saved-search-details .rate_box.left-r:after {
  height: 43px;
}
.saved-search-details .rate_box h2 {
  margin-bottom: 0px;
}
.saved-search-details .list-rate {
  padding-left: 0px;
}
.box-border {
  border: 1px solid #d4d4d4;
  padding: 15px;
  margin-bottom: 20px;
}

.ratecards .ratecard-label .city {
  color: #aaaaaa;
  font-size: 14px;
}

.search-peek-table-container {
  width: 100%;
  overflow: auto;
  &.flex-grid {
    justify-content: center;
    align-items: center;
  }
}
