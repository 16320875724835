@import "../../../base/labor_index/typography";
@import "../../../base/colors";

$option-v-padding: 5px;
$option-h-padding: 10px;
$option-size: 41px;

@mixin square-mixin($size) {
  width: $size;
  height: $size;
}

@mixin table-cell-mixin() {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  padding: $option-v-padding $option-h-padding;
}

.single-picker,
.pure-picker {
  position: relative;
  display: inline-block;

  &__container {
    display: table;
    border: 1px solid $gray-darker;
    border-collapse: collapse;
  }

  &__label {
    @include table-cell-mixin();
    min-width: 85px;
    padding: $option-v-padding 20px;
    background: $gray;
    color: $white;
    font-family: $ff-source-regular;
  }

  &__option {
    @include table-cell-mixin();
    @include square-mixin($option-size);
    border-right: 1px solid $gray-darker;
    cursor: pointer;
    background: $white;
    color: $gray;
    white-space: nowrap;

    &--active {
      color: $white;
      background: $olive;
    }

    &:hover {
      background: $gray-light;
    }

    &--active:hover {
      background: rgba($olive, 0.75);
    }
  }
}
