@import "../../../base/colors";
@import "../../../base/labor_index/typography";

$tile-padding: 12px;
$outer-tile-transition: 200ms;
$trend-data-transition: 500ms;

.pt-tile__outer {
  padding-top: 50px;
  width: 160px;
  height: 100%;
  float: left;
  margin-right: 1px;
  -webkit-transition: padding-top $outer-tile-transition;
  -moz-transition: padding-top $outer-tile-transition;
  -ms-transition: padding-top $outer-tile-transition;
  -o-transition: padding-top $outer-tile-transition;
  transition: padding-top $outer-tile-transition;
}

.pt-tile__outer--selected {
  padding-top: 0;
}

.pt-tile {
  background-color: $gray-darkest;
  height: 100%;
  padding: $tile-padding;
  -webkit-box-shadow: 5px 6px 0 rgba($gray, 0.8);
  -moz-box-shadow: 5px 6px 0 rgba($gray, 0.8);
  box-shadow: 5px 6px 0 rgba($gray, 0.8);
  overflow: hidden;
  position: relative;
  cursor: pointer;
}

.pt-tile__inner {
  margin-top: 10px;
  position: absolute;
  bottom: $tile-padding;
  font-family: $ff-source-light;
}

.pt-tile__trend-data {
  overflow: hidden;
  -webkit-filter: blur(0px);
  filter: blur(0px);

  -webkit-transition: -webkit-filter $trend-data-transition, height $outer-tile-transition;
  transition: filter $trend-data-transition, -webkit-filter $trend-data-transition,
    height $outer-tile-transition;
}

.pt-tile__trend-data--loading {
  -webkit-filter: blur(4px);
  filter: blur(4px);
}

.pt-tile__trend-data {
  height: 0;
  color: $white;
}

.pt-tile__trend-data--show {
  height: 50px;
}

.pt-tile__title {
  color: $white;
  font-size: 16px;
  font-family: $ff-source-semibold;
  text-transform: uppercase;
  -ms-word-wrap: break-word;
  word-wrap: break-word;
}

.pt-tile__change {
  font-size: 28px;
}

.pt-tile__title,
.pt-tile__change {
  line-height: 100%;
}

.pt-tile__percent-change {
  font-size: 16px;
}

.pt-tile__change,
.pt-tile__percent-change {
  color: $green-bright;
}

.pt-tile__change--negative,
.pt-tile__percent-change--negative {
  color: $red-bright;
}

.pt-tile__last-updated {
  color: $gray;
  font-size: 12px;
}

.pt-text-bold {
  font-family: $ff-source-regular;
}
