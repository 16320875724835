@import "../variables";

@keyframes wizard-slide-in-rtl {
  0% {
    transform: translateX(100%);
    opacity: 0;
    visibility: visible;
  }

  100% {
    transform: translate(0);
    opacity: 1;
    visibility: visible;
  }
}

@keyframes wizard-slide-out-rtl {
  0% {
    transform: translateX(0);
    opacity: 1;
    visibility: visible;
  }

  100% {
    transform: translate(-100%);
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes wizard-slide-in-ltr {
  0% {
    transform: translateX(-100%);
    opacity: 0;
    visibility: visible;
  }

  100% {
    transform: translate(0);
    opacity: 1;
    visibility: visible;
  }
}

@keyframes wizard-slide-out-ltr {
  0% {
    transform: translateX(0);
    opacity: 1;
    visibility: visible;
  }

  100% {
    transform: translate(100%);
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(222, 189, 44, 0.4);
    box-shadow: 0 0 0 0 rgba(222, 189, 44, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(222, 189, 44, 0);
    box-shadow: 0 0 0 10px rgba(222, 189, 44, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(222, 189, 44, 0);
    box-shadow: 0 0 0 0 rgba(222, 189, 44, 0);
  }
}

.pt-ui {
  .rc-vertical-wizard {
    .rc-vertical-wizard-header {
      display: flex;
      flex: 0 1 auto;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      background-color: $rc-gray7;

      .intersection {
        width: 36px;
        height: 36px;
        display: flex;
        flex: 0 1 auto;
        align-content: center;
        align-items: center;
        border-radius: 50px;
        border: none;
        z-index: 1;
        background-color: white;
        margin: 0 2px;

        &.disabled {
          cursor: default;
          opacity: 0.6;
        }

        .fa {
          margin: auto;
        }
      }
    }

    .rc-vertical-wizard-content {
      position: relative;
      margin: 0 -20px;
      padding: 20px;
      //overflow: hidden;

      .rc-vertical-wizard-step {
        display: flex;
        flex: 0 1 auto;
        opacity: 1;
        visibility: visible;
        width: 100%;
        font-size: 16px;
        line-height: normal;
        margin-bottom: 20px;

        .step-description {
          flex-shrink: 0;
          width: 150px;
          &,
          p {
            font-size: 14px;
            text-align: right;
            font-style: italic;
            color: $rc-gray3;
          }
        }
        .step-delimiter {
          padding: 0 20px;
          display: flex;
          flex: 0 0 auto;
          flex-flow: column nowrap;

          .step-number {
            border: 4px solid $rc-gray6;
            background-color: transparent;
            color: $rc-white1;
            width: 40px;
            height: 40px;
            min-width: 40px;
            min-height: 40px;
            max-width: 40px;
            max-height: 40px;
            border-radius: 50px;
            text-align: center;
            line-height: 32px;
            font-size: 20px;
            vertical-align: middle;
            display: inline-block;
            font-family: monospace;
            font-weight: bold;
          }

          .step-arrow {
            position: relative;
            width: 0;
            border: 4px solid $rc-gray2;
            margin: 10px auto 0 auto;
            height: 100%;
            border-top: 2px solid transparent;
          }

          .step-arrow-tip {
            width: 0;
            margin: auto;
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            border-top: 5px solid $rc-gray2;
          }
        }
        .step-body {
          flex-grow: 1;
          .step-title {
            margin-bottom: 10px;
            h3 {
              height: 40px;
              line-height: 40px;
              vertical-align: middle;
            }
          }

          .step-content {
            width: 100%;
            padding: 40px 0;
          }
        }

        &.animate-show-forward {
          animation: wizard-slide-in-rtl 250ms ease-in;
        }

        &.animate-hide-forward {
          animation: wizard-slide-out-rtl 250ms ease-in;
          transform: translate(-100%);
          width: auto;
          top: 20px;
          left: 0;
          opacity: 0;
          visibility: hidden;
          position: absolute;
        }

        &.animate-show-backward {
          animation: wizard-slide-in-ltr 250ms ease-out;
        }

        &.animate-hide-backward {
          animation: wizard-slide-out-ltr 250ms ease-out;
          transform: translate(100%);
          width: auto;
          top: 20px;
          left: 0;
          opacity: 0;
          visibility: hidden;
          position: absolute;
        }
      }

      .rc-vertical-wizard-step.active {
        .step-delimiter {
          .step-number {
            background-color: $rc-yellow2;
            border-color: $rc-yellow2;
            animation: pulse 2s infinite;
          }

          .step-arrow {
            border-color: $rc-yellow2;
            border-top-color: transparent;
            opacity: 0.7;
          }

          .step-arrow-tip {
            border-color: transparent;
            border-top-color: $rc-yellow2;
            opacity: 0.7;
          }
        }
      }

      .rc-vertical-wizard-step.completed {
        .step-delimiter {
          .step-number {
            background-color: $rc-green6;
            border-color: $rc-green6;
          }

          .step-arrow {
            border-color: $rc-green6;
            border-top-color: transparent;
            opacity: 0.7;
          }

          .step-arrow-tip {
            border-color: transparent;
            border-top-color: $rc-green6;
            opacity: 0.7;
          }
        }
      }
    }
  }
}
