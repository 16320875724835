@import "../base/colors";

$footer-background-color: #333333;
$footer-column-title-color: #999999;
$footer-column-text-color: #777777;

// TODO these fonts definitions must be placed in reset-css
.pt-footer {
  & h4,
  & p {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
  }
  & h2 {
    font-weight: 600;
    font-size: 36px;
    line-height: 36px;
  }
}
@media (max-width: 767px) {
  .pt-footer {
    h4,
    p {
      font-size: 14px;
      line-height: 18px;
    }
    h2 {
      font-size: 24px;
      line-height: 28px;
    }
  }
}
// TODO these fonts definitions must be placed in reset-css

.pt-footer {
  position: relative;
  height: 250px;
  padding: 30px 0;
  background: $footer-background-color;
  z-index: 1;

  // // for fixed positioning
  // position: fixed;
  // bottom: 0;
  // width: 100%;

  &__column {
    &-title {
      margin: 0 !important;
      color: $footer-column-title-color;
      padding-bottom: 8px;
      border-bottom: 1px solid $footer-column-text-color;
    }

    &-list {
      margin: 10px 0 0 !important;
      padding: 0 !important;

      & a {
        display: inline-block;
        padding: 2px 0;
        font-size: 17px;
        color: $footer-column-text-color !important;
        text-decoration: none;

        &:hover {
          color: $footer-column-title-color !important;
        }
      }
    }
  }

  &__contact-text {
    color: $footer-column-text-color;
    margin-top: 10px;
    margin-bottom: 0;
  }
  &__contact-phone {
    color: $footer-column-title-color;
    margin-top: 0;
    margin-bottom: 10px;
  }
  &__copyright {
    color: $footer-column-text-color;
    font-size: 12px !important;
    padding: 10px 0;
  }
}

// media queries

@media (max-width: 480px) {
  .pt-footer {
    &__column {
      width: 100%;
      float: none;
    }
  }
}

@media (min-width: 480px) and (max-width: 767px) {
  .pt-footer {
    &__fix:before,
    &__fix:after {
      content: " ";
      display: table;
      margin-bottom: 20px;
    }
  }
}

@media (max-width: 991px) {
  .pt-footer {
    position: relative !important;
    height: auto;
    bottom: auto !important;

    &__column {
      margin-bottom: 20px;
    }
  }
}
