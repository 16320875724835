$rc-black: #333333;
$rc-black8: #545454;
$rc-black7: #757575;
$rc-gray1: #cccccc;
$rc-gray2: #dddddd;
$rc-gray3: #666666;
$rc-gray4: #575656;
$rc-gray5: #888888;
$rc-gray6: #aaaaaa;
$rc-gray7: #f8f8f8;
$rc-gray8: #eeeeee;
$rc-white1: #f7f8fb;
$rc-white2: #ececec;
$rc-green1: #e2e9b9;
$rc-green2: #c2db30;
$rc-green3: #c3db38;
$rc-green4: #a2b624;
$rc-green5: #778712;
$rc-green6: #90a424;
$rc-green7: #88a46f;
$rc-green8: #f5f8e8;
$rc-green9: #bed146;
$rc-yellow: #e9c82c;
$rc-yellow2: #debd2c;
$rc-yellow3: #fcf8e3;
$rc-yellow4: #8a6d3b;
$rc-red1: red;
$rc-red2: #ffeaea;
$rc-element-border-color: #eceff1;
$rc-input-border-color: #dadada;

$rc-default-color: #999999; /* #93989C */
$rc-primary-color: #5a91c3;
$rc-success-color: #a2b624;
$rc-info-color: #e2e9b9;
$rc-warning-color: #e9c82c; // #F1C40F;
$rc-danger-color: #f24e4e;

$table-border-color: #e6e6e6;

// Keep these colors in sync with
// file: /src/constants/css.js
// const LEVEL_COLOR
$junior-color: #0e5d92;
$mid-color: #882d60;
$senior-color: #28784d;
$lead-color: #5b2971;
$guru-color: #2e0f36;

// Keep these colors in sync with
// file: /src/constants/css.js
// const VALUE_CHANGE_COLOR
$positive-change-color: $rc-green6;
$negative-change-color: $rc-danger-color;
$junior-positive-color: #e2f376;
$junior-negative-color: #fea9a9;
$mid-positive-color: #e2f376;
$mid-negative-color: #fea9a9;
$senior-positive-color: #e2f376;
$senior-negative-color: #fea9a9;
$lead-positive-color: #e2f376;
$lead-negative-color: #fea9a9;
$guru-positive-color: #e2f376;
$guru-negative-color: #fea9a9;

$edit-bar-height: 61px;
$pagination-box-height: 32px;

$selectVPadding: 10px;
$selectArrowWidth: 5px;

$dark-gray: #a0adb5;
$satin: #f8f8f8;
$platinum: #dfe4e8;
$metal: #707a80;
$citrine: #bcd500;
$olive-drab: #333333;
$taupe-gray: #999;
$true-blue: #1976d2;

$input-border-color: #979797;
