.btn-alt {
  background-color: transparent !important;
  border-width: 1px !important;
  border-color: #e6e6e6;
  border-style: solid;
  color: #aaaaaa !important;
  padding: 5px 15px;
  font-size: 14px;
  border-radius: 0;

  &.btn-lg {
    padding: 9px 20px;
    font-size: 16px;
  }
  &.btn-danger {
    border-color: #fd483e !important;
    color: #fd483e !important;
  }
  &.btn-danger:hover {
    border-color: #9e2522 !important;
    color: #9e2522 !important;
  }
  &.btn-default {
    border: 1px solid #dddddd !important;
  }
  &.btn-default:hover {
    border-color: #aaaaaa !important;
    color: #666666 !important;
  }
  &.btn-primary {
    border-color: #c2d646 !important;
    color: #c2d646 !important;
  }
  &.btn-primary:hover,
  &.btn-primary-dark {
    border-color: #b2c72c !important;
    color: #b2c72c !important;
  }
  &.btn-primary-dark:hover {
    border-color: #99ab26 !important;
    color: #99ab26 !important;
  }
}

.btn-warning-dark {
  background-color: #fb702b;
  &:hover,
  &:focus,
  &:active {
    background-color: #d74c22;
  }
}
