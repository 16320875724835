.margintop-20 {
  margin-top: 20px !important;
}
.ratecards .search-peek .overlap-b-padding {
  margin-bottom: 0px;
}
//.peek-table-container {
//  .flex-grid {
//    .table {
//      border-right: 1px solid #e6e6e6;
//    }
//  }
//}
.ratecards {
  .search-peek {
    padding: 0;
    .container-section {
      margin-left: 0;
      margin-right: 0;
    }
  }
}
.nopad {
  padding: 0 5px !important;
  font-size: 10px !important;
}
.pt-filter-modal {
  .table_label {
    td:nth-child(3n + 1) {
      vertical-align: middle !important;
    }
    td {
      vertical-align: top !important;
      .Select-control {
        font-size: 14px !important;
      }
      .rc-input-box {
        height: 34px;
        margin-top: 2px;
      }
    }
  }
}
.negotiation-view {
  .ratecard-list-item {
    &-left {
      max-width: 50%;
    }
    &-right {
      max-width: 600px;
    }
    .w-300 {
      width: 100%;
    }
  }
  header.alignclassalt {
    margin-left: 0px;
    margin-right: 0px;
  }
  .bring-forward header.alignclassalt {
    margin-left: -20px;
    margin-right: -20px;
  }
}
.pt-filter-modal .pt-range-filter__item .form-control {
  width: 130px;
}
.table.level-data tbody > tr > td.ticker-cell .fa.ticker {
  font-size: 14px;
}
.pt-card__list-group {
  padding: 10px;
}
.pt-worksheet__table-list {
  margin-top: 10px;
  margin-bottom: 10px;
}
.input-group .input-group-addon {
  pading: 5px;
}
.pt-card__list-group__item--no-border h4 {
  padding: 0 10px;
}
.ratecards.schedulesearch .ratecard-list-item-right {
  max-width: 45%;
}
.d {
  &-inline {
    display: inline-block;
  }
}
.v {
  &-middle {
    vertical-align: middle;
    margin: 0px;
  }
}
.p {
  &-t {
    &-24 {
      padding-top: 24px;
    }
  }
}
.ratecard-list-item-container.saved-searches-list {
  .ratecard-list-item-left {
    max-width: 45%;
  }
  .ratecard-list-item-right {
    max-width: 55%;
    small {
      font-size: 60%;
    }
  }
}

.search-list {
  margin: 0 -20px !important;
}
