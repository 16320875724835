@import "../base/colors";
@import "../utils/mixins";

$text-color: #666666;

.pt-base-text-input {
  @include pt-no-focus-outline;
  display: inline-block;
  vertical-align: middle;
  border: 1px solid $slate;
  border-radius: 3px;
  padding: 5px;
  color: $text-color;

  &::placeholder {
    color: $slate;
    font-weight: 300;
  }

  &--disabled,
  &:disabled {
    color: $slate;
    border-color: $gray-light;
    cursor: not-allowed !important;
    color: lighten($text-color, 20%);
  }

  &--invalid {
    border-color: #ff9f9f !important;
  }
}
