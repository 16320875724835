@import "../../../base/colors";

.pt-contractor-analysis-values {
  &__head,
  &__market-details-head {
    padding: 10px;
    border-bottom: 1px solid $gray-border;
    background-color: $gray-light;

    .fa-chevron-up,
    .fa-chevron-down {
      margin-right: 10px;
      color: $olive;
      font-size: 14px;
      cursor: pointer;

      &:hover {
        transform: scale(1.1);
      }
      &:active {
        transform: scale(0.9);
      }
    }

    .fa-map-marker {
      font-size: 15px;
      color: $olive;
    }
  }

  &__content {
    padding: 20px;
  }

  &__market-details {
    margin-top: 30px;
    border: 1px solid $gray-border;
    border-radius: 5px;

    & .alert {
      margin-bottom: 0;
    }

    & .pt-stats-table {
      border-bottom: 1px solid #e6e6e6;
    }

    & .pt-stats-table__titles > * {
      line-height: 76px;
    }

    &-content {
      padding: 0;
    }

    &-empty {
      padding: 15px;
    }
  }

  /* mofidiers */

  &--collapsed &__head {
    border-bottom: 0;
    background: none;
  }
}
