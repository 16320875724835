body[data-color="lime"] ::selection {
  background-color: #c2d646;
  color: #ffffff;
}
body[data-color="lime"] ::-moz-selection {
  background-color: #c2d646;
  color: #ffffff;
}
body[data-color="lime"],
body[data-color="lime"] #wrapper,
body[data-color="lime"] #sidebar {
  background-color: #4d4d4d;
}
body[data-color="lime"] .btn-primary {
  color: #ffffff;
  background-color: #c2d646;
  border-color: #c2d646;
}
body[data-color="lime"] .btn-primary:hover,
body[data-color="lime"] .btn-primary:focus,
body[data-color="lime"] .btn-primary:active,
body[data-color="lime"] .btn-primary.active,
.open .dropdown-togglebody[data-color="lime"] .btn-primary {
  color: #ffffff;
  background-color: #95ac2e;
  border-color: #95ac2e;
}
body[data-color="lime"] .btn-primary:active,
body[data-color="lime"] .btn-primary.active,
.open .dropdown-togglebody[data-color="lime"] .btn-primary {
  background-image: none;
}
body[data-color="lime"] .btn-primary.disabled,
body[data-color="lime"] .btn-primary[disabled],
fieldset[disabled] body[data-color="lime"] .btn-primary,
body[data-color="lime"] .btn-primary.disabled:hover,
body[data-color="lime"] .btn-primary[disabled]:hover,
fieldset[disabled] body[data-color="lime"] .btn-primary:hover,
body[data-color="lime"] .btn-primary.disabled:focus,
body[data-color="lime"] .btn-primary[disabled]:focus,
fieldset[disabled] body[data-color="lime"] .btn-primary:focus,
body[data-color="lime"] .btn-primary.disabled:active,
body[data-color="lime"] .btn-primary[disabled]:active,
fieldset[disabled] body[data-color="lime"] .btn-primary:active,
body[data-color="lime"] .btn-primary.disabled.active,
body[data-color="lime"] .btn-primary[disabled].active,
fieldset[disabled] body[data-color="lime"] .btn-primary.active {
  background-color: #c2d646;
  border-color: #c2d646;
}
body[data-color="lime"] .btn-primary .badge {
  color: #c2d646;
  background-color: #fff;
}
body[data-color="lime"] .btn-primary.btn-3d {
  border-bottom: 3px solid #b2c72c !important;
}
body[data-color="lime"] .btn-primary:hover.btn-3d,
body[data-color="lime"] .btn-primary:focus.btn-3d,
body[data-color="lime"] .btn-primary:active.btn-3d,
body[data-color="lime"] .btn-primary.active.btn-3d,
.open .dropdown-togglebody[data-color="lime"] .btn-primary.btn-3d {
  border-bottom-color: #97aa26 !important;
}
body[data-color="lime"] .btn-primary:active,
body[data-color="lime"] .btn-primary.active,
.open .dropdown-togglebody[data-color="lime"] .btn-primary {
  background-image: none;
}
body[data-color="lime"] .btn-primary:active.btn-3d,
body[data-color="lime"] .btn-primary.active.btn-3d,
.open .dropdown-togglebody[data-color="lime"] .btn-primary.btn-3d {
  border-bottom-width: 0 !important;
}
body[data-color="lime"] .btn-primary.disabled,
body[data-color="lime"] .btn-primary[disabled],
fieldset[disabled] body[data-color="lime"] .btn-primary,
body[data-color="lime"] .btn-primary.disabled:hover,
body[data-color="lime"] .btn-primary[disabled]:hover,
fieldset[disabled] body[data-color="lime"] .btn-primary:hover,
body[data-color="lime"] .btn-primary.disabled:focus,
body[data-color="lime"] .btn-primary[disabled]:focus,
fieldset[disabled] body[data-color="lime"] .btn-primary:focus,
body[data-color="lime"] .btn-primary.disabled:active,
body[data-color="lime"] .btn-primary[disabled]:active,
fieldset[disabled] body[data-color="lime"] .btn-primary:active,
body[data-color="lime"] .btn-primary.disabled.active,
body[data-color="lime"] .btn-primary[disabled].active,
fieldset[disabled] body[data-color="lime"] .btn-primary.active {
  background-color: #c2d646;
  border-color: #c2d646;
}
body[data-color="lime"] .btn-primary.btn-icon i,
body[data-color="lime"] .btn-primary.btn-icon-left i {
  background-color: #b2c72c;
}
body[data-color="lime"] .btn-primary .badge {
  color: #c2d646;
  background-color: #fff;
}
body[data-color="lime"] .btn-primary-alt {
  color: #c2d646;
  background-color: transparent;
  border: 1px solid #c2d646 !important;
}
body[data-color="lime"] .btn-primary-alt:hover,
body[data-color="lime"] .btn-primary-alt:focus,
body[data-color="lime"] .btn-primary-alt:active,
body[data-color="lime"] .btn-primary-alt.active,
.open .dropdown-togglebody[data-color="lime"] .btn-primary-alt {
  color: #859521;
  border-color: #859521;
}
body[data-color="lime"] .btn-primary-alt:active,
body[data-color="lime"] .btn-primary-alt.active,
.open .dropdown-togglebody[data-color="lime"] .btn-primary-alt {
  background-image: none;
}
body[data-color="lime"] .btn-primary-alt.disabled,
body[data-color="lime"] .btn-primary-alt[disabled],
fieldset[disabled] body[data-color="lime"] .btn-primary-alt,
body[data-color="lime"] .btn-primary-alt.disabled:hover,
body[data-color="lime"] .btn-primary-alt[disabled]:hover,
fieldset[disabled] body[data-color="lime"] .btn-primary-alt:hover,
body[data-color="lime"] .btn-primary-alt.disabled:focus,
body[data-color="lime"] .btn-primary-alt[disabled]:focus,
fieldset[disabled] body[data-color="lime"] .btn-primary-alt:focus,
body[data-color="lime"] .btn-primary-alt.disabled:active,
body[data-color="lime"] .btn-primary-alt[disabled]:active,
fieldset[disabled] body[data-color="lime"] .btn-primary-alt:active,
body[data-color="lime"] .btn-primary-alt.disabled.active,
body[data-color="lime"] .btn-primary-alt[disabled].active,
fieldset[disabled] body[data-color="lime"] .btn-primary-alt.active {
  background-color: transparent;
  border-color: #c2d646;
}
body[data-color="lime"] .progress-bar-primary {
  background-color: #c2d646;
}
.progress-striped body[data-color="lime"] .progress-bar-primary {
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
}

body[data-color="lime"] #usermenu li {
  border-left: 1px solid #555555;
}
body[data-color="lime"] #usermenu li.user-profile i {
  border-right: 1px solid #555555;
}
body[data-color="lime"] #usermenu li.user-profile a:hover i {
  border-right-color: #444444;
}
body[data-color="lime"] #usermenu li:first-child {
  border-left: 0;
}
body[data-color="lime"] #usermenu a {
  color: rgba(255, 255, 255, 0.55);
}
body[data-color="lime"] #usermenu a:hover {
  color: #ffffff;
  background-color: #333333;
}
body[data-color="lime"] #sidebar-menu {
  border-top: 1px solid #555555;
}
body[data-color="lime"] #sidebar-menu > .panel {
  border-bottom: 1px solid #555555;
}

body[data-color="lime"] #sidebar-menu > .panel.active > a,
body[data-color="lime"] #sidebar-menu > .panel:hover > a,
body[data-color="lime"] #sidebar-menu > .panel.expand > a,
body[data-color="lime"] #sidebar-menu > .panel > a.active,
body[data-color="lime"] #sidebar-menu > .panel > a:hover,
body[data-color="lime"] #sidebar-menu > .panel > a:not(.collapsed) {
  background-color: #333333;
}

body[data-color="lime"] #sidebar-menu > .panel:hover,
body[data-color="lime"] #sidebar-menu > .panel.expand {
  border-left-color: #333333;
}

body[data-color="lime"] #sidebar-menu > .panel.active {
  border-left-color: #c2d646;
}

body[data-color="lime"] .primary,
body[data-color="lime"] .label-primary,
body[data-color="lime"] .badge-primary,
body[data-color="lime"] label.einput input[type=radio]:checked + span:after,
body[data-color="lime"] label.einput-inline input[type=radio]:checked + span:after,
body[data-color="lime"] .daterangepicker .ranges li:hover,
body[data-color="lime"] .daterangepicker .ranges li.active,
body[data-color="lime"] .ms-selectable .ms-hover,
body[data-color="lime"] .ms-selection .ms-hover,
body[data-color="lime"] .has-switch span.switch-left,
body[data-color="lime"] .has-switch span.switch-primary,
/*body[data-color="lime"] #sidebar-menu > .panel > a.active,*/
body[data-color="lime"] #sidebar > ul > li.active,
body[data-color="lime"] #sidebar > ul ul li > a:hover:before,
body[data-color="lime"] #sidebar > ul ul li.active > a:before {
  background-color: #c2d646;
}

body[data-color="lime"] .input-group .input-group-addon.primary,
body[data-color="lime"] .form-control:focus,
body[data-color="lime"] label.einput input[type="checkbox"]:checked + span:before,
body[data-color="lime"] label.einput-inline input[type="checkbox"]:checked + span:before,
body[data-color="lime"] label.einput input[type="radio"]:checked + span:before,
body[data-color="lime"] label.einput-inline input[type="radio"]:checked + span:before,
body[data-color="lime"] .daterangepicker .ranges li:hover,
body[data-color="lime"] .daterangepicker .ranges li.active {
  border-color: #c2d646;
}
body[data-color="lime"] a:not(.btn),
body[data-color="lime"] .text-primary,
body[data-color="lime"] .input-group .input-group-addon.primary .arrow,
body[data-color="lime"] .input-group .input-group-addon.primary:last-child .arrow,
body[data-color="lime"] label.einput input[type="checkbox"]:checked + span:before,
body[data-color="lime"] label.einput-inline input[type="checkbox"]:checked + span:before,
body[data-color="lime"] #user-nav #menu-trigger:hover {
  color: #c2d646;
}
body[data-color="lime"] a:not(.btn):hover {
  color: #97aa26;
}
body[data-color="lime"] #header {
  background-color: #333333;
}
body[data-color="lime"] #header h1 {
  border-right: 1px dotted #262626;
}
body[data-color="lime"] #header h1 a {
  color: #b3b3b3;
}
body[data-color="lime"] #user-nav > ul {
  border-right: 1px dotted #262626;
}
body[data-color="lime"] #user-nav > ul > li > a,
body[data-color="lime"] #user-nav > ul > li > a > i {
  color: #b3b3b3;
}
body[data-color="lime"] #user-nav > ul > li.attention > a > i:after {
  border: 2px solid #333333;
}
body[data-color="lime"] #user-nav > ul > li:hover,
body[data-color="lime"] #user-nav > ul li.dropdown.open {
  background-color: #262626;
}
body[data-color="lime"] #user-nav > ul > li:hover.attention > a > i:after,
body[data-color="lime"] #user-nav > ul li.dropdown.open.attention > a > i:after {
  border-color: #262626;
}
body[data-color="lime"] #user-nav .user-menu > a {
  color: #b3b3b3;
}
body[data-color="lime"] #user-nav .user-menu:hover > a,
body[data-color="lime"] #user-nav .user-menu.open > a {
  background-color: #262626;
}
body[data-color="lime"] #user-nav > ul > li:hover > a,
body[data-color="lime"] #user-nav > ul > li:hover > a > i,
body[data-color="lime"] #user-nav > ul > li.dropdown.open > a,
body[data-color="lime"] #user-nav > ul > li.dropdown.open > a > i,
body[data-color="lime"] #user-nav .user-menu:hover > a,
body[data-color="lime"] #user-nav .user-menu.open > a {
  color: #c2d646;
}
body[data-color="lime"] #sidebar-menu > .panel > a.collapsed {
  color: rgba(255, 255, 255, 0.55);
}
body[data-color="lime"] #sidebar #search input[type="text"],
body[data-color="lime"] #sidebar #search input[type="text"]:focus,
body[data-color="lime"] #sidebar > ul > li > a,
body[data-color="lime"][data-sidebar-icon="true"] #sidebar #search:before,
body[data-color="lime"] #sidebar #search button i,
body[data-color="lime"] #sidebar #search button:hover i,
body[data-color="lime"] #sidebar #search button:active i,
body[data-color="lime"] #sidebar-menu > .panel > a:hover,
body[data-color="lime"] #sidebar-menu > .panel > a.active {
  color: #ffffff;
}
body[data-color="lime"] #sidebar #search input[type="text"],
body[data-color="lime"][data-sidebar-icon="true"] #sidebar #search:before {
  color: rgba(255, 255, 255, 0.55);
}
body[data-color="lime"] #sidebar #search button i {
  color: rgba(255, 255, 255, 0.35);
}
body[data-color="lime"] #sidebar-menu > .panel > .submenu > .inner > a {
  color: #c2d646;
}
body[data-color="lime"] #sidebar-menu > .panel > .submenu > .inner > a.active {
  color: #c2d646;
  border-left: 3px solid #3f3f3f;
}
body[data-color="lime"] #sidebar-menu > .panel.without-submenu > a.active:before,
body[data-color="lime"] #sidebar-menu > .panel.with-submenu.collapsed > a.active:before,
body[data-color="lime"] #sidebar-menu > .panel > .submenu > .inner > a.active:before {
  border-left-color: #c2d646;
}
body[data-color="lime"] #sidebar-menu > .panel > .submenu > .inner > a:hover,
body[data-color="lime"] #sidebar-menu > .panel > .submenu > .inner > a.active {
  background-color: #3f3f3f;
}
body[data-color="lime"] #sidebar #search {
  border-bottom-color: #ffffff;
  border-bottom-color: rgba(255, 255, 255, 0.15);
}
body[data-color="lime"] #sidebar-menu > .panel > .submenu {
  background-color: #333333;
}
body[data-color="lime"] .select2-results .select2-highlighted {
  background-color: #c2d646;
}
@media (min-width: 992px) {
  body[data-color="lime"].sidebar-icon #sidebar-menu > .panel > a > span {
    background-color: #333333 !important;
  }
  body[data-color="lime"].sidebar-icon #sidebar-menu > .panel > a.active:before {
    border-left-color: #c2d646;
  }
  body[data-color="lime"].sidebar-icon #sidebar-menu > .panel.with-submenu > a > span {
    border-bottom: 1px solid #3e3e3e !important;
  }
  body[data-color="lime"].sidebar-icon
    #sidebar-menu
    > .panel.with-submenu
    > .submenu
    > .inner {
    -webkit-transition: border-left 0.3s;
    -o-transition: border-left 0.3s;
    transition: border-left 0.3s;
    border-left: 3px solid #333333;
  }
  body[data-color="lime"].sidebar-icon
    #sidebar-menu
    > .panel.active.with-submenu
    > .submenu
    > .inner {
    border-left: 3px solid #c2d646;
  }
}
body[data-color="lime"] .datepicker td.active,
body[data-color="lime"] .daterangepicker td.active,
body[data-color="lime"] .datetimepicker td.active,
body[data-color="lime"] .datepicker span.year.active,
body[data-color="lime"] .daterangepicker span.year.active,
body[data-color="lime"] .datetimepicker span.year.active,
body[data-color="lime"] .datepicker span.month.active,
body[data-color="lime"] .daterangepicker span.month.active,
body[data-color="lime"] .datetimepicker span.month.active,
body[data-color="lime"] .datepicker span.month.active:hover,
body[data-color="lime"] .daterangepicker span.month.active:hover,
body[data-color="lime"] .datetimepicker span.month.active:hover,
body[data-color="lime"] .datepicker span.hour.active,
body[data-color="lime"] .daterangepicker span.hour.active,
body[data-color="lime"] .datetimepicker span.hour.active,
body[data-color="lime"] .datepicker span.minute.active,
body[data-color="lime"] .daterangepicker span.minute.active,
body[data-color="lime"] .datetimepicker span.minute.active {
  background-color: #c2d646 !important;
  color: #ffffff !important;
}
body[data-color="lime"] .dataTables_paginate .ui-button,
body[data-color="lime"] .pagination.alternate li a {
  border-color: #d6d6d6;
  /* for IE < 9 */
  background-color: #f7f7f7;
  background-image: -webkit-linear-gradient(top, #f7f7f7 0%, #efefef 100%);
  background-image: linear-gradient(to bottom, #f7f7f7 0%, #efefef 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff7f7f7', endColorstr='#ffefefef', GradientType=0);
}
body[data-color="lime"] .dataTables_paginate .ui-button:hover,
body[data-color="lime"] .pagination.alternate li a:hover {
  background-color: #efefef;
  background-image: -webkit-linear-gradient(top, #efefef 0%, #d6d6d6 100%);
  background-image: linear-gradient(to bottom, #efefef 0%, #d6d6d6 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffefefef', endColorstr='#ffd6d6d6', GradientType=0);
}
body[data-color="lime"] .dataTables_paginate .ui-state-disabled:hover,
body[data-color="lime"] .fc-state-disabled:hover,
body[data-color="lime"] .pagination.alternate li.disabled a:hover {
  background-color: #f7f7f7;
  background-image: -webkit-linear-gradient(top, #f7f7f7 0%, #efefef 100%);
  background-image: linear-gradient(to bottom, #f7f7f7 0%, #efefef 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff7f7f7', endColorstr='#ffefefef', GradientType=0);
  border-color: #d6d6d6;
}
body[data-color="lime"] .dataTables_paginate span .ui-state-disabled,
body[data-color="lime"] .pagination.alternate li.active a {
  background-color: #707070 !important;
  background-image: -webkit-linear-gradient(top, #707070 0%, #494949 100%) !important;
  background-image: linear-gradient(to bottom, #707070 0%, #494949 100%) !important;
  background-repeat: repeat-x !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff707070', endColorstr='#ff494949', GradientType=0) !important;
  border-color: #707070 !important;
}
body[data-color="lime"] .stat-boxes li,
body[data-color="lime"] .quick-actions li,
body[data-color="lime"] .quick-actions-horizontal li {
  background-color: #f7f7f7;
  background-image: -webkit-linear-gradient(top, #f7f7f7 0%, #efefef 100%);
  background-image: linear-gradient(to bottom, #f7f7f7 0%, #efefef 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff7f7f7', endColorstr='#ffefefef', GradientType=0);
}
body[data-color="lime"] .stat-boxes li:hover,
body[data-color="lime"] .quick-actions li:hover,
body[data-color="lime"] .quick-actions-horizontal li:hover {
  background-color: #f9f9f9;
  background-image: -webkit-linear-gradient(top, #f9f9f9 0%, #f7f7f7 100%);
  background-image: linear-gradient(to bottom, #f9f9f9 0%, #f7f7f7 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff9f9f9', endColorstr='#fff7f7f7', GradientType=0);
}
body[data-color="lime"] .nicescroll-rails {
  background-color: #4d4d4d;
}
body[data-color="lime"] .nicescroll-rails > div {
  background-color: #c2d646 !important;
}
body[data-color="lime"] #header {
  background-image: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-bottom: 0;
}
body[data-color="lime"] #content-header {
  background-image: none;
}
body[data-color="lime"] .stat-boxes li,
body[data-color="lime"] .quick-actions li,
body[data-color="lime"] .quick-actions-horizontal li,
body[data-color="lime"] .stat-boxes li:hover,
body[data-color="lime"] .quick-actions li:hover,
body[data-color="lime"] .quick-actions-horizontal li:hover {
  background-image: none;
}

body[data-color="lime"] #sidebar-icon {
  border-color: #555555;
  color: rgba(255, 255, 255, 0.55);
}
body[data-color="lime"] #sidebar-icon:hover {
  color: #ffffff;
  background-color: #333333;
}
body[data-color="lime"] #preloader {
  background-color: #4d4d4d;
  color: #ffffff !important;
}
body[data-color="lime"][data-header-light="true"] #header {
  background-color: #fff;
  border-bottom: 1px solid #e6e6e6;
}
body[data-color="lime"][data-header-light="true"] #header > h1,
body[data-color="lime"][data-header-light="true"] #user-nav > ul {
  border-right-color: #dddddd;
}
body[data-color="lime"][data-header-light="true"] #header > h1 > a,
body[data-color="lime"][data-header-light="true"] #user-nav > ul > li > a,
body[data-color="lime"][data-header-light="true"] #user-nav > ul > li > a > i,
body[data-color="lime"][data-header-light="true"] #user-nav .user-menu > a,
body[data-color="lime"][data-header-light="true"] #user-nav #menu-trigger {
  color: #4d4d4d;
}
body[data-color="lime"][data-header-light="true"] #user-nav > ul > li:hover,
body[data-color="lime"][data-header-light="true"] #user-nav > ul > li.dropdown.open,
body[data-color="lime"][data-header-light="true"] #user-nav .user-menu > a:hover {
  background-color: transparent;
}
body[data-color="lime"][data-header-light="true"] #user-nav > ul > li.open > a,
body[data-color="lime"][data-header-light="true"] #user-nav > ul > li:hover > a,
body[data-color="lime"][data-header-light="true"] #user-nav > ul > li.open > a > i,
body[data-color="lime"][data-header-light="true"] #user-nav > ul > li:hover > a > i,
body[data-color="lime"][data-header-light="true"] #user-nav .user-menu > a:hover,
body[data-color="lime"][data-header-light="true"] #user-nav #menu-trigger:hover {
  color: #c2d646;
}
body[data-color="lime"][data-header-light="true"]
  #user-nav
  > ul
  > li.attention
  > a
  > i:after,
body[data-color="lime"][data-header-light="true"]
  #user-nav
  > ul
  > li.open.attention
  > a
  > i:after {
  border-color: #ffffff;
}
body[data-color="lime"] .dataTables_filter input[type="text"]:focus {
  border-color: #c2d646;
}
body[data-color="lime"] .dataTables_paginate span .ui-state-disabled {
  border-top-color: #c2d646 !important;
  border-bottom-color: #c2d646 !important;
  border-right-color: #c2d646 !important;
  background-color: #c2d646 !important;
  background-image: none !important;
}
body[data-color="lime"] .select2-results .select2-highlighted {
  background-color: #c2d646;
}
@media (max-width: 767px) {
  body[data-color="lime"] #user-nav > ul {
    border-left: 0;
  }
  body[data-color="lime"] #user-nav > ul > li {
    border-right: 0;
  }
  body[data-color="lime"][data-header-light="true"] #user-nav .dropdown-menu {
    border-top: 1px solid #eeeeee;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  body[data-color="lime"] #sidebar #search:before {
    color: #ffffff;
    color: rgba(255, 255, 255, 0.55);
  }
  body[data-color="lime"] #sidebar #search .search-inner {
    background-color: #4d4d4d;
  }
  body[data-color="lime"] #sidebar > ul > li > a > span {
    background-color: #c2d646 !important;
  }
}
