@import "variables", "mixins", "ratecards", "client_job_library", "batch_search",
  "./components/multi_downshift", "./components/infinite_scroll_select",
  "./components/wizard", "./components/vertical_wizard", "../../base/variables";

.pt-ui {
  display: flex;
  flex-wrap: nowrap;
  color: $rc-gray3;
  background-color: #f4f4f7;
  font-size: 16px;
  font-family: "Source Sans Pro", sans-serif;
  padding: 0 15px ($edit-bar-height + 15px) 15px;

  /* Reset */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  small,
  span,
  header,
  footer,
  section {
    margin: 0;
    padding: 0;
    line-height: normal;
  }

  h1,
  h2,
  h3 {
    //font-family: "Source Sans Pro", sans-serif;
    font-weight: normal;
  }

  p {
    margin: 0 0 15px 0;
  }

  label {
    font-size: 18px;
    font-weight: normal;
    line-height: normal;
  }

  .pt-list-select-all-section {
    //animation: 250ms ease-out 0s 1 appearFromTop;
    border-bottom: 1px solid $rc-element-border-color;
    border-top: 1px solid $rc-element-border-color;
    text-align: center;
    background-color: lighten($rc-yellow, 38%);
    padding: 5px 20px;
    font-size: 16px;
    overflow: hidden;
  }

  .pt-all-items {
    margin-right: 10px;
  }

  .pt-all-items--active {
    font-weight: bold;
  }

  .pt-view-loading {
    min-height: calc(100vh - (#{$navbar-height} * 2) - #{$footer-height} - 16px);
    //height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &-table {
    //border-radius: 5px;
    //border: 1px solid $rc-gray2;
    //width: calc(100vw - 35px);
    //width: 100%;
    margin: 0 -20px;
    overflow: hidden;

    &-empty {
      display: flex;
      min-height: 30vh;
      text-align: center;
      justify-content: center;
      padding-top: 15vh;
      color: $rc-gray1;
      font-size: 28px;
      user-select: none;
    }

    &-fill-container {
      width: 100%;
      height: 100%;
    }
  }

  .pt-table-header {
    display: flex;
    justify-content: space-between;
    //border-bottom: 1px solid rgb(230, 230, 230);
    //font-weight: 100;
    font-size: 0.85em;
    padding: 15px 20px;
    margin: 0 -20px;
    border-radius: 4px 5px 0 0;
    background-color: white;
    transition: all 0.2ms ease;

    &.active {
      background-color: #fbf6dc;
      transition: all 0.2ms ease;
    }

    a,
    .pt-link {
      color: $rc-green4 !important;

      &:hover {
        text-decoration: underline;
        color: $rc-green5 !important;
      }
    }
  }

  .pt-list-item-label {
    font-size: 24px;
    white-space: pre;
    color: $rc-green4;
    transition: all 200ms;
    padding: 2px 0;
    margin-bottom: 2px;
    line-height: 1.2;
  }

  .tag {
    cursor: default;
    background-color: lighten($rc-yellow, 28%);
    color: darken($rc-yellow2, 14%);
    border-radius: 100px;
    padding: 2px 10px;
    margin-bottom: auto;
    &:not(:first-child) {
      margin-left: 10px;
    }

    &.tag-red {
      background-color: lighten($rc-danger-color, 32%);
      color: darken($rc-danger-color, 14%);
    }

    &.tag-green {
      background-color: lighten($rc-green7, 34%);
      color: darken($rc-green7, 14%);
    }
  }

  .pt-list-item-container {
    border-bottom: 1px solid $rc-element-border-color;

    &:first-child {
      border-top: 1px solid $rc-element-border-color;
    }
  }

  .pt-list-item {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    cursor: pointer;
    margin: 0;
    padding: 20px;
    font-size: 18px;
    //box-shadow: inset 6px 0 $rc-green4;
    transition: all 200ms;

    &:hover,
    &.selected {
      background-color: $rc-green8;
      //box-shadow: inset 6px 0 $rc-green5;
      .pt-list-item-label,
      .pt-ico-moon {
        color: $rc-green5;
      }
    }
  }

  .pt-list-item-left {
    display: flex;
    flex: 1;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: flex-start;
  }

  .pt-list-item-right {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .pt-list-item-expand-state {
    font-size: 24px;
    display: flex;
    align-items: center;
    padding-right: 10px;

    .pt-ico-moon {
      transition: transform 150ms ease-in, color 200ms;
      color: $rc-green4;
    }

    .expanded {
      transform: rotate(90deg);
    }
  }

  .pt-list-item-meta {
    flex: 1;
  }

  .pt-list-item-info {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    text-align: right;
    border-radius: 5px;
    //background-color: $rc-gray2;
    margin: 0 5px;
    padding: 0 15px;
    min-height: 40px;

    font-size: 20px;

    &.text-center {
      text-align: center;
    }

    header {
      color: $rc-gray5;
      background-color: inherit;
      font-size: 14px;
      //line-height: 14px;
      //font-weight: 200;
    }

    span {
      //line-height: 20px;
    }
  }

  .pt-list-item-peek {
    max-height: 0;
    overflow: hidden;
    background-color: #f9f9f9;
    transition: all 250ms ease-in-out;

    &-body {
      padding: 20px;
    }

    &-level-name {
      font-weight: 600;
      font-size: 20px;
      color: $rc-gray3;
      margin-right: 6px;
    }

    &-levels {
      padding: 10px;
    }

    &-level-card {
      flex: 1 0 370px;
      max-width: 370px;
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
      margin: 10px;
      position: relative;
      overflow: hidden;

      &::before {
        content: " ";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 6px;
      }

      &.junior::before {
        background-color: lighten($junior-color, 8.4%);
        //border: 1px solid $junior-color;
      }

      &.mid::before {
        background-color: lighten($mid-color, 8.4%);
        //border: 1px solid $mid-color;
      }

      &.senior::before {
        background-color: lighten($senior-color, 8.4%);
        //border: 1px solid $senior-color;
      }

      &.leader:before {
        background-color: lighten($lead-color, 8.4%);
        //border: 1px solid $lead-color;
      }

      &.guru::before {
        background-color: lighten($guru-color, 8.4%);
        //border: 1px solid $guru-color;
      }

      .table.level-data {
        margin: -10px 0 0 0;
      }
    }

    &-expand {
      max-height: 1020px;
    }

    &-item {
      padding: 5px;
      flex-grow: 1;
      flex-shrink: 0;
      flex-basis: 200px;
    }

    &-options {
      flex-grow: 0;
      flex-shrink: 1;
      flex-basis: 200px;
    }

    &-header {
      font-size: 36px;
      font-weight: 900;
      color: $rc-gray2;
    }

    &-section {
      font-size: 20px;

      &-header {
        font-size: 14px;
        font-weight: 100;
        color: $rc-gray4;
      }
    }
  }

  .pt-job-description {
    pre {
      font-family: inherit;
      font-size: 15px;
      font-weight: normal;
      line-height: 1.56;
      color: inherit;
      border: none;
      margin: 0;
      padding: 0;
      background-color: transparent;
    }
  }

  .rc-input-box {
    @include pt-input;

    &:disabled {
      background-color: $rc-element-border-color;
      opacity: 0.6;
    }
  }

  .has-error {
    border: 2px solid $rc-danger-color;
  }

  .has-error-info {
    border: 2px solid $rc-danger-color;
    padding-right: 40px;
  }

  .error-info-icon {
    position: absolute;
    right: 6px;
    top: 0;
    transform: translateY(50%);
  }

  .Select-control {
    @include applyVPadding($selectVPadding);
    border-radius: 3px;
    //  box-shadow: 1px 1px 3px $rc-gray1;
    padding: 10px;
    margin-top: 2px;
    margin-bottom: 10px;
    font-size: 16px !important;
    font-weight: bold;

    .Select-value {
      @include applyVPadding($selectVPadding);
    }

    .Select-arrow {
      border-color: #999 transparent transparent;
      top: 50%;
      margin-top: -($selectArrowWidth * 0.5);
    }

    .Select-item {
      .Select-item-icon {
        padding: 2px 5px 4px !important;
      }

      .Select-item-label {
        padding: 3px 5px !important;
      }
    }
  }

  .Select-menu-outer {
    // border-radius: 0 0 3px 3px;
    // border: 2px solid $olive;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    background-color: #ffffff;
    border: 1px solid #cccccc;
    border-top-color: #e6e6e6;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
    box-sizing: border-box;
    margin-top: -1px;
    max-height: 200px;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 1000;

    .Select-menu {
      .Select-option {
        font-size: 14px !important;
        font-weight: normal;
        @include applyVPadding($selectVPadding);
        font-family: $ff-source-regular;
        color: #666666;
        line-height: 19px;

        &:last-child {
          border-radius: 0;
        }

        &.is-focused {
          //  background-color: $gray-light;
          color: #333333;
          background-color: #f2f9fc;
        }
      }
    }

    .Select-noresults {
      font-size: 14px;
      font-weight: 400 !important;
    }
  }
}

ul.dropdown-menu[aria-labelledby="dropdown-custom-menu"]
  > li[role="presentation"]
  > a[role="menuitem"] {
  padding: 6px 30px 6px 10px;
}

//-----------------------------------------
//  Global styles
//-----------------------------------------

.job-category-select {
  min-width: 150px;
  max-width: 300px;
  font-family: $ff-source-regular;

  // react-select custom styles

  .Select-control {
    @include applyVPadding($selectVPadding);
    border-radius: 3px;
    box-shadow: 1px 1px 3px $rc-gray1;
    padding: 10px 10px;
    margin-top: 2px;
    margin-bottom: 10px;
    font-size: 16px;

    .Select-value {
      @include applyVPadding($selectVPadding);
    }

    .Select-arrow {
      border-color: $olive transparent transparent;
      top: 50%;
      margin-top: -($selectArrowWidth * 0.5);
    }
  }

  &.is-focused:not(.is-open) > .Select-control {
    border-color: #a2b624;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1), 0 0 5px -1px rgba(151, 169, 0, 0.5);
  }

  &.is-open > .Select-control {
    background-color: $olive;
    border-color: $olive transparent transparent;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    & > .Select-value {
      font-family: $ff-source-regular;
      font-size: 16px;
      line-height: 20px;
      color: $white;
    }
    & > .Select-arrow {
      border-color: transparent transparent $white;
    }
  }

  .Select-menu-outer {
    border-radius: 0 0 3px 3px;
    border: 2px solid $olive;

    .Select-menu {
      .Select-option {
        @include applyVPadding($selectVPadding);
        font-family: $ff-source-regular;
        font-size: 16px;
        color: #212121;
        line-height: 19px;

        &:last-child {
          border-radius: 0;
        }

        &.is-focused {
          background-color: $gray-light;
        }
      }
    }
  }

  .no-transition {
    transition: none !important;
  }
}

.pt-range-filter {
  margin-bottom: 10px;
}

.pt-filter-modal {
  position: fixed;
  z-index: 1040;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;

  &-backdrop {
    position: fixed;
    z-index: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }

  &-inner {
    position: absolute;
    max-width: 94vw;
    max-height: 94vh;
    display: flex;
    flex-direction: column;
    top: 10%;
    left: 50%;
    transform: translate(-50%, -20%);
    background-color: white;
    border-radius: 4px;
    margin-top: 20px;
    padding: 20px;
    outline: none;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.1);

    .pt-modal-body {
      padding: 10px 0;
    }
  }

  .btn-lg {
    min-width: 120px;
  }
}

.pt-filter-name {
  margin-top: 0;
}

.pt-filter {
  &-text {
    padding: 15px 0;

    &__input {
      width: 100%;
      @include pt-input;
    }
  }
}

.pt-filter-items {
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 3px;
  border: 1px solid $rc-gray1;
  margin-bottom: 10px;
  max-height: 40vh;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

.pt-filter-items__item {
  margin: 0;

  &-location {
    display: flex;
    justify-content: space-between;
    user-select: none;
  }
}

.pt-filter-items__item-label {
  width: 100%;
  padding-left: 25px !important;
  input[type="checkbox"] {
    margin-left: -25px;
    transform: translateY(28%);
  }
  input[type="radio"] {
    margin-left: -24px;
    transform: translateY(24%);
  }
}

.pt-filter-actions {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  justify-content: flex-end;
  color: $rc-white1;
}

.pt-filter-action {
  margin: 0 2px !important;
}

.action-bar {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  flex: 2;
}

//-----------------------------------------
//  Sort Controls
//-----------------------------------------

.sort-option input[type="checkbox"] {
  visibility: hidden;
}

.sort-option {
  width: 36px;
  height: 36px;
  background: #fff;

  margin: 20px 0;
  position: relative;
  border: 1px $rc-gray1 solid;
}

.sort-option label {
  cursor: pointer;
  position: absolute;
  padding-top: 6px;
  padding-left: 42px;
  max-width: inherit;
  font-weight: normal;
  width: 300px;
  height: 26px;
  left: -2px;
  top: -2px;
}

.sort-option i {
  position: absolute;
  top: 0;
  left: 0;
  margin: 8px;
}

.sort-option label:before {
  content: "";
  position: absolute;
  width: 34px;
  height: 34px;
  top: 2px;
  left: 2px;
}

.sort-option label:hover::before {
  background: #eee;
}

.sort-option input[type="checkbox"]:checked + label:before {
  background: $rc-gray2;
}

//-----------------------------------------
//  General
//-----------------------------------------

.panel-results {
  & > div.panel-heading {
    background-color: #f5f5f5 !important;
  }
}

.panel-details {
  border: hidden;
  & > div.panel-heading {
    background-color: #ffffff !important;
  }
}

.panel-peek {
  border: hidden;
  background-color: transparent !important;

  & > div.panel-heading,
  & > div.panel-body {
    background-color: transparent !important;
  }
}

.text-thin {
  font-family: inherit !important;
  font-weight: 300 !important;
}

.text-x-thin {
  font-family: inherit !important;
  font-weight: 200 !important;
}

.text-medium {
  font-size: 18px !important;
}

.text-small {
  font-size: 16px !important;
}

.text-x-small {
  font-size: 14px !important;
}

.text-xx-small {
  font-size: 13px !important;
}

.text-accent {
  color: $rc-green5 !important;
}
.text-muted {
  color: $rc-gray6 !important;
}
.text-danger {
  color: $rc-danger-color !important;
}
.text-green {
  color: $rc-green4 !important;
}
.text-yellow {
  color: $rc-yellow !important;
}

.full-width {
  width: 100%;
}

.no-padding {
  padding: 0 !important;

  &-top {
    padding-top: 0 !important;
  }
}

.no-margin {
  margin: 0 !important;
}

.no-border {
  border: none !important;
}

.no-wrap {
  white-space: nowrap !important;
}

.no-background {
  background-color: transparent !important;
}

div.pull-left.block {
  margin-right: 15px;
}

.results-col {
  padding-left: 0;

  &-last {
    padding: 0;
  }

  & > header,
  &-last > header {
    margin: 10px 0 !important;
    font-size: 18px !important;
  }
}

.footer-data-header {
  margin-bottom: 10px !important;
}

.section-detail {
  margin-bottom: 20px !important;

  & > header {
    margin-bottom: 2px;
    color: $rc-gray5;
  }
}

.text-wrap {
  white-space: pre-line; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: normal; /* Internet Explorer 5.5+ */
  word-break: keep-all;
}

.section-header {
  border-bottom: 1px $table-border-color solid;
  padding: 10px 0 !important;
  margin: 30px 0 !important;
  font-family: inherit;
}

.chart-container {
  position: relative;
  overflow: hidden;
  border-radius: 5px;
  //min-width: 400px;
  //min-height: 400px;
}

.pay-rate-vs-bill-rate-chart {
  height: 446px;
}

.punch-out-vs-market-chart {
  height: 240px;
  border-top: 1px solid $rc-gray2;
  border-radius: 0;
}

.small-chart-container {
  height: 30px;
  width: 120px;
}

.text-center-middle {
  position: inherit;
  float: left;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.alert.no-items {
  padding-top: 40px;
  padding-bottom: 40px;
  margin: 0;
  border: 2px dashed #ccc;
  background-color: #fafafa;
  text-align: center;
  color: #999;

  a.btn-lg {
    margin-top: 10px;
  }

  p {
    font-weight: 300;
  }
}

.show-overflow {
  overflow: visible !important;
}

//-----------------------------------------
//  Collapsible Panel
//-----------------------------------------

.collapse-toggle-button {
  cursor: pointer;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 60px;
  border-radius: 0 0 5px 5px;

  & > a {
    position: inherit;
    width: 100%;
    top: 0;
    bottom: 0;
    line-height: 60px;
    padding: 0 15px;
    border-radius: 0 0 5px 5px;

    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,0.8+5,1+50 */
    background: -moz-linear-gradient(
      top,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.8) 10%,
      rgba(255, 255, 255, 1) 50%,
      rgba(255, 255, 255, 1) 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      top,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.8) 10%,
      rgba(255, 255, 255, 1) 50%,
      rgba(255, 255, 255, 1) 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.8) 10%,
      rgba(255, 255, 255, 1) 50%,
      rgba(255, 255, 255, 1) 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00ffffff", endColorstr="#ffffff", GradientType=0); /* IE6-9 */

    -webkit-transition: all 0.25s ease;
    transition: all 0.25s ease;

    &.collapsed:hover {
      top: 10px;
    }

    &.expanded:hover {
      top: -30px;
    }
  }
}

.panel-body {
  &.collapsed {
    height: auto;
    //max-height: 110px;
    overflow: hidden !important;

    -webkit-transition: all 0.45s ease;
    transition: all 0.45s ease;
  }

  &.expanded {
    max-height: 1000px !important;
    overflow: scroll !important;

    -webkit-transition: all 0.45s ease;
    transition: all 0.45s ease;
  }

  & > .collapsible-content {
    padding-bottom: 50px;
  }
}

//-----------------------------------------
//  Level Card
//-----------------------------------------

.level-icon {
  color: white;
  width: 50px;
  height: 50px;

  & > header {
    font-size: 20px;
    font-weight: bold;
    opacity: 0.85;
    position: relative;
    float: left;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &.junior {
    background-color: $junior-color;
  }

  &.mid {
    background-color: $mid-color;
  }

  &.senior {
    background-color: $senior-color;
  }

  &.leader {
    background-color: $lead-color;
  }

  &.guru {
    background-color: $guru-color;
  }
}

.title-info {
  color: $rc-gray5;
  font-weight: normal;
  font-size: 14px;
  font-style: italic;
  line-height: 28px;
}

.sample-set-info {
  color: $rc-gray5;
  font-weight: normal;
  font-size: 14px;
  line-height: 28px;
}

.panel {
  &.level-card {
    flex: 0 1 auto;
    margin: 10px;
    & > .panel-heading {
      .title-info {
        color: $rc-gray5;
        font-weight: normal;
        font-size: 14px;
        font-style: italic;
        line-height: 28px;
      }
    }
    & > .panel-body {
      padding-bottom: 0;
    }
  }
}

.result-cards-container {
  display: flex;
  flex-flow: row wrap;
  margin: 0 -20px;
  padding: 10px;
}

.level-card-job-label {
  max-width: 300px;
}

.table.level-data {
  thead > tr > th {
    text-align: left;
    background-color: inherit;
    font-weight: normal;
    padding: 10px 5px;

    &.text-right {
      text-align: right;
    }
  }

  tbody > tr:last-child > td {
    border-bottom: 0;
  }

  tbody > tr > td {
    font-size: 14px;
    padding: 8px;

    border-top: 0;
    border-bottom: 1px solid $table-border-color;
    white-space: nowrap;

    &.ticker-cell {
      width: 20px;
      .fa.ticker {
        font-size: 18px;
      }
    }

    &.max-value {
      width: 148px;
      text-align: center;
      vertical-align: middle;
      border: 0;

      div.max-value-wrapper {
        min-height: 69px;

        & > header {
          color: $rc-gray3;
          h4 {
            font-size: 18px;
            margin-bottom: 2px;
          }

          h5 {
            font-size: 16px;
          }
        }

        & > div.caption {
          font-size: 14px;
          margin-top: 10px;
        }
      }
    }
  }

  .sub-table {
    background-color: transparent;
  }
}

span,
svg {
  &.ticker {
    -webkit-transform: scaleX(0.8) scaleY(1.25);
    -moz-transform: scaleX(0.8) scaleY(1.25);
    -ms-transform: scaleX(0.8) scaleY(1.25);
    -o-transform: scaleX(0.8) scaleY(1.25);
    transform: scaleX(0.8) scaleY(1.25);

    &.no-change {
      transform: scaleX(0.6) scaleY(1);
    }
  }
}

.positive-change {
  color: $positive-change-color;
  //opacity: 1;
}

.negative-change {
  color: $negative-change-color;
  //opacity: 1;
}

.no-change {
  color: $rc-gray1;
}

.text-right {
  text-align: right;
}

div.row {
  &.markup-info {
    background-color: #f5f5f5;
    //max-height: 0;
    //overflow: hidden;
  }

  &.bill-info {
    border-radius: 0 0 3px 3px;

    div > .table.level-data {
      thead > tr > th,
      tbody > tr > td {
        color: #ffffff;
        opacity: 0.76;
        border-color: rgba(255, 255, 255, 0.1);
      }

      tbody > tr > td.max-value {
        border-bottom: inherit;
        opacity: 0.85;
        header {
          color: #ffffff;
          h4 {
            margin-bottom: 2px;
          }
        }
        div.caption {
          opacity: 0.76;
        }
      }

      //tbody > tr:last-child > td {
      //  border: inherit;
      //}
      //
      //tbody > tr:first-child > td {
      //  border: inherit;
      //}
    }

    &.junior {
      background-color: $junior-color;
      .positive-change {
        color: $junior-positive-color;
      }
      .negative-change {
        color: $junior-negative-color;
      }
    }

    &.mid {
      background-color: $mid-color;
      .positive-change {
        color: $mid-positive-color;
      }
      .negative-change {
        color: $mid-negative-color;
      }
    }

    &.senior {
      background-color: $senior-color;
      .positive-change {
        color: $senior-positive-color;
      }
      .negative-change {
        color: $senior-negative-color;
      }
    }

    &.leader {
      background-color: $lead-color;
      .positive-change {
        color: $lead-positive-color;
      }
      .negative-change {
        color: $lead-negative-color;
      }
    }

    &.guru {
      background-color: $guru-color;
      .positive-change {
        color: $guru-positive-color;
      }
      .negative-change {
        color: $guru-negative-color;
      }
    }
  }
}

.level-card-container {
  padding: 0 15px;
  width: 100%;
  min-width: 390px;

  @media (min-width: 480px) {
    max-width: 480px;
  }

  @media (min-width: 740px) {
    max-width: inherit;
    width: 50%;
  }

  @media (min-width: 1090px) {
    max-width: inherit;
    width: 33.33%;
  }

  @media (min-width: 1450px) {
    max-width: inherit;
    width: 25%;
  }

  @media (min-width: 1760px) {
    max-width: inherit;
    width: 20%;
  }

  @media (min-width: 1980px) {
    max-width: 480px;
  }

  .level-card-job-label {
    font-size: 16px;
    margin-bottom: 4px;
    max-width: 300px;
  }

  .level-card-location {
    font-size: 16px;
  }
}

//-----------------------------------------
//  Buy Rate Card
//-----------------------------------------

.hidden-ml {
  @media (min-width: 1200px) {
    display: none !important;
  }
  @media (min-width: 1630px) {
    display: block !important;
  }
}

.visible-ml {
  display: none !important;

  @media (min-width: 1200px) {
    display: block !important;
  }
  @media (min-width: 1630px) {
    display: none !important;
  }
}

.buy-rate-card-container {
  @media (min-width: 1200px) {
    width: 100%;
  }
  @media (min-width: 1630px) {
    width: 50%;
  }
}

.table.buy-rate-level-data {
  min-height: 240px;

  thead > tr > th {
    text-align: left;
    background-color: inherit;
    font-weight: normal;

    &.text-right {
      text-align: right;
    }

    &.nte-column {
      font-weight: 600;
      color: #424242;
    }
  }

  tbody > tr.inline-input {
    td.form-input {
      padding: 2px 10px;

      &.buy-rate-level-icon {
        padding: 2px;
        .input-group {
          right: 0;
        }
      }

      .input-group {
        padding: 0;
        right: -10px;
      }
    }

    td.nte {
      padding-top: 6px;
      padding-bottom: 0;
    }
  }
}

.nte-column {
  font-weight: 600;
  color: #424242;
  padding-right: 0 !important;
}

.fa.icon + *,
.svg-inline--fa.icon + * {
  margin-left: 5px;
}

.options {
  padding: 0 10px;

  a {
    padding: 10px 14px 11px 14px;
    cursor: pointer;
    &:hover {
      background-color: $rc-green8;
    }
  }

  &.header-options {
    a:hover {
      background-color: $gray-border;
    }
  }
}

div.btn-group.options {
  padding: 0;
  line-height: 28px;
}

td.fixed-size,
th.fixed-size {
  min-width: 114px;
  max-width: 140px;
}

.buy-rate-level-icon {
  overflow: hidden;
  color: white;
  width: 40px;
  height: 40px;
  opacity: 0.85;
  text-align: center;

  &.junior {
    background-color: $junior-color;
    //border-bottom: 1px solid $junior-card;
  }

  &.mid {
    background-color: $mid-color;
    //border-bottom: 1px solid $mid-card;
  }

  &.senior {
    background-color: $senior-color;
    //border-bottom: 1px solid $senior-card;
  }

  &.leader {
    background-color: $lead-color;
    //border-bottom: 1px solid $lead-card;
  }

  &.guru {
    background-color: $guru-color;
    //border-bottom: 1px solid $guru-card;
  }
}

.row.buy-rate-details {
  min-height: 68px;
}

.buy-rate-level-place-holder {
  height: 40px;
}

.buy-rate-collapsible {
  overflow: hidden;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;

  &.collapsed {
    max-height: 0;
    opacity: 0.2;
  }

  &.expanded {
    max-height: 160px;
    display: inherit;
    opacity: 1;
  }

  a {
    width: 40px;
    height: 40px;
    display: block;
    float: right;
    clear: right;

    &.first {
      margin-top: 10px;
    }
  }
}

.neutral-icon {
  color: $rc-gray4;
}

.danger-icon {
  color: $rc-danger-color; // #df2e1b;
}

.btn-sm-device {
  width: 40px;
  height: 40px;

  span.fa-chevron-down {
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;

    &.collapsed {
      -moz-transform: scaleY(1);
      -o-transform: scaleY(1);
      -webkit-transform: scaleY(1);
      transform: scaleY(1);
    }

    &.expanded {
      -moz-transform: scaleY(-1);
      -o-transform: scaleY(-1);
      -webkit-transform: scaleY(-1);
      transform: scaleY(-1);
    }
  }
}

.xs-nte-table {
  width: 100%;
  tbody > tr {
    td {
      padding: 0 5px;

      &:first-child {
        min-width: 70px;
      }

      .input-group {
        width: 100%;
      }

      .form-control.input-sm {
        height: 24px;
        line-height: inherit;
        padding: 2px 6px;
      }
    }

    &.xs-nte-row-input {
      td,
      td.form-input,
      td.nte {
        padding-top: 5px;
        padding-bottom: 0;
      }
    }
  }
}

.table.level-data.buy-rates {
  font-weight: normal;
  margin-bottom: 0;
  margin-top: 10px;

  &.input {
    margin-top: 7px;
    margin-bottom: 8px;
  }

  tbody > tr {
    td {
      &:first-child {
        min-width: 70px;
      }

      .input-group {
        width: 100%;
      }

      .form-control.input-sm {
        height: 24px;
        line-height: inherit;
        padding: 2px 6px;
      }
    }
  }
}

.loading-indicator-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);

  visibility: hidden;
  opacity: 0;

  z-index: 1000;

  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -ms-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;

  &.show {
    visibility: visible;
    opacity: 1;
  }
}

//-----------------------------------------
//  Toggle radios, checkbox
//-----------------------------------------

.btn-group {
  &.rc-btn-group {
    margin: 0 15px 0 0;

    &:last-child {
      margin: 0;
    }

    &.pull-right {
      margin: 0 0 10px 15px;

      &:last-child {
        margin: 0;
      }
    }
  }

  &.toggle-group {
    //border: 1px solid $rc-gray2;
    border-radius: 0;

    &[data-toggle="buttons"] {
      .btn {
        margin: 0;

        &.btn-toggle {
          border-radius: 0;
          color: $rc-green7 !important;
          background-color: white;
          transition: all 0.3s;
          border: 0 !important;
          padding: 6px 12px;

          box-shadow: inset 0px -3px 0px 0 $rc-gray2;
          -moz-box-shadow: inset 0px -3px 0px 0 $rc-gray2;
          -webkit-box-shadow: inset 0px -3px 0px 0 $rc-gray2;

          &.active {
            background-color: $rc-green8;
            color: $rc-green7 !important;
            box-shadow: inset 0px -3px 0px 0 lighten($rc-yellow, 6.8%);
            -moz-box-shadow: inset 0px -3px 0px 0 lighten($rc-yellow, 6.8%);
            -webkit-box-shadow: inset 0px -3px 0px 0 lighten($rc-yellow, 6.8%);
          }

          &:hover {
            background-color: $rc-green8;
            color: $rc-green5 !important;
            box-shadow: inset 0 -3px 0px 0 $rc-green4;
            -moz-box-shadow: inset 0 -3px 0px 0 $rc-green4;
            -webkit-box-shadow: inset 0 -3px 0px 0 $rc-green4;
          }
        }

        &.btn-group-addon {
          color: inherit;
          background-color: #f5f5f5;
          border-right: 1px solid $rc-gray2 !important;
          cursor: default;

          &:active {
            box-shadow: none;
          }
        }
      }
    }
  }
}

.rc-alert {
  box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  border: 0;
  font-size: 16px;

  .options {
    .btn-green {
      margin-left: 6px;
      padding: 4px 8px;
    }
  }

  &.floating-alert {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translate(-50%, 0%);
  }
}

.btn-toolbar {
  &.main-toolbar {
    margin: 0;
  }
}

.section {
  position: relative;
}

.select-all-link {
  font-size: 16px;
  font-weight: normal;

  input[type="checkbox"] {
    margin-right: 6px;
    margin-left: 6px;
  }
}

.back-bar {
  margin-left: 10px;
  a,
  button {
    display: inline;
  }
}

.rc-ul {
  font-size: 14px;

  &.errors {
    padding-left: 20px;
  }

  li {
    margin-bottom: 10px;
  }
}

.table-border-left {
  border-left: 1px solid #e6e6e6;
}

.table-border-right {
  border-right: 1px solid #e6e6e6;
}

.table-border-bottom {
  border-bottom: 1px solid #e6e6e6;
}

.table-no-border-top {
  border-top: none !important;
}

.table-double-border-bottom {
  border-bottom: 2px solid #e6e6e6;
}

.table-header-no-background {
  background: none !important;
}

.table-header-background {
  background-color: #dde7f5 !important;
  color: darken(#dde7f5, 65%);
  font-weight: 300;

  //&.junior {
  //  background-color: $junior-color !important;
  //  color: lighten($junior-color, 50%);
  //}
  //&.mid {
  //  background-color: $mid-color !important;
  //  color: lighten($mid-color, 50%);
  //}
  //&.senior {
  //  background-color: $senior-color !important;
  //  color: lighten($senior-color, 50%);
  //}
  //&.leader {
  //  background-color: $lead-color !important;
  //  color: lighten($lead-color, 50%);
  //}
  //&.guru {
  //  background-color: $guru-color !important;
  //  color: lighten($guru-color, 50%);
  //}
}

.peek-table-header {
  font-weight: normal;
}

.peek-table-container {
  border-radius: 0;
  margin: -10px auto;
  padding: 0;
  //background-color: white;
  //box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2);
}

table.table-striped {
  &.pay-rate-table,
  &.markup-table,
  &.bill-rate-table {
    & > thead,
    & > thead > tr,
    & > thead > tr > th {
      border: none;
    }
    & > tbody > tr:nth-child(odd) > td {
      //background-color: lighten($rc-green8, 2%);
      background-color: #f9f9f9;
    }
    & > thead > tr > th {
      font-weight: 300;
      text-transform: uppercase;
      padding: 12px;
    }
    & > tbody > tr > td {
      border-top: none;
      padding: 12px;
    }
  }

  &.pay-rate-table {
    & > thead > tr:nth-child(even) > th {
      text-align: right;
      background-color: #dde7f5;
      color: darken(#dde7f5, 65%);
    }
  }

  &.markup-table {
    & > thead > tr:nth-child(even) > th {
      text-align: right;
      background-color: #cdd4d3;
      color: darken(#cdd4d3, 65%);
    }
  }

  &.bill-rate-table {
    & > thead > tr:nth-child(even) > th {
      text-align: right;
      background-color: #e5f1de;
      color: darken(#e5f1de, 65%);
    }
  }
}

.horizontal-scroll {
  overflow-y: auto;
}

td.align-right,
th.align-right {
  text-align: right;
  align-content: right;
}

.mt-60 {
  margin-top: 60px;
}

.mt-70 {
  margin-top: 70px;
}

.w-300 {
  width: 300px;
}

// react-dates overrides

.DateInput__input {
  @include pt-input;
  font-size: 16px;
  font-weight: 400;
  opacity: 1;
}

.DateInput_input__focused {
  border: none;
}

.text-right {
  text-align: right;
}

.hline {
  width: 1px;
  height: 12px;
  margin: 0 8px;
  background-color: $rc-gray5;
}

.flex-fill {
  flex: 1 1 auto;
}

.drawer-box.drawer-box-fullscreen {
  overflow-y: auto;
}

.input-border,
.form-control.input-border,
.height_auto.input-border {
  border: 2px solid $rc-input-border-color;
  border-radius: 4px;
}

.form-control,
input.form-control,
.ratecards input.form-control {
  border-color: hsl(0, 0%, 80%);
  min-height: 1.2em;
  line-height: 1em;
  font-size: 16px;
  //padding: 10px 12px;
  height: 2.625em;
  &::placeholder {
    color: #ccc;
  }
  &:hover {
    border-color: hsl(0, 0%, 70%);
  }
  &:focus,
  &:active {
    box-shadow: 0 0 0 1px $blue;
    border-color: $blue;
  }
  &:disabled,
  &:disabled + .input-group-addon {
    background-color: hsl(0, 0%, 95%);
    border-color: hsl(0, 0%, 90%);
  }
}

.ratecards .input-group-addon {
  padding: 6px 12px;
  border-color: hsl(0, 0%, 80%);
  &:first-child {
    border-radius: 3px 0 0 3px;
  }
  &:last-child {
    border-radius: 0 3px 3px 0;
  }

  button {
    height: 2.625em;
    font-size: 16px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.pt-code-block {
  color: $rc-black8;
  background-color: $rc-gray7;
  padding: 10px;

  &.copy-enabled {
    cursor: pointer;
    &:hover {
      background-color: $rc-gray2;
    }
  }
}
