@import "../variables";

.ds-input-wrapper {
  color: $rc-black;
  font-size: 14.5px;
  line-height: 24px;
  border: 1px solid $input-border-color;
  border-radius: 3px;
  background-color: transparent;
  padding: 1px;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  flex: 1 1 auto;
  max-height: 200px;
  min-height: 36px;
  overflow: auto;
}

.ds-tag-input {
  border: none;
  outline: none;
  cursor: inherit;
  background-color: transparent;
  font-size: 14.5px;
}

.multi-downshift-input {
  border: none;
  outline: none;
  cursor: inherit;
  background-color: transparent;
  font-size: 14.5px;
  padding-left: 8px;
}

.multi-downshift-dropdown {
  width: 100%;
  & > .dropdown-menu {
    max-height: 200px;
    overflow: auto;
  }
  background-color: white;

  &:focus {
    outline: none;
    background-color: #f8fafb;
  }
}

.ds-tag {
  margin: 2px;
  padding: 1px 7px;
  border-radius: 3px;
  border: 1px solid lighten($rc-yellow, 6.8%);
  background-color: $rc-green8;
  color: $rc-gray4;
  font-size: 13px;

  .fa.icon,
  .svg-inline--fa.icon {
    margin-left: 4px;
  }
}
