.modal-content {
  border: 1px solid #cccccc;
  border-radius: 0;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}
.modal-header {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  box-shadow: none;
}
.modal-header h3 {
  font-weight: 400;
  font-size: 18px;
  color: #666666;
}
.modal-header .close {
  color: #000000;
  opacity: 0.5;
  margin-top: 4px;
}
.modal-header .close:hover {
  opacity: 1;
}

#recordEditModal .modal-dialog {
  width: 700px;
}

#load-overlay {
  text-align: center;
}
#load-overlay p {
  color: #666;
  font-size: 38px !important;
  font-weight: 300;
}

#support-modal .modal-dialog {
  width: 500px;
}
#support-modal .modal-content,
#old-browser-modal .modal-content {
  border: 1px solid #cccccc;
}
#support-modal .modal-content,
#support-modal input[type="text"] {
  border-radius: 0;
}
#support-modal .modal-content .close,
#old-browser-modal .modal-content .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 12px 17px;
  font-size: 24px;
}
#support-modal .modal-content h2,
#old-browser-modal .modal-content h2 {
  background-color: transparent;
  margin-top: 0;
  margin-bottom: 25px;
}
#old-browser-modal .modal-content h2 {
  margin-bottom: 5px;
}

#support-modal .theForm .row .col-sm-8 {
  padding-left: 0;
}
#support-modal .theForm .row .col-sm-4 {
  padding-right: 0;
}
#support-modal form > div {
  margin-bottom: 15px;
}

#old-browser-modal .modal-content {
  padding-bottom: 15px;
}
#old-browser-modal ul {
  list-style: none;
  margin: 10px 0 0;
  padding: 0;
}
#old-browser-modal li {
  float: left;
  width: 50%;
  text-align: center;
}
#old-browser-modal img {
  display: inline-block;
  width: 64px;
  height: 64px;
}
#old-browser-modal .name {
  height: 20px;
  margin-bottom: 10px;
}

.field-container {
  position: relative;
}
.field-container .error {
  position: absolute;
  right: 15px;
  top: 13px;
  color: #f41323;
  opacity: 0;
}
.field-container .tooltip {
}
.field-container .tooltip .tooltip-inner {
  background-color: #f41323;
}
.field-container .tooltip.top .tooltip-arrow {
  border-top-color: #f41323;
}

.field-container .tooltip.top-left .tooltip-arrow {
  border-top-color: #f41323;
}

.field-container .tooltip.top-right .tooltip-arrow {
  border-top-color: #f41323;
}

.field-container .tooltip.right .tooltip-arrow {
  border-right-color: #f41323;
}

.field-container .tooltip.left .tooltip-arrow {
  border-left-color: #f41323;
}

.field-container .tooltip.bottom .tooltip-arrow {
  border-bottom-color: #f41323;
}

.field-container .tooltip.bottom-left .tooltip-arrow {
  border-bottom-color: #f41323;
}

.field-container .tooltip.bottom-right .tooltip-arrow {
  border-bottom-color: #f41323;
}
