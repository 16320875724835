@import "../../../base/colors";

.index-chart {
  &__container {
    position: relative;
    height: 360px;
    overflow: hidden;
  }

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $gray-darkest;
  }

  &__black-line {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 123px;
    background: rgba($black, 0.5);
    border-left: 4px solid rgba($white, 0.1);
  }

  // tooltip svg styles

  &__tooltip {
    color: $gray-darker;
    fill: $gray-darker;

    & span {
      color: $white;
      fill: $white;
    }
  }
}
