/* v7-stitches-content resets. keep it minimal */

html {
  font-size: 16px;
  line-height: 1.5rem;
}

#v7-stitches-content {
  width: 100%;
  padding: 24px 16px;
  font-size: 16px;
  color: var(--colors-ptBlack500);
}

#v7-stitches-content h1,
#v7-stitches-content h2,
#v7-stitches-content h3,
#v7-stitches-content h4,
#v7-stitches-content h5,
#v7-stitches-content h6 {
  color: var(--colors-ptBlack600);
}

#v7-stitches-content h1 {
  font-size: var(--fontSizes-5xl);
  line-height: var(--lineHeights-5xl);
}

#v7-stitches-content h2 {
  font-size: var(--fontSizes-4xl);
  line-height: var(--lineHeights-4xl);
}

#v7-stitches-content h3 {
  font-size: var(--fontSizes-3xl);
  line-height: var(--lineHeights-3xl);
}

#v7-stitches-content h4 {
  font-size: var(--fontSizes-2xl);
  line-height: var(--lineHeights-2xl);
}

#v7-stitches-content h5 {
  font-size: var(--fontSizes-xl);
  line-height: var(--lineHeights-xl);
}

#v7-stitches-content h6 {
  font-size: var(--fontSizes-lg);
  line-height: var(--lineHeights-lg);
}

#v7-stitches-content p {
  font-size: inherit;
  line-height: inherit;
  color: inherit;
}

#v7-stitches-content pre,
#v7-stitches-content code,
#v7-stitches-content kbd,
#v7-stitches-content samp {
  font: inherit;
  line-height: inherit;
  font-family: ui-monospace, SFMono-Regular, Consolas, "Liberation Mono", Menlo, monospace;
  color: inherit;
  padding: 0;
  margin: 0;
  border: none;
  background: none;
}

#v7-stitches-content code {
  color: var(--colors-ptRed800);
}
