.panel.rate-search {
  max-width: 1000px;
  margin: 16px auto;

  .panel-body {
    padding: 0;
  }

  textarea {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  form .form-actions {
    padding-bottom: 0;
    margin-bottom: 0;
    background-color: rgba(255, 255, 255, 0.4);
  }
}

.rate-search-results-panel-group {
  .heading-label {
    font-weight: bold;
    display: inline-block;
    .text {
      font-weight: normal;
    }
    &:not(:last-of-type) {
      margin-right: 35px;
    }
  }

  .level-heading {
    &:not(:last-of-type) {
      margin-right: 5px;
    }
    h5 {
      display: inline-block;
    }
  }
  .level-panel {
    .panel-heading {
      padding: 0;
      h5 {
        margin: 4px 0 4px;
      }
    }
  }
  .rate-chart-container {
    height: 220px;
    width: 350px;
    margin: 0 auto;
  }

  ul.job-description-skill-list {
    margin-left: 0;
    padding-left: 15px;
  }
}

.onet-wage-graph-container {
  margin: 60px auto 30px;
  width: 80%;
  height: 50px;
  background-color: #dddddd;

  .onet-wage-graph {
    margin: 0 auto;
    position: relative;
    width: 90%;
    height: 100%;

    .onet-wage-graph-percentile-part {
      position: absolute;
      height: 100%;
      display: inline-block;

      .rate {
        position: absolute;
        top: -20px;
        display: block;
        width: 40px;
        right: -20px;
        text-align: center;
      }
      &:after,
      &.p25:before {
        position: absolute;
        bottom: -20px;
        right: -12px;
      }
      &.p25 {
        background-color: #cccccc;
        left: 0;
        width: 25%;

        .rate-10 {
          right: auto;
          left: -20px;
        }

        &:after {
          content: "25%";
        }
        &:before {
          content: "10%";
          right: auto;
          left: -10px;
        }
      }
      &.p50 {
        left: 50%;
        width: 1px;
        height: 100%;
        z-index: 5;
        border-left: 2px dashed #ffffff;

        .median-tooltip {
          background-color: #b2c72c;
          position: absolute;
          top: -55px;
          left: -16px;
          font-size: 18px;
          padding: 5px 10px;
          color: #ffffff;

          > span {
            white-space: nowrap;
          }

          &:before {
            content: "";
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 15px solid #b2c72c;
            position: absolute;
            bottom: -15px;
          }
        }

        &:before {
          content: "";
          position: absolute;
          border-radius: 50%;
          width: 20px;
          height: 20px;
          left: -11px;
          top: 15px;
          background-color: #c2d646;
        }
        &:after {
          content: "50%";
        }
      }
      &.p75 {
        background-color: #bbbbbb;
        left: 25%;
        width: 50%;

        &:after {
          content: "75%";
        }
      }
      &.p90 {
        background-color: #aaaaaa;
        left: 75%;
        width: 15%;

        &:after {
          content: "90%";
        }
      }
    }
  }
}

nav.ratesearch-tabs {
  padding-top: 15px;
}

ul.ratesearch-tabs.nav-tabs {
  margin: -25px -15px 0;
  padding: 20px 0 0 15px;
  background-color: #f4f2f2;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, #e7e7e7),
    color-stop(0.6, #f4f2f2)
  );
  background-image: -webkit-linear-gradient(to top, #e7e7e7 0%, #f4f2f2 60%);
  background-image: -moz-linear-gradient(to top, #e7e7e7 0%, #f4f2f2 60%);
  background-image: -ms-linear-gradient(to top, #e7e7e7 0%, #f4f2f2 60%);
  background-image: linear-gradient(to top, #e7e7e7 0%, #f4f2f2 60%);
  border-bottom-color: #e6e6e6;
  li {
    font-size: 18px;
    a {
      padding: 10px 25px;
      color: #bbbbbb;
      i {
        margin-right: 10px;
      }
    }
    &:hover a {
      color: #999999;
      background-color: transparent;
      border-color: transparent;
    }
    a:focus {
      background-color: transparent;
      border-color: transparent;
    }
  }
  .fa {
    margin-right: 10px;
  }

  .active {
    a,
    &:hover a {
      background-color: #f8f8f8;
      text-shadow: 0 2px 0 rgba(255, 255, 255, 0.2);
      background-image: -webkit-gradient(
        linear,
        left top,
        left bottom,
        color-stop(0, #fdfdfd),
        color-stop(1, #f8f8f8)
      );
      background-image: -webkit-linear-gradient(to top, #fdfdfd 0%, #f8f8f8 100%);
      background-image: -moz-linear-gradient(to top, #fdfdfd 0%, #f8f8f8 100%);
      background-image: -ms-linear-gradient(to top, #fdfdfd 0%, #f8f8f8 100%);
      background-image: linear-gradient(to top, #fdfdfd 0%, #f8f8f8 100%);
      color: #999999;
      border-color: #e6e6e6 #e6e6e6 #f8f8f8;
    }
    a {
      &:active,
      &:focus {
        background-color: #f8f8f8;
        text-shadow: 0 2px 0 rgba(255, 255, 255, 0.2);
        background-image: -webkit-gradient(
          linear,
          left top,
          left bottom,
          color-stop(0, #fdfdfd),
          color-stop(1, #f8f8f8)
        );
        background-image: -webkit-linear-gradient(to top, #fdfdfd 0%, #f8f8f8 100%);
        background-image: -moz-linear-gradient(to top, #fdfdfd 0%, #f8f8f8 100%);
        background-image: -ms-linear-gradient(to top, #fdfdfd 0%, #f8f8f8 100%);
        background-image: linear-gradient(to top, #fdfdfd 0%, #f8f8f8 100%);
        color: #999999;
        border-color: #e6e6e6 #e6e6e6 #f8f8f8;
      }
    }
  }
  .inactive {
    a,
    &:hover a {
      visibility: hidden;
      display: none;
    }
    a {
      &:active,
      &:focus {
        visibility: hidden;
        display: none;
      }
    }
  }
}

.qs-actions {
  float: right;
  .btn {
    color: #ffffff !important;
  }
}

nav.results-tabs {
  position: relative;
  z-index: 3;

  ul {
    border-bottom: 0;
    text-align: center;
    width: 100%;
    margin-top: 12px;
    margin-bottom: -3px;
    li {
      float: none;
      display: inline-block;
      margin: 0 5px;
      position: relative;
      .number {
        display: block;
        position: absolute;
        font-size: 10px;
        left: 8px;
        top: -10px;
        color: #999999;
      }

      a {
        border-radius: 0;
        color: #999999 !important;
        background-color: #f2f2f2;
        border: 1px solid #e6e6e6;
        margin: 0;
        font-size: 16px;
        padding: 9px 20px 9px;
        white-space: nowrap;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom: none !important;
        .fa {
          margin-right: 10px;
        }
        &:hover {
          color: #555555;
          background-color: #eeeeee;
        }
      }
      &.active {
        a {
          padding-bottom: 10px;
          border-bottom: 0;
          border-color: #e6e6e6 !important;
          background-color: #ffffff;
          color: #333333 !important;
        }
        .number {
          color: #333333;
        }
      }

      &:hover .number {
        color: #555555;
      }
    }
  }
}

.rate-search-results {
  .tab-pane .panel:first-of-type {
    padding-top: 0;
    margin-top: 2px;
  }
}

.equalHW {
  .tab-pane {
    &.active {
      .info_head {
        padding-top: 0px;

        h2 {
          margin-top: 0px;
        }
      }

      .content-header {
        margin-top: 0px;
      }

      .custom-form {
        .refresh_btn {
          margin-top: -10px;
        }
      }
    }
  }
}

/***************************** Yatendra Styling start here **************************************/

.ratesearch {
  .rate-search-table {
    display: inline-block;
    // float: left;
    max-width: 100%;
    overflow-y: hidden;
  }
}
