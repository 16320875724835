@use "sass:math";
@import "variables", "mixins", "animations";

// all declarations in this file should be inside .pt-ui{} scope.
// declarations outside .pt-ui{} go in the file _pt-ui.scss
.pt-ui {
  &.view {
    max-width: 1470px; // 1440px content + 30px left/right padding
    margin: auto;
  }

  .u-m-left {
    margin-left: 5px;
  }

  .pt-pad-item {
    padding: 15px 20px;
  }

  .fade-out {
    visibility: hidden;
    opacity: 0;
    animation: 200ms ease-out 0s 1 fadeOut;
  }

  .fade-in {
    visibility: visible;
    opacity: 1;
    animation: 200ms ease-out 0s 1 fadeIn;
  }

  .fade-in-wait-fade-out {
    visibility: hidden;
    opacity: 0;
    animation: fadeInWaitFadeOut 6s ease forwards;
  }

  .bottom-appear {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);

    animation: 200ms ease-out 0s 1 fadeIn, 200ms ease-out 0s 1 slideInBottom;
  }

  .bottom-disappear {
    visibility: hidden;
    opacity: 0;
    transform: translateY(100%);

    animation: 200ms ease-out 0s 1 fadeOut, 200ms ease-out 0s 1 slideOutBottom;
  }

  .drop-shadow {
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.1);
  }

  .rc-container {
    //animation: 200ms ease-out 0s 1 fadeIn;
    background: #fff;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 4px 8px -1px rgba(0, 0, 0, 0.16);
    margin: 15px 0;
    position: relative;
    // overflow: hidden;
    opacity: 1;

    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -ms-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;

    &.send-back {
      box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
      opacity: 0.4;
      //-webkit-transition: all .2s;
      //-moz-transition: all .2s;
      //-ms-transition: all .2s;
      //-o-transition: all .2s;
      //transition: all .2s;
    }

    &.bring-forward {
      z-index: 11;
    }

    .header-action-bar {
      margin: -20px -20px 0 -20px;
      border-bottom: 1px solid $rc-element-border-color;
      position: relative;
      z-index: 3;
      display: block;
      padding: 15px 20px;
    }
  }

  .container-section {
    //animation: 200ms ease-out 0s 1 fadeIn;
    margin: 0 -20px;
    border-bottom: 1px solid $rc-element-border-color;
    position: relative;
    display: block;
    padding: 20px;

    &.header {
      margin: -20px -20px 0 -20px;
      h4 {
        line-height: 34px;
        margin-right: 20px;
      }
    }

    &.footer {
      margin: 0 -20px -20px -20px;
      border-bottom: none;
      background-color: #f3f3f3;
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    &.actions {
      padding: 15px 20px;
    }

    &.info {
      padding: 5px 20px;
      color: darken($rc-input-border-color, 30%);
    }

    &::after,
    .section-group::after {
      content: "";
      display: table;
      clear: both;
    }

    .section-group {
      padding: 0 0 15px 0;
    }
  }

  .rc-container-backdrop {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.5);
    transition: all 0.2s;
  }

  .rc-viewport {
    min-height: 200px;
    max-height: 800px;
    overflow-y: auto;
    padding: 20px;
  }

  .rc-actions-bar {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin-right: 20px;

    .info {
      display: inline-block;
      padding: 6px 12px;
      line-height: normal;
      text-align: center;
      vertical-align: middle;
      font-weight: normal;
      float: left;
    }

    &:not(:first-child) {
      margin: 0;
    }
  }

  .border {
    &-b {
      border-bottom: 1px solid $rc-element-border-color;
    }

    &-t {
      border-top: 1px solid $rc-element-border-color;
    }

    &-tb {
      border-top: 1px solid $rc-element-border-color;
      border-bottom: 1px solid $rc-element-border-color;
    }
  }

  .overlap-lr-padding {
    margin-left: -20px;
    margin-right: -20px;
  }

  .overlap-t-padding {
    margin-top: -20px;
  }

  .overlap-b-padding {
    margin-bottom: -20px;
  }

  .overlap-tb-padding {
    margin-top: -20px;
    margin-bottom: -20px;
  }

  .overlap-padding {
    margin: -20px;
  }

  .inline-block {
    display: inline-block;
  }

  .block {
    display: block;
  }

  .flex-wrap {
    display: flex;
    flex-wrap: wrap;
  }

  .selectable-list-container {
    &.empty {
      height: 0; // IE 11 fix
    }
    border: 0;
    padding: 0;
    min-height: 0;
    margin: 0 -20px;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 40vh;
    border-top: 1px solid $rc-element-border-color;
    border-bottom: 1px solid $rc-element-border-color;

    .rc-selectable-item {
      &:first-child {
        border-top: none;
      }
      &:last-child {
        border-bottom: none;
      }
    }
  }

  .selectable-list-pagination-container {
    display: flex;
    align-items: center;
    font-size: 14px;
    padding: 10px 20px;

    .pagination-button {
      font-size: 14px;
      color: $rc-green5;
      background-color: transparent;
      box-shadow: none;
      margin: 0 8px;
      padding: 0;
      border: none;
      cursor: pointer;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        text-decoration: underline;
        color: $rc-green4;
      }

      &:disabled {
        color: $rc-gray3;
        cursor: not-allowed;
        opacity: 0.7;
        &:hover {
          text-decoration: none;
        }
      }
    }

    .pagination-info {
      margin: 0 8px;
      color: $rc-gray3;
    }
  }

  .sort-indicator {
    background-color: $rc-yellow2;
    color: white;
    border-radius: 3px;
    margin: -4px 6px -4px -8px;
    padding: 2px 4px;
    font-size: inherit;

    .sort-position {
      margin-right: 6px;
      margin-left: 2px;
    }
  }

  .list-container {
    border: 0;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .list-container-item {
    padding: 5px 20px;
    border-bottom: 1px solid $rc-element-border-color;

    &:last-child {
      border-bottom: none;
    }
  }

  .rc-shroud {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 20px;
    background-color: $rc-element-border-color;
    opacity: 0.6;
  }

  .rc-selectable-item {
    width: 100%;
    color: darken($rc-input-border-color, 38%);
    border-bottom: none;
    border-radius: 0;
    padding: 10px 16px;
    font-size: 18px;
    font-weight: normal;
    margin: 0;
    cursor: pointer;
    position: relative;
    transition: all 0.2s;

    .media {
      margin: -10px 0;
    }

    .media-left,
    .media-body {
      padding: 10px 0;
    }

    input[type="radio"],
    input[type="checkbox"] {
      visibility: hidden;
      position: absolute;
      top: 0;
      left: 0;
    }

    .rc-check-icon,
    .rc-radio-icon {
      margin-right: 5px;
    }

    &.selected {
      color: $rc-green6;
      background-color: $rc-green8;

      .text-x-small {
        color: $rc-green4;
      }
    }

    &:hover {
      color: $rc-green6;
      background-color: $rc-green8;
    }

    &:first-child {
      border-top: 1px solid $rc-element-border-color;
    }

    .fa.icon,
    .svg-inline--fa.icon {
      margin-left: -4px;
      & + span {
        margin-left: 10px;
      }
    }

    .text-x-small {
      color: darken($rc-input-border-color, 24.2%);
      .fa.icon,
      .svg-inline--fa.icon {
        margin-left: 0;
        font-size: 13px;
        & + span {
          margin-left: 5px;
        }
      }
    }
  }

  .rc-checkbox-group {
    &:not(:only-child) {
      padding-bottom: 10px;
    }

    &.in-line {
      display: flex;
      flex-wrap: wrap;

      .rc-checkbox-component:not(:only-child),
      .rc-checkbox-component:not(:last-child) {
        margin-right: 10px;
      }

      .rc-checkbox-component {
        margin-bottom: 10px;
      }

      &.flex-200 {
        .rc-checkbox-component {
          flex: 1 0 200px;
        }
      }

      &.flex-150 {
        .rc-checkbox-component {
          flex: 1 0 150px;
        }
      }

      &.flex-100 {
        .rc-checkbox-component {
          flex: 1 0 100px;
        }
      }
    }
  }

  .rc-check-icon {
    // font: normal normal normal 14px/1 FontAwesome;
    // font-size: inherit;
    // text-rendering: auto;
    // -webkit-font-smoothing: antialiased;
    // content: "\F0C8";
    color: darken($rc-element-border-color, 10%);
    transition: color 0.25s;
    &:hover {
      color: $rc-yellow;
    }
  }

  .rc-check-icon.selected {
    color: $rc-yellow;
    // content: "\F14A";
  }

  .rc-radio-icon {
    // font: normal normal normal 14px/1 FontAwesome;
    // font-size: inherit;
    // text-rendering: auto;
    // -webkit-font-smoothing: antialiased;
    // content: "\F10c";
    color: darken($rc-element-border-color, 10%);
  }

  .rc-radio-icon.selected {
    color: $rc-yellow;
    // content: "\F111";
  }

  .rc-checkbox-component {
    color: darken($rc-input-border-color, 38%);
    border: none;
    font-size: 18px;
    font-weight: normal;
    margin: 0;
    cursor: pointer;
    position: relative;
    transition: all 0.2s;

    input[type="radio"],
    input[type="checkbox"] {
      visibility: hidden;
      position: absolute;
      top: 0;
      left: 0;
    }

    .rc-check-icon,
    .rc-radio-icon {
      margin-right: 5px;
    }

    &.selected {
      color: $rc-green6;
      .text-x-small,
      .text-small {
        color: $rc-green4;
      }
    }

    &:hover {
      color: $rc-green6;
    }

    .fa.icon,
    .svg-inline--fa.icon {
      margin-left: -4px;
      & + span {
        margin-left: 10px;
      }
    }

    .text-x-small {
      //color: darken($rc-input-border-color, 22.2%);
      .fa.icon,
      .svg-inline--fa.icon {
        margin-left: 0;
        font-size: 13px;
        & + span {
          margin-left: 5px;
        }
      }
    }
  }

  a {
    color: $rc-green4;
  }

  .view {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .notransition {
    transition: none !important;
  }

  .pt-link {
    color: $rc-green4 !important;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      color: $rc-green5 !important;
    }
  }

  .pt-error-message {
    padding: 15px;
  }

  .pt-user-messages {
    margin-top: 15px;
  }

  .pt-user-alert {
    margin-bottom: 0;
    font-size: 14px;
  }

  .ratecards {
    &-all-items {
      margin-right: 10px;
    }

    &-all-items--active {
      font-weight: bold;
    }

    &-list-select-all-section {
      //animation: 250ms ease-out 0s 1 appearFromTop;
      text-align: center;
      border-bottom: 1px solid $rc-yellow;
      background-color: lighten($rc-yellow, 38%);
      color: #d8a306;
      border-top: 1px solid $rc-yellow;
      padding: 5px 20px;
      font-size: 16px;
      overflow: hidden;
    }

    &-rc-label {
      border: 1px solid $rc-gray1;
      border-radius: 3px;
      box-shadow: 1px 1px 3px $rc-gray1;
      padding: 10px 10px;
      margin-top: 2px;
      margin-bottom: 10px;
      font-size: 16px;

      &:focus {
        outline: none;
      }
    }
  }

  .align-top {
    vertical-align: text-top;
  }

  .top {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    //width: calc(100vw - 30px);
    //margin-top: 15px;
    //margin-bottom: 15px;

    .ratecard-label-header {
      display: inline-flex;
      flex: 1;
      align-items: baseline;

      h2 {
        padding: 0 5px 6px 0;
      }

      .tag {
        margin-bottom: auto;
      }
    }

    .ratecard-meta {
      flex: 1;
    }

    .above {
      display: flex;
      justify-content: space-between;

      h1 {
        margin-bottom: 20px;
      }
    }

    .below {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }
  }

  .rc-select-box {
    &.Select {
      .Select-control {
        @include pt-input;
      }

      &.has-error {
        border: none;
        .Select-control {
          @include pt-input;
          border: 2px solid $rc-danger-color;
        }
      }

      &:disabled {
        .Select-control {
          @include pt-input;
          background-color: $rc-element-border-color;
          opacity: 0.6;
        }
      }
    }
  }

  .pt-search {
    display: flex;
    position: relative;

    &.small {
      .pt-search-box {
        padding: 4px 26px 4px 6px;
        font-size: 14px;
      }

      .pt-search-icon {
        padding: 4px 6px;
        font-size: 16px;
      }
    }

    &.icon-left {
      .pt-search-box {
        padding: 10px 10px 10px 38px;
      }

      &.small {
        .pt-search-box {
          padding: 4px 6px 4px 26px;
        }
      }

      .pt-search-icon {
        left: 10px;
        top: 10px;
        bottom: 10px;
        right: initial;
      }
    }
  }

  .pt-search-box {
    @include pt-input;
    flex: 1;
    // flex: 1 defaults to flex: 1 1 auto
    // but on IE11 default to flex: 1 1 0px
    // so we need to set flex-basis: auto explicitly
    flex-basis: auto;
    width: 180px;
    padding: 10px 38px 10px 10px;
  }

  .pt-search-icon {
    position: absolute;
    right: 10px;
    top: 10px;
    bottom: 10px;
    // padding: 10px;
    cursor: pointer;
    // font-size: 20px;
    color: $rc-gray4;
  }

  .ratecards-table,
  .search-table {
    //border-radius: 5px;
    //border: 1px solid $rc-gray2;
    //width: calc(100vw - 35px);
    //width: 100%;
    margin: 0;
    overflow: hidden;

    &-empty {
      display: flex;
      min-height: 30vh;
      text-align: center;
      justify-content: center;
      padding-top: 15vh;
      color: $rc-gray1;
      font-size: 28px;
      user-select: none;
    }

    &-fill-container {
      width: 100%;
      height: 100%;
    }
  }

  .ratecard-owner,
  .ratecard-created-at {
    //color: $rc-gray3;
    //font-size: 18px;
    line-height: initial;
    margin-bottom: 4px;

    .fa.icon,
    .svg-inline--fa.icon {
      margin-right: 2px;
    }
  }

  .ratecards-table-header,
  .search-table-header {
    display: flex;
    justify-content: space-between;
    //border-bottom: 1px solid rgb(230, 230, 230);
    //font-weight: 100;
    font-size: 0.85em;
    padding: 15px 20px;
    margin: 0 -20px;
    border-radius: 4px 5px 0 0;
    background-color: white;
    transition: all 0.2ms ease;

    &.active {
      background-color: #fbf6dc;
      transition: all 0.2ms ease;
    }

    a:not([role="menuitem"]),
    .pt-link:not([role="menuitem"]) {
      color: $rc-green4 !important;

      &:hover {
        text-decoration: underline;
        color: $rc-green5 !important;
      }
    }
  }

  //Allows scrolling table contents
  .ratecards-table-list,
  .search-table-list {
    &-scrolling {
      height: calc(100vh - 240px);
      max-height: 780px; // Roughly how many pixels 10 items take
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
    }
  }

  .ratecards-label-input {
    label {
      color: $rc-gray2;
      font-size: 0.7em;
      font-weight: 200;
      display: block;
      margin: 0;
      padding: 0;
    }
    input {
    }
  }

  .header-left-nav {
    flex-shrink: 2;
    flex-grow: 1;

    a.btn,
    .pt-link {
      font-size: 16px;
      margin-right: 10px;
    }
  }

  a[role="menuitem"]:hover {
    text-decoration: none;
  }

  li[role="heading"] {
    color: #bbb;
  }

  .header-right-nav {
    text-align: right;
    flex-shrink: 2;
    flex-grow: 1;

    a {
      margin-left: 15px;
    }
  }

  .btn-filter {
    color: $rc-green4;
    display: inline-block;
    cursor: pointer;
    margin-left: 20px;
    font-size: 16px;
    line-height: 19px;
    padding: 6px 0;
    position: relative;

    .children {
      &:hover {
        color: $rc-green5;
        text-decoration: underline;
      }
    }
  }

  .ratecard-list-item-left {
    flex: 2;
    display: flex;
    align-items: center;
  }

  .list-item-select {
    margin-right: 20px;
    cursor: pointer;
  }

  .ratecard-list-item-right {
    flex: 1;
    // flex: 1 defaults to flex: 1 1 auto
    // but on IE11 defaults to flex: 1 1 0px
    // so we need to set flex-basis: auto explicitly
    display: flex;
    justify-content: space-around;
    max-width: 300px;
  }

  .ratecard-label {
    font-size: 24px;
    color: $rc-green4;
    transition: all 200ms;
    margin-bottom: 2px;
    padding: 2px 0; /* for tag to show correctly */
    line-height: 1.2;

    label.list-item-title {
      font-size: 24px;
    }
  }

  .ratecard-meta {
    color: $rc-gray5;
    font-size: 16px;
    line-height: 18px;
    padding-bottom: 2px;
    margin-top: -2px;
  }

  .item-subtext {
    &-accent {
      color: $rc-green5;
    }
    &-muted {
      color: $rc-gray6;
    }
  }

  .ratecard-info,
  .search-info {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    text-align: right;
    border-radius: 5px;
    //background-color: $rc-gray2;
    margin: 0 5px;
    padding: 0 15px;
    min-height: 40px;

    font-size: 20px;

    &.text-center {
      text-align: center;
    }

    header {
      color: $rc-gray5;
      background-color: inherit;
      font-size: 14px;
      //line-height: 14px;
      //font-weight: 200;
    }

    span {
      //line-height: 20px;
    }
  }

  .change {
    &-positive {
      color: $rc-green3;
    }

    &-negative {
      color: $rc-red1;
    }
  }

  .ellipsis {
    min-width: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .divider {
    border-right: 1px solid $rc-gray1;
  }

  .search-meta {
    color: $rc-gray5;
    font-size: 16px;
    //font-weight: 200;
    text-align: right;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 5px;
    margin-left: 5px;
  }

  .search-peek {
    padding: 0;
    max-height: 0;
    overflow: hidden;
    background-color: #f9f9f9;
    transition: all 250ms ease-in-out;

    &-body {
      padding: 20px;
      width: 100%;
    }

    &-level-name {
      font-weight: 600;
      font-size: 20px;
      color: $rc-gray3;
      margin-right: 6px;
    }

    &-levels {
      padding: 10px;
    }

    &-level-card {
      flex: 1 0 370px;
      max-width: 385px;
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
      margin: 10px;
      position: relative;
      overflow: hidden;

      &::before {
        content: " ";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 6px;
      }

      &.junior::before {
        background-color: lighten($junior-color, 8.4%);
        //border: 1px solid $junior-color;
      }

      &.mid::before {
        background-color: lighten($mid-color, 8.4%);
        //border: 1px solid $mid-color;
      }

      &.senior::before {
        background-color: lighten($senior-color, 8.4%);
        //border: 1px solid $senior-color;
      }

      &.leader:before {
        background-color: lighten($lead-color, 8.4%);
        //border: 1px solid $lead-color;
      }

      &.guru::before {
        background-color: lighten($guru-color, 8.4%);
        //border: 1px solid $guru-color;
      }

      .table.level-data {
        margin: -10px 0 0 0;
      }
    }

    &-expand {
      max-height: 1020px;
    }

    &-item {
      padding: 5px;
      flex-grow: 1;
      flex-shrink: 0;
      flex-basis: 200px;
    }

    &-options {
      flex-grow: 0;
      flex-shrink: 1;
      flex-basis: 200px;
    }

    &-header {
      font-size: 36px;
      font-weight: 900;
      color: $rc-gray2;
    }

    &-section {
      font-size: 20px;

      &-header {
        font-size: 14px;
        font-weight: 100;
        color: $rc-gray4;
      }
    }
  }

  .btn {
    cursor: pointer;
    border-radius: 3px;
    font-size: 14px;
    line-height: normal;
    border: 2px solid $rc-default-color !important;
    background-color: $rc-default-color;
    color: $rc-white1;
    margin: 0 10px 0 0;
    padding: 4px 10px;
    transition: all 0.2s ease;

    .fa.icon,
    .svg-inline--fa.icon {
      margin-left: -2px;
      & + span {
        margin-left: 2px;
      }
    }

    &.btn-lg {
      padding: 10px 20px;
      font-size: 18px;
      line-height: 1.33333;

      .fa.icon,
      .svg-inline--fa.icon {
        margin-left: -4px;
        & + span {
          margin-left: 10px;
        }
      }
    }

    input[type="checkbox"] {
      margin: 0 8px 0 0;
    }

    &:last-child {
      margin: 0;
    }

    &:hover {
      background-color: darken($rc-default-color, 6.8%);
      border: 2px solid darken($rc-default-color, 6.8%) !important;
      //color: $rc-green5 !important;
    }
  }

  .btn-default {
    border: 2px solid $rc-default-color !important;
    background-color: $rc-default-color;
    color: $rc-white1 !important;

    &:hover {
      background-color: darken($rc-default-color, 6.8%);
      border: 2px solid darken($rc-default-color, 6.8%) !important;
    }
  }

  .btn-yellow {
    border: 2px solid $rc-yellow !important;
    background-color: $rc-yellow;
    color: $rc-white1 !important;

    &:hover {
      border: 2px solid $rc-yellow2 !important;
      background-color: $rc-yellow2;
    }
  }

  .btn-green {
    border: 2px solid $rc-green4 !important;
    background-color: $rc-green4;
    color: $rc-white1 !important;

    &:hover {
      border: 2px solid $rc-green6 !important;
      background-color: $rc-green6;
    }
  }

  .btn-danger {
    border: 2px solid $rc-danger-color !important;
    background-color: $rc-danger-color;
    color: $rc-white1 !important;

    &:hover {
      border: 2px solid darken($rc-danger-color, 6.8%) !important;
      background-color: darken($rc-danger-color, 6.8%);
    }
  }

  .btn-toggle {
    color: darken($rc-input-border-color, 32.8%);
    border: 2px solid $rc-input-border-color !important;
    background-color: white;
    position: relative;
    //transition: all .2s;

    input[type="radio"],
    input[type="checkbox"] {
      visibility: hidden;
      position: absolute;
      top: 0;
      left: 0;
    }

    &.borderless {
      border: 2px solid transparent !important;
      background-color: transparent;
    }

    &.btn-lg {
      border-radius: 6px;
    }

    &.selected,
    &.selected:hover {
      color: $rc-green6;
      border: 2px solid lighten($rc-yellow, 6.8%) !important;
      background-color: $rc-green8;
    }

    &:hover {
      color: $rc-green6 !important;
      border: 2px solid lighten($rc-green6, 6.8%) !important;
      background-color: $rc-green8;
    }

    &:active {
      box-shadow: none;
    }

    &:only-child,
    &:last-child {
      margin: 0;
    }

    .text-x-small {
      color: darken($rc-input-border-color, 14.2%);
      .fa.icon,
      .svg-inline--fa.icon {
        margin-left: 0;
        font-size: 13px;
        & + span {
          margin-left: 5px;
        }
      }
    }
  }

  .btn-toggle-group {
    margin: 0;
    padding: 0;
    display: inline;

    &:not(:last-child) {
      margin-right: 20px;
    }

    .btn-toggle {
      margin: 0 0 0 -1px;

      &:not(:first-child):not(:last-child) {
        border-right: 0 !important;
        border-left: 1px solid $rc-element-border-color !important;
        border-radius: 0;
      }

      &:first-child {
        border-right: 0 !important;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      &:last-child {
        border-left: 1px solid $rc-element-border-color !important;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      &:not(:first-child):hover,
      &:not(:first-child).selected,
      &:not(:first-child).selected:hover {
        border-left: 1px solid $rc-element-border-color !important;
      }
    }
  }

  .selectable {
    display: flex;

    .checkbox {
      display: flex;
      margin-left: 10px;
      margin-right: 10px;
      align-items: center;

      input {
        margin: 0;
        padding: 0;
      }
    }

    .ratecard-list-item {
      flex-grow: 1;
    }
  }

  .ratecard-list-item {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    cursor: pointer;
    margin: 0;
    padding: 20px;
    transition: all 200ms;

    &:hover,
    &.selected {
      background-color: $rc-green8;
      //box-shadow: inset 6px 0 $rc-green5;
      .ratecard-label {
        color: $rc-green5;
      }
    }
  }

  .ratecard-list-item-container,
  .search-list-item-container {
    border-bottom: 1px solid $rc-element-border-color;

    &:first-child {
      border-top: 1px solid $rc-element-border-color;
    }
  }

  .rc-center {
    display: flex;
    align-items: center;
  }

  .search-list-item {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    cursor: pointer;
    margin: 0;
    padding: 20px;
    font-size: 18px;
    //box-shadow: inset 6px 0 $rc-green4;
    transition: all 200ms;

    &:hover,
    &.selected {
      background-color: $rc-green8;
      //box-shadow: inset 6px 0 $rc-green5;
      .search-label,
      .pt-ico-moon {
        color: $rc-green5;
      }
    }
  }

  .search-label {
    font-size: 24px;
    white-space: pre;
    color: $rc-green4;
    transition: all 200ms;
    margin-bottom: 4px;
    line-height: 1.2;
  }

  .search-title {
    color: $rc-gray5;
    font-size: 16px;
    line-height: 18px;
    padding-bottom: 2px;
    margin-top: -2px;
  }

  .search-expand-state {
    font-size: 24px;
    display: flex;
    align-items: center;
    padding-right: 10px;

    .pt-ico-moon {
      transition: transform 150ms ease-in, color 200ms;
      color: $rc-green4;
    }

    .expanded {
      transform: rotate(90deg);
    }
  }

  .search-list-item-left {
    display: flex;
    flex: 1;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: flex-start;
  }

  .search-list-item-right {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .filter-modal {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  .filter-modal-content {
    min-width: 300px;
    margin: 50px;
    padding: 25px;
    border: 2px solid #333;
    background-color: $rc-white1;
  }

  .filter-modal-list {
    border: 1px solid #333;
    overflow-y: scroll;
  }

  .filter-modal-item {
    padding: 5px 10px;
    margin: 1px;
    border: 1px solid red;
  }

  .filter-modal-hidden {
    display: none;
  }

  .ratecards-edit-actions,
  .search-edit-actions {
    @include pt-action-bar($edit-bar-height);

    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    bottom: 40px;
    right: 40px;
    left: 40px;
    height: initial;
    background-color: rgba(51, 51, 51, 0.94);
    padding: 20px;
    z-index: 20;
  }

  .search {
    width: 100%;
    min-height: 100vh;

    &-summary {
      display: flex;
    }
    &-results {
      display: flex;
      flex-direction: column;
      border: 1px $rc-gray2 solid;
      border-radius: 5px;
      &-level {
        background-color: $rc-white1;
        padding: 10px;
        border-bottom: 1px $rc-gray2 solid;
        border-radius: 0;
        &-first {
          border-radius: 5px 5px 0 0;
        }
        &-last {
          border-radius: 0 0 5px 5px;
        }

        &-header {
          display: flex;
          h1 {
            font-weight: bolder;
            font-size: 32px;
          }
          .meta {
            margin-left: 50px;
            display: flex;
            justify-content: space-around;
            &-item {
              font-size: 28px;
              margin-left: 15px;
              margin-right: 15px;
              header {
                color: $rc-gray2;
                font-size: 14px;
              }
            }
          }
        }

        &-data {
          display: flex;
          justify-content: space-around;
        }

        &-section {
          & > header {
            color: $rc-gray4;
            font-size: 26px;
          }
          section {
            & > header {
              font-weight: 200;
              font-size: 14px;
              color: $rc-gray3;
            }
          }
        }
      }
    }
  }

  .pt-pagination-outer {
    color: $rc-green4;
    margin: 15px auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 18px;
    min-width: 510px;
    max-width: 80%;
  }

  .pt-first-page,
  .pt-last-page,
  .pt-prev-page,
  .pt-next-page,
  .pt-prev-page-btn,
  .pt-next-page-btn,
  .pt-page-box {
    height: $pagination-box-height;
    margin-left: 3px;
    margin-right: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .pt-page-left-chevron {
    width: math.div($pagination-box-height, 2);
    height: math.div($pagination-box-height, 2);
  }

  .pt-page-right-chevron {
    width: math.div($pagination-box-height, 2);
    height: math.div($pagination-box-height, 2);
    text-align: right;
  }

  .pt-first-page-left-chevron {
    @extend .pt-page-left-chevron;
    margin: 0 3px 0 5px;
  }

  .pt-last-page-right-chevron {
    @extend .pt-page-right-chevron;
    margin: 0 5px 0 3px;
  }

  .pt-first-page,
  .pt-last-page,
  .pt-prev-page-btn,
  .pt-next-page-btn,
  .pt-prev-page,
  .pt-next-page {
    cursor: pointer;
    border-radius: 5px;
    border: 2px solid $rc-green4;

    &:hover {
      background-color: $rc-green4;
      color: $rc-white1;
    }
  }

  .pt-page-box {
    min-width: 32px;
    padding: 5px;
  }

  .pt-page-box--active {
    @extend .pt-page-box;
    border-radius: 5px;
    background-color: $rc-yellow;
    color: $rc-white1;
  }

  .pt-page-box--collapse {
    font-size: 32px;
    height: 10px;
    line-height: 5px;
  }

  .pt-first-page,
  .pt-last-page {
    width: 110px;
  }

  .pt-first-page {
    justify-content: flex-start;
  }

  .pt-last-page {
    justify-content: flex-end;
  }

  .pt-first-page-text,
  .pt-last-page-text {
    line-height: 0;
  }

  .pt-prev-page,
  .pt-next-page {
    width: 32px;
  }

  .dropdown {
    .dropdown-toggle {
      float: none;
    }

    .dropdown-menu > li > a {
      font-size: 14px;
      padding: 6px 20px;
      color: darken($rc-input-border-color, 32.8%) !important;

      &:hover {
        color: $rc-green6 !important;
        //border: 2px solid lighten($rc-green6, 6.8%) !important;
        background-color: $rc-green8;
      }
    }
  }

  .open.dropdown {
    .dropdown-toggle {
      background-color: darken($rc-default-color, 6.8%);
      border: 2px solid darken($rc-default-color, 6.8%) !important;
    }
  }

  .punchout-input-value > td input[type="text"] {
    color: #555555;
  }

  .no-wrap {
    white-space: nowrap;
  }

  .fill-container {
    width: 100%;
    height: 100%;
  }

  // --------------------------------------
  //
  // flex grid
  //
  // --------------------------------------

  .flex-grid {
    display: flex;
    flex-flow: column;
  }

  .flex-row {
    display: flex;
    flex-flow: row wrap;
    flex-grow: 1;
    padding: 0 10px;

    &.no-gutters {
      padding: 0;
      .flex-col {
        margin: 0;
      }
    }

    &.no-wrap {
      flex-flow: row nowrap;
    }

    &.center-content {
      justify-content: center;
    }
  }

  .flex-col {
    margin: 0 10px;
    flex: 1 1 0;
  }

  .flex-auto {
    flex: 0 1 auto;
  }

  // --------------------------------------
  // END flex grid
  // --------------------------------------

  // --------------------------------------
  //
  // flex container
  //
  // --------------------------------------

  .flex-container {
    display: flex;
    flex: 0 1 auto;

    &.align-items-center {
      align-items: center;
    }

    &.justify-content-center {
      justify-content: center;
    }
  }

  // --------------------------------------
  // END flex container
  // --------------------------------------

  // --------------------------------------
  //
  // chart grid
  //
  // --------------------------------------

  .chart-grid {
    display: flex;
    flex-flow: row wrap;
    padding: 15px;

    .chart-grid-item {
      flex: 1 1 auto;
      min-width: 500px;
      margin: 15px;
    }
  }

  // --------------------------------------
  // END char grid
  // --------------------------------------

  .level-filter-criterion-icon {
    //width: 16px;
    //height: 16px;
    opacity: 0.8;
    margin-right: 0.4em;
    //border: 1px solid white;
    //transform-origin: center;
    //transform: rotate(45deg);
    font-size: 0.8em;
    &.level-i {
      color: $junior-color;
    }
    &.level-ii {
      color: $mid-color;
    }
    &.level-iii {
      color: $senior-color;
    }
    &.level-iv {
      color: $lead-color;
    }
    &.level-v {
      color: $guru-color;
    }
  }

  // --------------------------------------
  // leaflet map
  // --------------------------------------

  .leaflet-map-container {
    position: relative;

    .leaflet-container {
      z-index: 0;
    }

    .map-tooltip {
      position: absolute;
      z-index: 1;
      top: 10px;
      right: 10px;
      background-color: rgba(255, 255, 255, 0.95);
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
      border-radius: 3px;
      padding: 8px 12px;
      min-width: 150px;
      color: #333333;
    }

    .color-scale-container {
      position: absolute;
      z-index: 1;
      bottom: 10px;
      left: 10px;
      background-color: rgba(255, 255, 255, 0.95);
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
      border-radius: 3px;
      color: #333333;
      //padding: 8px 12px;
    }
    .color-scale-segment {
      padding: 2px 6px;
      border-bottom: 12px solid;
      display: inline-block;
    }
  }

  // --------------------------------------
  // END leaflet map
  // --------------------------------------

  .punchouts-container {
    flex-flow: row wrap;
    width: auto !important;
    margin: 0 -8px;
    & > .rc-container {
      min-width: 600px;
      flex: 1 1 auto;
      margin-left: 8px;
      margin-right: 8px;
    }
  }
}
