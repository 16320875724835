$green50: #d4e3fc;
$green100: #c8e6c9;
$border-color: #edeeee;
$tab-active-color: #3498db;

.pt-worksheet {
  &__table-list {
    margin: 0;
    padding: 0;
    text-align: center;
    font-size: 14px;
    table-layout: fixed;
    border-top: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
    text-decoration: none;
    display: table;
    width: 100%;
    p {
      font-size: 14px;
    }
    li {
      padding: 10px;
      display: table-cell;
      text-transform: uppercase;
      border-right: 1px solid $border-color;

      &:last-of-type {
        border-right: 0;
      }
    }
    &--header {
      li {
        font-size: 20px;
        font-weight: bold;
        font-family: $ff-source-semibold;
      }
    }
    &--single-value-picker {
      li {
        cursor: pointer;
        transition: background 200ms;
        &:hover {
          background-color: $green100;
        }
      }
    }
    &--selected {
      background-color: $green50;
    }
    &__rate {
      font-family: $ff-source-semibold;
      font-weight: bold;
      padding-left: 5px;
      font-size: 16px;
    }
    &__header {
      //text-align: center;
      position: relative;

      padding: 10px 20px;
      &--first {
        padding-top: 0;
      }
      &--inline {
        padding: 0;
      }
      &__label {
        position: absolute;
      }

      &__rate {
        text-align: center;
        font-family: $ff-source-semibold;
        font-weight: bold;
        padding-left: 5px;
        font-size: 20px;
      }
      &--bordered {
        border-bottom: 1px solid $border-color;
      }
    }
  }
  &__field-label {
    line-height: normal;
  }

  &__has-tooltip {
    border-bottom: 1px #545555 dotted;
    cursor: pointer;
  }

  &__pointer {
    cursor: pointer;
  }

  &__calculated-results {
    &__list-group {
      padding-top: 0;
    }
  }

  &__bottom-controls {
    &__summary {
      .fa,
      &__equals {
        padding-left: 5px;
        padding-right: 5px;
      }
      &__equals {
        font-family: $ff-source-semibold;
        font-size: 16px;
      }

      &__pay-rate,
      &__bill-rate,
      &__total-markup,
      &__total-tax,
      &__bill-rate-after-tax {
        cursor: pointer;
        font-size: 16px;
        border-bottom: 1px black dotted;
      }
    }
  }
  &__group-chart {
    &__chart {
      padding: 20px;
      height: 320px;
    }
  }
}

.pt-worksheet__calculated-results {
  .pt-card__list-group__item:first-child {
    .pt-worksheet__table-list--selected {
      background-color: #fff;
      position: relative;
    }

    .pt-worksheet__table-list--selected:after {
      height: 3px;
      width: 100%;
      display: block;
      content: " ";
      bottom: 0;
      left: 0;
      position: absolute;
      background: $tab-active-color;
    }
  }
}
.negotiation-worksheet {
  .row,
  .rc-container {
    &:after,
    &:before {
      display: table;
      content: " ";
    }
  }
  .rc-container {
    display: table;
    overflow: visible;
    width: 100%;
  }
  .fa {
    margin-left: 5px;
  }
  p {
    font-size: 14px !important;
  }
  div {
    font-size: 14px !important;
  }
}
.pt-card {
  font-size: 14px;
  &__list-group {
    .form-control {
      border-right: 1px solid #ddd !important;
      z-index: 0;
    }
  }
}
.cursor-default-ws .ratecard-list-item,
.cursor-default-ws .item-subtext-muted {
  cursor: default !important;
}

.ws-alert {
  width: calc(100% - 183px);
  padding: 13px;
}

.collapse-btn-cover {
  display: inline-block;
  width: 100%;
  padding: 10px 0 0;
  border: none !important;
}

.ws-table .ratecard-info {
  min-width: 140px;
}
.margin-top-5 {
  margin-top: 5px !important;
}
.punchouts .header-action-bar .btn-group.pull-right.rc-center {
  margin-top: 1px;
}
.regions {
  &.negotiation {
    &-worksheet {
      .Select-control {
        padding: 0px 5px 8px 3px !important;
      }
      .Select-placeholder {
        padding: 6px 5px 6px 10px;
      }
      .Select-input {
        padding: 6px 5px 0;
        vertical-align: middle;
      }
    }
  }
}
.ladda {
  &-spinner {
    div {
      top: 15px;
    }
  }
}
.pce-search {
  margin: 0 -20px;
  &.search-list-item-container {
    .search-peek {
      &-expand {
        .search-peek {
          &-body {
            .search-peek {
              &-levels {
                padding: 0px;
                .view {
                  .rc-container {
                    margin-bottom: 10px;
                    padding-bottom: 0px;
                    margin-top: 0px;
                    .info_head {
                      padding: 0 0 10px 0;
                      border-bottom: 1px solid #eceff1;
                      margin-bottom: 10px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.create-pce {
  padding: 0 20px;
  .ratecards-table-header {
    border-radius: 0px;
  }
  .ratecards {
    &-list {
      .rc {
        &-container {
          .header-action-bar {
            //padding-top:20px;
            //  padding-bottom: 20px;
            .pt-search {
              margin: 5px;
            }
            .ratecards-table-header {
              padding: 0px !important;
              .header-right-nav {
                margin-top: 5px;
              }
            }
          }
        }
      }
    }
  }
}
.marginminus20 {
  margin: 0 -20px;
}
