@import "../../../base/colors";
@import "../../../base/labor_index/typography";
@import "../../../base/device_types";

.labor-index-container {
  background-color: $white;

  .pt-header {
    margin-left: -15px;
    margin-right: -15px;
  }

  .pt-footer {
    margin-left: -15px;
    margin-right: -15px;
  }

  .index_select {
    margin-top: 50px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .labor-index,
  .labor-index-detail {
    margin: 50px 0;

    &__controls {
      & .range-type-picker,
      & .rate-type-picker {
        float: left;
        margin-right: 18px;
        margin-bottom: 18px;

        &:last-child {
          margin-right: 0;
        }
      }
      & .rate-type-picker .single-picker__option {
        font-size: 16px;
      }
    }

    &__table-line {
      margin-top: 30px;
    }

    &__table-title {
      margin: 0 0 20px 0 !important;
      color: $blue-dark;
    }

    .pt-overlay-messager .pt-messager {
      height: 175px;
    }

    &__overlay-message {
      display: inline-block;
      padding: 20px;
      background-color: $white;

      -webkit-box-shadow: 2px 2px 4px 3px rgba($gray-light, 0.6);
      -moz-box-shadow: 2px 2px 4px 3px rgba($gray-light, 0.6);
      box-shadow: 2px 2px 4px 3px rgba($gray-light, 0.6);

      span {
        display: block;
        font-size: 30px;
        color: $gray-darkest;
        margin-bottom: 20px;
      }
    }
  }
}

@media (max-width: 992px) {
  .labor-index-container {
    .labor-index,
    .labor-index-detail {
      &__side-panel {
        margin-top: 30px;
      }
    }
  }
}

@media #{$mobile-only} {
  .labor-index,
  .labor-index-detail {
    &__table-container {
      background-color: #eeeeee;
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
    }
  }
}

@media (min-width: 1281px) {
  .labor-index-container {
    .labor-index,
    .labor-index-detail {
      padding-left: 75px;
      padding-right: 75px;
    }
  }
}
