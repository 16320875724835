@import "../../base/colors";

.pt-range-filter {
  position: relative;
  text-align: left;

  &__item {
    padding-top: 10px;
    padding-left: 10px;
    white-space: nowrap;

    & > label {
      display: inline-block;
      margin-right: 5px;
      width: 40px;
      color: $gray-font;
    }
  }
}
