@import "../../base/colors";
@import "./utils";

@import "components/contractor_analysis_values";
@import "components/index_resources_visualizer";
@import "components/stored_index_chart";
@import "components/rates_chart";
@import "components/regular_contractor_editor";
@import "components/index_trend_values";
@import "components/processing_progress_bar";
@import "components/savings_bar";
@import "components/running_processings_alert";

// overrides

.pt-ui .pt-private-index,
.pt-private-index {
  position: relative;
  width: 100%;
  font-size: 14px;

  h2,
  h3,
  h4,
  h5 {
    margin-top: 0;
    margin-bottom: 0;
    color: inherit;
  }
}
