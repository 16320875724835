@import "../../../base/labor_index/typography";
@import "../../../base/colors";

$selectVPadding: 10px;
$selectArrowWidth: 5px;

@mixin applyVPadding($value) {
  padding-top: $value;
  padding-bottom: $value;
}

.rate-type-select {
  display: inline-block;
  min-width: 110px;
  max-width: 150px;
  font-family: $ff-source-regular;

  // react-select custom styles

  .Select-control {
    @include applyVPadding($selectVPadding);
    border-radius: 0;
    border-color: $gray-darker;

    .Select-placeholder {
      @include applyVPadding($selectVPadding);
      color: $olive;
    }

    .Select-arrow {
      border-color: $olive transparent transparent;
      top: 50%;
      margin-top: -($selectArrowWidth * 0.5);
    }
  }

  &.is-focused:not(.is-open) > .Select-control {
    border-color: #a2b624;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1), 0 0 5px -1px rgba(151, 169, 0, 0.5);
  }

  &.is-open > .Select-control > .Select-arrow {
    border-color: transparent transparent $olive;
  }

  .Select-menu-outer {
    border-radius: 0;

    .Select-menu {
      .Select-option {
        @include applyVPadding($selectVPadding);
        color: $olive;

        &:last-child {
          border-radius: 0;
        }

        &.is-focused {
          background-color: $gray-light;
        }
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
}
