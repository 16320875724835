@import "../../../base/colors";

.pt-stored-index-resources-visualizer {
  border: 1px solid $gray-border;
  border-radius: 5px;

  &__row {
    border-bottom: 1px solid $gray-border;

    &:last-child {
      border-bottom: 0;
    }
  }
}
