.pt-modal-messager {
  .modal-content {
    border: 0;
    box-shadow: none;
    text-align: center;
    background: transparent;
  }
  .modal-body {
    text-align: center;

    & > * {
      text-align: left;
    }

    h4,
    h3,
    h2,
    h1 {
      margin-top: 0;
    }
  }
}
