@import "../base/colors";

.arrow-chart {
  padding: 10px 0;
  font-size: 14px;

  ul {
    padding: 0;
  }
  li {
    display: inline-block;
  }

  &-graph {
    position: relative;

    @for $i from 1 through 6 {
      :nth-child(#{$i}) {
        background-color: darken(#fff, 5% * $i);
      }
    }
    li {
      width: 16.667%;
      height: 64px;
    }
    :nth-child(1) {
      &::after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border-left: 32px solid white;
        border-top: 32px solid transparent;
        border-bottom: 32px solid transparent;
      }
    }
    :nth-child(4) {
      border-left: 2px dashed white;
    }
    :nth-child(6) {
      &::after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        right: 0;
        border-left: 32px solid transparent;
        border-top: 32px solid white;
        border-bottom: 32px solid white;
      }
    }
  }

  &-your-rate {
    padding: 0.5rem 0.6rem;
    background: $lime;
    width: 200px;
    margin: 0 auto 1rem;
    text-align: center;
    position: relative;
    z-index: 100;
    color: #fff;
    font-size: 18px;

    @media (max-width: 400px) {
      font-size: 12px;
    }
  }

  &-top-labels {
    padding: 0;
    margin: 0;
    text-align: center;

    @media (max-width: 400px) {
      font-size: 12px;
    }

    li {
      width: 16.667%;
      vertical-align: bottom;
      padding-bottom: 1rem;
    }

    li:nth-of-type(3) {
      padding-bottom: 0;

      .arrow {
        height: 0;
        &::after {
          content: "";
          width: 0;
          height: 0;
          border-top: 4rem solid $lime;
          border-left: 1rem solid transparent;
          border-right: 1rem solid transparent;
        }
      }
    }
  }

  &-labels {
    padding: 0;
    margin: 0;
    text-align: center;

    @media (max-width: 400px) {
      font-size: 12px;
    }

    li {
      width: 16.667%;
    }
  }
}
