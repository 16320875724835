@import "../../../base/labor_index/typography";
@import "../../../base/colors";

.index-info {
  margin-bottom: 1.5rem;

  .index-number {
    font-family: $ff-source-regular;
    font-size: 22px;
    margin-top: 35px;

    &__heading {
      float: left;
      margin-top: 0;
      margin-bottom: 12px;
    }

    &__change,
    &__percent-change {
      float: right;
    }

    &__percent-change {
      clear: right;
    }

    &__rule {
      clear: both;
      margin-top: 0;
      margin-bottom: 12px;
    }
  }

  .annual,
  .hourly {
    margin-top: 20px;
    min-width: 150px;
    padding-right: 0;

    small,
    span {
      white-space: nowrap;
    }
  }

  .name {
    background: $white;
    font-family: $ff-source-semibold;
    color: #212121;

    h1 {
      font-size: 32px;
      line-height: 40px;
      margin: 0;
      padding: 0;
    }
  }

  .header {
    font-family: $ff-source-regular;
    font-size: 14px;
    color: $gray;
    line-height: 14px;
    margin: 10px 0;
    text-transform: uppercase;
    display: block;
  }

  .current {
    font-family: $ff-source-light;
    font-size: 34px;
    color: #212121;

    /* Aligns the icons to the current value */
    .pt-ico-moon {
      line-height: 35px; /* Not sure why. Should be 34 or 42 but looked best at 35 */
      vertical-align: middle;
    }

    /* minus icon didn't fit right */
    .neutral {
      font-size: 32px;
      padding-right: 5px;
    }
  }

  .change {
    display: block;
    margin: 10px 0;
    font-family: $ff-source-light;
    font-size: 18px;
    color: $black;
    letter-spacing: 0.25px;
    line-height: 18px;
  }

  .date {
    font-family: $ff-source-regular;
    font-style: italic;
    margin-top: 10px;
    font-size: 14px;
    color: $gray;
    line-height: 14px;
    display: block;
  }

  .name {
    background: $white;
    font-family: $ff-source-semibold;
    color: #212121;

    h1 {
      font-size: 32px;
      line-height: 40px;
      margin: 0;
      padding: 0;
    }
  }

  .annual,
  .hourly {
    margin-top: 20px;
  }
}

.index-info,
.pt-rates-card {
  .annual,
  .hourly {
    min-width: 150px;
    padding-right: 0;

    small,
    span {
      white-space: nowrap;
    }
  }

  .header {
    font-family: $ff-source-regular;
    font-size: 14px;
    color: $gray;
    line-height: 14px;
    margin: 10px 0;
    text-transform: uppercase;
    display: block;
  }

  .current {
    font-family: $ff-source-light;
    font-size: 34px;
    color: #212121;

    /* Aligns the icons to the current value */
    .pt-ico-moon {
      line-height: 35px; /* Not sure why. Should be 34 or 42 but looked best at 35 */
      vertical-align: middle;
    }

    /* minus icon didn't fit right */
    .neutral {
      font-size: 32px;
      padding-right: 5px;
    }
  }

  .change {
    display: block;
    margin: 10px 0;
    font-family: $ff-source-light;
    font-size: 18px;
    color: $black;
    letter-spacing: 0.25px;
    line-height: 18px;
  }

  .date {
    font-family: $ff-source-regular;
    font-style: italic;
    margin-top: 10px;
    font-size: 14px;
    color: $gray;
    line-height: 14px;
    display: block;
  }
}

.pt-rates-card {
  .change {
    margin-bottom: 0;
  }
}
