.rate-type-picker {
  .single-picker {
    &__option {
      font-size: 19px;
    }
    &__label {
      font-size: 15px;
    }
  }
}
