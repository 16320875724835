.pt-ui .pt-rate-card-validate,
.pt-rate-card-validate {
  position: relative;
  width: 100%;
  font-size: 14px;

  h2,
  h3,
  h4,
  h5 {
    margin-top: 0;
    margin-bottom: 0;
    color: inherit;
  }
}
