#add-user {
  width: 600px;
  margin: 25px auto;
}
#add-user .panel-body {
  overflow: hidden;
  width: 600px;
}
#add-user .panel-body .modal-content {
  background-image: -webkit-linear-gradient(top, #ffffff 0%, #f6f6f6 100%);
  background-image: linear-gradient(to bottom, #ffffff 0%, #f6f6f6 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#fff6f6f6', GradientType=0);
}
#add-user .panel-body .stepc {
  position: relative;
}
#add-user .panel-body .close,
#add-user .panel-body .add-new,
#add-user .panel-body .remove-new {
  width: 22px;
  height: 22px;
  float: none;
  position: absolute;
  top: -30px;
  left: -10px;
  text-align: center;
  padding-left: 1px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
  box-shadow: inset 1px 0.5px 2px rgba(0, 0, 0, 0.2);
  opacity: 1;
  color: #ffffff;
  border-radius: 50%;
  background-color: #d7d7d7;
}
#add-user .panel-body .close .fa,
#add-user .panel-body .add-new .fa,
#add-user .panel-body .remove-new .fa {
  position: absolute;
  font-size: 14px;
  top: 4px;
  left: 5px;
}
#add-user .panel-body .add-new,
#add-user .panel-body .remove-new {
  top: 12px;
  left: 19px;
  border: 0;
}
#add-user .panel-body .add-new .fa,
#add-user .panel-body .remove-new .fa {
  top: 5px;
  left: 5.5px;
}
#add-user .panel-body input[type="text"],
#add-user .panel-body input[type="password"] {
  margin-bottom: 15px;
}
#add-user .panel-body input[type="text"]::placeholder,
#add-user .panel-body input[type="password"]::placeholder {
  font-weight: 300;
}
#add-user .panel-body input[type="text"]::-moz-placeholder,
#add-user .panel-body input[type="password"]::-moz-placeholder {
  font-weight: 300;
}
#add-user .panel-body input[type="text"]::-webkit-placeholder,
#add-user .panel-body input[type="password"]::-webkit-placeholder {
  font-weight: 300;
}
#add-user .panel-body input[type="text"].has-error,
#add-user .panel-body input[type="password"].has-error {
  border-color: #f41323;
}
#add-user .panel-body input[type="button"] {
  display: inline-block;
  padding: 10px 45px;
  border-radius: 3px;
  margin-top: 10px;
}
#add-user .panel-body #go-login {
  display: inline-block;
  padding: 9.5px 45px;
  border-radius: 3px;
  margin-top: 10px;
}
#add-user .panel-body .steps {
  position: relative;
  width: 500px;
  margin: 25px auto 0;
  text-align: center;
}
#add-user .panel-body .steps:after {
  position: absolute;
  z-index: 2;
  top: 16.5px;
  left: 70px;
  width: 350px;
  content: "";
  height: 1px;
  border-bottom: 2px solid #dddddd;
}
#add-user .panel-body .steps .step {
  position: relative;
  z-index: 3;
  width: 130px;
  margin: 0 15px;
  display: inline-block;
  text-align: center;
  vertical-align: top;
}
#add-user .panel-body .steps .step .number {
  border-radius: 50%;
  width: 35px;
  height: 35px;
  text-align: center;
  color: #cccccc;
  padding-left: 1px;
  text-shadow: 1px 2px 2px rgba(0, 0, 0, 0.3);
  line-height: 33px;
  font-size: 20px;
  display: inline-block;
  background-image: -webkit-linear-gradient(top, #999999 0%, #555555 100%);
  background-image: linear-gradient(to bottom, #999999 0%, #555555 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff999999', endColorstr='#ff555555', GradientType=0);
  box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.3);
}
#add-user .panel-body .steps .step .text {
  margin-top: 10px;
  font-size: 17px;
  line-height: 22px;
  color: #bbbbbb;
}
#add-user .panel-body .steps .step.active .number {
  background-image: -webkit-linear-gradient(top, #c2d646 0%, #aabf2a 100%);
  background-image: linear-gradient(to bottom, #c2d646 0%, #aabf2a 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffc2d646', endColorstr='#ffaabf2a', GradientType=0);
  color: #ffffff;
}
#add-user .panel-body .steps .step.active .text {
  color: #777777;
}
#add-user .panel-body .form {
  width: 500px;
  margin: 15px auto;
  text-align: center;
}
#add-user .panel-body .form .required-text {
  display: block;
  color: #bbbbbb;
  padding-bottom: 10px;
  padding-left: 10px;
  text-align: left;
}
#add-user .panel-body .form .select2-container {
  width: 100%;
  margin-bottom: 15px;
}
#add-user .panel-body .form .select2-container .select2-choice {
  height: 46.5px;
}
#add-user .panel-body .form .select2-container .select2-chosen {
  text-align: left;
  font-size: 18px;
  padding-top: 7px;
  padding-left: 5px;
  color: #aaaaaa;
}
#add-user .panel-body .form .select2-container.has-error .select2-choice {
  border-color: #f41323;
}
#add-user .panel-body .package-info {
  margin-top: 25px;
}
#add-user .panel-body .package-info > div {
  margin-top: 10px;
  font-size: 24px;
}
#add-user .panel-body .package-info .icon > img {
  width: 120px;
}
#add-user .panel-body .package-info .desc {
  font-size: 18px;
  color: #999999;
}
#add-user .panel-body .total-charges {
  border-top: 1px dashed #cccccc;
  border-bottom: 1px dashed #cccccc;
  margin-bottom: 15px;
  font-size: 26px;
}
#add-user .panel-body .total-charges:before,
#add-user .panel-body .total-charges:after {
  content: " ";
  display: table;
}
#add-user .panel-body .total-charges:after {
  clear: both;
}
#add-user .panel-body .total-charges .text {
  float: left;
  padding-top: 14px;
}
#add-user .panel-body .total-charges .price {
  float: right;
  font-size: 38px;
  color: #c2d646;
}
#add-user .panel-body .total-charges .price .currency {
  font-size: 20px;
  vertical-align: top;
  display: inline-block;
  padding-top: 8px;
}
#add-user .panel-body .instant-box {
  margin-top: 25px;
  border: 1px solid #dddddd;
  border-radius: 5px;
}
#add-user .panel-body .instant-box.ntm {
  margin-top: 0;
}
#add-user .panel-body .instant-box .top {
  border-bottom: 1px solid #dddddd;
  text-align: left;
  padding: 4px 10px 1px;
}
#add-user .panel-body .instant-box .top label {
  font-size: 18px;
  font-weight: 400;
  color: #444444;
}
#add-user .panel-body .instant-box .top label input {
  margin-right: 5px;
}
#add-user .panel-body .instant-box .bottom {
  padding: 5px 10px;
  text-align: justify;
  font-size: 14px;
  line-height: 18px;
  color: #aaaaaa;
}
#add-user .panel-body .instant-box .bottom.large {
  font-size: 16px;
  line-height: 20px;
}
#add-user .panel-body .instant-box .bottom + .input-icon {
  margin-top: 2px;
  margin-bottom: -16px;
  margin-left: -1px;
  margin-right: -1px;
}
#add-user .panel-body dl {
  text-align: left;
  margin-top: 30px;
}
#add-user .panel-body dl dt {
  margin-top: 15px;
  color: #666666;
  font-weight: 400;
}
#add-user .panel-body dl dd {
  font-size: 12px;
  color: #aaaaaa;
  text-align: justify;
}
#add-user .panel-body .input-icon {
  position: relative;
  display: block;
}
#add-user .panel-body .input-icon:before,
#add-user .panel-body .input-icon:after {
  content: " ";
  display: table;
}
#add-user .panel-body .input-icon:after {
  clear: both;
}
#add-user .panel-body .input-icon.onleft .first {
  position: absolute;
  left: 3px;
  top: 10px;
  font-size: 18px;
  width: 40px;
  text-align: center;
}
#add-user .panel-body .input-icon.onleft input[type="text"] {
  padding-left: 40px;
}
#add-user .panel-body .thankyou {
  margin-top: 45px;
  font-size: 18px;
  color: #aaaaaa;
  text-align: center;
}
#add-user .panel-body .thankyou h3 {
  color: #444444;
  font-size: 28px;
}
#add-user .panel-body .thankyou input,
#add-user .panel-body .thankyou button,
#add-user .panel-body .thankyou #go-login {
  margin-top: 55px;
}

#add-user .row .col-md-5:first-child,
#add-user .row .col-md-6:first-child {
  padding-left: 0;
}
#add-user .row .col-md-4:last-child,
#add-user .row .col-md-6:last-child,
#add-user .selectedcountry .col-xs-11 {
  padding-right: 0;
}
#add-user .select2-container .select2-choice {
  min-width: 100% !important;
}
#add-user .select2-arrow {
  margin-top: 8px;
  margin-right: 5px;
}
#backtostep1,
.backtostep2 {
  margin-top: 10px;
  margin-left: 10px;
}
