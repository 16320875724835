@import "../utils/mixins";
@import "../base/colors";

$header-background-color: #333333;
$logo-big-width: 248px;
$logo-big-height: 38px;
$logo-medium-width: 196px;
$logo-medium-height: 30px;
$logo-small-width: 38px;
$logo-small-height: 38px;

.pt-ext-link {
  position: relative;

  &:after {
    content: "\e095";
    position: absolute;
    font-size: 12px;
    top: -5px;
    right: -20px;
    display: inline-block;
    font-family: "Glyphicons Halflings";
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

.pt-ddlist {
  position: relative;
  z-index: 1000;

  &__label {
    display: block;
    padding: 25px 25px 25px 14px;
    color: $gray-light !important;
    text-decoration: none !important;
    cursor: pointer;

    &:after {
      content: "";
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 6px solid $gray-light;
      position: absolute;
      top: 50%;
      right: 7px;
      margin-top: -2px;
    }
  }

  &:hover &__label,
  &--active &__label {
    color: $olive !important;
    &:after {
      border-top: 6px solid $olive;
    }
  }

  &__list {
    display: none;
    position: absolute;
    width: 160px;
    top: 100%;
    left: 50%;
    margin-left: -80px;
    z-index: 100;
    background: $white;
    border: 1px solid rgba($black, 0.15);
    box-shadow: 0 6px 12px rgba($black, 0.175);
    text-align: left;

    padding: 5px 0;
    margin-top: 5px;
    border-radius: 4px;
    list-style: none;

    &--opened {
      display: block;
    }

    &:before {
      content: "";
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid transparent;
      border-bottom: 8px solid $white;
      position: absolute;
      top: -16px;
      left: 50%;
      margin-left: -8px;
    }
  }

  &__item {
    display: block;
    font-size: 14px !important;
    color: $header-background-color !important;

    & > a {
      display: block;
      padding: 3px 20px;
      color: $header-background-color !important;
      text-decoration: none;

      &.active {
        background: $header-background-color;
        color: white !important;
      }
    }

    &:hover {
      background: $gray-light;
    }
  }
}

.pt-nav {
  text-align: center;
  font-size: 17px;

  &__list {
    display: block;
    padding-left: 0;
    margin-bottom: 0;
  }
  &__item {
    display: block;
    position: relative;

    & > a {
      display: block;
      padding: 15px 10px;
      text-decoration: none;
      color: #eeeeee !important;
      cursor: pointer;

      &:hover,
      &.active {
        color: $olive !important;
      }
    }
  }
}

.pt-header {
  background: $header-background-color;
  border-bottom: 1px solid $olive;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;

  // general header blocks

  &__content {
    display: block;
    position: relative;
    height: 50px;
    overflow: hidden;

    -webkit-transition: height 0.5s ease;
    -moz-transition: height 0.5s ease;
    -o-transition: height 0.5s ease;
    transition: height 0.5s ease;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &--opened {
      height: 642px;
      // height: auto;
    }
  }
  &__left,
  &__right,
  &__center {
    @include clearfix();
    white-space: nowrap;
    display: block;
  }
  &__left {
    display: block;
    text-align: left;
    padding: 10px 0;
  }

  // header parts

  &__logo {
    display: inline-block;
    float: left;
    width: $logo-medium-width;
    height: $logo-medium-height;
    background: url("../../assets/img/logo-light.png") no-repeat;
    background-size: $logo-medium-width $logo-medium-height;
  }

  &__nav-toggle {
    display: inline-block;
    float: right;
    font-size: 30px;
    color: $gray-light;
    cursor: pointer;

    &:hover {
      color: $olive;
    }
  }

  &__auth {
    margin-top: 20px;
    margin-bottom: 30px;
  }

  &__button {
    width: 45%;

    &--gray {
      background: #666666;
    }
    &--olive {
      margin-left: 10px;
      background: $olive;
    }
  }
}

@media (max-width: 769px) {
  .pt-ddlist {
    &__label {
      padding: 15px 10px;

      &:after {
        content: none;
      }
    }

    &__list {
      display: block;
      position: relative;
      top: 0;
      left: 0;
      width: 100%;
      margin-left: 0;
      z-index: 100;
      text-align: center;
      background: #282828;

      &:before {
        border-bottom: 8px solid #282828;
      }
    }

    &__item {
      color: $white !important;

      & > a {
        color: $white !important;

        &.active {
          background: none;
          color: $olive !important;
        }
      }

      &:hover {
        background: #242424;
      }
    }
  }
}

@media (min-width: 770px) {
  .pt-nav {
    &__list {
      display: inline-block;
    }
    &__item {
      display: inline-block;

      & > a {
        padding: 25px 14px;
      }
    }
  }

  .pt-header {
    // general header blocks

    &__content {
      display: inline-block;
      height: auto;
      overflow: inherit;
    }

    &__left {
      display: block;
      margin-top: 10px;
    }
    &__right {
      display: inline-block;
      position: absolute;
      top: 0;
      right: 0;
      margin-top: 15px;
    }
    &__center {
      display: block;
      width: 100%;
    }

    // header parts

    &__logo {
      float: none;
      width: $logo-big-width;
      height: $logo-big-height;
      background: url("../../assets/img/logo-light.png") no-repeat;
    }

    &__nav-toggle {
      display: none;
    }

    &__auth {
      margin-top: 0;
      margin-bottom: 0;
    }

    &__button {
      display: inline-block;
      width: auto;
    }
  }
}

@media (min-width: 1100px) {
  .pt-header {
    padding-left: 75px;
    padding-right: 75px;

    // general header blocks

    &__left {
      display: table-cell;
      position: relative;
      vertical-align: middle;
      float: none;
      padding: 20px 10px;
      margin-top: 0;
    }

    &__right {
      display: table-cell;
      position: relative;
      vertical-align: middle;
      float: none;
      padding: 15px 10px;
    }

    &__center {
      display: table-cell;
      position: relative;
      vertical-align: middle;
      float: none;
      // setting width: 0 on a table cell element will compress it to the size of the
      // containing elements.
      width: 0;
      padding: 0 15px 0 0;
    }

    // header parts

    &__logo {
      width: $logo-small-width;
      height: $logo-small-height;
      //background: url("../../assets/img/logo-micro.png") no-repeat;
    }
  }
}

@media (min-width: 1300px) {
  .pt-header {
    // header parts

    &__logo {
      width: $logo-big-width;
      height: $logo-big-height;
      background: url("../../assets/img/logo-light.png") no-repeat;
    }
  }
}
