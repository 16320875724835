@import "../base/colors";
@import "../utils/mixins";

.pt-confirm-modal {
  position: relative;

  .modal-header .close {
    @include pt-no-select;
    @include pt-no-focus-outline;
  }

  .modal-content {
    border-radius: 5px;
  }

  .modal-body {
    min-height: 100px;
  }

  .pt-button {
    margin-right: 10px;
    margin-bottom: 5px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__red-button {
    background: #d9534f !important;
  }
  &__green-button {
    background: #5cb85c !important;
  }
  &__yellow-button {
    background-color: $yellow !important;
  }
  &__gray-button {
    background-color: $gray !important;
  }
}
