@font-face {
  font-family: "IcoMoon Ultimate";
  src: url("../../../assets/fonts/IcoMoon-Ultimate.ttf") format("truetype");
}

@font-face {
  font-family: "Source Sans Pro Light";
  src: url("../../../assets/fonts/SourceSansPro-Light.ttf.woff") format("woff"),
    url("../../../assets/fonts/SourceSansPro-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Source Sans Pro Regular";
  src: url("../../../assets/fonts/SourceSansPro-Regular.ttf.woff") format("woff"),
    url("../../../assets/fonts/SourceSansPro-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Source Sans Pro Semibold";
  src: url("../../../assets/fonts/SourceSansPro-Semibold.ttf.woff") format("woff"),
    url("../../../assets/fonts/SourceSansPro-Semibold.ttf") format("truetype");
}

@font-face {
  font-family: "Proxima Nova Semibold";
  src: url("../../../assets/fonts/SourceSansPro-Semibold.ttf.woff") format("woff"),
    url("../../../assets/fonts/SourceSansPro-Semibold.ttf") format("truetype");
}

$ff-source-light: "Source Sans Pro Light", "Arial", sans-serif;
$ff-source-regular: "Source Sans Pro Regular", "Arial", sans-serif;
$ff-source-semibold: "Source Sans Pro Semibold", "Arial", sans-serif;

$ff-proxima-semibold: "Proxima Nova Semibold", "Arial", sans-serif;

body {
  font-family: "Source Sans Pro Regular", "Arial", sans-serif;
}

h1,
h2,
h3 {
  font-family: "Source Sans Pro Semibold", "Arial", sans-serif;
}

h4,
h5,
h6 {
  font-family: "Proxima Nova Semibold", "Arial", sans-serif;
}

.pt-ico-moon {
  font-family: "IcoMoon Ultimate";
}
