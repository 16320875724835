/* Form styles */
.form-group .row {
  margin-bottom: 15px;
}
@media (max-width: 1199px) {
  .form-group .row [class*="col-lg-"],
  .form-group .row [class*="col-md-"] {
    margin-bottom: 15px;
  }
  .form-group .row [class*="col-lg-"]:last-child,
  .form-group .row [class*="col-md-"]:last-child {
    margin-bottom: 0;
  }
}
.form-group .row:last-child {
  margin-bottom: 0;
}
form > .checkbox {
  padding-left: 0;
}
form .form-group.has-warning > label {
  color: #f1c40f;
}
form .form-group.has-warning .form-control {
  border-color: #f1c40f;
  -webkit-box-shadow: none;
  box-shadow: none;
}
form .form-group.has-error > label {
  color: #e74c3c;
}
form .form-group.has-error .form-control {
  border-color: #e74c3c;
  -webkit-box-shadow: none;
  box-shadow: none;
}
form .form-group.has-success > label {
  color: #2ecc71;
}
form .form-group.has-success .form-control {
  border-color: #2ecc71;
  -webkit-box-shadow: none;
  box-shadow: none;
}
form .form-group.has-info > label {
  color: #3498db;
}
form .form-group.has-info .form-control {
  border-color: #3498db;
  -webkit-box-shadow: none;
  box-shadow: none;
}
form .form-group.has-purple > label {
  color: #9b59b6;
}
form .form-group.has-purple .form-control {
  border-color: #9b59b6;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.form-horizontal {
  /*
	.controls {
		padding: 10px 10px 10px 0;
		
		label {
			margin-top: 6px;
			margin-right: 10px;
			
			div[class^="iradio_flat-"], div[class^="icheckbox_flat-"] {
				vertical-align: middle;
				margin-top: -3px;
			}
		}
		> .row {
			> [class*="col-"] {
				padding-left: 0;
				&:last-child {
					padding-right: 0;
				}
				> .input-group {
					padding: 0;
				}
			}
		}
	}
	*/
}
.form-horizontal .form-group {
  margin: 0;
  padding: 10px 0;
  border-bottom: 1px solid #eeeeee;
}
.form-horizontal .form-group:last-child {
  border-bottom: 0;
}
.form-horizontal .form-group > [class*="col-"] > .row > [class*="col-"]:first-child {
  padding-left: 0;
}
.form-horizontal .form-group > [class*="col-"] > .row > [class*="col-"]:last-child {
  padding-right: 0;
}
.form-horizontal .form-group > div > label.einput,
.form-horizontal .form-group > div > label.einput-inline {
  margin-top: 6px;
}
.form-horizontal .control-label {
  font-weight: bold;
  color: #777777;
}
@media (min-width: 768px) and (max-width: 991px) {
  .form-horizontal .control-label.col-sm-12 {
    text-align: left;
    margin-bottom: 10px;
  }
}

.form-horizontal .form-actions {
  background-color: #fbfbfb;
  padding: 10px 0;
}

/*
.form-horizontal .form-actions:before,
.form-horizontal .form-actions:after {
  content: " ";
  display: table;
}
.form-horizontal .form-actions:after {
  clear: both;
}
*/
.nopadding .form-horizontal .form-actions {
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}
@media (max-width: 767px) {
  .form-horizontal .control-label {
    padding-left: 15px;
    padding-bottom: 10px;
    position: relative;
  }
}
.form-horizontal .input-group {
  padding: 0;
}
@media (max-width: 991px) {
  .form-horizontal .form-group [class*="col-"] > .row > [class*="col-"] {
    padding-left: 0;
    padding-right: 0;
  }
}
.control-label {
  font-weight: normal;
}
.form-control {
  border-color: #dddddd;
  color: #888888;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.form-control:-moz-placeholder {
  color: #bbbbbb;
}
.form-control::-moz-placeholder {
  color: #bbbbbb;
  opacity: 1;
}
.form-control:-ms-input-placeholder {
  color: #bbbbbb;
}
.form-control::-webkit-input-placeholder {
  color: #bbbbbb;
}
.form-control:focus {
  //-webkit-box-shadow: none !important;
  //box-shadow: none !important;
}
.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  background-color: #f9f9f9;
  cursor: not-allowed;
}
.form-control[disabled]:-moz-placeholder,
.form-control[readonly]:-moz-placeholder,
fieldset[disabled] .form-control:-moz-placeholder {
  color: #bbbbbb;
}
.form-control[disabled]::-moz-placeholder,
.form-control[readonly]::-moz-placeholder,
fieldset[disabled] .form-control::-moz-placeholder {
  color: #bbbbbb;
  opacity: 1;
}
.form-control[disabled]:-ms-input-placeholder,
.form-control[readonly]:-ms-input-placeholder,
fieldset[disabled] .form-control:-ms-input-placeholder {
  color: #bbbbbb;
}
.form-control[disabled]::-webkit-input-placeholder,
.form-control[readonly]::-webkit-input-placeholder,
fieldset[disabled] .form-control::-webkit-input-placeholder {
  color: #bbbbbb;
}
.form-control.placeholder {
  color: #bbbbbb;
}
.form-control-static.selected-state,
.form-control-static.selected-province,
.form-control-static.selected-region {
  padding-bottom: 15px;
}
.form-actions:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
.form-actions.standalone {
  padding: 10px 15px;
  background-color: #f9f9f9;
}
#btnAnalystHelp {
  color: #ffffff !important;
}
.help-block,
.help-inline {
  color: #bbbbbb;
  font-size: 12px;
}
.form-group.has-error .help-block,
.form-group.has-error .help-inline {
  color: #e74c3c;
}
.form-group.has-success .help-block,
.form-group.has-success .help-inline {
  color: #2ecc71;
}
.form-group.has-warning .help-block,
.form-group.has-warning .help-inline {
  color: #f1c40f;
}
.form-group.has-info .help-block,
.form-group.has-info .help-inline {
  color: #3498db;
}
.form-group.has-purple .help-block,
.form-group.has-purple .help-inline {
  color: #9b59b6;
}
.input-icon .fa,
.input-icon .glyphicon {
  position: absolute;
  top: 11px;
  left: 10px;
  color: #999999;
}
.has-error .input-icon .fa,
.has-error .input-icon .glyphicon {
  color: #e74c3c;
}
.has-warning .input-icon .fa,
.has-warning .input-icon .glyphicon {
  color: #f1c40f;
}
.has-success .input-icon .fa,
.has-success .input-icon .glyphicon {
  color: #2ecc71;
}
.has-info .input-icon .fa,
.has-info .input-icon .glyphicon {
  color: #3498db;
}
.has-purple .input-icon .fa,
.has-purple .input-icon .glyphicon {
  color: #9b59b6;
}
.input-icon .form-control {
  padding-left: 28px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.input-icon.on-right .fa,
.input-icon.on-right .glyphicon {
  right: 10px;
  left: auto;
}
.input-icon.on-right .form-control {
  padding-left: 12px;
  padding-right: 28px;
}
.input-icon.icon-sm .fa,
.input-icon.icon-sm .glyphicon {
  top: 9px;
}
.input-icon.icon-lg .fa,
.input-icon.icon-lg .glyphicon {
  font-size: 20px;
  top: 13px;
  left: 12px;
}
.input-icon.icon-lg.on-right .fa,
.input-icon.icon-lg.on-right .glyphicon {
  left: auto;
  right: 12px;
}
.input-icon.icon-lg .form-control {
  padding-left: 30px;
}
.input-icon.icon-lg.on-right .form-control {
  padding-left: 12px;
  padding-right: 30px;
}
/* CSS3-only checkboxes and radios */
label.einput,
label.einput-inline {
  margin: 0 0 4px;
  display: block;
}
label.einput input[type="checkbox"],
label.einput-inline input[type="checkbox"],
label.einput input[type="radio"],
label.einput-inline input[type="radio"] {
  position: absolute;
  left: -9999px;
}
label.einput input[type="checkbox"] + span,
label.einput-inline input[type="checkbox"] + span,
label.einput input[type="radio"] + span,
label.einput-inline input[type="radio"] + span {
  position: relative;
  padding-left: 20px;
  color: #666666;
  font-weight: normal;
}
label.einput input[type="checkbox"] + span:before,
label.einput-inline input[type="checkbox"] + span:before,
label.einput input[type="radio"] + span:before,
label.einput-inline input[type="radio"] + span:before {
  content: "";
  width: 20px;
  height: 20px;
  display: block;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  position: absolute;
  top: -1px;
  left: 0;
  font-size: 16px !important;
  line-height: 20px !important;
  text-align: center;
  color: #ffffff;
  z-index: 2;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
label.einput input[type="checkbox"] + span.text-label,
label.einput-inline input[type="checkbox"] + span.text-label,
label.einput input[type="radio"] + span.text-label,
label.einput-inline input[type="radio"] + span.text-label {
  padding-left: 27px;
}
label.einput input[type="checkbox"]:checked + span:before,
label.einput-inline input[type="checkbox"]:checked + span:before,
label.einput input[type="radio"]:checked + span:before,
label.einput-inline input[type="radio"]:checked + span:before {
  font-family: "FontAwesome";
  margin: 0;
  content: "\f00c";
}
label.einput input[type="radio"] + span:before,
label.einput-inline input[type="radio"] + span:before {
  border-radius: 50%;
  //  behavior: url('../js/PIE.htc');
}
label.einput input[type="radio"] + span:after,
label.einput-inline input[type="radio"] + span:after {
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
  position: absolute;
  width: 10px;
  height: 10px;
  content: "";
  border-radius: 50%;
  //  behavior: url('../js/PIE.htc');
  top: 4px;
  left: 5px;
  z-index: 3;
}
label.einput input[type="radio"]:checked + span:before,
label.einput-inline input[type="radio"]:checked + span:before {
  content: "";
}
label.einput:hover input[type="checkbox"] + span:before,
label.einput-inline:hover input[type="checkbox"] + span:before,
label.einput:hover input[type="radio"] + span:before,
label.einput-inline:hover input[type="radio"] + span:before {
  border-color: #999999;
}
label.einput:hover input[type="checkbox"]:active + span:before,
label.einput-inline:hover input[type="checkbox"]:active + span:before,
label.einput:hover input[type="radio"]:active + span:before,
label.einput-inline:hover input[type="radio"]:active + span:before {
  background-color: #f9f9f9;
}
label.einput .help-inline,
label.einput-inline .help-inline {
  padding-left: 10px;
}
label.einput.einput-success input[type="checkbox"]:checked + span:before,
label.einput-inline.einput-success input[type="checkbox"]:checked + span:before {
  border-color: #2ecc71 !important;
  color: #2ecc71 !important;
}
label.einput.einput-success input[type="radio"]:checked + span:before,
label.einput-inline.einput-success input[type="radio"]:checked + span:before {
  border-color: #2ecc71 !important;
}
label.einput.einput-success input[type="radio"]:checked + span:after,
label.einput-inline.einput-success input[type="radio"]:checked + span:after {
  background-color: #2ecc71 !important;
}
label.einput.einput-warning input[type="checkbox"]:checked + span:before,
label.einput-inline.einput-warning input[type="checkbox"]:checked + span:before {
  border-color: #f1c40f !important;
  color: #f1c40f !important;
}
label.einput.einput-warning input[type="radio"]:checked + span:before,
label.einput-inline.einput-warning input[type="radio"]:checked + span:before {
  border-color: #f1c40f !important;
}
label.einput.einput-warning input[type="radio"]:checked + span:after,
label.einput-inline.einput-warning input[type="radio"]:checked + span:after {
  background-color: #f1c40f !important;
}
label.einput.einput-danger input[type="checkbox"]:checked + span:before,
label.einput-inline.einput-danger input[type="checkbox"]:checked + span:before {
  border-color: #e74c3c !important;
  color: #e74c3c !important;
}
label.einput.einput-danger input[type="radio"]:checked + span:before,
label.einput-inline.einput-danger input[type="radio"]:checked + span:before {
  border-color: #e74c3c !important;
}
label.einput.einput-danger input[type="radio"]:checked + span:after,
label.einput-inline.einput-danger input[type="radio"]:checked + span:after {
  background-color: #e74c3c !important;
}
label.einput.einput-info input[type="checkbox"]:checked + span:before,
label.einput-inline.einput-info input[type="checkbox"]:checked + span:before {
  border-color: #3498db !important;
  color: #3498db !important;
}
label.einput.einput-info input[type="radio"]:checked + span:before,
label.einput-inline.einput-info input[type="radio"]:checked + span:before {
  border-color: #3498db !important;
}
label.einput.einput-info input[type="radio"]:checked + span:after,
label.einput-inline.einput-info input[type="radio"]:checked + span:after {
  background-color: #3498db !important;
}
label.einput.einput-purple input[type="checkbox"]:checked + span:before,
label.einput-inline.einput-purple input[type="checkbox"]:checked + span:before {
  border-color: #9b59b6 !important;
  color: #9b59b6 !important;
}
label.einput.einput-purple input[type="radio"]:checked + span:before,
label.einput-inline.einput-purple input[type="radio"]:checked + span:before {
  border-color: #9b59b6 !important;
}
label.einput.einput-purple input[type="radio"]:checked + span:after,
label.einput-inline.einput-purple input[type="radio"]:checked + span:after {
  background-color: #9b59b6 !important;
}
label.einput.einput-inverse input[type="checkbox"]:checked + span:before,
label.einput-inline.einput-inverse input[type="checkbox"]:checked + span:before {
  border-color: #34495e !important;
  color: #34495e !important;
}
label.einput.einput-inverse input[type="radio"]:checked + span:before,
label.einput-inline.einput-inverse input[type="radio"]:checked + span:before {
  border-color: #34495e !important;
}
label.einput.einput-inverse input[type="radio"]:checked + span:after,
label.einput-inline.einput-inverse input[type="radio"]:checked + span:after {
  background-color: #34495e !important;
}
label.einput.disabled > input[type="checkbox"] + span,
label.einput-inline.disabled > input[type="checkbox"] + span,
label.einput input[type="checkbox"]:disabled + span,
label.einput-inline input[type="checkbox"]:disabled + span,
label.einput.disabled > input[type="checkbox"]:checked + span,
label.einput-inline.disabled > input[type="checkbox"]:checked + span,
label.einput input[type="checkbox"]:checked:disabled + span,
label.einput-inline input[type="checkbox"]:checked:disabled + span {
  color: #bbbbbb;
}
label.einput.disabled > input[type="checkbox"] + span:before,
label.einput-inline.disabled > input[type="checkbox"] + span:before,
label.einput input[type="checkbox"]:disabled + span:before,
label.einput-inline input[type="checkbox"]:disabled + span:before,
label.einput.disabled > input[type="checkbox"]:checked + span:before,
label.einput-inline.disabled > input[type="checkbox"]:checked + span:before,
label.einput input[type="checkbox"]:checked:disabled + span:before,
label.einput-inline input[type="checkbox"]:checked:disabled + span:before {
  background-color: #fff !important;
  border-color: #dddddd !important;
  color: #dddddd !important;
}
label.einput.disabled > input[type="radio"] + span,
label.einput-inline.disabled > input[type="radio"] + span,
label.einput input[type="radio"]:disabled + span,
label.einput-inline input[type="radio"]:disabled + span,
label.einput.disabled > input[type="radio"]:checked + span,
label.einput-inline.disabled > input[type="radio"]:checked + span,
label.einput input[type="radio"]:checked:disabled + span,
label.einput-inline input[type="radio"]:checked:disabled + span {
  color: #aaaaaa;
}
label.einput.disabled > input[type="radio"] + span:before,
label.einput-inline.disabled > input[type="radio"] + span:before,
label.einput input[type="radio"]:disabled + span:before,
label.einput-inline input[type="radio"]:disabled + span:before,
label.einput.disabled > input[type="radio"]:checked + span:before,
label.einput-inline.disabled > input[type="radio"]:checked + span:before,
label.einput input[type="radio"]:checked:disabled + span:before,
label.einput-inline input[type="radio"]:checked:disabled + span:before {
  background-color: #fff !important;
  border-color: #dddddd !important;
}
label.einput.disabled > input[type="radio"]:checked + span:after,
label.einput-inline.disabled > input[type="radio"]:checked + span:after,
label.einput input[type="radio"]:checked:disabled + span:after,
label.einput-inline input[type="radio"]:checked:disabled + span:after {
  background-color: #dddddd !important;
}
label.einput:hover,
label.einput-inline:hover {
  cursor: pointer;
}
label.einput-inline {
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
}
label.einput-inline + label.einput-inline {
  margin-left: 15px;
}
.has-error .einput .text-label,
.has-error .einput-inline .text-label {
  color: #e74c3c;
}
.has-error .einput input[type="radio"] + span:before,
.has-error .einput-inline input[type="radio"] + span:before,
.has-error .einput input[type="checkbox"] + span:before,
.has-error .einput-inline input[type="checkbox"] + span:before {
  border-color: #e74c3c;
}
.has-error .form-control {
  border-color: #e74c3c;
}
.input-group .input-group-addon {
  position: relative;
}
.input-group .input-group-addon.red,
.input-group .input-group-addon.blue,
.input-group .input-group-addon.green,
.input-group .input-group-addon.yellow,
.input-group .input-group-addon.purple,
.input-group .input-group-addon.inverse,
.input-group .input-group-addon.primary {
  color: #ffffff;
}
.input-group .input-group-addon.red .arrow,
.input-group .input-group-addon.blue .arrow,
.input-group .input-group-addon.green .arrow,
.input-group .input-group-addon.yellow .arrow,
.input-group .input-group-addon.purple .arrow,
.input-group .input-group-addon.inverse .arrow,
.input-group .input-group-addon.primary .arrow {
  color: #000000;
  font-size: 26px;
  position: relative;
  width: 100%;
  height: 100%;
  display: block;
  z-index: 100;
  line-height: 0;
}
.input-group .input-group-addon.red .arrow:before,
.input-group .input-group-addon.blue .arrow:before,
.input-group .input-group-addon.green .arrow:before,
.input-group .input-group-addon.yellow .arrow:before,
.input-group .input-group-addon.purple .arrow:before,
.input-group .input-group-addon.inverse .arrow:before,
.input-group .input-group-addon.primary .arrow:before {
  position: absolute;
  top: 8px;
  right: -20px;
  font-family: "FontAwesome";
  content: "\f0da";
}
.input-group .input-group-addon.red:last-child .arrow:before,
.input-group .input-group-addon.blue:last-child .arrow:before,
.input-group .input-group-addon.green:last-child .arrow:before,
.input-group .input-group-addon.yellow:last-child .arrow:before,
.input-group .input-group-addon.purple:last-child .arrow:before,
.input-group .input-group-addon.inverse:last-child .arrow:before,
.input-group .input-group-addon.primary:last-child .arrow:before {
  left: -20px;
  right: auto;
  content: "\f0d9";
}
.input-group .input-group-addon.red {
  border-color: #e74c3c;
}
.input-group .input-group-addon.red .arrow {
  color: #e74c3c;
}
.input-group .input-group-addon.red:last-child .arrow {
  color: #e74c3c;
}
.input-group .input-group-addon.blue {
  border-color: #3498db;
}
.input-group .input-group-addon.blue .arrow {
  color: #3498db;
}
.input-group .input-group-addon.blue:last-child .arrow {
  color: #3498db;
}
.input-group .input-group-addon.green {
  border-color: #2ecc71;
}
.input-group .input-group-addon.green .arrow {
  color: #2ecc71;
}
.input-group .input-group-addon.green:last-child .arrow {
  color: #2ecc71;
}
.input-group .input-group-addon.yellow {
  border-color: #f1c40f;
}
.input-group .input-group-addon.yellow .arrow {
  color: #f1c40f;
}
.input-group .input-group-addon.yellow:last-child .arrow {
  color: #f1c40f;
}
.input-group .input-group-addon.purple {
  border-color: #9b59b6;
}
.input-group .input-group-addon.purple .arrow {
  color: #9b59b6;
}
.input-group .input-group-addon.purple:last-child .arrow {
  color: #9b59b6;
}
.input-group .input-group-addon.inverse {
  border-color: #34495e;
}
.input-group .input-group-addon.inverse .arrow {
  color: #34495e;
}
.input-group .input-group-addon.inverse:last-child .arrow {
  color: #34495e;
}
/* Correct label of each section */
.control-label.left-bigger {
  font-size: 18px;
  font-weight: normal;
  text-align: left;
  padding-bottom: 15px;
}
@media (min-width: 768px) {
  .control-label.two-line {
    padding-top: 0;
    margin-bottom: 0 !important;
  }
}
/* Position relative for each custom charge row, allow to correct position of delete row button */
.custom-charge {
  position: relative;
}
/* Change cursor to help cursor when hovering on item which contain help text */
.hover-info:hover {
  cursor: help;
}
.form-control.has-error {
  border-color: #e74c3c;
}
.form-horizontal .form-group [class*="col-"] > .row.normal > [class*="col-"] {
  padding-left: 10px;
  padding-right: 10px;
}
.input-group-addon {
  border-color: #dddddd;
}
.has-error .help-block,
.has-error .control-label,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline,
.control-label.has-error {
  color: #e74c3c !important;
}

.input-help {
  padding: 7px 10px 5px;
  background-color: #fafafa;
  border-width: 0 1px 1px;
  border-style: solid;
  border-color: #dddddd;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.has-error .input-help {
  border-color: #e74c3c;
}
.input-help .count.danger {
  color: #e74c3c;
}

.input-group .input-group-btn:last-child .btn-alt {
  margin-left: -1px;
  padding: 6px 15px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.has-error .input-group-btn .btn-alt {
  border-color: #e74c3c !important;
  color: #e74c3c !important;
}
.has-error .input-group-btn .btn-alt:hover,
.has-error .input-group-btn .btn-alt:active,
.has-error .input-group-btn .btn-alt:focus {
  background-color: #fafafa !important;
}
.form-check {
  padding-bottom: 4px;
}

.form-group.inline {
  float: left;
  border-bottom: 0;
}
.clearfix.fline {
  border-bottom: 1px solid #eee;
}

label.einput-inline {
  margin-left: 0;
  margin-right: 15px;
}
label.einput-inline + label.einput-inline {
  margin-left: 10px;
}

@media (max-width: 768px) {
  .form-group.inline {
    border-bottom: 1px solid #eee;
  }
  .clearfix.fline {
    border-bottom: 0;
  }
}
