/* Sidebar */
#sidebar {
  /* Search input */
}
#sidebar #search {
  margin: 5px 10px 10px;
  z-index: 1;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
#sidebar #search input[type="text"],
#sidebar #search button {
  background-color: transparent;
}
#sidebar #search input[type="text"] {
  font-size: 12px;
  line-height: 18px;
  border-radius: 4px 0 0 4px;
  padding: 7px 10px 6px;
  border: 0;
  width: 128px;
}
#sidebar #search button {
  border: 0;
  margin-top: -10px;
  padding: 4px 10px 4px;
  border-radius: 0 4px 4px 0;
}
#sidebar #search button i {
  font-size: 12px;
  margin-top: 0;
}

#usermenu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
#usermenu li {
  float: left;
  width: 130px;
}
#usermenu li.user-help,
#usermenu li.user-logout {
  width: 34px;
}
#usermenu a {
  display: block;
  font-size: 12px;
  padding: 0;
  -webkit-transition: background-color 0.3s, color 0.3s;
  -o-transition: background-color 0.3s, color 0.3s;
  transition: background-color 0.3s, color 0.3s;
}
#usermenu a i,
#usermenu a span {
  line-height: 30px;
  text-align: center;
  display: inline-block;
}
#usermenu a i {
  width: 34px;
  line-height: 32px;
  -webkit-transition: border 0.3s;
  -o-transition: border 0.3s;
  transition: border 0.3s;
}
#usermenu a span {
  width: 45.8px;
  padding-left: 10px;
}
#usermenu li.user-help a span,
#usermenu li.user-logout a span {
  display: none;
}

#sidebar-menu > .panel {
  background-color: transparent;
  border-width: 0 0 0 3px;
  border-style: solid;
  border-color: transparent;
  border-radius: 0;
  margin: 0;
  padding: 0;
  -webkit-transition: border 0.3s;
  -o-transition: border 0.3s;
  transition: border 0.3s;
}
#sidebar-menu a {
  text-decoration: none !important;
}
#sidebar-menu > .panel > a {
  padding: 0;
  display: block;
  color: #aaaaaa;
  font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12px;
  text-transform: uppercase;
  -webkit-transition: background-color 0.3s, color 0.3s;
  -o-transition: background-color 0.3s, color 0.3s;
  transition: background-color 0.3s, color 0.3s;
}
#sidebar-menu > .panel > a:before {
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid transparent;
  position: absolute;
  left: -5px;
  top: 13px;
  content: "";
  -webkit-transition: left 0.3s ease-in-out, border-left 0.3s;
  -o-transition: left 0.3s ease-in-out, border-left 0.3s;
  transition: left 0.3s ease-in-out, border-left 0.3s;
}
#sidebar-menu > .panel.with-submenu.collapsed > a.active:before,
#sidebar-menu > .panel.without-submenu > a.active:before {
  left: 0px;
}
#sidebar-menu > .panel > a > i {
  width: 40px;
  font-size: 14px;
  margin-right: 3px;
  text-align: center;
  line-height: 36px;
}
#sidebar-menu > .panel > a > span {
  line-height: 36px;
}
#sidebar-menu > .panel > a > .label {
  margin: 2px 20px 0 0;
  float: right;
  padding: 3px 5px 2px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5) inset, 0 1px 0 rgba(255, 255, 255, 0.2);
}
#sidebar-menu > .panel > a .arrow {
  float: right;
  margin: 0 15px 0 -10px;
  font-size: 10px;
  width: auto;
  -webkit-transition: transform 0.3s ease-in-out;
  -ms-transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
#sidebar-menu > .panel > a.collapsed > .arrow {
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}

#sidebar > ul > li.sidebar-icon {
  margin-top: 10px;
  margin-bottom: 20px;
}
#sidebar > ul > li.sidebar-icon a {
  padding: 0;
  text-align: center;
}
#sidebar > ul > li.sidebar-icon a .fa {
  font-size: 20px;
  width: auto;
}
#sidebar-menu > .panel > .submenu {
  position: relative;
}
#sidebar-menu > .panel > .submenu > .inner {
  padding: 5px 0;
}
#sidebar-menu > .panel > .submenu > .inner > a {
  padding: 5px 0 5px 40px;
  font-size: 12px;
  display: block;
  color: #777777;
  position: relative;
  -webkit-transition: background-color 0.3s, color 0.3s;
  -o-transition: background-color 0.3s, color 0.3s;
  transition: background-color 0.3s, color 0.3s;
}
#sidebar-menu > .panel > .submenu > .inner > a:before {
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 7px solid transparent;
  position: absolute;
  left: -11px;
  top: 7px;
  content: "";
  -webkit-transition: left 0.3s ease-in-out, border-left 0.3s;
  -o-transition: left 0.3s ease-in-out, border-left 0.3s;
  transition: left 0.3s ease-in-out, border-left 0.3s;
}
#sidebar-menu > .panel > .submenu > .inner > a.active {
  padding-left: 37px;
}
#sidebar-menu > .panel > .submenu > .inner > a.active:before {
  left: -4px;
}

#sidebar > ul ul:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 17px;
  z-index: 1;
}
.flat #sidebar ul {
  margin-top: 0;
}
.flat #sidebar ul li a {
  text-shadow: none;
}
#sidebar.closed {
  margin-left: -180px;
}
#sidebar {
  display: block;
  position: absolute;
  margin-top: 33px;
  z-index: 16;
  top: 0;
  left: 0;
  bottom: 0;
  width: 200px;

  body[data-sidebar-fixed="true"] & {
    position: fixed;
    top: 44px;
    margin-top: 0;
    padding-top: 0 !important;
  }
}

#sidebar-icon {
  float: right;
  margin-top: 10px;
  margin-bottom: 30px;
  padding: 2px 7px 4px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-width: 1px 0 1px 1px;
  border-style: solid;
  border-color: transparent;
  -webkit-transition: background-color 0.3s, color 0.3s;
  -o-transition: background-color 0.3s, color 0.3s;
  transition: background-color 0.3s, color 0.3s;
  i {
    font-size: 12px;
    &:before {
      content: "\f060";
      -webkit-transition: transform 0.3s;
      -o-transition: transform 0.3s;
      transition: transform 0.3s;
    }
  }
}

@media (max-width: 991px) {
  #sidebar-icon {
    display: none !important;
  }
}

@media (min-width: 992px) {
  .sidebar-icon #usermenu {
    width: 200px;
    background-color: #3f3f3f;
    width: 44px;
    height: 34px;
    white-space: nowrap;
    overflow: hidden;
    -webkit-transition: width 0.3s;
    -o-transition: width 0.3s;
    transition: width 0.3s;
    margin-bottom: -1px;
  }
  .sidebar-icon #usermenu ul {
    height: 34px;
    width: 200px;
  }
  .sidebar-icon #usermenu:hover {
    width: 200px;
    overflow: none;
  }
  .sidebar-icon #usermenu .user-profile a i {
    width: 45px;
  }

  .sidebar-icon #sidebar-icon i {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  .sidebar-icon #sidebar {
    z-index: 20;
    width: 46px;
  }
  .sidebar-icon #sidebar-menu > .panel > a > .arrow {
    display: none;
  }
  .sidebar-icon #sidebar-menu > .panel > a.active:before {
    left: 0px;
  }
  .sidebar-icon #sidebar-menu > .panel > a > span,
  .sidebar-icon #sidebar-menu > .panel > .submenu {
    -webkit-transition: all 0.3s;
    -ms-transition: all 0.3s;
    transition: all 0.3s;
    -webkit-transform: translateX(-15px);
    -ms-transform: translateX(-15px);
    transform: translateX(-15px);
    opacity: 0;
    display: block !important;
    position: absolute;
    visibility: hidden;
    left: 43px;
    top: 0;
    width: 200px;
    z-index: -1;
    overflow: hidden;
  }
  .sidebar-icon #sidebar-menu > .panel > a > span {
    padding-left: 10px;
  }
  .sidebar-icon #sidebar-menu > .panel > .submenu {
    top: 37px;
    padding: 0;
    display: block !important;
    height: auto !important;
  }
  .sidebar-icon #sidebar-menu > .panel > .submenu > .inner > a {
    padding-left: 30px;
  }
  .sidebar-icon #sidebar-menu > .panel:hover > a > span,
  .sidebar-icon #sidebar-menu > .panel:hover > .submenu {
    -webkit-transform: translateX(0px);
    -ms-transform: translateX(0px);
    transform: translateX(0px);
    opacity: 1;
    visibility: visible;
    z-index: 17 !important;
  }
}
@media (max-width: 991px) {
  #sidebar {
    float: none;
    position: fixed;
    height: 100%;
    top: 0;
    left: 0;
    margin-top: 0 !important;
    overflow-y: hidden !important;
    overflow-x: hidden !important;
    z-index: 0;
    padding-top: 0 !important;
  }
}
#sidebar,
#sidebar > ul {
  width: 200px;
}

body[data-sidebar-icon="true"] #sidebar > ul > li > a span,
body[data-sidebar-icon="true"] #sidebar > ul > li.submenu.root-level > a span,
body[data-sidebar-icon="true"] #sidebar > ul > li > ul,
body[data-sidebar-icon="true"] #sidebar > ul > li.submenu.root-level > ul {
  width: 200px !important;
}
@media (min-width: 768px) and (max-width: 991px) {
  #sidebar,
  #sidebar > ul {
    width: 40px;
  }
}

#userinfo {
  position: relative;
  padding: 13px 15px 5px;
}
#userinfo h1,
#userinfo h2,
#userinfo ul {
  margin: 0;
  padding: 0;
  color: #dddddd;
  display: inline-block;
}
#userinfo h1 {
  font-size: 14px;
}
#userinfo ul {
  margin-top: 9px;
  list-style: none;
  width: 100%;
}
#userinfo li {
  display: block;
  float: left;
  width: 50%;
  text-align: center;
}
#userinfo li a {
  color: #aaaaaa;
  display: block;
  font-size: 12px;
}
#userinfo li a:hover,
#userinfo li a:active,
#userinfo li a:focus {
  color: #dddddd;
}
#userinfo li a i {
  margin-right: 5px;
}
#logout {
  position: absolute;
  top: 13px;
  right: 15px;
  font-size: 14px;
  color: #dddddd;
}
#logout:hover,
#logout:active,
#logout:focus {
  color: #ffffff;
}
#userinfot {
  display: none;
}
#userinfot a {
  display: block;
  padding: 10px 0;
  width: 45px;
  text-align: center;
}
#userinfot i {
  color: #bbbbbb;
}

.sidebar-icon #userinfot {
  display: block;
}

.sidebar-icon #userinfo {
  display: none;
  position: absolute;
  top: 11px;
  left: 55px;
  background-color: #4d4d4d;
  width: 200px;
}
.sidebar-icon #userinfo:before {
  position: absolute;
  left: -8px;
  top: 10px;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid #4d4d4d;
  content: "";
}
