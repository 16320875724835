#header {
  height: 44px;
  position: relative;
  width: 200px;
  z-index: 1;
  h1 {
    font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
    background: none no-repeat scroll 0 0 transparent;
    height: 44px;
    font-size: 20px;
    font-weight: 200;
    text-align: center;
    line-height: 38px;
    overflow: hidden;
    position: relative;
    width: 200px;
    margin: 0;
    a {
      color: #ffffff;
      display: block;
    }
    img {
      display: block;
      width: auto;
      margin: -1px auto 0;
    }
  }
  body[data-header-fixed="true"] & {
    position: fixed;
    z-index: 21;
  }
}
@media (max-width: 991px) {
  #header {
    height: 34px;
    width: 100%;
    z-index: 1;
    left: 0;
    -webkit-transition: left 0.5s;
    transition: left 0.5s;
    h1 {
      display: block;
      padding: 1px 0 0;
      margin: 0 auto;
      border-right: 0 !important;
      img {
        width: auto;
        height: 32px;
      }
    }
    .menu-open & {
      left: 200px;
    }
  }
}
/* Top user navigation */
#user-nav {
  display: none;
  position: absolute;
  left: 190px;
  right: 0;
  top: 0;
  z-index: 22;
  margin: 0;
  min-height: auto !important;
  height: 38px !important;
  #menu-trigger {
    float: left;
    font-size: 22px;
    color: rgba(255, 255, 255, 0.6);
    padding: 9px 15px 10px;
  }

  body[data-header-fixed="true"] & {
    position: fixed;
  }
}
@media (max-width: 991px) {
  #user-nav {
    height: 34px !important;
    #menu-trigger {
      padding: 5px 15px 4px;
      font-size: 16px;
    }
  }
}

@media (max-width: 991px) {
  #user-nav {
    display: block;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    -webkit-transition: left 0.5s;
    transition: left 0.5s;
    text-align: center;
  }
  .menu-open #user-nav {
    left: 200px;
  }
}
#user-nav > ul {
  margin: 0;
  padding: 0 10px 0 0;
  list-style: none;

  > li {
    float: left;
    list-style-type: none;
    margin: 0;
    position: relative;
    padding: 0;
    > a {
      padding: 12px 13px 12px;
      display: block;
      font-size: 10px;
      &,
      & > i {
        color: rgba(255, 255, 255, 0.6);
      }
      > i {
        font-size: 14px;
        vertical-align: top;
      }
      > .label {
        font-size: 10px;
        vertical-align: middle;
        padding: 2px 4px 2px;
        margin: -2px 4px 0;
        display: inline-block;
      }
      > .caret {
        border-top-color: rgba(255, 255, 255, 0.6);
      }
    }
    &.attention > a > i {
      position: relative;
      &:after {
        content: "";
        background-color: #dd494a;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        position: absolute;
        top: -3px;
        right: -3px;
      }
    }
    &:hover > a,
    &.open > a {
      &,
      & > i {
        background-color: transparent;
        color: #ffffff;
      }
      .caret {
        border-top-color: #fff;
      }
    }
  }
  ul > li > a {
    text-align: left;
    text-shadow: 0 1px 0 #ffffff;
  }
}
@media (max-width: 767px) {
  #user-nav > ul {
    border-right: 0 !important;
    > li {
      position: relative;
      > a {
        padding: 0 15px;
        > i {
          line-height: 48px;
          font-size: 16px;
        }
      }
      &.open:before {
        border-bottom: 8px solid #ffffff;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        content: "";
        display: inline-block;
        left: 14px;
        position: absolute;
        bottom: -3px;
      }
    }
  }
}

@media (max-width: 430px) {
  #user-nav #menu-trigger {
    z-index: 5;
    position: relative;
  }
  #user-nav > .btn-group {
    top: 0;
    left: 0 !important;
    bottom: 0;
    display: block;
    width: 100% !important;
    padding-right: 0;
    text-align: center;
    z-index: 4;
  }
  #user-nav > .btn-group > li {
    float: none;
    display: inline-block;
  }
}
@media (max-width: 767px) {
  #user-nav > .btn-group {
    position: absolute;
    left: 55px;
  }
  #user-nav > .btn-group > li.attention > a > i:after {
    top: 13px;
  }
  #user-nav .dropdown .dropdown-menu {
    border-radius: 0;
    width: 100%;
    position: fixed;
    left: 0;
    top: 50px;
    bottom: 0;
    overflow: auto;
    border: 0;
    margin: 0;
    -webkit-transition: left 0.5s;
    transition: left 0.5s;
    /*animation-duration:.4s;
				animation-iteration-count: 1;
				animation-timing-function: ease;
				animation-fill-mode: forwards;

				-webkit-animation-duration:.4s;
				-webkit-animation-iteration-count: 1;
				-webkit-animation-timing-function: ease;
				-webkit-animation-fill-mode: forwards;

				-moz-animation-duration:.4s;
				-moz-animation-iteration-count: 1;
				-moz-animation-timing-function: ease;
				-moz-animation-fill-mode: forwards;*/
  }
  #user-nav .dropdown .dropdown-menu .footer {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
  .menu-open #user-nav .dropdown .dropdown-menu {
    left: 180px;
  }
  #user-nav .dropdown.open .dropdown-menu {
    animation-name: slidenav-animation;
    -webkit-animation-name: slidenav-animation;
    -moz-animation-name: slidenav-animation;
  }
}
#user-nav .user-menu ul {
  list-style: none;
  margin: 0 15px 0 0;
  padding: 0;
}
#user-nav .user-menu li {
  float: left;
}
#user-nav .user-menu li a {
  display: block;
  font-size: 12px;
  padding: 13px 15px 12px 45px;
  vertical-align: middle;
  border-left: 1px solid #eeeeee;
  color: #666666 !important;
  white-space: nowrap;
}
[data-header-light="false"] #user-nav .user-menu li a {
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 0.7) !important;
}
#user-nav .user-menu li:last-child a {
  border-right: 1px solid #eeeeee;
}
[data-header-light="false"] #user-nav .user-menu li:last-child a {
  border-right: 1px solid rgba(255, 255, 255, 0.2);
}
#user-nav .user-menu li:hover a {
  background-color: #fbfbfb;
}
[data-header-light="false"] #user-nav .user-menu li:hover a {
  background-color: rgba(255, 255, 255, 0.1);
}
#user-nav .user-menu li a i {
  margin-right: 7px;
  margin-left: -30px;
  font-size: 18px;
  margin-top: -0.5px;
  display: inline-block;
  width: 18px;
  height: 18px;
  float: left;
}

@keyframes slidenav-animation {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}
@-moz-keyframes slidenav-animation {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}
@-webkit-keyframes slidenav-animation {
  from {
    -webkit-transform: translateY(100%);
    opacity: 0;
  }
  to {
    -webkit-transform: translateY(0px);
    opacity: 1;
  }
}
