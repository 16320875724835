@import "variables";

// all declarations in this file should be inside .pt-ui{} scope.
// declarations outside .pt-ui{} go in the file _pt-ui.scss
.pt-ui {
  font-size: 16px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;

  .cjl-view {
    padding-top: 15px;
    display: flex;
    flex-direction: column;
    width: 100%;

    // overrides due to .ratecards selector being parent to all views in v7

    .cjl-detail-menu.dropdown,
    .cjl-detail-menu.dropdown.open {
      .cjl-detail-menu-button.dropdown-toggle {
        border: none !important;

        &:hover,
        &:active,
        &[open] {
          border: none !important;
        }
      }
    }

    .pt-search {
      margin-top: 2px;
    }

    // end override
  }

  .flex {
    display: flex;
    flex-direction: row;
    flex: 1;
    // flex: 1 defaults to flex: 1 1 auto
    // but on IE11 defaults to flex: 1 1 0px
    // so we need to set flex-basis: auto explicitly
    flex-basis: auto;
    width: 100%;
  }

  .flex-column {
    flex-direction: column;
  }

  .loading-gradient {
    background: linear-gradient(to left top, $rc-gray7, $rc-gray1);
    background-size: 400% 400%;

    animation: loading-gradient-animation 2.5s ease-in-out infinite;

    @keyframes loading-gradient-animation {
      0% {
        background-position: 0% 50%;
      }
      50% {
        background-position: 100% 50%;
      }
      100% {
        background-position: 0% 50%;
      }
    }
  }

  .flex-center-content {
    justify-content: center;
  }

  .card {
    width: 180px;
    height: 170px;
    //display: flex;
    //flex-direction: column;
    //flex: 1;
    //// flex: 1 defaults to flex: 1 1 auto
    //// but on IE11 defaults to flex: 1 1 0px
    //// so we need to set flex-basis: auto explicitly
    //flex-basis: auto;
    padding: 0;
    margin: 10px;
    border: 1px solid $citrine;
    border-radius: 3px;
    background: white;
    //box-shadow: 2px 4px 10px 0 transparentize($taupe-gray, 0.8);
    transition: transform 0.2s cubic-bezier(0.4, 1, 0.75, 0.9),
      box-shadow 0.2s cubic-bezier(0.4, 1, 0.75, 0.9),
      -webkit-transform 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);

    &-interactive {
      &:hover {
        box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 2px 4px rgba(16, 22, 26, 0.2),
          0 8px 24px rgba(16, 22, 26, 0.2);
        cursor: pointer;
      }
    }

    &-list {
      display: flex;
      justify-content: center;
      flex-direction: column;

      &-horizontal {
        flex: 1;
        // flex: 1 defaults to flex: 1 1 auto
        // but on IE11 defaults to flex: 1 1 0px
        // so we need to set flex-basis: auto explicitly
        flex-basis: auto;
        //flex-wrap: wrap;
        flex-direction: column; // mobile
      }
    }

    &-number {
      font-size: 48px;
      font-weight: bold;
      //padding-left: 20px;
      color: $citrine;
    }

    &-body {
      position: relative;
      //padding: 20px;
      width: 100%;
      height: 100%;
    }

    &-info {
      position: absolute;
      bottom: 22px;
      left: 24px;
    }

    &-description {
      font-size: 14px;
      font-weight: normal;
      color: #9e9e9e;
      letter-spacing: 0;
      flex-grow: 1;
      max-height: 50px;
      overflow: hidden;
      margin: 0;
      //padding-left: 20px;
    }

    &-icon {
      color: $citrine;
      padding: 0;
      position: absolute;
      top: 20px;
      right: 20px;
    }
  }

  .cjl-main-section {
    flex-grow: 1;
    overflow: visible;
  }

  .cjl-help {
    background: $citrine;
    border: 1px solid $citrine;
    border-radius: 100px;
    width: 34px;
    height: 34px;
    font-size: 24px;
    line-height: 24px;
    font-weight: 300;
    color: white;
  }

  .cjl-button {
    font-size: 15px;
    line-height: 15px;
    text-align: center;
    padding: 8px 20px;
    background: $citrine;
    border: none;
    color: white !important;
    letter-spacing: 0;
    display: inline-block;
    cursor: pointer;

    & a:focus {
      text-decoration: none;
    }

    &:hover {
      background-color: darken($citrine, 5%);
    }

    &.large {
      font-size: 14.5px;
      line-height: 20px;
      text-align: center;
      padding: 14px 22px;
    }

    &.round {
      border-radius: 100px;
    }

    &.item-option {
      border-radius: 0;
    }
  }

  .cjl-matches-label {
    font-size: 15px;
    font-weight: 300;
    line-height: 15px;
    text-align: center;
    padding: 8px 20px;
    background: $citrine;
    border: none;
    color: white !important;
    letter-spacing: 0;
    display: inline-block;
    border-radius: 3px;
  }

  .cjl-overview {
    flex: 1;
    // flex: 1 defaults to flex: 1 1 auto
    // but on IE11 defaults to flex: 1 1 0px
    // so we need to set flex-basis: auto explicitly
    flex-basis: auto;
    display: flex;
    flex-direction: column;

    &-hero {
      flex: 0;
      // flex: 0 defaults to flex: 0 1 auto
      // but on IE11 defaults to flex: 0 1 0px
      // so we need to set flex-basis: auto explicitly
      flex-basis: auto;
      align-self: center;
      max-width: 800px;
      //min-width: 700px;
      margin: 10px;
      border-radius: 10px;
      background: white
        url("../../../assets/img/client_job_libraries-hero-bg-blurred.png") no-repeat
        right bottom;
      //box-shadow: 0 2px 54px 0 rgba(0, 0, 0, 0.2);
      border: 1px solid $rc-gray2;

      &-actions {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      &-content {
        padding: 10px;

        h1 {
          font-size: 30px;
        }

        header {
          padding: 20px;
        }

        section {
          padding: 20px;
        }
      }
    }

    &-tour {
      display: flex;
      align-items: center;
      margin-right: 30px;

      &-angle-right {
        padding-left: 20px;
        font-size: 30px;
      }
    }

    &-skip-tour {
      font-size: 16px;
      color: $taupe-gray !important;
      text-decoration: underline;
    }

    &-card {
      //max-width: 350px;
    }
  }

  .cjl-create {
    background: $citrine;
    border-radius: 100px;
    height: 34px;
    color: white !important;
    font-size: 14px;
    padding: 0 20px;
    letter-spacing: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:focus,
    &:hover {
      text-decoration: none;
    }
  }

  .cjl-create-plus {
    margin-top: 2px;
    margin-bottom: 2px;
    margin-right: 10px;
  }

  .cjl-create-options {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex: 1;
    // flex: 1 defaults to flex: 1 1 auto
    // but on IE11 defaults to flex: 1 1 0px
    // so we need to set flex-basis: auto explicitly
    flex-basis: auto;
    margin-bottom: 40px;

    .cjl-create-options-separator {
      overflow: visible;
      width: 1px;
      height: 200px;
      margin: 0 60px;
      border-left: 1px dashed $rc-gray1;
      position: relative;

      span {
        position: absolute;
        color: $rc-gray6;
        background-color: #f5f5f5;
        border-radius: 100px;
        top: 80px;
        left: -30px;
        width: 60px;
        height: 40px;
        text-align: center;
        border: 1px dashed $rc-gray1;
        line-height: 38px;
        font-size: 16px;
      }
    }
  }

  .cjl-create-option {
    background: white;
    border: 1px solid #e6e6e6;
    border-radius: 2px;
    color: $citrine !important;
    width: 276px;
    height: 188px;
    padding: 20px;
    margin: 0;

    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;

    cursor: pointer;
    letter-spacing: 0.2px;
    word-spacing: 1.2px;

    text-decoration: none;

    h5 {
      font-size: 22px;
      line-height: initial;
      text-align: left;
      margin-bottom: 10px;
    }

    p {
      font-size: 14px;
      color: $rc-gray6;
      font-weight: 300;
      margin-bottom: 0;
    }

    &:hover {
      //background-color: #f5f6ee;
      border: 1px solid $citrine;
      //border-top: 3px solid $citrine;
    }
  }

  .cjl-create-option-icon {
    font-size: 96px;
    margin-bottom: 20px;
  }

  .cjl-custom-info {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }

  .cjl-custom-members {
    display: flex;
    flex-direction: column;
    flex: 1;
    // flex: 1 defaults to flex: 1 1 auto
    // but on IE11 defaults to flex: 1 1 0px
    // so we need to set flex-basis: auto explicitly
    flex-basis: auto;
    // Mobile
    padding-bottom: 10px;
  }

  .cjl-custom-members-name {
    font-size: 14px;
    color: $olive-drab;
    letter-spacing: 0;
    margin-bottom: 10px;
  }

  .cjl-custom-input {
    border-radius: 0;
    border: none;
    padding: 0 3px;
    margin: 0 -3px;
    background: #ffffff;

    &:focus {
      outline: none;
      border-bottom: 1px dashed #d4e3fc;
    }
  }

  .cjl-custom-title {
    flex: 0;
    flex-basis: auto;
    font-size: 34px;
    line-height: 38px;
    width: 100%;
    margin-bottom: 5px;
    border: none;
    border-bottom: 1px dashed lighten($platinum, 1%);

    color: $rc-gray3;

    &::placeholder {
      color: $rc-gray1;
    }

    &:focus {
      margin-bottom: 5px;
      background-color: #f8fafb;
    }

    &.error {
      border-bottom: 1px dashed $rc-danger-color;
    }
  }

  .cjl-custom-description {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    width: 100%;
    padding-top: 2px;
    border: 1px dashed transparent;
    resize: none;

    &::placeholder {
      color: $rc-gray1;
    }

    &:focus {
      border: 1px dashed $citrine;
      background-color: #f8fafb;
    }
  }

  .cjl-custom-search-jobs {
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
  }

  .cjl-custom-search-jobs-name {
    font-size: 14px;
    color: $olive-drab;
    letter-spacing: 0;
    margin-bottom: 10px;
  }

  .flag-icon-wrapper {
    margin-right: 0.4em;
  }

  .cjl-header {
    height: 34px;
    margin-bottom: 15px;
    display: flex;
  }

  .cjl-header-right {
    display: flex;
    flex: 0;
    flex-basis: auto; // IE 11 fix
    align-self: flex-end;
    min-width: 230px;
    margin-left: auto;
    justify-content: space-evenly;
  }

  .cjl-body {
    display: flex;
    flex: 1;
    // flex: 1 defaults to flex: 1 1 auto
    // but on IE11 defaults to flex: 1 1 0px
    // so we need to set flex-basis: auto explicitly
    flex-basis: auto;
    flex-direction: column;
    align-items: stretch;
  }

  .cjl-list {
    flex-grow: 0;
    flex-shrink: 1;
    width: auto;
    margin-right: 20px;
  }

  .btn-toggle-group.cjl-list-toggle {
    display: block;
    margin-bottom: 15px;
  }

  .cjl-list-item {
    position: relative;
    display: flex;
    flex-direction: column;
    background: white;
    border: 1px solid #e6e6e6;
    border-radius: 2px;
    color: $citrine;
    width: 230px;
    height: 150px;
    padding: 20px 20px 10px;
    margin-bottom: 15px;
    text-decoration: none;
    cursor: pointer;
    transition: border 150ms, box-shadow 250ms;

    &:hover {
      text-decoration: none;
      border: 1px solid $citrine;
    }
  }

  .cjl-list-item--selected {
    background: #f5f6ee;
    border: 1px solid $citrine;
    text-decoration: none;
    box-shadow: inset 0 3px $citrine;

    .cjl-list-item-description-fade {
      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+100 */
      background: -moz-linear-gradient(
        top,
        rgba(245, 246, 238, 0) 20%,
        rgba(245, 246, 238, 0.7) 100%
      ); /* FF3.6-15 */
      background: -webkit-linear-gradient(
        top,
        rgba(245, 246, 238, 0) 20%,
        rgba(245, 246, 238, 0.7) 100%
      ); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(
        to bottom,
        rgba(245, 246, 238, 0) 20%,
        rgba(245, 246, 238, 0.7) 100%
      ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient(
          startColorstr="#00f5f6ee",
          endColorstr="#f5f6ee",
          GradientType=0
        ); /* IE6-9 */
    }
  }

  .cjl-list-create {
    background: white;
    border: 1px solid #e6e6e6;
    border-radius: 2px;
    color: $citrine !important;
    width: 230px;
    height: 150px;
    padding: 20px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    cursor: pointer;

    &:hover {
      border: 1px solid $citrine;
    }

    .fa-stack-1x {
      transform: translateY(50%);
    }

    .fa-stack {
      margin-bottom: 10px;
    }
  }

  .cjl-list-create-plus {
    font-size: 60px;
    margin-bottom: 20px;
  }

  .cjl-list-item-name {
    font-size: 16px;
    color: $olive-drab;
    letter-spacing: 0;
    margin-bottom: 6px;

    .logo {
      width: 32px;
      height: 32px;
      margin-right: 0.4em;
      display: inline-block;
      border-radius: 2px;
      overflow: hidden;

      .logo-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .logo-image {
          font-size: 12px;
          font-weight: 300;
          max-width: 32px;
          max-height: 32px;
        }
      }
    }
  }

  .cjl-list-item-description {
    font-size: 14px;
    font-weight: 300;
    color: $dark-gray;
    letter-spacing: 0;
    flex-grow: 1;
    max-height: 50px;
    width: 190px;
    overflow: hidden;
  }

  .cjl-list-item-description-fade {
    width: 100%;
    height: 10px;
    //border-bottom: 1px solid lighten($platinum, 1%);
    margin-top: -10px;

    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+100 */
    background: -moz-linear-gradient(
      top,
      rgba(255, 255, 255, 0) 20%,
      rgba(255, 255, 255, 0.7) 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      top,
      rgba(255, 255, 255, 0) 20%,
      rgba(255, 255, 255, 0.7) 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 20%,
      rgba(255, 255, 255, 0.7) 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00ffffff", endColorstr="#ffffff", GradientType=0); /* IE6-9 */
  }

  .cjl-list-item-members {
    flex: 0;
    flex-basis: auto; // IE 11 fix
  }

  .cjl-flex-table {
    display: flex;
    flex-direction: column;
    flex: 1;
    // flex: 1 defaults to flex: 1 1 auto
    // but on IE11 defaults to flex: 1 1 0px
    // so we need to set flex-basis: auto explicitly
    flex-basis: auto;
    width: 100%;

    &.header,
    &.row {
      flex-direction: row;
      margin: 0;

      .column {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 10px;
        max-width: 100%;
        word-break: break-all; // fallback
        word-break: break-word;
        word-wrap: break-word;
        white-space: normal;
        flex: 1; // flex-grow: 1, flex-shrink: 1, flex-basis: auto
        // flex: 1 defaults to flex: 1 1 auto
        // but on IE11 defaults to flex: 1 1 0px
        // so we need to set flex-basis: auto explicitly
        flex-basis: auto;

        & > * {
          // direct children of .column won't grow by default
          flex: 0 0 auto;
        }

        &.auto {
          // adjust to content width
          flex: 0 0 auto;
          width: auto;
          word-break: normal;
          word-wrap: normal;
        }

        &.center-items {
          align-items: center;
        }

        &.align-top {
          justify-content: flex-start;
        }

        &.no-padding-left {
          padding-left: 0;
        }

        &.no-padding-right {
          padding-right: 0;
        }

        &.click-enabled {
          cursor: pointer;
          .sort-icon.sort-none {
            visibility: hidden;
          }

          &:hover {
            background-color: #f8fafb;
            .sort-icon.sort-none {
              visibility: visible;
            }
          }
        }
      }

      &.click-enabled {
        cursor: pointer;
        .sort-icon.sort-none {
          visibility: hidden;
        }

        &:hover {
          background-color: #f8fafb;
          .sort-icon.sort-none {
            visibility: visible;
          }
        }
      }

      &.no-gutters {
        .column {
          padding: 0;
        }
      }

      &.double-vertical-space {
        .column {
          padding-top: 20px;
          padding-bottom: 20px;
        }
      }
    }

    &.header {
      color: #6d7e8f;
      font-size: 14px;
      font-weight: 300;
      border-bottom: 2px solid lighten($platinum, 1%);
    }

    &.row.list-item {
      justify-content: space-around;
      font-size: 16px;
      font-weight: 400;
      border-bottom: 1px solid lighten($platinum, 2%);
      padding: 5px 0;

      .cjl-button-bar {
        visibility: hidden;
      }

      &.expandable {
        cursor: pointer;
      }

      &:hover,
      &.selected {
        background-color: #f8fafb;

        .cjl-button-bar {
          visibility: visible;
        }
      }

      &.empty {
        color: #6d7e8f;
        font-size: 14px;
        font-weight: 300;
        border-bottom: none;

        &:hover {
          background-color: transparent;
        }
      }
    }

    .collapsible-content {
      height: auto;
      max-height: 0;
      overflow-y: auto;

      transition: all 300ms ease-out;

      &.collapsed {
        max-height: 0;
      }

      &.expanded {
        max-height: 1000px;
        border-bottom: 1px solid lighten($platinum, 2%);
      }
    }
  }

  .center-items {
    align-items: center !important;
  }

  .sort-icon {
    margin-left: 5px;
    color: lighten(#6d7e8f, 5%);
    transform: scale(0.74, 1.2);
    visibility: visible;
  }

  .cjl-caret-col {
    min-width: 30px;
    color: #6d7e8f;

    .fa-caret-right {
      transform: scaleX(1.6) scaleY(0.92);
    }
    .fa-play {
      font-size: 10px !important;
      transition: all 150ms ease-out;
      transform-origin: 25% 50%;
      transform-style: preserve-3D;

      &:hover {
        color: $citrine;
      }

      &.expanded {
        color: $citrine;
        transform: rotate(90deg);
      }
    }
  }

  .cjl-date-col {
    min-width: 110px;
  }

  .cjl-latest-search-col {
    //min-width: 110px;
    padding-right: 12px;
  }

  .cjl-countries-col {
    min-width: 230px;
    text-align: center;
  }

  .cjl-countries-count-col {
    min-width: 100px;
    text-align: center;
  }

  .cjl-has-file-col {
    min-width: 54px;
    text-align: left;
  }

  .cjl-title-request-status-col {
    min-width: 90px;

    .cjl-title-request-status {
      display: inline-block;
      border: none;
      margin-right: auto;
      padding: 5px 6px;
      border-radius: 3px;
      font-weight: bold;
      font-size: 12px;

      &.denied {
        background: transparentize(#888888, 0.9);
        color: #888888;
      }

      &.approved {
        background: transparentize(#44af69, 0.9);
        color: #44af69;
      }

      &.processing {
        background: transparentize(#fcab10, 0.9);
        color: #fcab10;
      }

      &.pending {
        background: transparentize(#2b9eb3, 0.9);
        color: #2b9eb3;
      }
    }
  }

  .cjl-library-request-status-col {
    min-width: 90px;

    .cjl-library-request-status {
      display: inline-block;
      border: none;
      margin-right: auto;
      padding: 5px 6px;
      border-radius: 3px;
      font-weight: bold;
      font-size: 12px;

      &.denied {
        background: transparentize(#888888, 0.9);
        color: #888888;
      }

      &.approved {
        background: transparentize(#44af69, 0.9);
        color: #44af69;
      }

      &.processing {
        background: transparentize(#fcab10, 0.9);
        color: #fcab10;
      }

      &.pending {
        background: transparentize(#2b9eb3, 0.9);
        color: #2b9eb3;
      }
    }
  }

  .cjl-job-title-status-col {
    min-width: 70px;
  }

  .cjl-job-title-id-col {
    min-width: 80px;
  }

  .cjl-job-title-status {
    display: inline-block;
    border: none;
    margin: auto;
    padding: 5px 6px;
    border-radius: 3px;
    font-weight: bold;
    font-size: 12px;
    min-width: 100px;
    text-align: center;

    &.cjl-status-tag {
      text-align: left;
      margin: 3px;
      min-width: auto;
    }

    &.ad-hoc {
      background: transparentize(#d0021b, 0.9);
      color: #d0021b;
    }

    &.certified {
      background: transparentize(#008148, 0.9);
      color: #008148;
    }

    &.disabled {
      opacity: 0.25;
    }
  }

  .cjl-latest-search-range {
    font-family: monospace;
    font-size: 14px;
    background: linear-gradient(
      45deg,
      rgba(210, 205, 57, 0.26) 0%,
      rgb(231, 242, 237) 100%
    );
    padding: 2px 8px 1px;
    border-radius: 20px;
    .cjl-search-rate {
      font-weight: bold;
    }
  }

  .cjl-item-subtitle {
    font-size: 14px;
    font-weight: 300;
    color: $taupe-gray;
    padding-right: 8px;
    font-family: monospace;
  }

  .cjl-tag {
    display: inline-block;
    border: none;
    margin: 2px 5px;
    padding: 5px 6px;
    border-radius: 3px;
    font-weight: bold;
    font-size: 12px;

    background: transparentize(#333, 0.9);
    //color: #d0021b;

    //&.ad-hoc {
    //  background: transparentize(#d0021b, 0.9);
    //  color: #d0021b;
    //}
    //
    //&.certified {
    //  background: transparentize(#008148, 0.9);
    //  color: #008148;
    //}
  }

  .cjl-job-title-col {
    .client-title {
      flex: 0;
      flex-basis: auto; // IE 11 fix
      font-size: 18px;
      font-weight: 400;
      color: $metal;
    }

    .mapped-title {
      flex: 0;
      flex-basis: auto; // IE 11 fix
      font-size: 16px;
      font-weight: 300;
      color: $taupe-gray;
    }
  }

  .cjl-job-title-actions-col {
    min-width: 120px;
  }

  .cjl-detail {
    flex: 1;
    flex-basis: 800px;
    padding: 50px 40px;
    background: #ffffff;
    border: 1px solid #e6e6e6;
    border-radius: 2px;
    position: relative;
  }

  .cjl-detail-name {
    font-size: 34px;
    line-height: 36px;
    font-weight: normal;
    margin-bottom: 10px;

    &-placeholder {
      height: 36px;
      width: 70%;
    }
  }

  .cjl-search-query {
    font-size: 14px;
    margin-top: -5px;
    margin-bottom: 5px;
    padding: 0 5px;
  }

  .cjl-detail-desc {
    font-size: 16px;
    line-height: 22px;
    min-height: 22px;
    font-weight: 300;

    &-placeholder {
      height: 150px;
      width: 100%;
    }
  }

  .cjl-list-item-header {
    font-size: 18px;
    font-weight: 600;
    color: #707a80;
    margin-bottom: 5px;
  }

  .cjl-title-desc {
    font-size: 14px;
    line-height: 18px;
    min-height: 18px;
    font-weight: normal;
    padding: 40px;
  }

  .cjl-detail-menu-target {
    position: absolute;
    top: 34px;
    right: 40px;
    font-size: 26px;
    padding: 16px;
    cursor: pointer;
  }

  .cjl-menu-list {
    background: white;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
    border-radius: 2px;
    width: 105px;
  }

  .cjl-menu-list-item {
    height: 28px;
  }

  .cjl-detail-menu-delete {
    color: $citrine;
  }

  .cjl-detail-menu-export {
    color: $citrine;
  }

  .cjl-detail-info {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }

  .cjl-detail-search-jobs {
    flex: 1;
    // flex: 1 defaults to flex: 1 1 auto
    // but on IE11 defaults to flex: 1 1 0px
    // so we need to set flex-basis: auto explicitly
    flex-basis: auto;
    display: flex;
    flex-direction: column;
  }

  .cjl-details-search-jobs-name {
    font-size: 14px;
    color: $olive-drab;
    letter-spacing: 0;
    margin-bottom: 10px;
  }

  .cjl-detail-members {
    flex: 1;
    // flex: 1 defaults to flex: 1 1 auto
    // but on IE11 defaults to flex: 1 1 0px
    // so we need to set flex-basis: auto explicitly
    flex-basis: auto;
    display: flex;
    flex-direction: column;
    // Mobile
    padding-bottom: 10px;
  }

  .cjl-detail-members-name {
    font-size: 14px;
    color: $olive-drab;
    letter-spacing: 0;
    margin-bottom: 10px;
  }

  .cjl-p {
    color: $taupe-gray;
    font-size: 14px;
    line-height: 22px;
    font-weight: 300;
    letter-spacing: 0.4px;
    word-spacing: 1.2px;
  }

  .cjl-input {
    color: $rc-black;
    font-size: 14.5px;
    line-height: 22px;
    border: 1px solid $input-border-color;
    border-radius: 3px;
    background-color: #ffffff;
    padding: 6px 8px;

    &::placeholder {
      color: lighten($taupe-gray, 1%);
      font-size: 14px;
      line-height: 22px;
      font-weight: 300;
      letter-spacing: 0.4px;
      word-spacing: 1.2px;
    }

    &:focus {
      background-color: #f8fafb;
      outline: none;
    }
  }

  textarea.cjl-input {
    min-height: 66px;
  }

  .cjl-label {
    color: $taupe-gray;
    font-size: 10.5px;
    font-weight: 600;
    letter-spacing: 0.6px;
    word-spacing: 1.2px;

    .cjl-label-button {
      border: 1px solid lighten($taupe-gray, 20%);
      border-radius: 4px;
      padding: 1px 5px;
      margin-bottom: 1px;

      &:active {
        border: 1px solid darken($citrine, 5%);
        color: white;
        background-color: darken($citrine, 5%);
      }

      &:focus {
        background-color: #f8fafb;
        outline: none;
      }
    }
  }

  #cjl-label-dropdown-libraries-selection,
  #cjl-label-dropdown-countries-selection {
    font-size: 10.5px !important;
    word-spacing: 1.2px !important;
    border: 1px solid #cccccc !important;
    border-radius: 4px;
    padding: 1px 5px;
    margin-top: -1px;
    margin-bottom: 1px;
    margin-right: 0;
    background: none;
    color: $taupe-gray !important;
    line-height: 14px;

    &:active {
      box-shadow: none !important;
      border: 1px solid $taupe-gray !important;
      color: white !important;
      background-color: $taupe-gray !important;
    }

    &:focus {
      color: $taupe-gray !important;
      background-color: #f8fafb !important;
      outline: none !important;
    }
  }

  .cjl-thin {
    font-weight: 300;
  }

  .cjl-input-icon-right {
    font-size: 18px;
    position: absolute;
    right: 6px;
    top: 9px;
  }

  .cjl-centered-view-container {
    display: flex;
    flex: 1;
    // flex: 1 defaults to flex: 1 1 auto
    // but on IE11 defaults to flex: 1 1 0px
    // so we need to set flex-basis: auto explicitly
    flex-basis: auto;
    width: 100%;
    justify-content: center;
    align-items: center;
    overflow: visible;
  }

  .cjl-centered-view {
    position: relative;
    width: 100%;
    padding: 50px 40px;
    background: #ffffff;
    border: 1px solid #e6e6e6;
    border-radius: 2px;
    align-self: flex-start;

    &.create-custom-library,
    &.cjl-library-detail {
      max-width: 980px;
    }
  }

  .cjl-centered-form {
    //margin-top: 50px;
    padding: 50px 65px;
    background: #ffffff;
    border: 1px solid #e6e6e6;
    border-radius: 2px;
    width: 100%;
    max-width: 556px;
    position: relative;
    overflow: hidden;
    color: $rc-black;
    align-self: flex-start;

    .cjl-form-title {
      color: $rc-black;
      font-size: 32px;
      font-weight: bold;
      line-height: 32px;
      margin-bottom: 11px;
      letter-spacing: 0.4px;
      word-spacing: 1.2px;
    }

    label,
    input,
    textarea {
      display: block;
      width: 100%;
    }
  }

  .cjl-button-bar {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
    *:not(:first-child) {
      margin-left: 10px;
    }

    .cjl-spacer {
      flex-grow: 1;
    }
  }

  .cjl-custom-submit {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .cjl-custom-submit-country-request {
    position: absolute;
    top: 20px;
    right: 40px;
  }

  .cjl-form-close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    color: $rc-black;
    font-size: 16px;
    line-height: 16px;
    padding: 4px;
    margin: 0;
    background: none;
    border: none;
  }

  .cjl-custom-file-upload-help {
    font-size: 12px;
    color: $dark-gray;
  }

  .cjl-custom-file-upload-button {
    font-size: 12px;
    display: inline-block;
    padding: 10px 14px;
    cursor: pointer;
    margin: 0;
    border: 1px dashed $input-border-color;
    border-radius: 3px;
    background-color: #f4f4f4;

    input[type="file"] {
      display: none;
    }

    &:hover {
      background-color: darken(#f4f4f4, 5%);
    }
  }

  .cjl-library-request-list {
    //max-width: 680px;
    flex-grow: 1;
  }

  .cjl-system-library-icon {
    position: absolute;
    top: 6px;
    right: 6px;
    font-size: 10px;
    padding: 0 4px;
    //color: $citrine;
    color: #878d92;

    .fa.icon + *,
    .svg-inline--fa.icon + * {
      font-size: 10px !important;
      margin-right: 0.2em;
    }
  }

  // --------------------------------------------------------
  // Members
  // --------------------------------------------------------

  .cjl-members-list {
    display: flex;
    justify-content: flex-start;
  }

  .cjl-member {
    border-radius: 100px;
    height: 30px;
    width: 30px;
    color: white;
    font-size: 14px;
    letter-spacing: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    &:not(last-child) {
      margin-right: 4px;
    }
  }

  .cjl-member--empty {
    background: #ffffff;
    border: 1px dashed $rc-gray1;
  }

  .cjl-member--red {
    background: #d0021b;
  }

  .cjl-member--gray {
    background: $taupe-gray;
  }

  .cjl-member--light-gray {
    background: $rc-gray6;
  }

  .cjl-member--citrine {
    background: $citrine;
  }

  .cjl-member--blue {
    background: $true-blue;
  }

  .cjl-search {
    display: flex;
    flex: 0 0 auto;
    width: auto;
    position: relative;
    height: 34px;
    min-width: 250px;
    //border: 1px solid #a8a8a8;
    //border-radius: 100px;

    &-input-container {
      display: flex;
      flex: 1;
      // flex: 1 defaults to flex: 1 1 auto
      // but on IE11 defaults to flex: 1 1 0px
      // so we need to set flex-basis: auto explicitly
      flex-basis: auto;
      border-top: 1px solid #a8a8a8;
      border-bottom: 1px solid #a8a8a8;
      border-left: 1px solid #a8a8a8;
      border-bottom-left-radius: 100px;
      border-top-left-radius: 100px;
      background-color: white;

      .cjl-search-input {
        flex: 1;
        // flex: 1 defaults to flex: 1 1 auto
        // but on IE11 defaults to flex: 1 1 0px
        // so we need to set flex-basis: auto explicitly
        flex-basis: auto;
        outline: none;
        padding-left: 40px;
        padding-top: 6px;
        padding-bottom: 6px;
        font-size: 14px;
        border: none;
        border-bottom-left-radius: 100px;
        border-top-left-radius: 100px;

        &::placeholder {
          color: $rc-gray1;
        }

        &:focus {
          background-color: #f8fafb;
        }
      }

      .cjl-search-filter-options {
        display: flex;
        flex: 0 0 auto;
        width: auto;
        margin: 4px;

        .cjl-search-filter-toggle {
          font-size: 12px;
          line-height: 1em !important;
          margin: 0;
          padding: 0 10px;
          width: 100%;
          height: 100%;
          background-color: $citrine;
          border: none !important;

          i,
          span {
            line-height: inherit !important;
          }

          &:hover {
            background-color: darken($citrine, 5%);
          }

          .fa.icon,
          .svg-inline--fa.icon {
            margin: 0 0 0 5px;
          }
        }
      }
    }

    .cjl-search-icon {
      position: absolute;
      left: 13px;
      top: 8px;
      font-size: 16px !important;
      color: #a8a8a8;
    }

    .cjl-search-clear-btn {
      position: absolute;
      left: 14px;
      top: 8px;
      font-size: 18px;
      color: #a8a8a8;
      border-radius: 100px;
      cursor: pointer;

      &:hover {
        color: $rc-gray4;
      }
    }

    .cjl-search-btn {
      // adjust to content width
      flex: 0 0 auto;
      width: auto;
      font-weight: bold;
      font-size: 12px;
      color: white;
      border: none;
      //width: 25%;
      background-color: $rc-gray4;
      border-radius: 0 100px 100px 0;
      padding: 1px 7px 2px;
      min-width: 70px;
    }
  }

  // --------------------------------------------------------
  // Pagination
  // --------------------------------------------------------

  .cjl-pagination-container {
    font-weight: 300;
    font-size: 16px;
    color: #6d7e8f;
    border: none;
    margin: 15px auto;
    display: flex;
    align-items: center;
    justify-content: center;

    .separator {
      margin: 0 10px;
      width: 1px;
      height: 16px;
      border-left: 2px solid #e5e9ec;
    }
  }

  .cjl-page-box {
    margin: 0;
    padding: 4px 10px;
    border-radius: 3px;
    font-weight: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;

    &.active {
      background-color: #e6e6e6;
    }

    &.collapsed {
      font-size: 16px;
      background-color: #ffffff;
      padding: 0;
      margin: 0 -5px;
      letter-spacing: 2px;
    }
  }

  .cjl-first-page,
  .cjl-last-page,
  .cjl-prev-page,
  .cjl-next-page {
    margin: 0;
    padding: 6px 12px;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    cursor: pointer;
    line-height: 22px;

    &:hover {
      background-color: #e6e6e6;
    }

    .arrow:last-child {
      margin-left: 8px;
    }

    .arrow:first-child {
      margin-right: 8px;
    }
  }

  .caption {
    color: #6d7e8f;
    font-size: 14px;
    font-weight: normal;
  }

  .cjl-detail-menu {
    position: absolute;
    right: 40px;

    .cjl-detail-menu-button,
    &.open > .cjl-detail-menu-button {
      font-size: 30px;
      line-height: 30px;
      color: #888 !important;
      background: none !important;
      border: none !important;
      box-shadow: none !important;
      margin: 0;
    }
  }

  .cjl-title-detail-menu {
    .dropdown-toggle.cjl-detail-menu-button,
    .dropdown-toggle.cjl-detail-menu-button:hover,
    .dropdown-toggle.cjl-detail-menu-button[open],
    &.open > .dropdown-toggle.cjl-detail-menu-button {
      font-size: 22px;
      line-height: 24px;
      color: #888 !important;
      background: none !important;
      border: none !important;
      box-shadow: none !important;
      margin: 0;
    }
  }

  .cjl-search-filter-options.open > .dropdown-toggle.cjl-search-filter-toggle {
    border: none !important;
  }

  // --------------------------------------------------------
  // Non-Ideal State
  // --------------------------------------------------------

  .cjl-non-ideal-state {
    padding: 40px;
    justify-content: center;
    color: #6d7e8f;

    &.icon,
    .svg-inline--fa.icon {
      padding: 0;
      font-size: 52px;
      margin-bottom: 20px;
    }

    &.title {
      padding: 0;
      font-size: 24px;
      margin-bottom: 10px;
      max-width: 500px;
      text-align: center !important;
    }

    &.description {
      padding: 0;
      font-size: 16px;
      font-weight: 300;
      margin: 0;
      max-width: 500px;
      text-align: center !important;
    }
  }

  .no-text-select {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
  }

  .cjl-link-button {
    margin: 0;
    padding: 4px 8px;
    color: $citrine;
    border: none;
    box-shadow: none;
    background: none;
    outline: none;
    &:hover {
      text-decoration: underline;
    }
  }

  .cjl-download-template {
    font-size: 14px;
    letter-spacing: 0.6px;
    word-spacing: 1.2px;
  }

  .cjl-progress-bar {
    &.cjl-country-status {
      margin-bottom: 0;
      width: 200px;
      height: 4px;

      .progress-bar-success {
        background-color: #80b59e;
      }

      .progress-bar-danger {
        background-color: #f9e2e6;
      }
    }
  }

  .cjl-progress-label {
    font-size: 14px;
    font-weight: 300;
    color: #6d7e8f;
  }

  .cjl-logo-select-field {
    width: 80px;
    height: 80px;
    margin-right: 0.4em;
    display: inline-block;
    background-color: #e6eaed1f;
    border-radius: 3px;
    overflow: hidden;
    position: relative;

    .logo-upload-overlay {
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      cursor: pointer;
      background-color: transparent;
      border: 2px solid #e6eaed1f;
      margin: 0;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      transition: all 200ms;

      .clear-logo-button {
        position: absolute;
        top: -18px;
        right: 1px;
        width: 18px;
        height: 18px;
        opacity: 0;
        background-color: #d0021b;
        color: white;
        border-radius: 3px;
        font-size: 16px;
        line-height: 16px;
        text-align: center;
        transition: all 200ms;
      }

      &:hover {
        background-color: transparentize(#e6eaed, 0.4);
        border: 2px solid #e6eaed;
        .clear-logo-button {
          top: 1px;
          opacity: 1;
        }
      }
    }

    .placeholder {
      color: #aaa;
      font-size: 60px;
      text-align: center;
      transition: all 300ms;
      opacity: 0.6;
      &:hover {
        opacity: 1;
      }
    }

    .logo-wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .logo-image {
      font-size: 18px;
      font-weight: 300;
      border-radius: 3px;
    }

    input[type="file"] {
      display: none;
    }
  }
}

@media (min-width: 768px) {
  .pt-ui {
    .view {
      flex-direction: column;
    }

    .card-list-horizontal {
      flex-direction: row;
    }

    .cjl-overview-hero {
      margin: 40px;
      background: white url("../../../assets/img/client_job_libraries-hero-bg.png")
        no-repeat right bottom;

      &-content {
        padding: 20px 325px 30px 10px;
      }
    }

    .cjl-body {
      flex-direction: row;
    }

    .cjl-detail-info,
    .cjl-custom-info {
      flex-direction: row;
    }

    .cjl-detail-members,
    .cjl-custom-members {
      padding-right: 10px;
      padding-bottom: inherit;
    }
  }
}
