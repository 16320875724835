@mixin page-padding {
  padding: 15px;
}

$lime: #c2d646;
$icon-blue: #337ab7;
$icon-black: #333333;

.pt-ss-job-titles,
.pt-ss-ratecard {
  @include page-padding;
}

.pt-step-count {
  color: #666666;
  padding: 8px 12px 8px 0;
  float: right;
}

#btn-next,
#btn-prev {
  outline: none;
}

#btn-next,
#btn-scheduled-search {
  float: right;
}

.pt-table-icon {
  padding: 0 0 0 7px;
}

.pt-job-titles-table {
  min-height: 300px;
}

.pt-ss-title-form > .form-group {
  border-bottom: none;
}

.pt-autocomplete-list {
  background-color: #fff;
  width: 100%;
  max-height: 300px;
}

.pt-autocomplete-item {
  padding: 10px;

  &:hover {
    background-color: $lime;
    color: #fff;
  }

  &:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

.pt-choose-ratecard .select2-container,
.pt-choose-location-regions .select2-container,
.margin-20 {
  margin: 20px 0;
}

.jobs-table.table th,
.pt-summary-body .table th {
  text-align: left;
}

.pt-summary-body .glyphicon-edit {
  float: right;
  cursor: pointer;
  color: $icon-blue;

  &:hover {
    color: $icon-black;
  }
}

.pt-summary-body .pt-rate-type-label {
  text-align: left;
}

#btn-scheduled-search > .glyphicon-search {
  vertical-align: middle;
  margin-right: 8px;
}

.pt-light-bold {
  font-weight: 600;
}

.form-horizontal .pt-new-ratecard-form-group {
  padding: 20px 0;
}

textarea#jobSkills {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.pt-ss-control-btn {
  text-align: center;
}

.pt-panel-heading-style {
  color: #666666 !important;
  text-shadow: 0 1px 0 #ffffff;
  font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: normal;
  padding: 0 12px 0 0;
  line-height: 16px;
  margin: 0;
}

.pt-text-left {
  text-align: left !important;
}

.pt-ss-date-created {
  padding-right: 30px !important;
}

.pt-ss-title-form .form-group.has-feedback {
  padding-bottom: 0;
  margin-bottom: -4px;
  .help-block {
    margin-bottom: 0;
    margin-top: 3px;
  }
}
.select2-default .select2-chosen {
  color: #b3b4bc !important;
}

.select2-chosen {
  font-size: 14px !important;
  margin-left: 3px;
}

.pt-centered-content {
  text-align: center;
  & > * {
    display: inline-block;
  }
}

.pt-ss-info-tooltip {
  height: 32px;
  line-height: 32px;
}
