#newWorksheetModal,
#addRecordModal {
  .nav.nav-tabs {
    margin-bottom: 10px;
    padding: 5px 5px 0;
    border-bottom-color: #eeeeee;
    background-color: #fbfbfb;
    li {
      width: 50%;
      a {
        text-align: center;
        color: #bbbbbb;
      }
      &:hover a {
        background-color: #f9f9f9;
        border-color: #eeeeee;
        color: #999999;
      }
      &.active a {
        background-color: #fff;
        border-color: #eeeeee #eeeeee #ffffff;
        color: #666666;
      }
    }
  }
}
