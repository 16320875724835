@import "../variables";

@keyframes wizard-slide-in-rtl {
  0% {
    transform: translateX(100%);
    opacity: 0;
    visibility: visible;
  }

  100% {
    transform: translate(0);
    opacity: 1;
    visibility: visible;
  }
}

@keyframes wizard-slide-out-rtl {
  0% {
    transform: translateX(0);
    opacity: 1;
    visibility: visible;
  }

  100% {
    transform: translate(-100%);
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes wizard-slide-in-ltr {
  0% {
    transform: translateX(-100%);
    opacity: 0;
    visibility: visible;
  }

  100% {
    transform: translate(0);
    opacity: 1;
    visibility: visible;
  }
}

@keyframes wizard-slide-out-ltr {
  0% {
    transform: translateX(0);
    opacity: 1;
    visibility: visible;
  }

  100% {
    transform: translate(100%);
    opacity: 0;
    visibility: hidden;
  }
}

.pt-ui {
  .rc-wizard {
    .rc-wizard-header {
      display: flex;
      flex: 0 1 auto;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      background-color: $rc-gray7;

      .wizard-step-header {
        padding: 20px 40px;
        margin: -20px;
        display: block;
        line-height: 12px;
        z-index: 0;
        //border-radius: 3px;

        //border: 2px solid $rc-default-color;
        background-color: $rc-gray8;
        color: $rc-gray5;

        //&:hover {
        //  background-color: darken($rc-default-color, 6.8%);
        //  border: 2px solid darken($rc-default-color, 6.8%);
        //}

        & > label {
          font-size: 12px;
          margin-bottom: 0;
        }

        & > header {
          font-size: 21px;
        }

        &.active {
          //border: 2px solid $rc-yellow;
          background-color: $rc-green4;
          color: $rc-white1;
        }
        &.completed {
          //border: 2px solid $rc-green4;
          background-color: $rc-green6;
          color: $rc-white1;
          cursor: pointer;
          &:hover {
            //border: 2px solid $rc-green6;
            background-color: $rc-green4;
          }
        }

        .fa {
          font-size: 10px;
        }
      }

      .intersection {
        width: 36px;
        height: 36px;
        display: flex;
        flex: 0 1 auto;
        align-content: center;
        align-items: center;
        justify-content: center;
        border-radius: 50px;
        border: none;
        z-index: 1;
        background-color: white;
        margin: 0 2px;

        &.disabled {
          cursor: default;
          opacity: 0.6;
        }

        .fa {
          margin: auto;
          font-size: inherit !important;
        }
      }
    }

    .rc-wizard-content {
      //position: relative;
      //margin: 0 -20px;
      //padding: 20px;
      //border-bottom: 1px solid #eceff1;
      //overflow: hidden;

      .rc-wizard-step {
        display: inline-block;
        opacity: 1;
        visibility: visible;
        width: 100%;
        font-size: 16px;
        line-height: normal;

        &.animate-show-forward {
          animation: wizard-slide-in-rtl 250ms ease-in;
        }

        &.animate-hide-forward {
          animation: wizard-slide-out-rtl 250ms ease-in;
          transform: translate(-100%);
          width: auto;
          top: 20px;
          left: 0;
          opacity: 0;
          visibility: hidden;
          position: absolute;
        }

        &.animate-show-backward {
          animation: wizard-slide-in-ltr 250ms ease-out;
        }

        &.animate-hide-backward {
          animation: wizard-slide-out-ltr 250ms ease-out;
          transform: translate(100%);
          width: auto;
          top: 20px;
          left: 0;
          opacity: 0;
          visibility: hidden;
          position: absolute;
        }
      }
    }
  }
}
