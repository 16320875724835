@import "../base/colors";
@import "../utils/mixins";

.pt-base-search-input {
  // bootstrap overrides

  & .form-group {
    margin-bottom: 0;
    display: inline-block;
  }
  & .input-group {
    padding-top: 0;
    padding-bottom: 0;
  }
  & .input-group-addon {
    @include pt-no-select();
    @include pt-no-focus-outline();

    -webkit-transition: color 0.2s ease;
    -moz-transition: color 0.2s ease;
    -o-transition: color 0.2s ease;
    transition: color 0.2s ease;
  }

  & .form-control {
    padding: 6px 12px !important;
  }
  & .form-control:focus {
    border-color: $olive;
  }

  // modifiers

  &--active .input-group-addon {
    cursor: pointer;

    &:hover,
    &:active {
      color: $olive;
    }
  }
  &--disabled .input-group-addon {
    cursor: not-allowed;
    color: $slate;
  }
}
