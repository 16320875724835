.clients {
  .ratecard {
    &-list {
      &-item {
        &-right {
          max-width: 40%;
        }
        &-left {
          max-width: 28%;
        }
      }
    }
  }
  label {
    font-size: 16px;
  }
}
.alert {
  margin-top: 10px;
}
.rc-checkbox-component {
  display: inline-block;
  vertical-align: top;
}
.selectfix {
  .input_group {
    .Select {
      &-input {
        //margin-top: -8px;
      }
      &-placeholder {
        padding: 0px !important;
        position: absolute;
        left: 5px;
        top: 6px;
      }
      &-clear {
        top: 5px;
        right: 30px;
      }
      &-loading {
        top: 7px;
      }
      &-control {
        padding: 0px !important;
        min-height: 34px;
      }
    }
  }
}
.cross-fix {
  .Select {
    &-clear {
      top: 5px;
      right: 25px;
    }
    &-loading {
      top: 7px;
    }
    &-placeholder {
      padding-left: 6px;
    }
  }
}
.max-80 {
  max-width: 450px !important;
  display: inline-block;
}
.jobclients {
  .ratecard {
    &-list {
      &-item {
        &-left,
        &-right {
          max-width: 50%;
        }
      }
    }
  }
}

.vshidden {
  visibility: hidden;
}
.max-w-80 {
  max-width: 80%;
  .btn-toggle.borderless {
    margin-bottom: 5px;
    margin-left: 10px;
    margin-right: 0px;
  }
  .btn.btn-yellow {
    // margin-top:-5px;
  }
}
.flex-end {
  @include justify-content(flex-end !important);
}
.width-110 {
  max-width: 110 - 8px;
  //margin-left: 15px;
}
.atlfixformborder {
  .Select {
    &-clear {
      top: -1px;
      right: 30px;
    }
    &-loading {
      top: 8px;
    }
  }
}
.tooltip {
  &-inner {
    color: #fff;
    span {
      color: #fff !important;
    }
  }
}
.atlfixformborders.atlfixformborder {
  .Select {
    &-loading {
      top: 14px;
    }
    &-clear {
      top: -1px;
      right: 30px;
    }
  }
}
.nohover.ratecard-list-item-container .ratecard-list-item:hover {
  background: #fff;
  cursor: default;
}
.nohover.ratecard-list-item-container .ratecard-list-item:hover .ratecard-label {
  color: #a2b624;
}
.header {
  &-box {
    @include flexbox();
    @include justify-content(space-between);
    @include align-items(center);
    &-left,
    &-right {
      @include flex(0 0 50%);
      max-width: 50%;
      @include flex-direction(column);
    }
    .back {
      &-bar {
        margin-left: 0px;
        text-align: right;
        a,
        button {
          display: inline-block;
        }
      }
    }
    .btn {
      margin-bottom: 10px;
      margin-left: 10px;
      margin-right: 0px;
      &:last-child {
        margin-bottom: 10px;
        margin-left: 10px;
      }
    }
  }
}
.ps {
  position: absolute;
  right: 12px;
  bottom: 11px;
}
.container-section {
  .form-group {
    position: relative;
  }
}
.headerfix {
  padding-top: 0px !important;
}
.marginTopzero {
  margin-top: 0px !important;
}
.marginTopzero .pt-search {
  margin-top: 0px;
}
.subtext {
  font-size: 16px;
  margin-bottom: 0px !important;
}
.dragging-item {
  border: 2.9px dashed #fff;
  cursor: move !important;
  padding: 10px 5px;
  color: #fff;
  border-radius: 2px;
  font-size: 16px;
  margin: 2px 0;
}
.submenu-item-editing > div {
  padding: 10px 5px;
  font-size: 16px;
}
.history-list-items div {
  font-size: 16px;
}
.submenu-item > div {
  padding: 5px 10px;
  font-size: 16px;
}
.submenu-item > div.submenu-item-description,
.submenu-item-editing div.submenu-item-description {
  font-size: 14px;
  padding: 0px 10px 5px;
}
.height-auto {
  .Select {
    &-control {
      height: auto;
    }
  }
}
.b-height-34 {
  height: 34px;
  padding: 6px 10px !important;
}
.pt-user-messages {
  max-width: 500px;
}
.userjob {
  .form-group {
    .input-group {
      padding: 0px;
    }
  }
}
.list-group {
  margin-bottom: 0px;
}
.notifications-blank {
  div {
    font-size: 14px;
    @include flexbox();
    @include align-items(center);
    @include justify-content(center);
    width: 100%;
    padding: 10px;
  }
}
.ws-table,
.wstable {
  .ratecard-list-item {
    cursor: default;
    &:hover {
      background-color: #fff;
      cursor: default;
      .ratecard-label {
        color: #a2b624;
      }
    }
  }
}
.align-items-center {
  @include align-items(center);
}
.ratecards .rc-container .header-action-bar,
.regions .rc-container .header-action-bar {
  h4 {
    display: inline-block;
    vertical-align: middle;
  }
}

.regions .placeholderfix .Select-control .Select-placeholder {
  padding-left: 8px;
}
.select-list .col-sm-4,
.select-list .col-sm-6 {
  padding-left: 0px;
  padding-right: 0px;
}
.ratecards.pt-filter-modal-inner .Select.is-multi .Select-control,
.pt-filter-modal-inner.regions .Select.is-multi .Select-control {
  padding-bottom: 1px !important;
  height: auto;
}
.ratecards .atlfixformborder .Select.is-multi .Select-control {
  height: auto;
}
.wstable.ratecard-list-item-container {
  border: 0px !important;
  .ratecard-list-item {
    padding: 0px;
  }
}
.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
  cursor: not-allowed !important;
}
.d-flex {
  @include flexbox();
}
.btn-primary:active:hover,
.btn-primary:active:focus,
.btn-primary:active.focus,
.btn-primary.active:hover,
.btn-primary.active:focus,
.btn-primary.active.focus,
.open > .btn-primary.dropdown-toggle:hover,
.open > .btn-primary.dropdown-toggle:focus,
.open > .btn-primary.dropdown-toggle.focus {
  background-color: #b2c72c;
}
.ratecards .header-right-nav,
.regions .header-right-nav {
  max-width: 80%;
  .btn {
    margin-right: 0px;
    margin-left: 10px;
    margin-bottom: 5px;
  }
}
.ratecards .ratecards-table-header {
  @include justify-content(flex-end);
}
.nego {
  .ratecards-table {
    .ratecard-list-item-container {
      .ratecard-list-item-right {
        max-width: 50%;
      }
    }
  }
}
.sech-search {
  .ratecards-table {
    .ratecard-list-item-container {
      .ratecard-list-item-right {
        max-width: 40%;
      }
    }
  }
}
.Select-control.height_auto.pad-bot-1 {
  padding-bottom: 1px !important;
}
.ratecards .pt-card h1,
.ratecards .pt-card h2,
.ratecards .pt-card h3,
.ratecards .pt-card h4,
.ratecards .pt-card h5 {
  display: block !important;
}
.sidebar-menu li:first-child {
  cursor: pointer;
}
.sidebar-menu li .remove-list {
  line-height: 1px;
}
.sidebar-menu li a {
  font-size: 14px;
}
.sidebar-menu li .fa-search.pt-search-icon {
  color: #fff;
}
.sidebar-menu li.active .fa-search.pt-search-icon {
  color: #575656;
}

.alignSearch {
  padding: 0px !important;
}

.filename {
  font-size: 16px;
  margin-top: 8px;
}
.width100perc {
  width: 100%;
}
.selecnewbox {
  border: 1px solid #d2d6de;
  line-height: 1.2;
  border-radius: 4px;
  min-height: 34px;
  height: auto;
  .Select-item {
    margin: 2px 2px;
    &-label {
      padding: 3px 5px !important;
      font-size: 14px;
    }
  }
}
.marginminus20lert {
  margin-left: -20px;
  margin-right: -20px;
  .ratecards-list {
    margin: 0px !important;
  }
  .pce-search {
    margin: 0px !important;
  }
}
.ratesearch {
  .search-card-content.atlfixformborder {
    .custom-form {
      textarea {
        &#job_description {
          min-height: 346px;
        }
      }
    }
  }
}
.ratecards {
  .search-card-content.atlfixformborder {
    .custom-form {
      textarea {
        &#job_description {
          min-height: 205px;
        }
      }
    }
  }
}
