.login-page {
  display: flex;
  flex-flow: column nowrap;
  height: 100vh;
  min-height: 700px;
  overflow: hidden;

  .login-header {
    background-color: #333333;
    padding: 40px;
    font-size: 14px;
    height: 160px;
    flex: 0 1 auto;

    .logo-image {
      background-image: url("../../../assets/img/logo-light.png");
      margin: auto;
      width: 248px;
      height: 38px;
      //background: url('https://www.peopleticker.com/themes/peopleticker/img/logo.png') right top no-repeat;
    }
  }

  .login-body {
    background: url("../../../assets/img/pattern_bg.png") bottom center no-repeat;
    flex: 1 1 auto;
    position: relative;
    overflow: visible;
    width: 100%;
    min-height: 100px;
    border-bottom: 3px solid #c5d850;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.6);

    .pt-ui.box-container {
      display: flex;
      justify-content: center;
      background-color: transparent;
    }
  }

  .login-footer {
    flex: 0 1 auto;
    color: #999999;
    background-color: #333333;
    font-weight: normal;
    font-size: 14px;
    padding: 40px 40px 0;
    width: 100%;
    text-align: left;
    //height: 160px;

    .row {
      max-width: 1100px;
      margin: auto;
    }

    .copyright {
      color: #777777;
      font-size: 12px;
      padding: 10px 0;
    }

    h4.footer-title {
      margin: 0;
      padding-top: 0;
      padding-bottom: 8px;
      border-bottom: 1px solid #777777;
    }
    h4.footer-title,
    h2.contact-phone {
      color: #999999;
    }
    h4.contact-text {
      color: #777777;
      margin-bottom: 0;
      padding-bottom: 0;
    }
    h2.contact-phone {
      margin-top: 0;
      padding-top: 0;
    }
    ul.footer-list {
      list-style: none;
      margin: 10px 0 0;
      padding: 0;
    }
    ul.footer-list li a {
      color: #777777;
      font-size: 17px;
      display: inline-block;
      padding: 2px 0;
    }
    ul.footer-list li a:hover {
      text-decoration: none;
      color: #aaaaaa;
    }
    .social {
      margin: 15px 0 0;
      padding: 0;
      list-style: none;
    }
    .social li {
      float: left;
      margin-left: 20px;
    }
    .social li:first-child {
      margin-left: 0;
    }
    .social li a {
      font-size: 30px;
      background-color: #5c5c5c;
      color: #333333;
      display: block;
      text-align: center;
      line-height: 50px;
      border-radius: 50%;
      width: 50px;
      -webkit-transition: background-color 0.3s;
      -ms-transition: background-color 0.3s;
      transition: background-color 0.3s;
    }
    .social li a:hover {
      background-color: #777777;
    }
  }
}

.center {
  text-align: center;
}

.box-login {
  background: white;
  width: 350px;
  border-radius: 6px;
  padding: 40px;
  border-top: #a2b624 4px solid;
  box-sizing: border-box;
  margin: -54px auto 10px;
  box-shadow: 0px 6px 30px 0px #0000004a;
  overflow: hidden;
  //background: url('../assets/img/page_head_bg.jpg') center center no-repeat;
  //background-size: 100% 100%;

  .box-header {
    margin: -42px -40px 40px;
    padding: 30px 40px;
    background: #5f5f5f;
    position: relative;
    .box-header-bg {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: url("../../../assets/img/pattern_bg.png") bottom center no-repeat;
      background-size: 200%;
    }
  }

  .box-footer {
    display: flex;
    justify-content: center;
    background-color: #e6e6e650;
    font-size: 14px;
    margin: 40px -40px -40px;
    padding: 20px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
}
.box-login h1 {
  margin: 0;
  padding: 0;
  font-size: 26px;
  font-weight: 300;
  text-align: center;
  color: #ffffff;
}

.box-login {
  //input {
  //  margin-bottom: 15px;
  //  background: #ffffff50;
  //}

  //input:-webkit-autofill,
  //input:-webkit-autofill:hover,
  //input:-webkit-autofill:focus,
  //input:-webkit-autofill:active  {
  //  -webkit-box-shadow: 0 0 0 30px #ffffff50 inset !important;
  //  background: #ffffff50 !important;
  //}
}

.box-login .form-buttons {
  margin-top: 30px;
  display: flex;
  justify-content: center;

  button {
    min-width: 130px;
  }
}

//.box-login .btn.btn-default.pull-right{
// margin-right: 0px;
//}
//.login{
//  background:#ecf0f1;
//  border: #ccc 1px solid;
//  border-bottom: #ccc 2px solid;
//  padding: 8px;
//  width:250px;
//  color:#AAAAAA;
//  margin-top:10px;
//  font-size:1em;
//  border-radius:4px;
//}

//.password{
//  border-radius:4px;
//  background:#ecf0f1;
//  border: #ccc 1px solid;
//  padding: 8px;
//  width:250px;
//  font-size:1em;
//}
