@import "../base/colors";

.pt-button {
  display: inline-block;
  position: relative;
  padding: 0 5px;
  border: 0;
  border-radius: 5px;
  color: $white !important;
  font-size: 17px;
  text-align: center;
  text-decoration: none !important;
  background: $olive;
  cursor: pointer;
  outline: none;

  &:enabled:hover:before,
  &--pressed:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background: rgba($black, 0.1);
  }

  &:enabled:active:after {
    display: none;
  }

  &:disabled,
  &--disabled {
    background: #d6d6d6 !important;

    &:hover {
      cursor: not-allowed;
    }
  }

  & a {
    color: $white !important;
    text-decoration: none !important;
  }

  &--xsmall {
    font-size: 14px;
    padding: 3px 5px;
  }
  &--small {
    padding: 5px 10px;
  }
  &--medium {
    padding: 10px 20px;
  }
  &--large {
    padding: 20px 40px;
  }
  &--wide {
    padding: 10px;
    width: 100%;
  }
}
