@import "../../base/colors";
@import "../pt_ui/variables";

.main-wrap {
  margin: 0 auto !important;
  min-height: 100vh;
  position: relative;
}

.save-btn {
  margin-bottom: 20px !important;
}

.main-sidebar {
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100vh;
  width: 260px;
  z-index: 810;
  -webkit-transition: -webkit-transform 0.3s ease-in-out, width 0.3s ease-in-out;
  -moz-transition: -moz-transform 0.3s ease-in-out, width 0.3s ease-in-out;
  -o-transition: -o-transform 0.3s ease-in-out, width 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, width 0.3s ease-in-out;
  background: #a19f9f;
  height: 100%;
}

.sidebar {
  padding-bottom: 20px;
}

.sidebar-menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav-tabs {
  margin: 0px;
}

.sidebar-menu > li {
  position: relative;
  margin: 0;
  padding: 0;
  background: #a19f9f;
  border-bottom: 1px solid #868484;
  border-right: 0px;
  border-left: 0px;
}

.sidebar-menu > li > a {
  padding: 15px 12px;
  font-size: 12px;
  color: #fff !important;
  padding-left: 8px;
  max-width: 81%;
  display: inline-block;
  vertical-align: middle;
}

.sidebar-menu > li > a:hover {
  color: #e9c82c !important;
}

.input-group .form-control {
  text-align: left;
}

.sidebar-menu > li > .remove-list {
  background: transparent;
  border: 0;
  display: inline-block;
  /*float: left;*/
  vertical-align: middle;
  color: #fff;
  font-size: 18px;
  padding: 11px 9px;
  vertical-align: middle;
  outline: none !important;
  padding-left: 20px;
}

.sidebar-menu > li.header.on {
  color: #a2b624;
  background: #ffffff;
  font-size: 15px;
  padding: 15px 20px;
  border-right: 0px;
  font-weight: 700;
}

.sidebar-menu > li.header.on span {
  padding: 0 7px;
}

.sidebar-menu > li.header.on::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 10px;
  top: 0px;
  left: 0px;
  background: #a2b624;
}

.sidebar-menu > li.header.off {
  color: #fff;
  background: #a19f9f;
  font-size: 15px;
  padding: 15px 20px;
  border-right: 0px;
  font-weight: 700;
}

.sidebar-menu > li.header.off span {
  padding: 0 7px;
}

.sidebar-menu > li.active {
  background: #fff !important;
}

.sidebar-menu > li.active::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 10px;
  left: 0px;
  background: #a2b624 !important;
}

.sidebar-menu > li.active a {
  color: #666 !important;
}

.sidebar-menu > li.active .remove-list {
  color: #a2b624 !important;
}

.content-wrapper {
  -webkit-transition: -webkit-transform 0.3s ease-in-out, margin 0.3s ease-in-out;
  -moz-transition: -moz-transform 0.3s ease-in-out, margin 0.3s ease-in-out;
  -o-transition: -o-transform 0.3s ease-in-out, margin 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, margin 0.3s ease-in-out;
  margin-left: 260px;
  z-index: 820;
  padding: 34px 15px 0 20px;
  min-height: 100vh;
}

.content-header {
  position: relative;
  border-bottom: 1px solid #cccccc;
  padding-bottom: 17px !important;
  margin-top: 53px;
}

.content-wrapper .content-header h1 {
  margin: 0px;
  padding: 0px;
}

.rateSearchDetail {
  .ratesearch-chart-container {
    display: flex;
  }

  .ratesearch-arrow-chart {
    flex-grow: 1;
    padding: 20px;
  }
  .ratesearch-bar-chart {
    flex-grow: 1;
    padding: 50px 20px 0 20px;
  }

  transition: margin 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, margin 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, margin 0.3s ease-in-out,
    -webkit-transform 0.3s ease-in-out;
  margin-left: 260px;
  z-index: 820;
  padding: 34px 15px 0 20px;
  .info_head {
    padding-top: 0px;
    h2 {
      margin-top: 0px;
    }
  }
}

.newForm {
  //padding: 44px 0 0 21%;
}

.countdown {
  //box-shadow: 0 0 6px 0px rgba(202, 173, 173, 0.5);
  display: flex;
  align-items: baseline;
  background: transparent;
  margin-top: 0;
  margin-bottom: 0;
  //position: absolute;
  //top: -12px;
  //right: 0;
  border-radius: 2px;
  //padding: 5px 9px 5px 20px;
  color: #666666;
  font-size: 18px;
}

.countdown strong {
  color: #333333;
  font-size: 18px;
}

.countdown .remaining-title {
  margin-right: 20px;
}

.countdown .yellow-btn {
  font-size: 16px;
  padding: 4px 25px;
  margin-top: -2px;
}

.search-card-content {
  min-height: 250px;
  padding: 35px 0 !important;
  margin-right: auto !important;
  margin-left: auto !important;
}

.custom-form label {
  font-size: 13px;
  color: #999999;
  font-weight: normal;
}

.custom-form .input-group {
  padding: 0px;
}

.custom-form .form-group {
  margin-bottom: 20px;
}

.newForm .custom-form .form-group {
  margin-bottom: 15px;
}

.custom-form #job-description {
  height: 207px;
}

.submit-btn {
  font-size: 15px;

  padding: 4px 25px;
  font-weight: bold;
  border-radius: 2px;
  min-width: 170px;
}

.compare-btn {
  text-align: center;
  margin-top: 116px;
}

.form-control {
  border-radius: 4px;
  border: 2px solid #d2d6de;
  box-shadow: none;
  resize: none;
  height: 34px;
  color: #333 !important;
}

.input-group-btn:last-child > .btn,
.input-group-btn:last-child > .btn-group {
  background: #a2b624;
  border-color: #a2b624;
  color: #ffffff;
  padding: 6px 12px;
  font-size: 16px;
}

.input-group-btn {
  overflow: hidden;
}

.input-group-btn:last-child > .btn:hover,
.input-group-btn:last-child > .btn-group:hover {
  background: #a2b624 !important;
  border: 1px solid #a2b624 !important;
  border-radius: 0 3px 3px 0 !important;
}

.form-control:focus {
  border-color: #cccccc;
  box-shadow: none;
}

.form-control::-moz-placeholder,
.form-control:-ms-input-placeholder,
.form-control::-webkit-input-placeholder {
  //  color: #bbb;
  color: #333;
  opacity: 1;
}

.form-control:not(select) {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.form-group.has-success label {
  color: #00a65a;
}

.form-group.has-success .form-control,
.form-group.has-success .input-group-addon {
  border-color: #00a65a;
  box-shadow: none;
}

.form-group.has-success .help-block {
  color: #00a65a;
}

.form-group.has-warning label {
  color: #f39c12;
}

.form-group.has-warning .form-control,
.form-group.has-warning .input-group-addon {
  border-color: #f39c12;
  box-shadow: none;
}

.form-group.has-warning .help-block {
  color: #f39c12;
}

.form-group.has-error label {
  color: #dd4b39;
}

.form-group.has-error .form-control,
.form-group.has-error .input-group-addon {
  border-color: #dd4b39 !important;
  box-shadow: none;
}

.form-group.has-error .help-block {
  color: #dd4b39;
}

//.input-group .input-group-addon {
//  border-radius: 0;
//  border-color: #d2d6de;
//  background-color: #fff;
//}

.btn-group-vertical .btn.btn-flat:first-of-type,
.btn-group-vertical .btn.btn-flat:last-of-type {
  border-radius: 0;
}

.icheck > label {
  padding-left: 0;
}

.form-control-feedback.fa {
  line-height: 34px;
}

.input-lg + .form-control-feedback.fa,
.input-group-lg + .form-control-feedback.fa,
.form-group-lg .form-control + .form-control-feedback.fa {
  line-height: 46px;
}

.input-sm + .form-control-feedback.fa,
.input-group-sm + .form-control-feedback.fa,
.form-group-sm .form-control + .form-control-feedback.fa {
  line-height: 30px;
}

.react-tags {
  position: relative;
  border-radius: 3px;
  border: 2px solid #d1d1d1;
  box-shadow: none;
  border-color: #d2d6de;
  resize: none;
  height: auto;
  font-size: 1em;
  line-height: 1.2;
  margin-right: -2px;
  cursor: text;
  padding: 3px 4px !important;
}

.react-tags.is-focused {
  border-color: #c2d646;
}

.form-group .input-group-btn:last-child > .btn:hover,
.form-group .input-group-btn:last-child > .btn-group:hover {
  border: 0px !important;
}

.react-tags__selected {
  display: inline;
}

.react-tags__selected-tag {
  display: inline-block;
  box-sizing: border-box;
  margin: 0 5px 0 0;
  padding: 3px 8px;
  border: 0px;
  border-radius: 2px;
  background: #e2e9b9;
  color: #afb972;
  font-size: 13px;
  font-weight: 600;
  position: relative;
  line-height: inherit;
  outline: none;
}

.react-tags__selected-tag:after {
  content: "\f00d";
  font: normal normal normal 16px/1 FontAwesome;
  color: #afb972;
  margin-left: 10px;
}

.react-tags__selected-tag:hover:after,
.react-tags__selected-tag:focus:after {
  color: #778712;
}

.react-tags__selected-tag:hover,
.react-tags__selected-tag:focus {
  background: #e9c82c;
  color: #ffffff;
}

.react-tags__search {
  display: inline-block;
  padding: 4px 2px;
  margin-bottom: 0;
  max-width: 100%;
}

@media screen and (min-width: 30em) {
  .react-tags__search {
    /* this will become the offsetParent for suggestions */
    position: relative;
  }
}

.react-tags__search input {
  /* prevent autoresize overflowing the container */
  max-width: 100%;

  /* remove styles and layout from this element */
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;

  /* match the font styles */
  font-size: inherit;
  line-height: inherit;
}

.react-tags__search input::-ms-clear {
  display: none;
}

.react-tags__suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
}

@media screen and (min-width: 30em) {
  .react-tags__suggestions {
    width: 240px;
    z-index: 99999;
  }
}

.react-tags__suggestions ul {
  margin: 4px -1px;
  padding: 0;
  list-style: none;
  background: white;
  border: 1px solid #d1d1d1;
  border-radius: 2px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.react-tags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 6px 8px;
}

.react-tags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}

.react-tags__suggestions li:hover {
  cursor: pointer;
  background: #e9c82c;
}

.react-tags__suggestions li.is-active {
  background: #b7cfe0;
}

.react-tags__suggestions li.is-disabled {
  opacity: 0.5;
  cursor: auto;
}

.tabs-left > .nav-tabs > li,
.tabs-right > .nav-tabs > li {
  float: none;
}

.tabs-left > .nav-tabs > li > a,
.tabs-right > .nav-tabs > li > a {
  min-width: 74px;
  margin-right: 0;
  margin-bottom: 3px;
}

.tabs-left > .nav-tabs {
  float: left;
  margin-right: 19px;
  border-right: 1px solid #ddd;
}

.tabs-left > .nav-tabs > li > a {
  margin-right: -1px;
  -webkit-border-radius: 4px 0 0 4px;
  -moz-border-radius: 4px 0 0 4px;
  border-radius: 4px 0 0 4px;
}

.tabs-left > .nav-tabs > li > a:hover,
.tabs-left > .nav-tabs > li > a:focus {
  border-color: #eeeeee #dddddd #eeeeee #eeeeee;
}

.tabs-left > .nav-tabs .active > a,
.tabs-left > .nav-tabs .active > a:hover,
.tabs-left > .nav-tabs .active > a:focus {
  border-color: #ddd transparent #ddd #ddd;
  *border-right-color: #ffffff;
}

.tabs-left > .nav-tabs {
  border-bottom: 0;
}

.row-fluid {
  display: flex;
}

.container-custom {
  width: 900px;
  margin: auto;
  border: 3px solid;
}

table.no-spacing {
  border-spacing: 0; /* Removes the cell spacing via CSS */
  border-collapse: collapse; /* Optional - if you don't want to have double border where cells touch */
}

.tabs-left .nav li a {
  font-size: 14px;
}

.tabs-left .nav-tabs {
  min-width: 353px;
  height: 100%;
}

.bootstrap-typeahead .dropdown-menu > li a {
  overflow: hidden;
  text-overflow: ellipsis;
}

.bootstrap-typeahead .dropdown-menu > li a:focus {
  outline: none;
}

.bootstrap-typeahead .dropdown-menu-justify {
  right: 0;
}

/* Hide IE's native "clear" button */
.bootstrap-typeahead input[type="text"]::-ms-clear {
  display: none;
}

.bootstrap-typeahead-menu-paginator {
  text-align: center;
}

/* Input sizes */
.bootstrap-tokenizer {
  padding-bottom: 2px;
  padding-top: 5px;
}

.input-sm.bootstrap-tokenizer {
  padding-bottom: 1px;
  padding-top: 4px;
}

.input-lg.bootstrap-tokenizer {
  padding-bottom: 6px;
  padding-top: 9px;
}

.bootstrap-tokenizer-input {
  margin: 1px 0 4px;
}

.has-aux {
  padding-right: 32px;
}

.has-aux.input-lg {
  padding-right: 42px;
}

/* Fix styles when used with input-group */
.input-group .bootstrap-typeahead:first-child .form-control {
  border-radius: 4px 0 0 4px;
}

.input-group .bootstrap-typeahead:last-child .form-control {
  border-radius: 0 4px 4px 0;
}

.bootstrap-typeahead-loader {
  -webkit-animation: loader-animation 600ms infinite linear;
  -o-animation: loader-animation 600ms infinite linear;
  animation: loader-animation 600ms infinite linear;
  border: 1px solid #d5d5d5;
  border-radius: 50%;
  border-top-color: #666;
  display: block;
  height: 16px;
  right: 12px;
  margin-top: -8px;
  position: absolute;
  top: 50%;
  width: 16px;
}

.loader-lg {
  height: 20px;
  margin-top: -10px;
  right: 16px;
  width: 20px;
}

.loader-sm {
  right: 10px;
}

@keyframes loader-animation {
  to {
    transform: rotate(1turn);
  }
}

@-webkit-keyframes loader-animation {
  to {
    -webkit-transform: rotate(1turn);
  }
}

/* Token */
.bootstrap-tokenizer .token {
  background-color: #999999;
  border: 0;
  border-radius: 0px;
  border: 1px solid #666666;
  color: #fff;
  display: inline-block;
  line-height: 1em;
  margin: 0 3px 3px 0;
  padding: 4px 7px;
  position: relative;
}

.bootstrap-tokenizer .token > span {
  margin-right: 12px;
  font-size: 13px;

  font-weight: normal;
}

.bootstrap-tokenizer .token-disabled {
  background-color: #ddd;
  color: #888;
  pointer-events: none;
}

.bootstrap-tokenizer .token-removeable {
  cursor: pointer;
  padding-right: 21px;
}

.bootstrap-tokenizer .token-selected {
  background-color: #666;
  color: #fff;
  outline: none;
  text-decoration: none;
}

.bootstrap-tokenizer .token .close-button {
  bottom: 0;
  padding: 4px 5px;
  position: absolute;

  top: 0;
  font-size: 21px;
}

.modal-dialog {
  width: 800px;
}

.modal.fade .modal-dialog {
  border: 8px solid #666666;
}

.modal-dialog .form-control {
  border: 1px solid #666666;
  border-radius: 0px;
  padding: 4px 5px;
}

.bootstrap-typeahead .dropdown-menu-justify {
  border: 1px solid #666666;
  border-radius: 0px;
  box-shadow: none !important;
  border-top: 0px;
  margin-top: 0px;
}

.select-list {
  border: 1px solid #dddddd;
}

.select-list .list-group {
  min-height: 225px;
  max-height: 225px;
  overflow-y: scroll;
}

.select-list .col-sm-4 {
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 0px;
  padding-bottom: 0px;
}

.list-group-item:first-child {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 6px 15px;
  cursor: pointer;
  margin-bottom: -1px;
  background-color: #fff;
  border: 0px solid #ddd;
}

.list-group-item:hover,
.list-group-item._active {
  background: #dddddd;
}

.btn-foot {
  background: #cccccc;
  color: #000;

  margin-right: 12px;
  border-color: #666666;
  min-width: 173px;
  border-radius: 0;
  min-height: 43px;
}

.btn-foot:hover {
  background: #666666;
  color: #fff;
}

.modal-footer,
.modal-header {
  border: 0px;
}

table.no-spacing {
  border-spacing: 0; /* Removes the cell spacing via CSS */
  border-collapse: collapse; /* Optional - if you don't want to have double border where cells touch */
}

.tabs-left .nav li a {
  font-size: 14px;
}

.tabs-left .nav-tabs {
  min-width: 353px;
  height: 100%;
}

.bootstrap-typeahead .dropdown-menu > li a {
  overflow: hidden;
  text-overflow: ellipsis;
}

.bootstrap-typeahead .dropdown-menu > li a:focus {
  outline: none;
}

.bootstrap-typeahead .dropdown-menu-justify {
  right: 0;
}

/* Hide IE's native "clear" button */
.bootstrap-typeahead input[type="text"]::-ms-clear {
  display: none;
}

.bootstrap-typeahead-menu-paginator {
  text-align: center;
}

/* Input sizes */
.bootstrap-tokenizer {
  padding-bottom: 2px;
  padding-top: 5px;
}

.input-sm.bootstrap-tokenizer {
  padding-bottom: 1px;
  padding-top: 4px;
}

.input-lg.bootstrap-tokenizer {
  padding-bottom: 6px;
  padding-top: 9px;
}

.bootstrap-tokenizer-input {
  margin: 1px 0 4px;
}

.has-aux {
  padding-right: 32px;
}

.has-aux.input-lg {
  padding-right: 42px;
}

/* Fix styles when used with input-group */
.input-group .bootstrap-typeahead:first-child .form-control {
  border-radius: 4px 0 0 4px;
}

.input-group .bootstrap-typeahead:last-child .form-control {
  border-radius: 0 4px 4px 0;
}

.bootstrap-typeahead-loader {
  -webkit-animation: loader-animation 600ms infinite linear;
  -o-animation: loader-animation 600ms infinite linear;
  animation: loader-animation 600ms infinite linear;
  border: 1px solid #d5d5d5;
  border-radius: 50%;
  border-top-color: #666;
  display: block;
  height: 16px;
  right: 12px;
  margin-top: -8px;
  position: absolute;
  top: 50%;
  width: 16px;
}

.loader-lg {
  height: 20px;
  margin-top: -10px;
  right: 16px;
  width: 20px;
}

.loader-sm {
  right: 10px;
}

@keyframes loader-animation {
  to {
    transform: rotate(1turn);
  }
}

@-webkit-keyframes loader-animation {
  to {
    -webkit-transform: rotate(1turn);
  }
}

/* Token */
.bootstrap-tokenizer .token {
  background-color: #999999;
  border: 0;
  border-radius: 0px;
  border: 1px solid #666666;
  color: #fff;
  display: inline-block;
  line-height: 1em;
  margin: 0 3px 3px 0;
  padding: 4px 7px;
  position: relative;
}

.bootstrap-tokenizer .token > span {
  margin-right: 12px;
  font-size: 13px;

  font-weight: normal;
}

.bootstrap-tokenizer .token-disabled {
  background-color: #ddd;
  color: #888;
  pointer-events: none;
}

.bootstrap-tokenizer .token-removeable {
  cursor: pointer;
  padding-right: 21px;
}

.bootstrap-tokenizer .token-selected {
  background-color: #666;
  color: #fff;
  outline: none;
  text-decoration: none;
}

.bootstrap-tokenizer .token .close-button {
  bottom: 0;
  padding: 4px 5px;
  position: absolute;

  top: 0;
  font-size: 21px;
}

.modal-dialog {
  width: 800px;
}

.modal.fade .modal-dialog {
  border: 8px solid #666666;
}

.modal-dialog .form-control {
  border: 1px solid #666666;
  border-radius: 0px;
  padding: 4px 5px;
}

.bootstrap-typeahead .dropdown-menu-justify {
  border: 1px solid #666666;
  border-radius: 0px;
  box-shadow: none !important;
  border-top: 0px;
  margin-top: 0px;
}

.select-list {
  border: 1px solid #dddddd;
}

.select-list .list-group {
  min-height: 225px;
  max-height: 225px;
  overflow-y: scroll;
}

.select-list .col-sm-4 {
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 0px;
  padding-bottom: 0px;
}

.list-group-item:first-child {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 6px 15px;
  cursor: pointer;
  margin-bottom: -1px;
  background-color: #fff;
  border: 0px solid #ddd;
}

.list-group-item:hover,
.list-group-item._active {
  background: #dddddd;
}

.btn-foot {
  background: #cccccc;
  color: #000;

  margin-right: 12px;
  border-color: #666666;
  min-width: 173px;
  border-radius: 0;
  min-height: 43px;
}

.btn-foot:hover {
  background: #666666;
  color: #fff;
}

.modal-footer,
.modal-header {
  border: 0px;
}

table.no-spacing {
  border-spacing: 0; /* Removes the cell spacing via CSS */
  border-collapse: collapse; /* Optional - if you don't want to have double border where cells touch */
}

.custom-table-graph td {
  padding: 5px 2px !important;
  text-align: center;
  border: 0px !important;
}

.custom-table-graph tr:first-child td {
  border-bottom: 1px solid #fff !important;
}

.custom-table-graph td:nth-child(3) {
  background: #6d6b6b;
  color: #fff;
}

.custom-table-graph td:nth-child(2) {
  background: #5a5757;
  color: #fff;
}

.custom-table-graph td:nth-child(1) {
  background: #333333;

  color: #fff;
}

.info-box {
  background: #dde7f5;
  float: left;
  height: 73px;
  width: 75px;
  color: #888888;
  text-align: center;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.radio-inline {
  color: #666;
  font-size: 11px;
  font-weight: normal;
  line-height: 23px;
  padding-bottom: 0;
  margin-bottom: 0px;
}

.list-rate .info-box.info-box_second {
  background: #cdd4d3;
}

.list-rate .info-box.info-box_third {
  background: #e5f1de;
}

.info-box i {
  margin: 15px auto 0;
}

.info-box span {
  display: block;
}

.rate_box {
  width: 37%;
  float: left;
  text-align: center;
  padding-top: 20px;
  position: relative;
  padding: 20px 8px;
}

.rate_box h2 {
  word-wrap: break-word;
  font-size: 16px;
  color: #666666;
  margin: 2px;
  line-height: 15px;
}

.rate_box.left-r:after {
  content: "";
  position: absolute;
  top: 16px;
  right: 0px;
  height: 40px;
  border-right: 1px solid #cccccc;
}

.rate_box h2 {
  color: #666666;
}

.list-rate {
  margin: 3px 0px;
}

.info_head h2 {
  font-size: 26px;
  color: #666;
  font-weight: 600;
  margin-bottom: 10px;
}

.info_head .city {
  font-size: 16px;
  color: #666;
}

.info_head p {
  font-size: 13px;
  color: #666;
}

.info_head .flag {
  margin-right: 10px;
  vertical-align: top;
}

.info_head .pipe {
  margin: 0px 6px 0px 2px;
}

.by-rate-mode {
  padding: 0px 20px;
}

.by-rate-mode .byratelable-filter {
  padding-top: 26px;
  padding-bottom: 26px;
}

.main-wrap-content-area .nav-tabs {
  border-bottom: 7px solid #c2d646;
  display: inline-flex;
}

.main-wrap-content-area .nav-tabs > li.active > a,
.main-wrap-content-area .nav-tabs > li.active > a:hover,
.main-wrap-content-area .nav-tabs > li.active > a:focus {
  border-radius: 0px;
}

.main-wrap-content-area .nav li {
  margin: 0px;
  min-width: 25.5%;
  text-align: center;
}

.main-wrap-content-area {
  margin-top: 25px;
}

.main-wrap-content-area .nav-tabs > li > a {
  font-size: 16px;
  text-transform: uppercase;
}

.hide-show-graph {
  float: right;
  text-decoration: underline;
  display: inline-block;
  margin-bottom: 13px;
  font-size: 14px;
}

.btn-groups .notes-btn {
  margin: 28px 21px 0;
  cursor: pointer;
  font-size: 14px;
}

.btn-groups .notes-btn i {
  margin-right: 4px;
}

.btn-groups .btn-danger {
  margin-top: 27px;
}

.punchout-list {
  background: #fff;
  margin-bottom: 18px;
  padding-top: 12px;
  padding-bottom: 12px;
  // width: 330%;
}

.punchout-list label {
  color: #777777;
  font-weight: normal;
}

.remove-row {
  margin: 25px 40px 0;
}

.create-new {
  display: inline-block;
  font-size: 16px;
  margin: 13px 0;
  text-transform: uppercase;
}

.create-new i {
  margin-right: 5px;
}

.custom-panel td:nth-child(2),
.custom-panel th:nth-child(2) {
  padding-left: 15px;
}

.custom-panel h4 {
  color: #666;
  margin-bottom: -6px;
  font-size: 18px;
}

.mb-12 {
  margin-bottom: 12px;
}

.custom-panel .panel-info {
  border-color: #bce8f1;
  border-top: 1px solid #e6e6e6;
  margin-top: 25px;
  padding-top: 25px;
}

.custom-panel .panel-info p {
  font-size: 13px;
  color: #666;
}

.custom-panel {
  margin-top: 20px;
}

.custom-panel .panel {
  min-height: 475px;
}

.react-tabs__tab-list {
  border-bottom: 7px solid #c2d646;
  margin: 0 0 10px;
  padding: 0;
  display: inline-flex;
  width: 100%;
}

.react-tabs__tab {
  display: inline-block;
  border: 1px solid transparent;
  border-bottom: none;
  position: relative;
  list-style: none;
  padding: 6px 12px;
  color: #999;
  cursor: pointer;
  border-radius: 0;
  font-size: 16px;
  text-align: center;
  text-transform: uppercase;
  outline: none !important;
  width: 25.5%;
  line-height: 1.52857;
}

.react-tabs__tab--selected {
  background: #c2d646;
  border-color: #c2d646;
  color: white;
}

.react-tabs__tab-panel {
  display: none;
}

.react-tabs__tab-panel--selected {
  display: block;
}

.react-tabs__tab--disabled {
  color: GrayText;
  cursor: default;
}

.searchicon {
  float: left;
  margin-top: 18px;
  margin-right: 7px;
  margin-left: 17px;
}

.ratesearchtext {
  font-size: 14px !important;
}

.pad-lft-none {
  padding-left: 0px !important;
}

.pad-rgt-none {
  padding-right: 0px !important;
  padding-left: 0px;
  margin-left: -1px;
}

.table_label thead tr th {
  height: 40px !important;
  background: transparent !important;
  border: none !important;
}

.table_label tbody tr td {
  padding: 5px 0px;
  border: none;
}

.tool_text {
  position: absolute;
  right: 15px;
  top: 1px;
  cursor: pointer;

  &.pt-link {
    color: $rc-green4 !important;

    &:hover {
      //text-decoration: underline;
      color: $rc-green5 !important;
    }
  }
}

.tool_text span {
  font-weight: 100;
  margin-right: 3px;
}

.mr-none {
  margin-right: 0px;
}

.react-tabs__tab-panel:before,
.react-tabs__tab-panel:after {
  content: " ";
  display: table;
}

.w100 {
  width: 100%;
  position: relative;
}

.refresh_btn,
.refresh_btn:hover {
  background: none;
  border: 0px solid #ffffff !important;
  color: #b8b8b8 !important;
  margin-right: 0px;
  padding-right: 4px !important;
}

.height_auto {
  min-height: 34px;
  height: auto !important;
  padding: 5.5px 4px;
  border-right: 2px solid #d1d1d1 !important;
}

.body-visible {
  overflow: visible !important;
}

.byratemodeatl img {
  width: 1000px;
}

.searchbtnatl {
  background: #a2b624;
  height: 100%;
  width: 40px;
  position: absolute;
  top: 0;
  border: none;
  right: 0;
  color: #fff;
  border-radius: 0 4px 4px 0px;
}

.mr-top {
  margin-top: -64px;
}

.btn-green {
  border: 2px solid #a2b624 !important;
  background-color: #a2b624;
  color: #f7f8fb !important;
}

h4.m-title {
  margin-bottom: 10px;
}

.ratecards .ratecards-edit-actions,
.ratecards .search-edit-actions {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  height: 61px;
  background-color: #666;
  padding: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  bottom: 40px;
  right: 40px;
  left: 40px;
  height: auto;
  background-color: rgba(51, 51, 51, 0.94);
  padding: 20px;
  z-index: 20;
}

// .drawer-notification {
//   position: absolute;
//   z-index: 3;
//   cursor: pointer;
//   height: 100%;
//   width: 70px;
//   background-color: rgb(51, 51, 51);
//   transition-duration: 0.15s;
//   transition-timing-function: ease-in-out;
//   transition-delay: initial;
//   transition-property: background-color;
//   -webkit-user-select: none;
//   -moz-user-select: none;
//   -ms-user-select: none;
//   user-select: none;
//   padding-top: 16px;
//   font-size: 16px;
//   text-align: center;
//   right: 70px;
//   &:hover {
//     background-color: #3b3b3b;
//   }
// }

.fa.fa-user.drawer-content {
  font-size: 16px;
  //margin-top: -1px;
}

.flex {
  &-grid {
    @include flexbox();
    @include flex-direction(normal);
    @include flex-flow(column);
  }
  &-auto {
    @include flex(0 1 auto);
  }
  &-fill {
    @include flex(1 1 auto);
  }
  &-row {
    @include flex-flow(row nowrap);
    @include flexbox();
    @include flex-grow(1);
  }
  &-col {
    .table > thead > tr > th {
      vertical-align: bottom;
      border-bottom: 2px solid #ddd;
    }
    .table-bordered > thead > tr > td,
    .table-bordered > thead > tr > th,
    .table thead > tr > th {
      border-bottom-width: 1px;
    }
    .table-border-right,
    table.table > thead > tr > th.table-border-right {
      border-right: 1px solid #e6e6e6;
    }
  }

  &-align-center {
    align-items: center;
  }

  &-align-baseline {
    align-items: baseline;
  }

  &-align-start {
    align-items: flex-start;
  }

  &-align-end {
    align-items: flex-end;
  }

  &-justify-center {
    justify-content: center;
  }

  &-justify-start {
    justify-content: flex-start;
  }

  &-justify-end {
    justify-content: flex-end;
  }
}

table.table-striped.pay-rate-table > thead > tr:nth-child(2n) > th {
  background-color: #dde7f5;
}

.table-header-background {
  background-color: #dde7f5 !important;
}

table.table-striped.markup-table > thead > tr:nth-child(2n) > th {
  background-color: #cdd4d3;
}

table.table-striped.bill-rate-table > thead > tr:nth-child(2n) > th {
  background-color: #e5f1de;
}

.table-header-no-background {
  background: none !important;
}

.table-no-border-top {
  border-top: none !important;
  border-top-width: initial;
  border-top-style: none;
  border-top-color: initial;
}

//.peek-table-container {
//  background-color: #fff;
//  border-radius: 0;
//  margin: 0px auto;
//  padding: 0;
//  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
//}

.ratecards-table .overlap-b-padding {
  margin-bottom: -20px;
}

.horizontal-scroll {
  overflow-y: auto;
}

.no-border {
  border: none !important;
}

span.ticker.no-change {
  -webkit-transform: scaleX(0.6) scaleY(1);
  -ms-transform: scaleX(0.6) scaleY(1);
  transform: scaleX(0.6) scaleY(1);
}

span.ticker {
  -webkit-transform: scaleX(0.8) scaleY(1.25);
  -ms-transform: scaleX(0.8) scaleY(1.25);
  transform: scaleX(0.8) scaleY(1.25);
}

.negative-change {
  color: #f24e4e;
}

.positive-change {
  color: #a2b624;
}

.btn-right {
  float: right;
}

.input-height {
  max-height: 34px;
}

.rc-input-box {
  @include pt-input;

  &:disabled {
    background-color: $rc-element-border-color;
    opacity: 0.6;
  }
}

.alert-set {
  color: #a2b624;
  background: transparent;
  border: none;
}

.set-default-alert {
  background: transparent;
  color: inherit;
  border: none;
}

.mt-32 {
  margin-top: 32px;
  .btn-green {
    height: 34px;
  }
}

.toast-cover {
  cursor: pointer;
  border: none;
  font-size: 14px;
  padding: 4px 8px;
  margin-left: 0px;
  /* font-weight: 600; */
  box-sizing: border-box;
  /* box-shadow: 0 1px 2px 0 rgba(0,0,0,0.2); */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 5px 5px 0 0;
  background: #f6eaad;
  color: #a88e1a;
  border-radius: 4px;

  &.fa-tag {
    margin-right: 4px;
  }

  .tag-remove {
    display: inline-block;
    margin: -5px -8px -5px 10px;
    padding: 5px 8px 4px 4px;
    //border-left: 1px dashed;
    height: 27px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    color: scale-color($rc-danger-color, $lightness: 20%);
    background-color: scale-color(#f6eaad, $lightness: -8%);
    //.fa-times {
    //  color: $rc-danger-color;
    //}

    &:hover {
      background-color: $rc-danger-color;
      color: scale-color(white, $lightness: -10%);
      //.fa-times {
      //  color: scale-color(white, -20%);
      //}
    }
  }
}

.flex-column {
  @include flex-direction(column);
  @include align-items(flex-start !important);
  .ellipsis {
    max-width: 100%;
  }
  .tagbox {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .action-bar {
    width: 100%;
  }
}

.userjob {
  padding-top: 15px;
  p {
    font-size: 14px;
  }
}

.atlfixformborder .form-group .form-group {
  margin-bottom: 0px !important;
}

.texts {
  max-width: 70%;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.headerfixing {
  padding: 15px 20px;
}

.modal-container {
  padding: 0 20px;
}

.border-bottom-1 {
  border-bottom: 1px solid #eceff1;
}

.bb-right {
  .btn-default[disabled] {
    border-color: #999999 !important;
    background-color: #999999 !important;
    color: #f7f8fb !important;
  }
  .btn-default {
    font-size: 12px;
    min-height: 32px;
  }
  .btn-default:hover,
  .btn-default:focus {
    background-color: #999999 !important;
  }
}

.rs-tag {
  display: inline;
  font-size: 11px;
  background-color: rgb(241, 240, 236);
  color: rgb(140, 139, 137);
  opacity: 0.6;
  border-radius: 4px;
  padding: 2px 6px;
  margin: 0 0 0 5px;
  transition: opacity 250ms ease 0s;
}

.rs-tag.rs-tag-no-opacity {
  opacity: 1;
}

.rs-tag:hover {
  opacity: 1;
}

.rs-tag-green {
  background-color: rgb(224, 231, 217);
  color: rgb(101, 125, 79);
}

.rs-tag-yellow {
  background-color: scale-color($rc-warning-color, $lightness: 70%);
  color: scale-color($rc-warning-color, $lightness: -50%);
}

.rs-tag-blue {
  background-color: scale-color($rc-primary-color, $lightness: 70%);
  color: scale-color($rc-primary-color, $lightness: -50%);
}

.rs-tag-red {
  background-color: rgb(253, 230, 230);
  color: rgb(232, 17, 17);
}

.rs-alert-info {
  color: #31708f;
  background-color: #fafdff;
  border: 1px solid #c8e3e8;
  border-radius: 4px;
  margin-bottom: 0;
  padding: 10px 15px;
  margin-top: 4px;
  font-weight: normal;
}

.rs-z-index-zero {
  z-index: 0 !important;
}

.sidebar-menu > li.rate-search-tab {
  padding: 0 0 0 10px;
  background-color: #dddddd;
  position: relative;

  .disabled-mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
    background-color: transparent;
  }

  .rate-search-tab-content {
    display: flex;
    align-items: center;
    padding: 12px 12px 12px 0;

    .icon-container {
      padding: 12px;
      min-width: 24px;
      min-height: 24px;
      line-height: 1em;
      flex: 0 0 auto;

      .tab-icon {
        width: 16px;
        height: 16px;
      }

      button.close-tab-button {
        margin: 0;
        padding: 0;
        line-height: 1em;
        border: none;
        background: none;
        width: 16px;
        height: 16px;
        &:focus {
          outline-style: dashed;
        }
      }
    }

    .label-container {
      line-height: 1em;
      .title {
        cursor: pointer;
        margin-bottom: 4px;
        &:hover {
          text-decoration: underline;
        }
      }
      .subtitle {
        color: #888888;
        cursor: default;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 192px;
      }
    }
  }

  &.disabled {
    opacity: 0.8;
    cursor: not-allowed;
    .rate-search-tab-content {
      color: #666666;
      .label-container {
        .title {
          cursor: not-allowed;
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }
}
