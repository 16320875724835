/* Breadcrumb */
#breadcrumb {
  background-color: #fdfdfd;
  border-bottom: 1px solid #e6e6e6;
  /* padding-left: 10px; */
  height: 45px;
  a {
    padding: 9px 20px 9px 10px;
    display: inline-block;
    //    background-image: url('../img/breadcrumb.png');
    background-position: center right;
    background-repeat: no-repeat;
    font-size: 11px;
    color: #999999;
    i {
      margin-right: 5px;
      opacity: 0.6;
      vertical-align: middle;
    }
    &:hover {
      color: #333333;
      i {
        opacity: 0.8;
      }
    }
    &:last-child {
      background-image: none;
    }
    &.current {
      font-weight: bold;
      color: #777777;
    }
  }
  body[data-header-fixed="true"] & {
    position: fixed;
    top: 0;
    left: 200px;
    right: 0;
    z-index: 30;
  }
}
