@import "../../../base/colors";

$card-padding: 20px;

.pt-cards-list {
  position: relative;
}

.pt-rates-card {
  display: block;
  position: relative;
  cursor: pointer;
  background-color: white;
  box-shadow: 0 1px 2px #aaa;
  border-radius: 2px;
  margin-bottom: 1.5rem;
  padding: 1.5rem;

  &:last-of-type {
    margin-bottom: 0;
  }

  &--selected {
    box-shadow: 0 0 3px 2px $olive;
  }

  &__title {
    font-size: 2rem;
    margin-top: 0;
    //margin-left: 10px;
  }
}
