$pt-store-base-color: #3d6deb;

.ps-pack-pdf-results-page {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;

  &__title {
    flex: 0 0 7%;
    display: flex;
    align-items: center;
    font-size: 36px;
    padding: 0 10px;
  }

  &__job-parameters {
    flex: 0 0 5%;

    & > * {
      font-size: 14px;
      padding: 0 10px;
    }
  }

  &__job-title {
    flex: 0 0 5%;
    display: flex;
    align-items: center;
    font-size: 32px;
    padding: 0 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__job-description {
    flex: 0 0 40%;
    overflow: hidden;
    position: relative;

    & > *:first-child {
      font-size: 14px;
      display: inline-block;
      margin-bottom: 10px;
    }

    pre {
      font-family: inherit;
      border: 0;
      border-left: 2px solid $gray-border;
      background-color: $white;
      width: 100%;
      max-height: 335px;
      padding: 0;
      padding-left: 15px;
      font-size: 10px;
      white-space: pre-wrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 0;
    }
  }

  &__results {
    flex: 0 0 42%;
    overflow: hidden;

    .level-card-container {
      width: 33.33% !important;
      min-width: 33.33% !important;

      .panel {
        font-size: 8px;
        margin-top: 10px;
        margin-bottom: 10px;

        .panel-heading {
          padding: 6px 8px;
          font-size: 10px;

          & > * {
            line-height: 16px;
          }
          .panel-title {
            font-size: 10px;
          }
          .sample-set-info {
            font-size: 8px;
          }
        }

        .panel-body {
          padding: 6px 8px;

          & > br {
            display: none;
          }

          .media {
            .media-left {
              .level-icon {
                width: 25px;
                height: 25px;

                header {
                  font-size: 10px;
                }
              }
            }
            .media-body {
              .level-card-job-label,
              .level-card-location {
                display: block;
                font-size: 10px;
                line-height: 12px;
                margin: 0;
                white-space: nowrap;
              }
            }
          }

          table.level-data {
            margin-bottom: 0;

            th,
            td {
              font-size: 8px;
              padding: 4px;
            }
            td.max-value {
              width: 90px;
              vertical-align: middle;

              .max-value-wrapper {
                vertical-align: middle;
                min-height: 55px;
              }

              h4,
              h5,
              .caption {
                white-space: nowrap;
                margin: 0;
                line-height: 14px;
              }
              h4 {
                font-size: 10px;
                margin-top: 15px;
              }
              h5 {
                font-size: 8px;
              }
              .caption {
                font-size: 8px;
              }
            }
          }
        }
      }
    }
  }
}
