@import "../../../../base/colors";

.ps-multi-select {
  &__not-selected-text {
    color: $gray-font;
    font-size: 13px;
  }

  &__select {
    margin: 15px;
  }

  &__selected-list {
    min-height: 33px;
    margin: 10px 0;
    line-height: 33px;
  }

  &__selected-item {
    display: inline-block;
    margin-left: 10px;
    margin-bottom: 7px;
    line-height: initial;
  }

  .pt-search {
    font-size: inherit;

    .pt-search-box {
      line-height: normal;
      font-size: inherit;
      padding: 5px 10px;
      border-width: 1px;
      border-bottom: 0;
      border-radius: 3px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;

      &:focus {
        border: 1px solid $rc-input-border-color;
        border-bottom: 0;
      }
    }
    .pt-search-icon {
      font-size: 14px;
      border: 1px solid $rc-input-border-color;
      border-left: 0;
      border-bottom: 0;
      border-top-right-radius: 3px;
      background: $rc-gray2;

      -webkit-transition: color 0.2s ease;
      -moz-transition: color 0.2s ease;
      -o-transition: color 0.2s ease;
      transition: color 0.2s ease;

      &:hover {
        color: $rc-green7;
      }
    }
  }
  &--disabled .pt-search-icon {
    background-color: lighten($rc-gray2, 7%);
    border-color: lighten($rc-input-border-color, 7%);
    cursor: not-allowed;

    &,
    &:hover {
      color: lighten($rc-gray3, 20%);
    }
  }

  &__results-list {
    display: block;
    width: 100%;
    height: 200px;
    overflow: hidden;
    overflow-y: auto;
    border: 1px solid $gray-border;
    border-radius: 3px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    color: $rc-gray3;
  }
  &--disabled &__results-list {
    color: lighten($rc-gray3, 20%);
  }

  &__not-found-text {
    padding: 3px 10px;
    color: $gray-font;
    font-size: 13px;
  }

  &__loading-text {
    padding: 10px;
    padding-bottom: 15px;
    text-align: center;
    color: $gray-font;
  }

  &__results-item {
    padding: 3px 10px;
    cursor: pointer;

    &:hover {
      background-color: $rc-gray7;
    }
  }
  &--disabled &__results-item {
    cursor: not-allowed;
  }
}
