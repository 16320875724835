@import "../base/colors";

@mixin card-shadow {
  -moz-box-shadow: 0 2px 5px 1px $gray-darker;
  -webkit-box-shadow: 0 2px 5px 1px $gray-darker;
  box-shadow: 0 2px 5px 1px $gray-darker;
}

.pt-card {
  background: $white;
  border-radius: 2px;
  box-shadow: 0px 0px 5px 1px #dadada;
  width: 100%;
  float: left;
  margin-top: 20px;

  &:first-of-type {
    margin-top: 20px;
  }

  margin-bottom: 20px;

  // @include card-shadow;

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: $ff-source-regular;
    margin-top: 0;
    margin-bottom: 0;
  }

  .input-group {
    padding: 0;
  }
  .form-group {
    margin-bottom: 0;
  }

  &__heading {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;

    &__title {
      padding-top: 10px;
      font-size: 24px;
      color: #666;
      margin-bottom: 0;
    }
    &__controls {
      padding-top: 5px;
      display: inline;
      float: right;
    }
  }

  &__list-group {
    list-style-type: none;
    margin: 0;
    padding: 0;
    padding-top: 10px;
    &__item {
      padding: 10px 20px;
      //transition: background 200ms;
      color: #545555;
      border-top: 1px solid #edeeee;
      text-decoration: none;
      &--no-padding {
        padding: 0;
      }
      &--no-border {
        border: 0;
      }
    }
  }
}

.pt-bottom-controls {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #ebebeb;
  border: 2px;
  z-index: 900;
  @include card-shadow;

  &__container {
    display: table;
    height: 100%;
    width: 100%;
  }

  &__control-group {
    padding-left: 10px;
    padding-right: 10px;
    display: table-cell;
    vertical-align: middle;
  }
}
