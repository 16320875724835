@import "../../base/colors";

@mixin pi-base-content-block-mixin {
  border: 1px solid $gray-border;
  border-radius: 5px;
  background: $white;
  margin-top: 30px;

  &:first-child {
    margin-top: 0;
  }

  h2,
  h3,
  h4 {
    margin: 0;
  }
}

@mixin pi-content-block-mixin {
  @include pi-base-content-block-mixin;

  & > * {
    padding: 25px;

    &:nth-child(1) {
      border-bottom: 1px solid $gray-border;
    }

    &:first-child {
      padding: 15px;
    }
  }
}

@mixin pi-content-block-with-buttons-mixin {
  @include pi-base-content-block-mixin;

  & > * {
    padding: 25px;

    &:nth-child(1) {
      text-align: left;
      padding: 0 15px;
      margin: 15px 0;
    }
    &:nth-child(2) {
      text-align: right;
      padding: 0 15px;
      margin: 15px 0;

      .pt-button {
        margin-left: 10px;
        margin-bottom: 5px;

        &:first-child {
          margin-left: 0;
        }
      }
    }
    &:nth-child(3) {
      border-top: 1px solid $gray-border;
    }
  }
}

.pt-private-index {
  .yellow-button {
    background-color: $yellow;
  }
  .red-button {
    background-color: $red-bright;
  }
  .green-button {
    background-color: $green-bright;
  }
  .gray-button {
    background-color: $gray;
  }

  .link-button {
    color: $olive;
    cursor: pointer;
    background: none;
    border: none;

    &:disabled {
      color: $slate;
      cursor: initial;
    }
    &:hover:not(:disabled),
    &:active:not(:disabled) {
      color: darken($olive, 5%) !important;
      transform: scale(1.01);
    }
    &:active:not(:disabled) {
      transform: scale(1);
    }
  }

  .red-block {
    background-color: $red-bright !important;
    color: $white;
  }
  .green-block {
    background-color: $green-bright !important;
    color: $white;
  }
  .gray-block {
    background-color: $gray !important;
    color: $white;
  }
  .olive-block {
    background-color: $olive !important;
    color: $white;
  }

  .gray-text {
    color: $gray-font;
  }
  .green-text {
    color: $green-bright;
  }
  .red-text {
    color: $red-bright;
  }
  .olive-text {
    color: $olive;
  }

  .thin-text {
    font-weight: 300;
  }
  .bold-text {
    font-weight: bold;
  }
  .no-wrap-text {
    white-space: nowrap;
  }
  .centered-text {
    text-align: center;
  }
}
