@import "../../variables";

.ps-jobs-list {
  &__warning {
    padding: 20px;
    border-bottom: 1px solid $rc-element-border-color;
    background-color: $rc-yellow3;
    color: $rc-yellow4;
    text-align: center;

    p {
      margin: 0;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid $rc-element-border-color;

    &:last-child {
      border-bottom: 0;
    }
    & > * {
      flex: 1 1 0;
      padding: 10px;
    }
  }
  &__item--invalid {
    background-color: #ffe2e2;
  }
  &__item--invalid &__title-container .fa-exclamation,
  &__item--invalid &__description .fa-exclamation {
    color: red;
    font-size: 20px;
  }
  &__checkbox {
    flex: 0 0;
  }
  &__title-container {
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
  &__description {
    position: relative;
    flex-grow: 2;
    display: block;
    min-width: 0;
    max-height: 78px;
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre;
  }
  &__see-description {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 16px;
    height: 16px;
    font-size: 16px;
    text-align: center;
    cursor: pointer;
  }
  &__levels {
    flex: 0 1 90px;
    text-align: center;
  }
}
