.ps-pack-details {
  width: 100%;
  display: flex;
  flex-direction: column;

  &__title {
    margin-bottom: 10px;
  }

  &__description {
    display: block;
    width: 100%;
    min-height: 100px;
  }

  &__meta {
    .fa {
      margin-right: 10px;
    }
  }

  &__jobs-list {
    padding: 0;
  }

  &__description-view {
    width: 100%;
    max-height: 450px;
    white-space: pre-wrap;
    overflow-y: scroll;
  }

  &__rename-modal-input,
  &__rename-modal-area {
    display: block;
    width: 100%;
    font-size: 16px;

    &.invalid {
      border: 1px solid red;
    }
  }
  &__rename-modal-area {
    min-height: 300px;
  }
  &__validation-box {
    font-size: 14px;
    margin-top: 5px;

    .valid {
      color: green;
    }

    .invalid {
      color: red;
    }
  }

  &__file-icon {
    text-decoration: none !important;
    .fa {
      font-size: 40px;
    }
  }

  &__image-select .pt-image-select__image {
    width: 100%;
    text-align: center;

    & img {
      max-height: 180px;
      max-width: 180px;
    }
  }
}
