.dataTable .ui-state-default {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-right: 0;
  border-left: 0;
}
.dataTables_length {
  color: #878787;
  margin: 7px 5px 0;
  position: absolute;
  right: 2px;
  top: -2px;
}
.dataTables_length div {
  vertical-align: middle;
}
div.dataTables_wrapper .ui-widget-header {
  border-right: medium none;
  border-top: 1px solid #d5d5d5;
  font-weight: normal;
  margin-top: -1px;
  height: 36px !important;
}
.dataTables_wrapper .ui-toolbar {
  padding: 5px;
  margin: 0;
  border-bottom: 0 !important;
}
.dataTables_filter {
  color: #878787;
  font-size: 11px;
  margin: -1px 8px 2px 10px;
  text-align: left;
  float: left;
}
@media (max-width: 979px) {
  .dataTables_filter {
    position: relative;
  }
}
.dataTables_filter input {
  margin-bottom: 0;
}
.DataTables_sort_icon {
  margin-top: 1px;
}
.table {
  thead,
  tbody,
  tfoot {
    tr > th,
    tr > td {
      padding: 10px;
    }
  }
}
.table th {
  height: auto;
  border-bottom: 0;
  text-align: center;
  color: #666666;
  vertical-align: middle !important;
}
.table tr.checked td {
  background-color: #ffffe3 !important;
}
.table.with-check tr th:first-child,
.table.with-check tr td:first-child {
  width: 10px;
}
.table.with-check tr th:first-child i {
  margin-top: -2px;
  opacity: 0.6;
}
.table.with-check tr td:first-child .checker {
  margin-right: 0;
}
.table.table-striped tbody > tr:nth-child(2n + 1) > th,
.table.table-striped tbody > tr:nth-child(2n + 1) > td {
  background-color: #fbfbfb;
}
.table.table-hover tbody > tr:hover > td {
  background-color: #f9f9f9;
}
.table.table-condensed > thead > tr > th,
.table.table-condensed > tbody > tr > th,
.table.table-condensed > tfoot > tr > th,
.table.table-condensed > thead > tr > td,
.table.table-condensed > tbody > tr > td,
.table.table-condensed > tfoot > tr > td {
  padding: 5px;
}
.table.table-bordered > thead > tr > th,
.table.table-bordered > thead > tr > td {
  background-color: #f9f9f9;
  color: #888888;
}
span.icon .checker {
  margin-top: -5px;
  margin-right: 0;
}
.dataTables_wrapper {
  overflow-x: auto !important;
  overflow-y: hidden !important;
}
.dataTables_wrapper .table {
  margin-bottom: 0;
  width: 100% !important;
}

.dataTables_wrapper .table.table-bordered {
  border-width: 1px 0;
}
.panel-body .dataTables_wrapper .table {
  border-left: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
}
.dataTables_wrapper .table.table-bordered,
.dataTables_wrapper .table-bordered > thead > tr > th,
.dataTables_wrapper .table-bordered > tbody > tr > th,
.dataTables_wrapper .table-bordered > tfoot > tr > th,
.dataTables_wrapper .table-bordered > thead > tr > td,
.dataTables_wrapper .table-bordered > tbody > tr > td,
.dataTables_wrapper .table-bordered > tfoot > tr > td {
  border-color: #e6e6e6;
}

.dataTables_wrapper .table-bordered > thead > tr > th:first-child,
.dataTables_wrapper .table-bordered > tbody > tr > th:first-child,
.dataTables_wrapper .table-bordered > tfoot > tr > th:first-child,
.dataTables_wrapper .table-bordered > thead > tr > td:first-child,
.dataTables_wrapper .table-bordered > tbody > tr > td:first-child,
.dataTables_wrapper .table-bordered > tfoot > tr > td:first-child {
  border-left-width: 0;
}
.dataTables_wrapper .table-bordered > thead > tr > th:last-child,
.dataTables_wrapper .table-bordered > tbody > tr > th:last-child,
.dataTables_wrapper .table-bordered > tfoot > tr > th:last-child,
.dataTables_wrapper .table-bordered > thead > tr > td:last-child,
.dataTables_wrapper .table-bordered > tbody > tr > td:last-child,
.dataTables_wrapper .table-bordered > tfoot > tr > td:last-child {
  border-right-width: 0;
}

.dataTables_wrapper .select2-container .select2-choice .select2-chosen {
  min-width: 35px;
}

.fg-toolbar {
  border-radius: 0 0 4px 4px !important;
  background-color: #ffffff !important;
  border-top-color: #e6e6e6 !important;
}

.dataTables_length {
  z-index: 20;
  margin-top: 9.5px;
  margin-right: 10px;
}

.dataTables_length > label > .select2-container {
  margin: 0 5px;
}

.dataTables_filter input[type="text"] {
  border: 1px solid #eeeeee;
  box-shadow: none;
  color: #888888;
  padding: 3px 10px;
  height: 27px;
  line-height: 1.5;
  font-size: 12px;
  border-radius: 3px;
}

.table-wrapper {
  overflow-x: auto;
  overflow-y: hidden;
}

.dataTables_filter input[type="text"]:focus {
  border-color: #66afe9;
}
.dataTables_paginate .ui-button {
  background-image: none !important;
  color: #999999 !important;
  text-shadow: none !important;
}
.dataTables_paginate .ui-state-disabled {
  border-color: #e6e6e6 !important;
  color: #dddddd !important;
  background-color: #ffffff !important;
  background-image: none !important;
}
.dataTables_paginate span .ui-state-disabled {
  background-color: #444444 !important;
  border-top-color: #444444 !important;
  border-bottom-color: #444444 !important;
  background-image: none !important;
  box-shadow: none !important;
  color: #ffffff !important;
}
.DataTables_sort_icon.ui-icon-triangle-1-n,
.DataTables_sort_icon.ui-icon-triangle-1-s {
  float: none;
  margin-left: 10px;
}
.DataTables_sort_icon.ui-icon-triangle-1-n:before,
.DataTables_sort_icon.ui-icon-triangle-1-s:before {
  font-family: "FontAwesome";
}
.DataTables_sort_icon.ui-icon-triangle-1-n:before {
  content: "\f107";
}
.DataTables_sort_icon.ui-icon-triangle-1-s:before {
  content: "\f106";
}
@media (max-width: 979px) {
  .dataTables_filter,
  .dataTables_paginate {
    text-align: center;
  }
}
@media (max-width: 979px) and (max-width: 767px) {
  .dataTables_filter,
  .dataTables_paginate {
    float: none !important;
  }
}
.sort-indicator {
  margin-left: 10px;
  font-size: 12px;
}
.sort-indicator.sort-asc i:before {
  content: "\f077";
}
.sort-indicator.sort-desc i:before {
  content: "\f078";
}
tr.anim-success td {
  -webkit-animation: ass 40s;
  animation: ass 40s;
  background-color: rgba(0, 255, 0, 0.2);
}
tr.anim-error td {
  -webkit-animation: aerr 40s;
  animation: aerr 40s;
  background-color: rgba(255, 0, 0, 0.2);
}

@-webkit-keyframes ass {
  50% {
    bakground-color: rgba(0, 255, 0, 0.2);
  }
  100% {
    background-color: rgba(255, 255, 255, 1);
  }
}
@keyframes ass {
  50% {
    bakground-color: rgba(0, 255, 0, 0.2);
  }
  100% {
    background-color: rgba(255, 255, 255, 1);
  }
}
@-webkit-keyframes aerr {
  50% {
    bakground-color: rgba(255, 0, 0, 0.2);
  }
  100% {
    background-color: rgba(255, 255, 255, 1);
  }
}
@keyframes aerr {
  50% {
    bakground-color: rgba(255, 0, 0, 0.2);
  }
  100% {
    background-color: rgba(255, 255, 255, 1);
  }
}

@media (max-width: 768px) {
  div.dataTables_wrapper .ui-widget-header {
    height: 68px !important;
  }
}

td.compress,
th.compress {
  width: 1%;
  white-space: nowrap;
}

td.v-aligned,
th.v-aligned {
  vertical-align: middle !important;
}
