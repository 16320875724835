@import "../variables", "../mixins";

.pt-ui {
  .infinite-scroll-select-field {
    @include pt-input;
    outline: none;
    padding: 0;
    background-color: white;

    &:active {
      border: 2px solid $rc-green4;
      .infinite-scroll-select-wrapper-button.select-caret {
        color: $rc-green4;
      }
    }

    &:hover {
      cursor: pointer;
      .infinite-scroll-select-wrapper-button.select-caret {
        color: $rc-green4;
      }
    }

    .infinite-scroll-select-wrapper-button.select-clear {
      right: 40px;
      width: 20px;
      color: #999999;
    }

    .infinite-scroll-select-wrapper-button.select-clear:hover,
    .infinite-scroll-select-wrapper-button.select-clear:active {
      color: $rc-danger-color;
    }

    &.disabled,
    &.disabled:active {
      cursor: not-allowed;
      background-color: #eceff1;
      opacity: 0.6;
      border: 2px solid #ececec;
      .infinite-scroll-select-wrapper-button {
        color: lighten($rc-input-border-color, 8%);
      }
    }

    &.small {
      .infinite-scroll-select-wrapper {
        padding: 5px 34px 5px 10px;
      }

      .infinite-scroll-select-wrapper-button {
        width: 32px;
        height: 30px;
      }
    }

    .infinite-scroll-select {
      width: 100%;
      outline: none;

      &-wrapper {
        position: relative;
        border-radius: 4px;
        padding: 8px 44px 8px 12px;

        outline: none;
        width: 100%;

        .infinite-scroll-select-text {
          margin: 0;
          font-size: 16px;
          line-height: normal;
        }

        &-button {
          position: absolute;
          display: flex;
          flex: 0 1 auto;
          justify-content: center;
          align-items: center;
          width: 40px;
          height: 38px;
          right: 1px;
          top: 1px;
        }

        .placeholder {
          font-size: 16px;
          color: $rc-gray1;
        }
      }

      .dropdown-menu.pt-dropdown {
        border: 1px solid $rc-input-border-color;
        border-radius: 0;
        outline: none;
        min-width: 100%;

        .search-box-container {
          background-color: transparent;
          padding: 10px;
          margin-top: -5px;
          margin-bottom: 5px;
          border-bottom: none;
        }

        .pt-search {
          .pt-search-icon {
            font-size: 1em;
            margin-left: -40px;
            margin-top: auto;
            margin-bottom: auto;
            opacity: 0.7;
          }
          .pt-search-box {
            padding-right: 40px;
          }
        }

        .pt-dropdown-list {
          margin: -5px 0;
          max-height: 250px;
          overflow: auto;
        }
      }
    }
  }
}
