@use "sass:math";

$rc-black: #333333;
$rc-gray1: #cccccc;
$rc-gray2: #dddddd;
$rc-gray3: #666666;
$rc-gray4: #575656;
$rc-gray5: #888888;
$rc-gray6: #aaaaaa;
$rc-white1: #f7f8fb;
$rc-white2: #ececec;
$rc-green1: #e2e9b9;
$rc-green2: #c2db30;
$rc-green3: #c3db38;
$rc-green4: #a2b624;
$rc-green5: #778712;
$rc-green6: #90a424;
$rc-green7: #88a46f;
$rc-green8: #f5f8e8;
$rc-green9: #bed146;
$rc-yellow: #e9c82c;
$rc-yellow2: #debd2c;
$rc-red1: red;
$rc-element-border-color: #eceff1;
$rc-input-border-color: #ccc;
$rc-default-color: #999999;
/* #93989C */
$rc-primary-color: #e2e9b9;
$rc-success-color: #e2e9b9;
$rc-info-color: #e2e9b9;
$rc-warming-color: #e2e9b9;
$rc-danger-color: #f24e4e;
$table-border-color: #e6e6e6; // Keep these colors in sync with
// file: /src/constants/css.js
// const LEVEL_COLOR
$junior-color: #0e5d92;
$mid-color: #882d60;
$senior-color: #28784d;
$lead-color: #5b2971;
$guru-color: #2e0f36; // Keep these colors in sync with
// file: /src/constants/css.js
// const VALUE_CHANGE_COLOR
$positive-change-color: $rc-green4;
$negative-change-color: #db6a92;
$junior-positive-color: #e2f376;
$junior-negative-color: #fea9a9;
$mid-positive-color: #e2f376;
$mid-negative-color: #fea9a9;
$senior-positive-color: #e2f376;
$senior-negative-color: #fea9a9;
$lead-positive-color: #e2f376;
$lead-negative-color: #fea9a9;
$guru-positive-color: #e2f376;
$guru-negative-color: #fea9a9;
$edit-bar-height: 61px;
$pagination-box-height: 32px;
@mixin pt-input {
  border: 1px solid $rc-input-border-color;
  border-radius: 4px;
  //box-shadow: 1px 1px 3px $rc-gray1;
  padding: 10px;
  font-size: 16px;
  transition: all 0.2s;

  &:focus {
    outline: none;
    border: 1px solid $rc-green4;
  }
}

@mixin pt-action-bar($action-bar-height, $bar-background-color: $rc-gray3) {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  height: $action-bar-height;
  background-color: $bar-background-color;
  padding: 15px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@keyframes slideInBottom {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes slideOutBottom {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(100%);
  }
}

@keyframes fadeIn {
  0% {
    visibility: hidden;
    opacity: 0;
  }

  100% {
    visibility: visible;
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    visibility: visible;
    opacity: 1;
  }

  100% {
    visibility: hidden;
    opacity: 0;
  }
}

.u-m-left {
  margin-left: 5px;
}

.ratecards {
  display: flex;
  flex-wrap: nowrap;
  color: $rc-gray3;
  background-color: #f4f4f7;
  font-size: 21px;
  font-family: "Source Sans Pro", sans-serif;
  padding: 0 15px 0 15px;
  /* Reset */
  footer,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  header,
  section,
  small,
  span {
    margin: 0;
    padding: 0;
    line-height: normal;
  }

  h1,
  h2,
  h3 {
    //font-family: "Source Sans Pro", sans-serif;
    font-weight: normal;
    letter-spacing: -0.0125em;
  }

  p {
    margin: 0 0 15px;
  }

  label {
    font-size: 14px;
    font-weight: normal;
    line-height: normal;
  }

  .fade-out {
    visibility: hidden;
    opacity: 0;
    animation: 200ms ease-out 0s 1 fadeOut;
  }

  .fade-in {
    visibility: visible;
    opacity: 1;
    animation: 200ms ease-out 0s 1 fadeIn;
  }

  .no-punchouts {
    margin-left: 18px;
  }

  .bottom-appear {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
    animation: 200ms ease-out 0s 1 fadeIn, 200ms ease-out 0s 1 slideInBottom;
  }

  .bottom-disappear {
    visibility: hidden;
    opacity: 0;
    transform: translateY(100%);
    animation: 200ms ease-out 0s 1 fadeOut, 200ms ease-out 0s 1 slideOutBottom;
  }

  .drop-shadow {
    box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1);
  }

  .rc-container {
    //animation: 200ms ease-out 0s 1 fadeIn;
    background: #fff;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 4px 8px -1px rgba(0, 0, 0, 0.16);
    margin: 15px 0 0;
    position: relative;
    // overflow: hidden;
    opacity: 1;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -ms-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;

    &.send-back {
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
      opacity: 0.4;
      //-webkit-transition: all .2s;
      //-moz-transition: all .2s;
      //-ms-transition: all .2s;
      //-o-transition: all .2s;
      //transition: all .2s;
    }

    &.bring-forward {
      z-index: 11;
    }

    .header-action-bar {
      margin: -20px -20px 0;
      border-bottom: 1px solid $rc-element-border-color;
      position: relative;
      z-index: 3;
      display: block;
      padding: 15px 20px;
    }
  }

  .container-section {
    //animation: 200ms ease-out 0s 1 fadeIn;
    margin: 0 -20px;
    border-bottom: 1px solid $rc-element-border-color;
    position: relative;
    display: block;
    padding: 20px;

    &.header {
      margin: -20px -20px 0;

      h4 {
        line-height: 34px;
      }
    }

    &.footer {
      margin: 0 -20px -20px;
      border-bottom: none;
    }

    &.actions {
      padding: 15px 20px;
    }

    &.info {
      padding: 5px 20px;
      color: darken($rc-input-border-color, 30%);
    }

    &::after,
    .section-group::after {
      content: "";
      display: table;
      clear: both;
    }

    .section-group {
      padding: 0 0 15px;
    }
  }

  .rc-container-backdrop {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.5);
    transition: all 0.2s;
  }

  .rc-viewport {
    min-height: 200px;
    max-height: 800px;
    overflow-y: auto;
    padding: 20px;
  }

  .rc-actions-bar {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin-right: 20px;

    .info {
      display: inline-block;
      padding: 6px 12px;
      line-height: normal;
      text-align: center;
      vertical-align: middle;
      font-weight: normal;
      float: left;
    }

    &:not(:first-child) {
      margin: 0;
    }
  }

  .border {
    &-b {
      border-bottom: 1px solid $rc-element-border-color;
    }

    &-t {
      border-top: 1px solid $rc-element-border-color;
    }

    &-tb {
      border-top: 1px solid $rc-element-border-color;
      border-bottom: 1px solid $rc-element-border-color;
    }
  }

  .overlap-lr-padding {
    margin: 0 -20px;
  }

  .overlap-t-padding {
    margin-top: -20px;
  }

  .overlap-b-padding {
    margin-bottom: -20px;
  }

  .overlap-tb-padding {
    margin: -20px 0;
  }

  .overlap-padding {
    margin: -20px;
  }

  .inline-block {
    display: inline-block;
  }

  .block {
    display: block;
  }

  .flex-wrap {
    display: flex;
    flex-wrap: wrap;
  }

  .selectable-list-container {
    border: 0;
    padding: 0;
    margin: 0 -20px;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 40vh;
    border-top: 1px solid $rc-element-border-color;
    border-bottom: 1px solid $rc-element-border-color;

    .rc-selectable-item {
      &:first-child {
        border-top: none;
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }

  .sort-indicator {
    background-color: transparent;
    color: #d8a306;
    border-radius: 3px;
    margin: -4px 0 -4px -8px;
    padding: 2px 4px;
    font-size: inherit;

    .sort-position {
      margin-right: 2px;
      margin-left: 2px;
    }
  }

  .list-container {
    border: 0;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .list-container-item {
    padding: 5px 20px;
    border-bottom: 1px solid $rc-element-border-color;

    &:last-child {
      border-bottom: none;
    }
  }

  .rc-shroud {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 20px;
    background-color: $rc-element-border-color;
    opacity: 0.6;
  }

  .rc-selectable-item {
    width: 100%;
    color: darken($rc-input-border-color, 38%);
    border-bottom: none;
    border-radius: 0;
    padding: 10px 16px;
    font-size: 18px;
    font-weight: normal;
    margin: 0;
    cursor: pointer;
    position: relative;
    transition: all 0.2s;

    input[type="checkbox"],
    input[type="radio"] {
      visibility: hidden;
      position: absolute;
      top: 0;
      left: 0;
    }

    .rc-check-icon,
    .rc-radio-icon {
      margin-right: 5px;
    }

    &.selected {
      color: $rc-green6;
      background-color: $rc-green8;

      .text-x-small {
        color: $rc-green4;
      }
    }

    &:hover {
      color: $rc-green6;
      background-color: $rc-green8;
    }

    &:first-child {
      border-top: 1px solid $rc-element-border-color;
    }

    .fa.icon {
      margin-left: -4px;

      & + span {
        margin-left: 10px;
      }
    }

    .text-x-small {
      color: darken($rc-input-border-color, 24.2%);

      .fa.icon {
        margin-left: 0;
        font-size: 13px;

        & + span {
          margin-left: 5px;
        }
      }
    }
  }

  .rc-checkbox-group {
    &:not(:only-child) {
      padding-bottom: 10px;
    }

    &.in-line {
      display: flex;
      flex-wrap: wrap;

      .rc-checkbox-component:not(:last-child),
      .rc-checkbox-component:not(:only-child) {
        margin-right: 10px;
      }

      .rc-checkbox-component {
        margin-bottom: 10px;
      }

      &.flex-200 {
        .rc-checkbox-component {
          flex: 1 0 200px;
        }
      }

      &.flex-150 {
        .rc-checkbox-component {
          flex: 1 0 150px;
        }
      }

      &.flex-100 {
        .rc-checkbox-component {
          flex: 1 0 100px;
        }
      }
    }
  }

  .rc-check-icon {
    // font: normal normal normal 14px/1 FontAwesome;
    // font-size: inherit;
    // text-rendering: auto;
    // -webkit-font-smoothing: antialiased;
    // content: "\F0C8";
    color: darken($rc-element-border-color, 10%);
  }

  .rc-check-icon.selected {
    color: $rc-yellow;
    // content: "\F14A";
  }

  .rc-radio-icon::before {
    // font: normal normal normal 14px/1 FontAwesome;
    // font-size: inherit;
    // text-rendering: auto;
    // -webkit-font-smoothing: antialiased;
    // content: "\F10c";
    color: darken($rc-element-border-color, 10%);
  }

  .rc-radio-icon.selected::before {
    color: $rc-yellow;
    // content: "\F111";
  }

  .rc-checkbox-component {
    color: darken($rc-input-border-color, 38%);
    border: none;
    font-size: 18px;
    font-weight: normal;
    margin: 0;
    cursor: pointer;
    position: relative;
    transition: all 0.2s;

    input[type="checkbox"],
    input[type="radio"] {
      visibility: hidden;
      position: absolute;
      top: 0;
      left: 0;
    }

    .rc-check-icon,
    .rc-radio-icon {
      margin-right: 5px;
    }

    &.selected {
      color: $rc-green6;

      .text-small,
      .text-x-small {
        color: $rc-green4;
      }
    }

    &:hover {
      color: $rc-green6;
    }

    .fa.icon {
      margin-left: -4px;

      & + span {
        margin-left: 10px;
      }
    }

    .text-x-small {
      //color: darken($rc-input-border-color, 22.2%);
      .fa.icon {
        margin-left: 0;
        font-size: 13px;

        & + span {
          margin-left: 5px;
        }
      }
    }
  }

  a {
    color: $rc-green4;
  }

  .view {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .notransition {
    transition: none !important;
  }

  .pt-link {
    color: $rc-green4 !important;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      color: $rc-green5 !important;
    }
  }

  .pt-error-message {
    padding: 15px;
  }

  .pt-user-messages {
    margin-top: 15px;
  }

  .pt-user-alert {
    margin-bottom: 0;
    font-size: 14px;
  }

  &-all-items {
    margin-right: 10px;
  }

  &-all-items--active {
    font-weight: bold;
  }

  &-list-select-all-section {
    //animation: 250ms ease-out 0s 1 appearFromTop;
    border-bottom: 1px solid $rc-element-border-color;
    border-top: 1px solid $rc-element-border-color;
    text-align: center;
    background-color: lighten($rc-yellow, 38%);
    padding: 5px 20px;
    font-size: 16px;
    overflow: hidden;
  }

  &-rc-label {
    border: 1px solid $rc-gray1;
    border-radius: 3px;
    box-shadow: 1px 1px 3px $rc-gray1;
    padding: 10px;
    margin-top: 2px;
    margin-bottom: 10px;
    font-size: 16px;

    &:focus {
      outline: none;
    }
  }

  .tag {
    cursor: default;
    background-color: lighten($rc-yellow, 28%);
    color: darken($rc-yellow2, 14%);
    border-radius: 100px;
    padding: 2px 10px;
    margin-bottom: auto;

    &:not(:first-child) {
      margin-left: 10px;
    }
  }

  .align-top {
    vertical-align: text-top;
  }

  .top {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    //width: calc(100vw - 30px);
    //margin-top: 15px;
    //margin-bottom: 15px;
    .ratecard-label-header {
      display: inline-flex;
      flex: 1;
      align-items: baseline;

      h1 {
        padding: 0 5px 6px 0;
      }

      .tag {
        margin-bottom: auto;
      }
    }

    .ratecard-meta {
      flex: 1;
    }

    .above {
      display: flex;
      justify-content: space-between;

      h1 {
        margin-bottom: 20px;
      }
    }

    .below {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }
  }

  .has-error {
    border-color: $rc-danger-color;
  }

  //.pt-search {
  //  display: flex;
  //}
  //
  //.pt-search-box {
  //  @include pt-input;
  //  flex: 1;
  //  width: 180px;
  //  padding: 0 10px;
  //
  //  &--open {
  //    //padding: 10px 10px;
  //    //width: 180px;
  //    // TODO: Set a max-width
  //  }
  //}
  //
  //.pt-search-icon {
  //  padding: 10px;
  //  cursor: pointer;
  //  font-size: 20px;
  //  color: $rc-gray4;
  //}

  &-table,
  .search-table {
    //border-radius: 5px;
    //border: 1px solid $rc-gray2;
    //width: calc(100vw - 35px);
    //width: 100%;
    margin: 0 -20px;
    overflow: hidden;

    &-empty {
      display: flex;
      min-height: 30vh;
      text-align: center;
      justify-content: center;
      padding-top: 15vh;
      color: $rc-gray1;
      font-size: 28px;
      user-select: none;
    }

    &-fill-container {
      width: 100%;
      height: 100%;
    }
  }

  .ratecard-created-at,
  .ratecard-owner {
    //color: $rc-gray3;
    //font-size: 18px;
    line-height: initial;
    margin-bottom: 4px;

    .fa.icon {
      margin-right: 2px;
    }
  }

  .ratecards-table-header,
  .search-table-header {
    display: flex;
    justify-content: space-between;
    //border-bottom: 1px solid rgb(230, 230, 230);
    //font-weight: 100;
    font-size: 0.85em;
    padding: 15px 20px;
    margin: 0 -20px;
    border-radius: 4px 5px 0 0;
    background-color: white;
    transition: all 0.2ms ease;

    &.active {
      background-color: #fbf6dc;
      transition: all 0.2ms ease;
    }

    .pt-link,
    a {
      color: $rc-green4 !important;

      &:hover {
        text-decoration: underline;
        color: $rc-green5 !important;
      }
    }
  }
  //Allows scrolling table contents
  .ratecards-table-list,
  .search-table-list {
    &-scrolling {
      height: calc(100vh - 240px);
      max-height: 780px; // Roughly how many pixels 10 items take
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
    }
  }

  .ratecards-label-input {
    label {
      color: $rc-gray2;
      font-size: 0.7em;
      font-weight: 200;
      display: block;
      margin: 0;
      padding: 0;
    }

    input {
    }
  }

  .header-left-nav {
    flex-shrink: 2;
    flex-grow: 1;

    .pt-link,
    a {
      font-size: 16px;
      margin-right: 10px;
    }
  }

  .header-right-nav {
    text-align: right;
    flex-shrink: 2;
    flex-grow: 1;

    a {
      margin-left: 15px;
    }
  }

  .btn-filter {
    color: $rc-green4;
    display: inline-block;
    cursor: pointer;
    margin-left: 20px;
    font-size: 16px;
    line-height: 19px;
    padding: 6px 0;
    position: relative;

    .children {
      &:hover {
        color: $rc-green5;
        text-decoration: underline;
      }
    }
  }

  .ratecard-list-item-left {
    flex: 2;
    display: flex;
    align-items: center;
  }

  .list-item-select {
    margin-right: 20px;
    cursor: pointer;
  }

  .ratecard-list-item-right {
    flex: 1;
    display: flex;
    justify-content: space-around;
    max-width: 310px;
  }

  .ratecard-label {
    font-size: 24px;
    color: $rc-green4;
    transition: all 200ms;
    margin-bottom: 2px;
    padding: 2px 0;
    /* for tag to show correctly */
    line-height: 1.2;
  }

  .ratecard-meta {
    color: $rc-gray5;
    font-size: 16px;
    line-height: 18px;
    padding-bottom: 2px;
    margin-top: -2px;
  }

  .item-subtext {
    &-accent {
      color: $rc-green5;
    }

    &-muted {
      color: $rc-gray6;
    }
  }

  .ratecard-info,
  .search-info {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    text-align: right;
    border-radius: 5px;
    //background-color: $rc-gray2;
    margin: 0 5px;
    padding: 0 15px;
    min-height: 40px;
    font-size: 20px;
    min-width: 100px;
    white-space: nowrap;

    &.text-center {
      text-align: center;
    }

    header {
      color: $rc-gray5;
      background-color: inherit;
      font-size: 14px;
      //line-height: 14px;
      //font-weight: 200;
    }

    span {
      //line-height: 20px;
    }
  }

  .change {
    &-positive {
      color: $rc-green3;
    }

    &-negative {
      color: $rc-red1;
    }
  }

  .ellipsis {
    min-width: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .divider {
    border-right: 1px solid $rc-gray1;
  }

  .search-meta {
    color: $rc-gray5;
    font-size: 16px;
    //font-weight: 200;
    text-align: right;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 5px;
    margin-left: 5px;
  }

  .search-peek,
  &.search-peek {
    display: block;
    padding: 0;
    height: 0;
    overflow: hidden;
    background-color: #f9f9f9;
    transition: all 250ms ease-in-out;

    .container-section.actions {
      margin-left: -20px;
      margin-right: -20px;
    }

    &-body {
      padding: 20px;
      width: 100%;
    }

    &-level-name {
      font-weight: 600;
      font-size: 20px;
      color: $rc-gray3;
      margin-right: 6px;
    }

    &-levels {
      padding: 10px;
    }

    &-level-card {
      flex: 1 0 370px;
      max-width: 370px;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
      margin: 10px;
      position: relative;
      overflow: hidden;

      &::before {
        content: " ";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 6px;
      }

      &.junior::before {
        background-color: lighten($junior-color, 8.4%);
        //border: 1px solid $junior-color;
      }

      &.mid::before {
        background-color: lighten($mid-color, 8.4%);
        //border: 1px solid $mid-color;
      }

      &.senior::before {
        background-color: lighten($senior-color, 8.4%);
        //border: 1px solid $senior-color;
      }

      &.leader:before {
        background-color: lighten($lead-color, 8.4%);
        //border: 1px solid $lead-color;
      }

      &.guru::before {
        background-color: lighten($guru-color, 8.4%);
        //border: 1px solid $guru-color;
      }

      .table.level-data {
        margin: -10px 0 0;
      }
    }

    &-expand {
      height: inherit;
    }

    &-item {
      padding: 5px;
      flex-grow: 1;
      flex-shrink: 0;
      flex-basis: 200px;
    }

    &-options {
      flex-grow: 0;
      flex-shrink: 1;
      flex-basis: 200px;
    }

    &-header {
      font-size: 36px;
      font-weight: 900;
      color: $rc-gray2;
    }

    &-section {
      font-size: 20px;

      &-header {
        font-size: 14px;
        font-weight: 100;
        color: $rc-gray4;
      }
    }
  }

  .btn {
    cursor: pointer;
    border-radius: 3px;
    font-size: 14px;
    line-height: normal;
    border-color: $rc-default-color !important;
    background-color: $rc-default-color;
    color: $rc-white1;
    margin: 0 10px 0 0;
    padding: 4px 10px;
    transition: all 0.2s ease;

    .fa.icon {
      margin-left: -2px;

      & + span {
        margin-left: 2px;
      }
    }

    &.btn-lg {
      padding: 10px 20px;
      font-size: 18px;
      line-height: 1.33333;

      .fa.icon {
        margin-left: -4px;

        & + span {
          margin-left: 10px;
        }
      }
    }

    input[type="checkbox"] {
      margin: 0 8px 0 0;
    }

    &:last-child {
      margin: 0;
    }

    &:hover {
      background-color: darken($rc-default-color, 6.8%);
      border: 2px solid darken($rc-default-color, 6.8%) !important;
      //color: $rc-green5 !important;
    }
  }

  .btn-default {
    border: 2px solid $rc-default-color !important;
    background-color: $rc-default-color;
    color: $rc-white1 !important;

    &:hover {
      background-color: darken($rc-default-color, 6.8%);
      border: 2px solid darken($rc-default-color, 6.8%) !important;
    }
  }

  .btn-yellow {
    border: 2px solid $rc-yellow !important;
    background-color: $rc-yellow;
    color: $rc-white1 !important;

    &:hover {
      border: 2px solid $rc-yellow2 !important;
      background-color: $rc-yellow2;
    }
  }

  .btn-green {
    border: 2px solid $rc-green4 !important;
    background-color: $rc-green4;
    color: $rc-white1 !important;

    &:hover {
      border: 2px solid $rc-green6 !important;
      background-color: $rc-green6;
    }
  }

  .btn-danger {
    border: 2px solid $rc-danger-color !important;
    background-color: $rc-danger-color;
    color: $rc-white1 !important;

    &:hover {
      border: 2px solid darken($rc-danger-color, 6.8%) !important;
      background-color: darken($rc-danger-color, 6.8%);
    }
  }

  .btn-toggle {
    color: darken($rc-input-border-color, 32.8%);
    border: 2px solid $rc-input-border-color !important;
    background-color: white;
    position: relative;
    //transition: all .2s;
    input[type="checkbox"],
    input[type="radio"] {
      visibility: hidden;
      position: absolute;
      top: 0;
      left: 0;
    }

    &.borderless {
      border: 2px solid transparent !important;
      background-color: transparent;
    }

    &.btn-lg {
      border-radius: 6px;
    }

    &.selected,
    &.selected:hover {
      color: $rc-green6;
      border: 2px solid lighten($rc-yellow, 6.8%) !important;
      background-color: $rc-green8;
    }

    &:hover {
      color: $rc-green6 !important;
      border: 2px solid lighten($rc-green6, 6.8%) !important;
      background-color: $rc-green8;
    }

    &:active {
      box-shadow: none;
    }

    &:last-child,
    &:only-child {
      margin: 0;
    }

    .text-x-small {
      color: darken($rc-input-border-color, 14.2%);

      .fa.icon {
        margin-left: 0;
        font-size: 13px;

        & + span {
          margin-left: 5px;
        }
      }
    }
  }

  .btn-toggle-group {
    margin: 0;
    padding: 0;
    display: inline;

    &:not(:last-child) {
      margin-right: 20px;
    }

    .btn-toggle {
      margin: 0 0 0 -1px;

      &:not(:first-child):not(:last-child) {
        border-right: 0 !important;
        border-left: 1px solid $rc-element-border-color !important;
        border-radius: 0;
      }

      &:first-child {
        border-right: 0 !important;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      &:last-child {
        border-left: 1px solid $rc-element-border-color !important;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      &:not(:first-child).selected,
      &:not(:first-child).selected:hover,
      &:not(:first-child):hover {
        border-left: 1px solid $rc-element-border-color !important;
      }
    }
  }

  .selectable {
    display: flex;

    .checkbox {
      display: flex;
      margin-left: 10px;
      margin-right: 10px;
      align-items: center;

      input {
        margin: 0;
        padding: 0;
      }
    }

    .ratecard-list-item {
      flex-grow: 1;
    }
  }

  .ratecard-list-item {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    cursor: pointer;
    margin: 0;
    padding: 20px;
    transition: all 200ms;

    &.selected,
    &:hover {
      background-color: $rc-green8;
      //box-shadow: inset 6px 0 $rc-green5;
      .ratecard-label {
        color: $rc-green5;
      }
    }
  }

  .ratecard-list-item-container,
  .search-list-item-container {
    border-bottom: 1px solid $rc-element-border-color;

    &:first-child {
      border-top: 1px solid $rc-element-border-color;
    }
  }

  .rc-center {
    display: flex;
    align-items: center;
  }

  .search-list-item {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    cursor: pointer;
    margin: 0;
    padding: 20px;
    font-size: 18px;
    //box-shadow: inset 6px 0 $rc-green4;
    transition: all 200ms;

    &.selected,
    &:hover {
      background-color: $rc-green8;
      //box-shadow: inset 6px 0 $rc-green5;
      .pt-ico-moon,
      .search-label {
        color: $rc-green5;
      }
    }
  }

  .search-label {
    font-size: 24px;
    white-space: pre;
    color: $rc-green4;
    transition: all 200ms;
    margin-bottom: 4px;
    line-height: 1.2;
  }

  .search-title {
    color: $rc-gray5;
    font-size: 16px;
    line-height: 18px;
    padding-bottom: 2px;
    margin-top: -2px;
  }

  .search-expand-state {
    font-size: 24px;
    display: flex;
    align-items: center;
    padding-right: 10px;

    .pt-ico-moon {
      transition: transform 150ms ease-in, color 200ms;
      color: $rc-green4;
    }

    .expanded {
      transform: rotate(90deg);
    }
  }

  .search-list-item-left {
    display: flex;
    flex: 1;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: flex-start;
  }

  .search-list-item-right {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .filter-modal {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  .filter-modal-content {
    min-width: 300px;
    margin: 50px;
    padding: 25px;
    border: 2px solid #333;
    background-color: $rc-white1;
  }

  .filter-modal-list {
    border: 1px solid #333;
    overflow-y: scroll;
  }

  .filter-modal-item {
    padding: 5px 10px;
    margin: 1px;
    border: 1px solid red;
  }

  .filter-modal-hidden {
    display: none;
  }

  .ratec ards-edit-actions,
  .search-edit-actions {
    @include pt-action-bar($edit-bar-height);
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    bottom: 40px;
    right: 40px;
    left: 40px;
    height: initial;
    background-color: rgba(51, 51, 51, 0.94);
    padding: 20px;
    z-index: 20;
  }

  .search {
    width: 100%;
    min-height: 100vh;

    &-summary {
      display: flex;
    }

    &-results {
      display: flex;
      flex-direction: column;
      border: 1px $rc-gray2 solid;
      border-radius: 5px;

      &-level {
        background-color: $rc-white1;
        padding: 10px;
        border-bottom: 1px $rc-gray2 solid;
        border-radius: 0;

        &-first {
          border-radius: 5px 5px 0 0;
        }

        &-last {
          border-radius: 0 0 5px 5px;
        }

        &-header {
          display: flex;

          h1 {
            font-weight: bolder;
            font-size: 32px;
          }

          .meta {
            margin-left: 50px;
            display: flex;
            justify-content: space-around;

            &-item {
              font-size: 28px;
              margin-left: 15px;
              margin-right: 15px;

              header {
                color: $rc-gray2;
                font-size: 14px;
              }
            }
          }
        }

        &-data {
          display: flex;
          justify-content: space-around;
        }

        &-section {
          & > header {
            color: $rc-gray4;
            font-size: 26px;
          }

          section {
            & > header {
              font-weight: 200;
              font-size: 14px;
              color: $rc-gray3;
            }
          }
        }
      }
    }
  }

  .pt-pagination-outer {
    color: $rc-green4;
    cursor: pointer;
    margin: 15px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    min-width: 510px;
    max-width: 80%;
  }

  .pt-first-page,
  .pt-last-page,
  .pt-next-page,
  .pt-page-box,
  .pt-prev-page {
    height: $pagination-box-height;
    margin-left: 3px;
    margin-right: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .pt-page-left-chevron {
    width: math.div($pagination-box-height, 2);
    height: math.div($pagination-box-height, 2);
  }

  .pt-page-right-chevron {
    width: math.div($pagination-box-height, 2);
    height: math.div($pagination-box-height, 2);
    text-align: right;
  }

  .pt-first-page-left-chevron {
    @extend .pt-page-left-chevron;
    margin: 0 3px 0 5px;
  }

  .pt-last-page-right-chevron {
    @extend .pt-page-right-chevron;
    margin: 0 5px 0 3px;
  }

  .pt-first-page,
  .pt-last-page,
  .pt-next-page,
  .pt-prev-page {
    border-radius: 5px;
    border: 2px solid $rc-green4;

    &:hover {
      background-color: $rc-green4;
      color: $rc-white1;
    }
  }

  .pt-page-box {
    min-width: 32px;
    padding: 5px;
  }

  .pt-page-box--active {
    @extend .pt-page-box;
    border-radius: 5px;
    background-color: $rc-yellow;
    color: $rc-white1;
  }

  .pt-page-box--collapse {
    font-size: 32px;
    height: 10px;
    line-height: 5px;
  }

  .pt-first-page,
  .pt-last-page {
    width: 110px;
  }

  .pt-first-page {
    justify-content: flex-start;
  }

  .pt-last-page {
    justify-content: flex-end;
  }

  .pt-first-page-text,
  .pt-last-page-text {
    line-height: 0;
  }

  .dropdown {
    .dropdown-toggle {
      float: none;
    }

    .dropdown-menu > li > a {
      font-size: 14px;
      padding: 6px 20px;
      color: darken($rc-input-border-color, 32.8%) !important;

      &:hover {
        color: hsl(217, 87%, 55%) !important;
        //border: 2px solid lighten($rc-green6, 6.8%) !important;
        background-color: hsla(217, 87%, 97%, 100);
      }
    }
  }

  .open.dropdown {
    .dropdown-toggle {
      background-color: darken($rc-default-color, 6.8%);
      border: 2px solid darken($rc-default-color, 6.8%) !important;
    }
  }

  .Select-control {
    border-radius: 3px;
    //  box-shadow: 1px 1px 3px $rc-gray1;
    margin-top: 2px;
    margin-bottom: 10px;
    font-size: 14px !important;
    font-weight: normal;
    height: 34px;

    .Select-value {
      @include applyVPadding($selectVPadding);
    }

    .Select-arrow {
      border-color: #999 transparent transparent;
      top: 50%;
      margin-top: -(math.div($selectArrowWidth, 2));
    }

    .Select-item {
      margin-top: 3px;
      .Select-item-icon {
        padding: 2px 5px 4px !important;
      }

      .Select-item-label {
        padding: 3px 5px !important;
      }
    }
  }

  .Select-menu-outer {
    // border-radius: 0 0 3px 3px;
    // border: 2px solid $olive;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    background-color: #ffffff;
    border: 1px solid #cccccc;
    border-top-color: #e6e6e6;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
    box-sizing: border-box;
    margin-top: -1px;
    max-height: 200px;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 1000;

    .Select-menu {
      .Select-option {
        font-size: 14px;
        font-weight: normal;
        @include applyVPadding($selectVPadding);
        font-family: $ff-source-regular;
        color: #666666;
        line-height: 19px;

        &:last-child {
          border-radius: 0;
        }

        &.is-focused {
          //  background-color: $gray-light;
          color: #333333;
          background-color: #f2f9fc;
        }
      }
    }

    .Select-noresults {
      font-size: 14px;
    }
  }
}

.search-peek-expand {
  overflow: auto;
}

.job-category-select {
  min-width: 150px;
  max-width: 300px;
  font-family: $ff-source-regular;
  // react-select custom styles
  .Select-control {
    @include applyVPadding($selectVPadding);
    border-radius: 3px;
    //  box-shadow: 1px 1px 3px $rc-gray1;
    padding: 10px;
    margin-top: 2px;
    margin-bottom: 10px;
    font-size: 16px !important;
    font-weight: 600;

    .Select-value {
      @include applyVPadding($selectVPadding);
    }

    .Select-arrow {
      border-color: $olive transparent transparent;
      top: 50%;
      margin-top: -(math.div($selectArrowWidth, 2));
    }

    .Select-arrow,
    .Select-arrow-zone {
      content: " ";
      display: block;
      position: absolute;
      cursor: pointer;
    }

    .Select-arrow-zone {
      right: 0;
      top: 0;
      bottom: 0;
      width: 30px;
    }
  }

  &.is-focused:not(.is-open) > .Select-control {
    border-color: #a2b624;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1), 0 0 5px -1px rgba(151, 169, 0, 0.5);
  }

  &.is-open > .Select-control {
    background-color: #a2b624;
    border-color: #a2b624 transparent transparent;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    & > .Select-value {
      font-family: $ff-source-regular;
      font-size: 16px;
      line-height: 20px;
      color: $white;
    }

    & > .Select-arrow {
      border-color: transparent transparent $white;
    }
  }

  .Select-menu-outer {
    border-radius: 0 0 3px 3px;
    border: 2px solid $olive;

    .Select-menu {
      .Select-option {
        @include applyVPadding($selectVPadding);
        font-family: $ff-source-regular;
        color: #212121;
        line-height: 19px;

        &:last-child {
          border-radius: 0;
        }

        &.is-focused {
          background-color: $gray-light;
        }
        font-size: 16px;
        font-weight: 600;
      }
    }
  }
}

.pt-range-filter {
  margin-bottom: 10px;
}

.pt-filter-modal {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  bottom: 0;
  right: 0;
  overflow: auto;
  //bottom: 0;
  //right: 0;

  &-backdrop {
    position: fixed;
    z-index: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }

  &-inner {
    position: absolute;
    max-width: 94vw;
    max-height: 94vh;
    display: flex;
    flex-direction: column;
    top: 20%;
    left: 50%;
    margin-bottom: 5%;
    transform: translate(-50%, -20%);
    background-color: white;
    border-radius: 4px;
    padding: 20px;
    outline: none;
    box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1);

    .pt-modal-body {
      padding: 10px 0;
    }
  }
}

.pt-filter-name {
  margin-top: 0;
}

.pt-filter {
  &-text {
    padding: 15px 0;

    &__input {
      width: 100%;
      @include pt-input;
    }
  }
}

.pt-filter-items {
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 3px;
  border: 1px solid $rc-gray1;
  margin-bottom: 10px;
  max-height: 40vh;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

.pt-filter-items__item {
  margin: 0;

  &-location {
    display: flex;
    justify-content: space-between;
    user-select: none;
  }
}

.pt-filter-items__item-label {
  width: 100%;
  padding-left: 25px !important;

  input[type="checkbox"] {
    margin-left: -25px;
    transform: translateY(28%);
  }

  input[type="radio"] {
    margin-left: -24px;
    transform: translateY(24%);
  }
}

.pt-filter-actions {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  justify-content: flex-end;
  color: $rc-white1;
}

.pt-filter-action {
  margin: 0 2px !important;
}

.action-bar {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  flex: 2;
  text-align: right;
}

//-----------------------------------------
//  Sort Controls
//-----------------------------------------

.sort-option input[type="checkbox"] {
  visibility: hidden;
}

.sort-option {
  width: 36px;
  height: 36px;
  background: #fff;
  margin: 20px 0;
  position: relative;
  border: 1px $rc-gray1 solid;
}

.sort-option label {
  cursor: pointer;
  position: absolute;
  padding-top: 6px;
  padding-left: 42px;
  max-width: inherit;
  font-weight: normal;
  width: 300px;
  height: 26px;
  left: -2px;
  top: -2px;
}

.sort-option i {
  position: absolute;
  top: 0;
  left: 0;
  margin: 8px;
}

.sort-option label:before {
  content: "";
  position: absolute;
  width: 34px;
  height: 34px;
  top: 2px;
  left: 2px;
}

.sort-option label:hover::before {
  background: #eee;
}

.sort-option input[type="checkbox"]:checked + label:before {
  background: $rc-gray2;
}

//-----------------------------------------
//  General
//-----------------------------------------

.text-thin {
  font-family: inherit !important;
  font-weight: 300 !important;
}

.text-x-thin {
  font-family: inherit !important;
  font-weight: 200 !important;
}

.text-small {
  font-size: 16px !important;
}

.text-x-small {
  font-size: 14px !important;
}

.text-xx-small {
  font-size: 13px !important;
}

.no-padding {
  padding: 0 !important;

  &-top {
    padding-top: 0 !important;
  }
}

.no-margin {
  margin: 0 !important;
}

.no-border {
  border: none !important;
}

.no-wrap {
  white-space: nowrap !important;
}

div.pull-left.block {
  margin-right: 15px;
}

.results-col {
  padding-left: 0;

  &-last {
    padding: 0;
  }

  & > header,
  &-last > header {
    margin: 10px 0 !important;
    font-size: 18px !important;
  }
}

.footer-data-header {
  margin-bottom: 10px !important;
}

.section-detail {
  margin-bottom: 20px !important;

  & > header {
    margin-bottom: 2px;
    color: $rc-gray5;
  }
}

.job-description {
  pre {
    font-family: inherit;
    font-size: 15px;
    font-weight: normal;
    line-height: 1.56;
    color: inherit;
    border: none;
    margin: 0;
    padding: 0;
    background-color: transparent;
  }
}

.text-wrap {
  white-space: pre-wrap;
  /* Since CSS 2.1 */
  white-space: -moz-pre-wrap;
  /* Mozilla, since 1999 */
  white-space: -pre-wrap;
  /* Opera 4-6 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  word-wrap: normal;
  /* Internet Explorer 5.5+ */
  word-break: keep-all;
}

.section-header {
  border-bottom: 1px $table-border-color solid;
  padding: 10px 0 !important;
  margin: 30px 0 !important;
  font-family: inherit;
}

.panel-results {
  & > div.panel-heading {
    background-color: #f5f5f5 !important;
  }
}

.panel-details {
  border: hidden;

  & > div.panel-heading {
    background-color: #ffffff !important;
  }
}

.chart-container {
  position: relative;
  overflow: hidden;
  border-radius: 5px;
  //min-width: 400px;
  //min-height: 400px;
}

.pay-rate-vs-bill-rate-chart {
  height: 446px;
}

.punch-out-vs-market-chart {
  height: 240px;
  border-top: 1px solid $rc-gray2;
  border-radius: 0;
}

.small-chart-container {
  height: 30px;
  width: 120px;
}

.text-center-middle {
  position: inherit;
  float: left;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.alert.no-items {
  padding-top: 40px;
  padding-bottom: 40px;
  margin: 0;
  border: 2px dashed #ccc;
  background-color: #fafafa;
  text-align: center;
  color: #999;

  a.btn-lg {
    margin-top: 10px;
  }

  p {
    font-weight: 300;
  }
}

.show-overflow {
  overflow: visible !important;
}

//-----------------------------------------
//  Collapsible Panel
//-----------------------------------------

.collapse-toggle-button {
  cursor: pointer;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 60px;
  border-radius: 0 0 5px 5px;

  & > a {
    position: inherit;
    width: 100%;
    top: 0;
    bottom: 0;
    line-height: 60px;
    padding: 0 15px;
    border-radius: 0 0 5px 5px;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,0.8+5,1+50 */
    background: -moz-linear-gradient(
      top,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.8) 10%,
      rgba(255, 255, 255, 1) 50%,
      rgba(255, 255, 255, 1) 100%
    );
    /* FF3.6-15 */
    background: -webkit-linear-gradient(
      top,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.8) 10%,
      rgba(255, 255, 255, 1) 50%,
      rgba(255, 255, 255, 1) 100%
    );
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.8) 10%,
      rgba(255, 255, 255, 1) 50%,
      rgba(255, 255, 255, 1) 100%
    );
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=  '#00ffffff', endColorstr='#ffffff', GradientType=0);
    /* IE6-9 */
    -webkit-transition: all 0.25s ease;
    transition: all 0.25s ease;

    &.collapsed:hover {
      top: 10px;
    }

    &.expanded:hover {
      top: -30px;
    }
  }
}

.panel-body {
  &.collapsed {
    height: auto;
    //max-height: 110px;
    overflow: hidden !important;
    -webkit-transition: all 0.45s ease;
    transition: all 0.45s ease;
  }

  &.expanded {
    max-height: 1000px !important;
    overflow: scroll !important;
    -webkit-transition: all 0.45s ease;
    transition: all 0.45s ease;
  }

  & > .collapsible-content {
    padding-bottom: 50px;
  }
}

//-----------------------------------------
//  Level Card
//-----------------------------------------

.level-icon {
  color: white;
  width: 50px;
  height: 50px;

  & > header {
    font-size: 20px;
    font-weight: bold;
    opacity: 0.85;
    position: relative;
    float: left;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &.junior {
    background-color: $junior-color;
  }

  &.mid {
    background-color: $mid-color;
  }

  &.senior {
    background-color: $senior-color;
  }

  &.leader {
    background-color: $lead-color;
  }

  &.guru {
    background-color: $guru-color;
  }
}

.panel {
  &.level-card {
    & > .panel-heading {
      .title-info {
        color: $rc-gray5;
        font-weight: normal;
        font-size: 14px;
        font-style: italic;
        line-height: 28px;
      }
    }

    & > .panel-body {
      padding-bottom: 0;
    }
  }
}

.table.level-data {
  thead > tr > th {
    text-align: left;
    background-color: inherit;
    font-weight: normal;
    padding: 10px 5px;
  }

  tbody > tr:last-child > td {
    border-bottom: 0;
  }

  tbody > tr > td {
    font-size: 14px;
    padding: 8px;
    border-top: 0;
    border-bottom: 1px solid $table-border-color;

    &.ticker-cell {
      width: 20px;

      .fa.ticker {
        font-size: 18px;
      }
    }

    &.max-value {
      width: 148px;
      text-align: center;
      vertical-align: middle;
      border: 0;

      div.max-value-wrapper {
        min-height: 69px;

        & > header {
          color: $rc-gray3;

          h4 {
            font-size: 18px;
            margin-bottom: 2px;
          }

          h5 {
            font-size: 16px;
          }
        }

        & > div.caption {
          font-size: 14px;
          margin-top: 10px;
        }
      }
    }

    span {
      &.ticker {
        -webkit-transform: scaleX(0.8) scaleY(1.25);
        -moz-transform: scaleX(0.8) scaleY(1.25);
        -ms-transform: scaleX(0.8) scaleY(1.25);
        -o-transform: scaleX(0.8) scaleY(1.25);
        transform: scaleX(0.8) scaleY(1.25);

        &.no-change {
          transform: scaleX(0.6) scaleY(1);
        }
      }
    }
  }

  .sub-table {
    background-color: transparent;
  }
}

.positive-change {
  color: $positive-change-color;
  //opacity: 1;
}

.negative-change {
  color: $negative-change-color;
  //opacity: 1;
}

.fa-caret-up.positive-change {
  margin-right: 5px;
}

.fa-caret-down.negative-change {
  margin-right: 5px;
}

lis.no-change {
  color: $rc-gray1;
}

.text-right {
  text-align: right;
}

div.row {
  &.markup-info {
    background-color: #f5f5f5;
    //max-height: 0;
    //overflow: hidden;
  }

  &.bill-info {
    border-radius: 0 0 3px 3px;

    div > .table.level-data {
      tbody > tr > td,
      thead > tr > th {
        color: #ffffff;
        opacity: 0.76;
        border-color: rgba(255, 255, 255, 0.1);
      }

      tbody > tr > td.max-value {
        border-bottom: inherit;
        opacity: 0.85;

        header {
          color: #ffffff;

          h4 {
            margin-bottom: 2px;
          }
        }

        div.caption {
          opacity: 0.76;
        }
      }
      //tbody > tr:last-child > td {
      //  border: inherit;
      //}
      //
      //tbody > tr:first-child > td {
      //  border: inherit;
      //}
    }

    &.junior {
      background-color: $junior-color;

      .positive-change {
        color: $junior-positive-color;
      }

      .negative-change {
        color: $junior-negative-color;
      }
    }

    &.mid {
      background-color: $mid-color;

      .positive-change {
        color: $mid-positive-color;
      }

      .negative-change {
        color: $mid-negative-color;
      }
    }

    &.senior {
      background-color: $senior-color;

      .positive-change {
        color: $senior-positive-color;
      }

      .negative-change {
        color: $senior-negative-color;
      }
    }

    &.leader {
      background-color: $lead-color;

      .positive-change {
        color: $lead-positive-color;
      }

      .negative-change {
        color: $lead-negative-color;
      }
    }

    &.guru {
      background-color: $guru-color;

      .positive-change {
        color: $guru-positive-color;
      }

      .negative-change {
        color: $guru-negative-color;
      }
    }
  }
}

.level-card-container {
  padding: 0 15px;
  width: 100%;
  @media (min-width: 480px) {
    max-width: 480px;
  }
  @media (min-width: 740px) {
    max-width: inherit;
    width: 50%;
  }
  @media (min-width: 1090px) {
    max-width: inherit;
    width: 33.33%;
  }
  @media (min-width: 1450px) {
    max-width: inherit;
    width: 25%;
  }
  @media (min-width: 1760px) {
    max-width: inherit;
    width: 20%;
  }
  @media (min-width: 1980px) {
    max-width: 480px;
  }

  .level-card-job-title {
    font-size: 16px;
    margin-bottom: 4px;
  }

  .level-card-location {
    font-size: 16px;
  }
}

//-----------------------------------------
//  Buy Rate Card
//-----------------------------------------

.hidden-ml {
  @media (min-width: 1200px) {
    display: none !important;
  }
  @media (min-width: 1630px) {
    display: block !important;
  }
}

.visible-ml {
  display: none !important;
  @media (min-width: 1200px) {
    display: block !important;
  }
  @media (min-width: 1630px) {
    display: none !important;
  }
}

.buy-rate-card-container {
  @media (min-width: 1200px) {
    width: 100%;
  }
  @media (min-width: 1630px) {
    width: 50%;
  }
}

.table.buy-rate-level-data {
  min-height: 240px;

  thead > tr > th {
    text-align: left;
    background-color: inherit;
    font-weight: normal;

    &.text-right {
      text-align: right;
    }

    &.nte-column {
      font-weight: 600;
      color: #424242;
    }
  }

  tbody > tr.inline-input {
    td.form-input {
      padding: 2px 10px;

      &.buy-rate-level-icon {
        padding: 2px;

        .input-group {
          right: 0;
        }
      }

      .input-group {
        padding: 0;
        right: -10px;
      }
    }

    td.nte {
      padding-top: 6px;
      padding-bottom: 0;
    }
  }
}

.nte-column {
  font-weight: 600;
  color: #424242;
  padding-right: 0 !important;
}

.fa.icon + em,
.fa.icon + span {
  margin-left: 5px;
}

.options {
  position: relative;
  padding: 0 10px;

  button,
  a {
    padding: 10px 14px 11px;
    cursor: pointer;
    background: none;
    border: none;
    margin: -10px 0;
    color: #a2b624;

    &:hover {
      background-color: $rc-green8;
    }
    &:active,
    &:focus {
      outline: 1px solid #a2b624;
    }
  }

  &.header-options {
    button,
    a {
      &:hover {
        background-color: $gray-border;
      }

      &:active,
      &:focus {
        outline: 1px solid #a2b624;
      }
    }
  }
}

div.btn-group.options {
  padding: 0;
  line-height: 28px;
}

td.fixed-size,
th.fixed-size {
  min-width: 114px;
  max-width: 140px;
}

.buy-rate-level-icon {
  overflow: hidden;
  color: white;
  width: 40px;
  height: 40px;
  opacity: 0.85;
  text-align: center;

  &.junior {
    background-color: $junior-color;
    //border-bottom: 1px solid $junior-card;
  }

  &.mid {
    background-color: $mid-color;
    //border-bottom: 1px solid $mid-card;
  }

  &.senior {
    background-color: $senior-color;
    //border-bottom: 1px solid $senior-card;
  }

  &.leader {
    background-color: $lead-color;
    //border-bottom: 1px solid $lead-card;
  }

  &.guru {
    background-color: $guru-color;
    //border-bottom: 1px solid $guru-card;
  }
}

.row.buy-rate-details {
  min-height: 68px;
}

.buy-rate-level-place-holder {
  height: 40px;
}

.buy-rate-collapsible {
  overflow: hidden;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;

  &.collapsed {
    max-height: 0;
    opacity: 0.2;
  }

  &.expanded {
    max-height: 160px;
    display: inherit;
    opacity: 1;
  }

  a {
    width: 40px;
    height: 40px;
    display: block;
    float: right;
    clear: right;

    &.first {
      margin-top: 10px;
    }
  }
}

.btn-sm-device {
  width: 40px;
  height: 40px;

  span.fa-chevron-down {
    //position: relative;
    //top: 10px;
    //right: 10px;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;

    &.collapsed {
      -moz-transform: scaleY(1);
      -o-transform: scaleY(1);
      -webkit-transform: scaleY(1);
      transform: scaleY(1);
    }

    &.expanded {
      -moz-transform: scaleY(-1);
      -o-transform: scaleY(-1);
      -webkit-transform: scaleY(-1);
      transform: scaleY(-1);
    }
  }
}

.xs-nte-table {
  width: 100%;

  tbody > tr {
    td {
      padding: 0 5px;

      &:first-child {
        min-width: 70px;
      }

      .input-group {
        width: 100%;
      }

      .form-control.input-sm {
        height: 24px;
        line-height: inherit;
        padding: 2px 6px;
      }
    }

    &.xs-nte-row-input {
      td,
      td.form-input,
      td.nte {
        padding-top: 5px;
        padding-bottom: 0;
      }
    }
  }
}

.table.level-data.buy-rates {
  font-weight: normal;
  margin-bottom: 0;
  margin-top: 10px;

  &.input {
    margin-top: 7px;
    margin-bottom: 8px;
  }

  tbody > tr {
    td {
      &:first-child {
        min-width: 70px;
      }

      .input-group {
        width: 100%;
      }

      .form-control.input-sm {
        height: 24px;
        line-height: inherit;
        padding: 2px 6px;
      }
    }
  }
}

.loading-indicator-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  visibility: hidden;
  opacity: 0;
  z-index: 1000;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -ms-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;

  &.show {
    visibility: visible;
    opacity: 1;
  }
}

//-----------------------------------------
//  Toggle radios, checkbox
//-----------------------------------------

.btn-group {
  &.rc-btn-group {
    margin: 0 15px 0 0;

    &:last-child {
      margin: 0;
    }

    &.pull-right {
      margin: 0 0 10px 15px;

      &:last-child {
        margin: 0;
      }
    }
  }

  &.toggle-group {
    //border: 1px solid $rc-gray2;
    border-radius: 0;

    &[data-toggle="buttons"] {
      .btn {
        margin: 0;

        &.btn-toggle {
          border-radius: 0;
          color: $rc-green7 !important;
          background-color: white;
          transition: all 0.3s;
          border: 0 !important;
          padding: 6px 12px;
          box-shadow: inset 0 -3px 0 0 $rc-gray2;
          -moz-box-shadow: inset 0 -3px 0 0 $rc-gray2;
          -webkit-box-shadow: inset 0 -3px 0 0 $rc-gray2;

          &.active {
            background-color: $rc-green8;
            color: $rc-green7 !important;
            box-shadow: inset 0 -3px 0 0 lighten($rc-yellow, 6.8%);
            -moz-box-shadow: inset 0 -3px 0 0 lighten($rc-yellow, 6.8%);
            -webkit-box-shadow: inset 0 -3px 0 0 lighten($rc-yellow, 6.8%);
          }

          &:hover {
            background-color: $rc-green8;
            color: $rc-green5 !important;
            box-shadow: inset 0 -3px 0 0 $rc-green4;
            -moz-box-shadow: inset 0 -3px 0 0 $rc-green4;
            -webkit-box-shadow: inset 0 -3px 0 0 $rc-green4;
          }
        }

        &.btn-group-addon {
          color: inherit;
          background-color: #f5f5f5;
          border-right: 1px solid $rc-gray2 !important;
          cursor: default;

          &:active {
            box-shadow: none;
          }
        }
      }
    }
  }
}

.rc-alert {
  box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  border: 0;
  font-size: 16px;

  .options {
    .btn-green {
      margin-left: 6px;
      padding: 4px 8px;
    }
  }

  &.floating-alert {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translate(-50%, 0%);
  }
}

.btn-toolbar {
  &.main-toolbar {
    margin: 0;
  }
}

.section {
  position: relative;
}

.select-all-link {
  font-size: 16px;
  font-weight: normal;

  input[type="checkbox"] {
    margin-right: 6px;
    margin-left: 6px;
  }
}

.back-bar {
  margin-left: 10px;

  a,
  button {
    display: inline;
  }
}

.rc-ul {
  font-size: 14px;

  &.errors {
    padding-left: 20px;
  }

  li {
    margin-bottom: 10px;
  }
}

.unique-job-label label {
  display: inline-block !important;
  margin-left: 12px;
  margin-right: 12px;
}

.unique-job-label .rc-input-box {
  padding: 8px 10px;
  width: 221px !important;
}

.ratecards .ratecards-table-header,
.ratecards .search-table-header {
  display: flex !important;
}

.ratecards .btn-group-lg > .btn,
.ratecards .btn.btn-lg {
  font-size: 18px;
}

.app-container {
  width: 100%;
  height: calc(100vh - 60px);
  padding-left: 0;
  padding-right: 0;
  overflow: auto;
}

.ratecards .search-table,
.ratecards-table {
  // width: 104% !important;
}

.is-open > .Select-control > .Select-arrow {
  border-color: transparent transparent #999999 !important;
  border-width: 0 5px 5px;
}

.modal-sm {
  width: 500px !important;
  background: #fff;
}

.ratecards {
  input {
    font-size: 14px;
    text-align: left;
  }
  .table {
    tbody {
      tr {
        td {
          vertical-align: middle;
        }
      }
    }
  }
}

.ratecard_new_table {
  border: none;
}

.ratecard_new_table .table_label tbody tr td {
  border-bottom: 1px solid #e6e6e6;
  text-align: center;
}

.ratecard_new_table .table_label tbody tr td:nth-child(4n + 1) {
  text-align: left;
}

.ratecard_new_table .table_label tbody tr td:nth-child(4n + 2),
.ratecard_new_table .table_label tbody tr td:nth-child(4n + 3) {
  border-right: 1px solid #e6e6e6;
}

.ratecard_new_table .rc-checkbox-component {
  display: inline-block;
  vertical-align: middle;
}

.ratecard_new_table .rc-checkbox-component + span + span {
  display: inline-block;
  vertical-align: middle;
}

.ratecard_new_table .table_label thead tr th:nth-child(4n + 2),
.ratecard_new_table .table_label thead tr th:nth-child(4n + 3) {
  border-right: 1px solid #e6e6e6 !important;
}

.ratecard_new_table .table_label thead tr th {
  background: #f9f9f9 !important;
}

.pt-pad-item {
  padding: 15px 20px;
}

.view.list .ratecard-info header {
  text-align: center;
}

.ratecards.pt-filter-modal-inner {
  .ratecards-list {
    .ratecards-table-header {
      margin: 0px;
    }
    .ratecards-table {
      width: 100% !important;
      margin: 0px;
    }
  }
}

.table {
  &-responsive {
    min-height: 0.01%;
    overflow-x: auto;
  }
}

#quote_wrap {
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.regions {
  @extend .ratecards;
}

.regions .Select-control,
.ratecards.pt-filter-modal-inner .Select-control {
  padding: 0px 5px 8px 3px !important;
  .Select-clear {
    padding: 5px 10px !important;
  }
  .Select-placeholder {
    padding: 6px 5px 6px 6px;
  }
  .Select-loading {
    margin-top: -8px;
  }
}

.ratecards.pt-filter-modal-inner {
  .ratecard-info header {
    text-align: center;
  }
}

.regions .Select-control,
.regions .Select-menu-outer .Select-menu .Select-option {
  font-size: 14px !important;
  font-weight: normal;
}

.regions .rc-input {
  color: #333;
}

.regions {
  .ratecard-list-item {
    cursor: text;
    &:hover {
      background-color: #fff;
    }
  }
}

.table-responsive {
  min-height: 0.01%;
  overflow-x: auto;
}

#feeds {
  &:focus {
    text-decoration: none;
  }
}

.pt-filter-modal-inner .regions {
  .Select.is-multi {
    .Select-control {
      padding: 0px 52px 1px 3px !important;
    }
  }
}

.select-list {
  .list-group-item {
    font-size: 14px;
  }
}

.regions.rt {
  .ratecards-list {
    .ratecards-table {
      .ratecard-list-item {
        .w-300 {
          width: auto;
        }
      }
    }
    .ratecards-table-header {
      margin-top: 20px;
    }
  }
}

.regions.rt .ratecard-info.text-center {
  text-align: right;
}

.regions.rt {
  background: #fff;
  padding-bottom: 0px;
}

.regions .ratecard-list-item:hover .ratecard-label {
  color: #a2b624;
}

.ratecards input,
.regions input .rc-input-box {
  color: #333;
}

//.ratecards .fa {
//  font-size: 14px !important;
//}

.ratecards-table,
.ratecards .search-table,
.regions .search-table {
  margin: 0;
  overflow: inherit;
}

.ratecards-table-header {
  padding: 20px !important;
  margin: 0 !important;
}

.ratecards-list {
  margin: 0 -20px !important;
}

.ratecards .ratecard-list-item-right,
.regions .ratecard-list-item-right {
  padding-right: 0 !important;
}

.tagbox {
  @include flexbox();
  width: 100%;
  @include flex-wrap(wrap);
}

.pt-filter-modal .table_label td:nth-child(3n + 1) {
  text-align: center;
}

.pt-filter-modal .table_label td,
.pt-filter-modal .table_label th {
  text-align: left;
}

.table.level-data tbody > tr > td {
  white-space: nowrap;
}

.setings {
  float: right;
  margin-top: 8px;
  cursor: pointer;
}

.custom-modal-box label {
  font-weight: normal;
}

.schedulesearch {
  .ratesearch {
    margin-top: 0px;
  }
  .Select-clear {
    right: 22px;
    top: 5px;
  }
  .search-card-content {
    min-height: 150px;
  }
  .table-striped td {
    text-align: center;
  }
  .rc-checkbox-component {
    display: inline-block;
    vertical-align: middle;
  }
}

.ratecards .pt-search-box,
.regions .pt-search-box {
  font-size: 14px !important;
}

.margin-right {
  margin: 0 5px 0 0 !important;
}

.Select-noresults {
  font-size: 14px !important;
  font-weight: 400 !important;
}

.rc-input-box.small {
  font-size: 14px;
  height: 34px;
}

.row.userjob,
.user-job-data {
  .Select-input {
    padding: 5px 5px 5px 10px !important;
    margin-top: 2px;
  }
}

.header-pad {
  padding: 15px 0;
}

.atlfixformborder .regionfix .Select-placeholder {
  padding: 0px 5px 3px 7px;
}

.atlfixformborder .regionfix .Select-input {
  padding: 3px 0;
}

.ratecards.pt-filter-modal-inner .dashboard-cur .Select-control .Select-placeholder {
  padding: 6px 5px 6px 8px;
}

.ratesearch {
  .rc-actions-bar {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin-right: 20px;
    .info {
      display: inline-block;
      padding: 6px 12px;
      line-height: normal;
      text-align: center;
      vertical-align: middle;
      font-weight: normal;
      float: left;
    }
  }
  .table {
    color: #666;
  }
}

.is-focused:not(.is-open) > .Select-control {
  border-color: #a2b624;
}

.alt-add-job .is-focused:not(.is-open) > .Select-control {
  border-color: #a2b624 !important;
}

.max-width-80 {
  max-width: 80% !important;
}

.pce-table-container {
  margin: 0 -20px 20px;
}

.pce-table {
  margin: 0;
  thead {
    background-color: $rc-white1;

    tr {
      th:first-child {
        padding-left: 20px;
      }
      th:last-child {
        padding-right: 20px;
      }

      th {
        text-align: left;
        line-height: 14px;
        padding: 10px;

        small {
          font-weight: normal;
        }

        &.text-right {
          text-align: right;
        }
        &.text-center {
          text-align: center;
        }

        &.input-pad {
          padding-right: 22px;
        }
      }
    }
  }
  tbody {
    tr {
      td:first-child {
        padding-left: 20px;
      }
      td:last-child {
        padding-right: 20px;
      }

      td {
        text-align: left;
        padding: 10px;
        border-bottom: 1px solid $rc-white2;

        &.text-right {
          text-align: right;
        }
        &.text-center {
          text-align: center;
        }

        &.job-label {
          width: 40%;
        }
        &.job-title {
          width: 35%;
        }
        &.location {
          width: 25%;
        }
        &.level {
          min-width: 84px;
        }
        &.bill-rate-usd {
          min-width: 120px;
        }
        button.remove-action {
          border: none;
          box-shadow: none;
          outline: none;
          font-size: 20px;
          color: $rc-gray1;
          transition: all 0.3s;

          &:hover {
            color: $rc-danger-color;
          }
        }
      }
    }
  }
}

.pce-table-summary {
  thead {
    background-color: $rc-white1;

    tr {
      th:first-child {
        padding-left: 20px;
      }
      th:last-child {
        padding-right: 20px;
      }

      th {
        text-align: left;
        padding: 10px;

        &.text-right {
          text-align: right;
        }
        &.text-center {
          text-align: center;
        }
      }
    }
  }
}
