@import "../base/variables";

#wrapper {
  position: absolute;
  top: 0px;
  width: 100%;
  left: 0px;
}

#content {
  margin-left: 200px;
  margin-right: 0;
  position: relative;
  min-height: 100%;
  width: auto;
  -webkit-background-clip: padding-box;
  background-color: #f8f8f8;

  .sidebar-icon & {
    z-index: 15 !important;
    margin-left: 46px;
  }
  body[data-sidebar-light="true"] & {
    border-left: 1px solid #e6e6e6;
  }
  &.side-closed {
    margin-left: 0 !important;
  }
}

@media (max-width: 991px) {
  #content {
    margin-left: 0 !important;
    margin-top: 34px !important;
    margin-bottom: 0 !important;
    border-radius: 0;
    left: 0;
    -webkit-transition: left 0.5s;
    transition: left 0.5s;
  }
  .fixed #content {
    padding-top: 67px;
  }
  .menu-open #content {
    left: 200px;
  }
}

#content-header {
  height: 70px;
  width: 100%;
  z-index: 15;
  position: relative;
  background-color: #fdfdfd;
  border-bottom: 1px solid #e6e6e6;
  body[data-header-fixed="true"] & {
    width: auto;
    position: fixed;
    top: 45px;
    left: 200px;
    right: 0;
  }
  h1,
  h4 {
    font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  h1 {
    color: #555555;
    font-size: 28px;
    font-weight: normal;
    text-shadow: 0 1px 0 #ffffff;
    padding-left: 20px;
    line-height: 67px;
    display: inline-block;
    margin: 0;
  }
  h4 {
    margin-left: 10px;
    display: inline-block;
    font-size: 16px;
    color: #bbbbbb;
    text-shadow: 0 1px 0 #ffffff;
  }
  > .btn-group {
    margin-top: 25px;
  }
  > .nav-tabs {
    float: right;
    position: relative;
    top: 42px;
    right: 25px;
    border-bottom: 1px solid #e6e6e6;

    li {
      a {
        font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-weight: 300;
        font-size: 18px;
        padding: 10px 20px 8px;
        color: #aaaaaa;
        &:hover {
          border-color: transparent;
          background-color: transparent;
          color: #666666;
        }
      }
      &.active a {
        background-color: #f8f8f8;
        border-color: #e6e6e6 #e6e6e6 transparent #e6e6e6;
        color: #555555;
      }
    }
  }
  > .btn-group {
    float: right;
    right: 40px;
    margin-top: 7px;
    .btn {
      line-height: 18px;
      font-size: 18px;
      padding: 12px 16px;
      border-width: 0 1px 0 0;
      border-style: solid;
      border-color: #dddddd;

      .label {
        font-size: 11px;
        line-height: 12px;
        position: absolute;
        top: 4px;
        right: 5px;
        padding: 2px 5px;
      }
    }
    &.colored {
      box-shadow: none;
      border: 0;
      .btn {
        border: 0;
        color: #ffffff;
        &:hover {
          color: #eeeeee;
        }
      }
    }
  }
  &.mini {
    min-height: 60px;

    h1,
    > .btn-group {
      margin-top: 10px;
    }
  }
}

#content-inner {
  padding: 25px 20px;
  body[data-header-fixed="true"] & {
    margin-top: 115px;
  }
}

.modal-open > .row,
.alert-open > .row,
.modal-open > #wrapper,
.modal-open > #footer,
.alert-open > #footer,
#jobData .panel-body.blurred > *:not(.load-ov),
#ratecardData.blurred > .dataTables_wrapper {
  -webkit-filter: blur(3px);
  //  filter: url(blur.svg#blur);
}
#jobData .panel-body.blurred .load-ov,
#ratecardData.blurred .load-ov {
  background-color: rgba(255, 255, 255, 0.7);
}
#ratecardData.blurred .dataTables_length {
  display: none;
}
.blur {
  //  filter: url(blur.svg#blur);
  width: 2px;
  height: 2px;
  visibility: hidden;
}

@media (max-width: 400px) {
  #content-header .mini-stats li .left,
  #content-header .mini-stats li .right {
    float: none;
    padding-left: 0;
    padding-right: 0;
    text-align: center;
  }
}
@media (max-width: 767px) {
  #content-header {
    margin-top: 0;
    /*height: 140px; FIX iPhone: Makes header take up too much space/not enough, let it dictate (by h1 text length) */
    height: auto;
    /* FIX iPhone: allow h1, btn-group to set height*/
    text-align: center;

    h1,
    .btn-group,
    .mini-stats {
      float: none;
      position: relative;
      /*FIX iPhone: let btn group go in document flow, not absolute. Prevents button/header from running into each other*/
    }
    h1 {
      display: block;
      text-align: center;
      margin-left: auto;
      margin-top: 0;
      padding-top: 0 !important;
      padding-top: 15px;
      width: 100%;
    }
    .btn-group {
      /*margin-top: 70px; FIX iPhone: Makes button group go needlessly far below the header. */
      margin: 10px 0;
      /* FIX iPhone: give equal margins (as below h1), still able to target with finger */
      left: 0;
      right: auto;
    }
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  #content-header {
    border-top-left-radius: 0;
  }
}

.pt-app {
  min-height: 100vh;
}

.container {
  --footer-height: 72px;
  width: 100%;
  min-height: calc(100vh - #{$navbar-height} - var(--footer-height));
  padding-left: 0;
  padding-right: 0;
}
