$pt-store-base-color: #3d6deb;
$pt-store-table-cell-color: #d1dbef;

.ps-pack-pdf-summary-page {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;

  &__title {
    flex: 0 0 7%;
    display: flex;
    align-items: center;
    font-size: 36px;
    padding: 0 10px;
  }

  &__location {
    flex: 0 0 7%;

    & > * {
      font-size: 14px;
      padding: 0 10px;
    }
  }

  &__stats {
    flex: 0 0 5%;
    display: flex;
    align-items: center;

    & > * {
      font-size: 20px;
      padding: 0 10px;
    }
  }

  &__table {
    flex: 0 0 81%;
    overflow: hidden;

    table {
      table-layout: fixed;
      width: 100%;
      font-size: 15px;

      th {
        text-align: center;
        background-color: $pt-store-base-color;
        color: $white;
        padding: 12px 5px;
        border: 2px solid $white;
        border-bottom-width: 4px;
        white-space: nowrap;

        &:nth-child(1) {
          width: 55%;
        }
      }

      td {
        text-align: center;
        padding: 13px 5px;
        border: 2px solid $white;
        background-color: $pt-store-table-cell-color;
        color: $black;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:nth-child(1) {
          text-align: left;
          padding: 13px 10px;
          width: 55%;
        }
      }
    }
  }
}
