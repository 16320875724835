@import "../../../base/labor_index/typography";

$selectVPadding: 10px;
$selectArrowWidth: 5px;

@mixin applyVPadding($value) {
  padding-top: $value;
  padding-bottom: $value;
}

.index_select {
  min-width: 150px;
  font-family: $ff-source-regular;

  // react-select custom styles

  .Select-control {
    @include applyVPadding($selectVPadding);
    border-radius: 0;
    border-color: $gray-darker;

    .Select-value {
      @include applyVPadding($selectVPadding);
    }

    .Select-arrow {
      border-color: $olive transparent transparent;
      top: 50%;
      margin-top: -($selectArrowWidth * 0.5);
    }
  }

  &.is-focused:not(.is-open) > .Select-control {
    border-color: #a2b624;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1), 0 0 5px -1px rgba(151, 169, 0, 0.5);
  }

  &.is-open > .Select-control {
    background-color: $olive;
    & > .Select-value {
      font-family: $ff-source-regular;
      font-size: 16px;
      line-height: 20px;
      color: $white;
    }
    & > .Select-arrow {
      border-color: transparent transparent $white;
    }
  }

  .Select-menu-outer {
    border-radius: 0;
    border: 2px solid $olive;

    .Select-menu {
      .Select-option {
        @include applyVPadding($selectVPadding);
        font-family: $ff-source-regular;
        font-size: 14px;
        color: #212121;
        line-height: 19px;

        &:last-child {
          border-radius: 0;
        }

        &.is-focused {
          background-color: $gray-light;
        }
      }
    }
  }
}
