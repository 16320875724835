@import "../base/colors";
@import "../utils/mixins";

$pagination-button-size: 26px;
$pagination-button-border-width: 2px;

@mixin pt-pagination-button-disabled-mixin {
  color: $gray-darker;
  cursor: not-allowed;

  &:hover,
  &:active {
    color: $gray-darker;
  }
}

.pt-pagination-button {
  @include pt-no-focus-outline;
  @include pt-no-select;
  display: inline-block;
  position: relative;
  min-width: $pagination-button-size;
  height: $pagination-button-size;
  line-height: $pagination-button-size;
  border: $pagination-button-border-width solid transparent;
  border-radius: 3px;
  vertical-align: middle;
  text-align: center;
  text-decoration: none;
  color: $gray-font;
  font-size: 16px;
  cursor: pointer;

  & > * {
    line-height: $pagination-button-size - 2 * $pagination-button-border-width;
    vertical-align: top;
    text-align: center;
  }

  & > .svg-inline--fa {
    height: $pagination-button-size - 2 * $pagination-button-border-width;
  }

  &:hover,
  &:active {
    color: darken($gray-font, 20%);
  }

  &--active {
    color: $white;
    background-color: $yellow;
    cursor: initial;

    &:hover,
    &:active {
      color: $white;
    }
  }
  &--disabled {
    @include pt-pagination-button-disabled-mixin;
  }
}

.pt-pagination-button-ellipsis {
  @extend .pt-pagination-button;
  cursor: initial;

  &:hover,
  &:active {
    color: $gray-font;
  }
}

.pt-pagination-button-prev,
.pt-pagination-button-next,
.pt-pagination-button-first,
.pt-pagination-button-last {
  @extend .pt-pagination-button;
  color: $lime-darker;

  &:hover,
  &:active {
    color: darken($lime-darker, 7%);
  }

  &--disabled {
    @include pt-pagination-button-disabled-mixin;
  }

  & .svg-inline--fa {
    font-size: 10px;
  }
}

.pt-pagination-button-prev,
.pt-pagination-button-next {
  border-color: $lime-darker;
  font-size: 16px;

  &:hover,
  &:active {
    border-color: darken($lime-darker, 7%);
  }

  &--disabled {
    border-color: $gray-darker;
    font-size: 16px;

    &:hover,
    &:active {
      border-color: $gray-darker;
    }
  }
}
