@import "variables";

@mixin pt-input {
  border: 2px solid $rc-input-border-color;
  border-radius: 4px;
  padding: 10px 10px;
  font-size: 16px;
  transition: all 0.2s;
  color: $rc-gray3;

  &::placeholder {
    color: lighten($rc-gray3, 20%);
  }
  &:focus {
    outline: none;
    border: 2px solid $rc-green4;
  }
  &:disabled {
    border-color: lighten($rc-input-border-color, 7%);
    color: lighten($rc-gray3, 20%);
    cursor: not-allowed;
  }
  &:disabled::placeholder {
    color: lighten($rc-gray3, 40%);
  }
}

@mixin pt-action-bar($action-bar-height, $bar-background-color: $rc-gray3) {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  height: $action-bar-height;
  background-color: $bar-background-color;
  padding: 15px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@mixin applyVPadding($value) {
  padding-top: $value;
  padding-bottom: $value;
}
