@import "../base/colors";

.pt-base-list-view {
  font-size: 14px;

  &__selected-list {
    margin-bottom: 10px;
  }
  &__selected-item {
    display: inline-block;
    margin-left: 10px;
    margin-bottom: 10px;
    line-height: initial;
  }
  &__not-selected-text {
    font-size: 13px;
    color: $gray-font;
  }

  &__search-input {
    color: inherit;

    .form-group {
      display: block;
      width: 100%;
    }
    .form-control {
      font-size: 14px !important;
      border-top-left-radius: 3px !important;
      border-bottom: 0 !important;
    }
    .form-control,
    .form-control:focus,
    .form-control:active {
      border-color: #dddddd !important;
      box-shadow: none !important;
    }

    .input-group-addon {
      border-bottom-right-radius: 0 !important;
      border-bottom: 0 !important;
      border-color: #dddddd !important;
    }
  }

  &__results-list {
    display: block;
    width: 100%;
    height: 200px;
    overflow: hidden;
    overflow-y: auto;
    border: 1px solid $gray-border;
    border-radius: 3px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top: none;
  }

  &__not-found-text {
    padding: 3px 10px;
    color: $gray-font;
    font-size: 13px;
  }

  &__loading-text {
    padding: 10px;
    padding-bottom: 15px;
    text-align: center;
  }

  &__results-item {
    display: block;
    width: 100%;
    padding: 3px 10px;
    cursor: pointer;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    &:hover {
      background-color: $gray-light;
    }
    &:active {
      background-color: $lime-light;
    }
    &--selected {
      background-color: lighten($lime-light, 10%) !important;
    }

    .fa {
      color: $olive;
    }
  }

  // modifiers

  &--disabled &__search-input input {
    color: $slate;
  }
  &--disabled &__results-item {
    &:active {
      background-color: $gray-light;
    }
    .fa {
      color: $slate;
    }
  }
  &--disabled &__results-list {
    overflow-y: hidden;
  }
  &--disabled &__results-item {
    color: $slate;
    cursor: not-allowed;
  }
  &--disabled &__not-selected-text {
    color: $slate;
  }
}
