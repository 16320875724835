@use "sass:math";
@import "../base/colors";
@import "../base/labor_index/typography";

// Clearfix
//
// For modern browsers
// 1. The space content is one way to avoid an Opera bug when the
//    contenteditable attribute is included anywhere else in the document.
//    Otherwise it causes space to appear at the top and bottom of elements
//    that are clearfixed.
// 2. The use of `table` rather than `block` is only necessary if using
//    `:before` to contain the top-margins of child elements.
//
// Source: http://nicolasgallagher.com/micro-clearfix-hack/

@mixin clearfix() {
  &:before,
  &:after {
    content: " "; // 1
    display: table; // 2
  }
  &:after {
    clear: both;
  }
}

@mixin active-highlight($height: 5px, $color: $lime) {
  position: relative;

  &:after {
    height: $height;
    width: 100%;
    display: block;
    content: " ";
    top: 0;
    left: 0;
    position: absolute;
    background: $color;
  }
}

@mixin hover-highlight($color: $gray-darkest, $lighten: 3%) {
  background-color: $color;
  transition: background-color 0.15s ease-in-out;

  &:hover,
  &:active {
    background-color: lighten($color, $lighten);
  }
}

@mixin navbar-default {
  background-color: $gray-darkest;
}

@mixin navbar-fixed-top {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  border-width: 0 0 1px;
}

// Add borders around inputs
@mixin pt-bordered-input($background: $white, $border: 2px solid $gray-border) {
  background: $background;
  border: $border;
}

@mixin pt-form-text($opacity: 0.75) {
  opacity: $opacity;
  font-family: $ff-source-regular;
  font-size: 16px;
  color: $gray-font;
  line-height: 20px;
}

@mixin pt-raised-shadow() {
  border: 1px solid $gray-border;
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.2);
}

@mixin pt-no-select() {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin pt-no-focus-outline {
  &:focus {
    outline: none;
  }
}

@mixin grow {
  transition-duration: 0.3s;
  transition-property: transform;

  &:hover,
  &:focus,
  &:active {
    transform: scale(1.05);
  }
}

// Vertical/Horizontal centering utility
// See documentation here: http://davidtheclark.github.io/scut/center-absolutely.html
@mixin pt-center-absolutely($dimensions) {
  $width: nth($dimensions, 1);
  $height: nth($dimensions, 2);

  position: absolute;

  @if $width != n {
    width: $width;
    left: 50%;
    margin-left: math.div(-$width, 2);
  }

  @if $height != n {
    height: $height;
    top: 50%;
    margin-top: math.div(-$height, 2);
  }
}

@mixin pt-valign-mixin($size) {
  height: $size;
  & > * {
    line-height: $size;
    vertical-align: middle;
  }
}

@mixin pt-size-mixin($size) {
  width: $size;
  height: $size;
}
