@import "../../../base/colors";
@import "../../../utils/shadows";

.pt-rates-chart {
  position: relative;
  min-height: 400px;
  background-color: $white;
  border: 1px solid #e6e6e6;
  border-radius: 3px;
  padding: 10px;
}
