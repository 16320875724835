.news-box {
  margin-top: 15px;
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #e6e6e6;
  overflow: hidden;
  position: relative;
}
.news-box img {
  width: 100%;
}
.news-box .industry-name {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #c2d646;
  display: block;
  color: #ffffff;
  font-size: 16px;
  padding: 3px 10px;
  border-bottom-right-radius: 5px;
}
.news-box .industry-btn {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 18px;
  color: #ffffff !important;
  background-color: rgba(0, 0, 0, 0.7);
  display: block;
  padding: 1px 6px;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 4px;
  opacity: 0.85;
}
.news-box .industry-btn:hover {
  opacity: 1;
  cursor: pointer;
}
.news-box .news-box-content {
  position: absolute;
  bottom: 0;
  background-color: #fff;
  width: 100%;
}
.news-box .news-box-content {
  display: block;
  font-size: 24px;
  line-height: 30px;
  padding: 8px 15px;
  -webkit-box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.15);
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.15);
}
#quote_wrap {
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.news-box .ov-load {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #fff;
}
.news-box .ov-load .text {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -20px;
  margin-left: -50px;
  font-size: 32px;
}
.change-up {
  color: #98a928;
}
.change-up i {
  margin-left: 5px;
  margin-right: 5px;
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}
.change-down {
  color: #df275d;
}
.change-down i {
  margin-left: 5px;
  margin-right: 5px;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
}
.change-none {
  color: #888888;
}
.change-none i {
  margin: 0 10px 0 3px;
}
.muted-i {
  color: #aaaaaa;
  font-style: italic;
}
.vam {
  vertical-align: middle !important;
}
.nw {
  white-space: nowrap;
}
.news-list {
  list-style: none;
  margin: -10px;
  padding: 0;
}
.news-list.ii {
  margin: -10px -15px;
}
.news-list li {
  border-top: 1px solid #eeeeee;
  font-size: 14px;
}
.news-list li.loading {
  padding: 10px;
  font-weight: bold;
}
.news-list.ii li.loading {
  padding: 10px 15px;
}
.news-list li:first-child {
  border-top: 0;
}
.news-list li a {
  padding: 10px;
  display: inline-block;
}
.news-list.ii li a {
  font-size: 18px;
  padding: 7px 15px 0;
}
.news-list.ii li > span {
  display: block;
  font-size: 14px;
  padding: 0 0 0 15px;
  color: #999999;
}
.news-list.ii li > p {
  padding: 10px 15px;
  margin: 0;
}

#industryList ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
#industryList li {
  display: block;
  padding: 0;
  margin: 0 0 10px;
}
.ratecard-btn {
  position: absolute;
  right: 10px;
  top: 9px;
  font-size: 18px;
  color: #666666 !important;
  display: block;
  padding: 1px 6px;
  border-radius: 5px;
  opacity: 0.85;
}
.ratecard-btn:hover {
  opacity: 1;
}
.modal-fullscreen {
  overflow: hidden;
}
.modal-fullscreen .modal-dialog {
  width: auto;
  top: 10px;
  bottom: 10px;
  right: 10px;
  left: 10px;
  position: fixed;
  margin: 0;
}
#rssFeedModal .modal-content,
#modal-ratecard-view .modal-content {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  overflow: hidden;
  height: 100%;
}
#rssFeedModal .modal-header {
  border-bottom: 0;
}
.modal-fullscreen .modal-body {
  overflow: hidden;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 60px;
}
.modal-fullscreen iframe {
  width: 100%;
  height: 100%;
}
#rssFeedModal .load-ov,
#jobData .load-ov,
#ratecardData .load-ov {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #fff;
  z-index: 5;
}
#rssFeedModal .load-ov .text,
#jobData .load-ov .text,
#ratecardData .load-ov .text {
  position: absolute;
  text-align: center;
  width: 100%;
  top: 50%;
  margin-top: -20px;
  font-size: 32px;
}
#jobData .load-ov .text .text-danger,
#ratecardData .load-ov .text .text-danger {
  font-size: 16px;
}
#ratecardData .load-ov {
  top: 48px;
}

#jobDataLocation {
  font-size: 16px;
  font-weight: 400;
  padding: 18px 0 13px;
  font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
  border-bottom: 1px solid #eeeeee;
  margin: -15px -15px 10px;
  color: #666;
}
#jobDataLocation .icon {
  margin-top: -18px;
  margin-bottom: -13px;
  margin-right: 10px;
  padding: 18px 14px 13px 17px;
  display: inline-block;
  border-right: 1px solid #eee;
}
#hs .highcharts-container {
  position: inherit !important;
}
#hs .highcharts-tooltip {
  z-index: 9998 !important;
  margin-top: 30px;
  margin-left: 20px;
}
#hs g.highcharts-tooltip {
  display: none !important;
  opacity: 0 !important;
}
#hs .highcharts-tooltip > span {
  border-radius: 5px;
  padding: 10px;
  background-color: #fff;
  border: 3px solid #c2d646;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}
#hsFilter {
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 25px;
  top: 67px;
  z-index: 1;
}
#hsFilter li {
  float: left;
}
#hsFilter a {
  background-color: #f7f7f7;
  color: #444444;
  display: block;
  padding: 2px 10px;
  margin-left: 5px;
}
#hsFilter li a:hover {
  background-color: #f0f0f0;
}
#hsFilter a.active {
  background-color: #e7f0f9 !important;
  color: #000000 !important;
}

#negoTabs li.active a,
#negoTabs li.active:hover a,
#negoTabs li.active a:hover,
#negoTabs li.active:hover a:hover,
#negoTabs li.active a:focus,
#negoTabs li.active:hover a:focus {
  background-color: #f9f9f9;
  border-bottom-color: #f9f9f9 !important;
}

#recentActivity .dataTables_length {
  top: -50px;
}
