@import "../../../base/colors";

$name-width: 35px;
$value-width: 80px;
$percentage-width: 30px;
$legend-padding: 10px;
$legend-item-height: 16px;
$legend-item-font-size: 12px;

.pt-stored-index-chart {
  & > * {
    text-align: center;
  }

  &__legend-row {
    display: block;
    position: relative;
    text-align: left;
  }
  &__legend-name {
    display: inline-block;
    position: absolute;
    width: $name-width;
    left: 0;
    top: 0;
  }
  &__legend-value {
    display: inline-block;
    position: absolute;
    width: $value-width;
    left: $name-width + $legend-padding;
    top: 0;
  }
  &__legend-percentage {
    display: inline-block;
    position: absolute;
    width: $percentage-width;
    left: $name-width + $value-width + (2 * $legend-padding);
    top: 0;
  }
  &__legend-row,
  &__legend-name,
  &__legend-value,
  &__legend-percentage {
    height: $legend-item-height;
    line-height: $legend-item-height;
    vertical-align: middle;
    font-size: $legend-item-font-size;
  }

  &__leaf-back,
  &__leaf-forward {
    & .fa-chevron-left,
    & .fa-chevron-right {
      display: inline-block;
      position: absolute;
      top: 50%;
      margin-top: -7px;
      font-size: 20px;
      cursor: pointer;
      color: $olive;

      &.active {
        &:hover {
          transform: scale(1.1);
        }

        &:active {
          transform: scale(0.9);
        }
      }

      &.disabled {
        color: $slate;
        cursor: not-allowed;
      }
    }
  }
}
