.progress.progress-xs {
  height: 7px;
}
.progress.progress-sm {
  height: 12px;
}
.table .progress {
  margin-bottom: 0;
}
.progress-bar {
  background-color: #cccccc;
}
.progress-striped .progress-bar {
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
}
.progress-bar-danger {
  background-color: #e74c3c;
}
.progress-striped .progress-bar-danger {
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
}
.progress-bar-success {
  background-color: #2ecc71;
}
.progress-striped .progress-bar-success {
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
}
.progress-bar-info {
  background-color: #3498db;
}
.progress-striped .progress-bar-info {
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
}
.progress-bar-warning {
  background-color: #e67e22;
}
.progress-striped .progress-bar-warning {
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
}
.progress-bar-purple {
  background-color: #9b59b6;
}
.progress-striped .progress-bar-purple {
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
}
.progress-bar-yellow {
  background-color: #f1c40f;
}
.progress-striped .progress-bar-yellow {
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
}
.progress-bar-inverse {
  background-color: #34495e;
}
.progress-striped .progress-bar-inverse {
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
}
.tooltip .tooltip-inner {
  font-size: 12px;
  padding: 5px 10px;
  border-radius: 0;
}
.flat .progress {
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: #f3f3f3;
}
.flat .progress .progress-bar {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.text-success {
  color: #2ecc71;
}
.text-warning {
  color: #f1c40f;
}
.text-danger {
  color: #d14;
}
a.text-danger:hover {
  color: #ca2729;
}
.text-info {
  color: #3498db;
}
.text-purple {
  color: #9b59b6;
}
.well {
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: #f9f9f9;
  color: #888888;
}
.jumbotron {
  background-color: #f2f2f2;
  border: 1px solid #eeeeee;
}
.jumbotron h1,
.jumbotron h2,
.jumbotron h3,
.jumbotron h4,
.jumbotron h5,
.jumbotron h6,
.jumbotron p {
  font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.jumbotron a {
  font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.dropdown-menu li a {
  font-size: 12px;
}
.dropdown-menu li a:hover {
  color: inherit;
  background-color: #eeeeee;
}
.dropdown-primary.dropdown-menu li a:hover,
.dropdown-success.dropdown-menu li a:hover,
.dropdown-warning.dropdown-menu li a:hover,
.dropdown-danger.dropdown-menu li a:hover,
.dropdown-info.dropdown-menu li a:hover,
.dropdown-inverse.dropdown-menu li a:hover,
.dropdown-dark-red.dropdown-menu li a:hover,
.dropdown-dark-green.dropdown-menu li a:hover,
.dropdown-dark-blue.dropdown-menu li a:hover,
.dropdown-pink.dropdown-menu li a:hover,
.dropdown-purple.dropdown-menu li a:hover {
  color: #fff;
}
.dropdown-primary.dropdown-menu li a:hover {
  background-color: #34495e;
}
.dropdown-success.dropdown-menu li a:hover {
  background-color: #2ecc71;
}
.dropdown-warning.dropdown-menu li a:hover {
  background-color: #f39c12;
}
.dropdown-danger.dropdown-menu li a:hover {
  background-color: #e74c3c;
}
.dropdown-info.dropdown-menu li a:hover {
  background-color: #3498db;
}
.dropdown-inverse.dropdown-menu li a:hover {
  background-color: #555555;
}
.dropdown-dark-red.dropdown-menu li a:hover {
  background-color: #c0392b;
}
.dropdown-dark-green.dropdown-menu li a:hover {
  background-color: #27ae60;
}
.dropdown-dark-blue.dropdown-menu li a:hover {
  background-color: #2980b9;
}
.dropdown-pink.dropdown-menu li a:hover {
  background-color: #f09ff2;
}
.dropdown-purple.dropdown-menu li a:hover {
  background-color: #9b59b6;
}
.dropdown-yellow.dropdown-menu li a:hover {
  color: #ab3326;
  background-color: #f1c40f;
}
.dropdown-menu > li > a:hover,
.dropdown-menu .active a,
.dropdown-menu .active a:hover,
.ui-menu .ui-menu-item a.ui-state-focus,
.ui-menu .ui-menu-item a.ui-state-active {
  background-image: none !important;
}
.popover {
  font-size: 13px;
  font-weight: normal;
  color: #666666;
}
.popover .show-more {
  height: 0;
  display: none;
}
.popover .content-big {
  font-size: 14px;
  font-weight: bold;
}
.popover .content-small {
  font-size: 11px;
  color: #777777;
}
.panel-warning .panel-heading,
.panel-info .panel-heading,
.panel-default .panel-heading,
.panel-danger .panel-heading {
  border-bottom: 1px;
}
.panel {
  .panel-heading {
    background-color: #fff;
    font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 16px;
    padding: 10px 15px;
  }
}
pre {
  background-color: #f9f9f9;
  border: 1px solid #eeeeee;
  color: #888888;
  font-size: 11px;
}
.badge-default,
.label-default {
  background-color: #34495e;
}
.badge-default[href]:hover,
.label-default[href]:hover,
.badge-default[href]:focus,
.label-default[href]:focus {
  background-color: #222f3d;
}
.badge-success,
.label-danger {
  background-color: #2ecc71;
}
.badge-success[href]:hover,
.label-danger[href]:hover,
.badge-success[href]:focus,
.label-danger[href]:focus {
  background-color: #25a25a;
}
.badge-warning,
.label-warning {
  background-color: #f39c12;
}
.badge-warning[href]:hover,
.label-warning[href]:hover,
.badge-warning[href]:focus,
.label-warning[href]:focus {
  background-color: #c87f0a;
}
.badge-info,
.label-info {
  background-color: #3498db;
}
.badge-info[href]:hover,
.label-info[href]:hover,
.badge-info[href]:focus,
.label-info[href]:focus {
  background-color: #217dbb;
}
.badge-danger,
.label-danger {
  background-color: #e74c3c;
}
.badge-danger[href]:hover,
.label-danger[href]:hover,
.badge-danger[href]:focus,
.label-danger[href]:focus {
  background-color: #d62c1a;
}
.badge-purple,
.label-purple {
  background-color: #9b59b6;
}
.badge-purple[href]:hover,
.label-purple[href]:hover,
.badge-purple[href]:focus,
.label-purple[href]:focus {
  background-color: #804399;
}
.label-inverse,
.badge-inverse {
  background-color: #555555;
}
.label-inverse[href]:hover,
.badge-inverse[href]:hover,
.label-inverse[href]:focus,
.badge-inverse[href]:focus {
  background-color: #3c3c3c;
}
.label {
  padding: 0.5em 0.8em;
  font-size: 85%;
}
.btn {
  margin: 2px 0;
  border: 0 !important;
}
.input-group-btn .btn {
  margin: 0;
}
.btn.btn-xs,
.btn-group-xs .btn {
  font-size: 10.5px;
  padding: 3px 8px;
}
.btn.btn-square {
  // border-radius: 0;
}
.btn.btn-square-left {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.btn.btn-square-right {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.btn.btn-rounded {
  border-radius: 25px;
}
.btn.btn-default {
  border-width: 1px;
}
.btn.btn-3d {
  border-bottom-width: 3px;
}
.btn.btn-3d:active {
  border-bottom-width: 1px;
  margin-top: 4px;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.btn.btn-icon,
.btn.btn-icon-left {
  position: relative;
}
.btn.btn-icon .fa,
.btn.btn-icon-left .fa {
  text-align: center;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px 10px;
  font-size: 12px;
}
.btn.btn-icon.btn-xs .fa,
.btn.btn-icon-left.btn-xs .fa {
  font-size: 10px;
  padding: 5.5px 10px;
}
.btn.btn-icon.btn-sm .fa,
.btn.btn-icon-left.btn-sm .fa {
  padding: 8px 10px;
}
.btn.btn-icon.btn-lg .fa,
.btn.btn-icon-left.btn-lg .fa {
  font-size: 14px;
  padding: 15px 15px;
}
.btn.btn-icon {
  padding-right: 39px;
}
.btn.btn-icon.btn-lg {
  padding-right: 55px;
}
.btn.btn-icon i {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}
.btn.btn-icon-left {
  padding-left: 39px;
}
.btn.btn-icon-left .fa {
  right: auto;
  left: 0;
}
.btn.btn-icon-left.btn-lg {
  padding-left: 55px;
}
.btn.btn-icon-left i {
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.btn-default {
  color: #444444;
  background-color: #f3f3f3;
  border-color: #f3f3f3;
}
.btn-default:hover,
.btn-default:focus,
.btn-default:active,
.btn-default.active,
.open .dropdown-toggle.btn-default {
  color: #444444;
  background-color: #dfdfdf;
  border-color: #d4d4d4;
}
.btn-default:active,
.btn-default.active,
.open .dropdown-toggle.btn-default {
  background-image: none;
}
.btn-default.disabled,
.btn-default[disabled],
fieldset[disabled] .btn-default,
.btn-default.disabled:hover,
.btn-default[disabled]:hover,
fieldset[disabled] .btn-default:hover,
.btn-default.disabled:focus,
.btn-default[disabled]:focus,
fieldset[disabled] .btn-default:focus,
.btn-default.disabled:active,
.btn-default[disabled]:active,
fieldset[disabled] .btn-default:active,
.btn-default.disabled.active,
.btn-default[disabled].active,
fieldset[disabled] .btn-default.active {
  background-color: #f3f3f3;
  border-color: #f3f3f3;
}
.btn-default .badge {
  color: #f3f3f3;
  background-color: #fff;
}
.btn-default.btn-3d {
  border-bottom: 3px solid #dfdfdf !important;
}
.btn-default:hover,
.btn-default:focus,
.btn-default:active,
.btn-default.active,
.open .dropdown-toggle.btn-default {
  color: #444444;
  background-color: #dfdfdf;
  border-color: #dfdfdf;
}
.btn-default:hover.btn-3d,
.btn-default:focus.btn-3d,
.btn-default:active.btn-3d,
.btn-default.active.btn-3d,
.open .dropdown-toggle.btn-default.btn-3d {
  border-bottom-color: #cdcdcd !important;
}
.btn-default:active,
.btn-default.active,
.open .dropdown-toggle.btn-default {
  background-image: none;
}
.btn-default:active.btn-3d,
.btn-default.active.btn-3d,
.open .dropdown-toggle.btn-default.btn-3d {
  border-bottom-width: 0 !important;
}
.btn-default.disabled,
.btn-default[disabled],
fieldset[disabled] .btn-default,
.btn-default.disabled:hover,
.btn-default[disabled]:hover,
fieldset[disabled] .btn-default:hover,
.btn-default.disabled:focus,
.btn-default[disabled]:focus,
fieldset[disabled] .btn-default:focus,
.btn-default.disabled:active,
.btn-default[disabled]:active,
fieldset[disabled] .btn-default:active,
.btn-default.disabled.active,
.btn-default[disabled].active,
fieldset[disabled] .btn-default.active {
  background-color: #f3f3f3;
  border-color: #f3f3f3;
}
.btn-default.btn-icon i,
.btn-default.btn-icon-left i {
  background-color: #dfdfdf;
}
.btn-default .badge {
  color: #f3f3f3;
  background-color: #fff;
}
.btn-primary {
  color: #ffffff;
  background-color: #34495e;
  border-color: #34495e;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
  color: #ffffff;
  background-color: #253544;
  border-color: #1e2a37;
}
.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
  background-image: none;
}
.btn-primary.disabled,
.btn-primary[disabled],
fieldset[disabled] .btn-primary,
.btn-primary.disabled:hover,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary:hover,
.btn-primary.disabled:focus,
.btn-primary[disabled]:focus,
fieldset[disabled] .btn-primary:focus,
.btn-primary.disabled:active,
.btn-primary[disabled]:active,
fieldset[disabled] .btn-primary:active,
.btn-primary.disabled.active,
.btn-primary[disabled].active,
fieldset[disabled] .btn-primary.active {
  background-color: #34495e;
  border-color: #34495e;
}
.btn-primary .badge {
  color: #34495e;
  background-color: #fff;
}
.btn-primary.btn-3d {
  border-bottom: 3px solid #253544 !important;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
  color: #ffffff;
  background-color: #253544;
  border-color: #253544;
}
.btn-primary:hover.btn-3d,
.btn-primary:focus.btn-3d,
.btn-primary:active.btn-3d,
.btn-primary.active.btn-3d,
.open .dropdown-toggle.btn-primary.btn-3d {
  border-bottom-color: #19232d !important;
}
.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
  background-image: none;
}
.btn-primary:active.btn-3d,
.btn-primary.active.btn-3d,
.open .dropdown-toggle.btn-primary.btn-3d {
  border-bottom-width: 0 !important;
}
.btn-primary.disabled,
.btn-primary[disabled],
fieldset[disabled] .btn-primary,
.btn-primary.disabled:hover,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary:hover,
.btn-primary.disabled:focus,
.btn-primary[disabled]:focus,
fieldset[disabled] .btn-primary:focus,
.btn-primary.disabled:active,
.btn-primary[disabled]:active,
fieldset[disabled] .btn-primary:active,
.btn-primary.disabled.active,
.btn-primary[disabled].active,
fieldset[disabled] .btn-primary.active {
  background-color: #34495e;
  border-color: #34495e;
}
.btn-primary.btn-icon i,
.btn-primary.btn-icon-left i {
  background-color: #253544;
}
.btn-primary .badge {
  color: #34495e;
  background-color: #fff;
}
.btn-success {
  color: #ffffff;
  background-color: #2ecc71;
  border-color: #2ecc71;
}
.btn-success:hover,
.btn-success:focus,
.btn-success:active,
.btn-success.active,
.open .dropdown-toggle.btn-success {
  color: #ffffff;
  background-color: #26ab5f;
  border-color: #239a55;
}
.btn-success:active,
.btn-success.active,
.open .dropdown-toggle.btn-success {
  background-image: none;
}
.btn-success.disabled,
.btn-success[disabled],
fieldset[disabled] .btn-success,
.btn-success.disabled:hover,
.btn-success[disabled]:hover,
fieldset[disabled] .btn-success:hover,
.btn-success.disabled:focus,
.btn-success[disabled]:focus,
fieldset[disabled] .btn-success:focus,
.btn-success.disabled:active,
.btn-success[disabled]:active,
fieldset[disabled] .btn-success:active,
.btn-success.disabled.active,
.btn-success[disabled].active,
fieldset[disabled] .btn-success.active {
  background-color: #2ecc71;
  border-color: #2ecc71;
}
.btn-success .badge {
  color: #2ecc71;
  background-color: #fff;
}
.btn-success.btn-3d {
  border-bottom: 3px solid #26ab5f !important;
}
.btn-success:hover,
.btn-success:focus,
.btn-success:active,
.btn-success.active,
.open .dropdown-toggle.btn-success {
  color: #ffffff;
  background-color: #26ab5f;
  border-color: #26ab5f;
}
.btn-success:hover.btn-3d,
.btn-success:focus.btn-3d,
.btn-success:active.btn-3d,
.btn-success.active.btn-3d,
.open .dropdown-toggle.btn-success.btn-3d {
  border-bottom-color: #208e4e !important;
}
.btn-success:active,
.btn-success.active,
.open .dropdown-toggle.btn-success {
  background-image: none;
}
.btn-success:active.btn-3d,
.btn-success.active.btn-3d,
.open .dropdown-toggle.btn-success.btn-3d {
  border-bottom-width: 0 !important;
}
.btn-success.disabled,
.btn-success[disabled],
fieldset[disabled] .btn-success,
.btn-success.disabled:hover,
.btn-success[disabled]:hover,
fieldset[disabled] .btn-success:hover,
.btn-success.disabled:focus,
.btn-success[disabled]:focus,
fieldset[disabled] .btn-success:focus,
.btn-success.disabled:active,
.btn-success[disabled]:active,
fieldset[disabled] .btn-success:active,
.btn-success.disabled.active,
.btn-success[disabled].active,
fieldset[disabled] .btn-success.active {
  background-color: #2ecc71;
  border-color: #2ecc71;
}
.btn-success.btn-icon i,
.btn-success.btn-icon-left i {
  background-color: #26ab5f;
}
.btn-success .badge {
  color: #2ecc71;
  background-color: #fff;
}
.btn-warning {
  color: #ffffff;
  background-color: #f1c40f;
  border-color: #f1c40f;
}
.btn-warning:hover,
.btn-warning:focus,
.btn-warning:active,
.btn-warning.active,
.open .dropdown-toggle.btn-warning {
  color: #ffffff;
  background-color: #cba50c;
  border-color: #b8960b;
}
.btn-warning:active,
.btn-warning.active,
.open .dropdown-toggle.btn-warning {
  background-image: none;
}
.btn-warning.disabled,
.btn-warning[disabled],
fieldset[disabled] .btn-warning,
.btn-warning.disabled:hover,
.btn-warning[disabled]:hover,
fieldset[disabled] .btn-warning:hover,
.btn-warning.disabled:focus,
.btn-warning[disabled]:focus,
fieldset[disabled] .btn-warning:focus,
.btn-warning.disabled:active,
.btn-warning[disabled]:active,
fieldset[disabled] .btn-warning:active,
.btn-warning.disabled.active,
.btn-warning[disabled].active,
fieldset[disabled] .btn-warning.active {
  background-color: #f1c40f;
  border-color: #f1c40f;
}
.btn-warning .badge {
  color: #f1c40f;
  background-color: #fff;
}
.btn-warning.btn-3d {
  border-bottom: 3px solid #cba50c !important;
}
.btn-warning:hover,
.btn-warning:focus,
.btn-warning:active,
.btn-warning.active,
.open .dropdown-toggle.btn-warning {
  color: #ffffff;
  background-color: #cba50c;
  border-color: #cba50c;
}
.btn-warning:hover.btn-3d,
.btn-warning:focus.btn-3d,
.btn-warning:active.btn-3d,
.btn-warning.active.btn-3d,
.open .dropdown-toggle.btn-warning.btn-3d {
  border-bottom-color: #aa8a0a !important;
}
.btn-warning:active,
.btn-warning.active,
.open .dropdown-toggle.btn-warning {
  background-image: none;
}
.btn-warning:active.btn-3d,
.btn-warning.active.btn-3d,
.open .dropdown-toggle.btn-warning.btn-3d {
  border-bottom-width: 0 !important;
}
.btn-warning.disabled,
.btn-warning[disabled],
fieldset[disabled] .btn-warning,
.btn-warning.disabled:hover,
.btn-warning[disabled]:hover,
fieldset[disabled] .btn-warning:hover,
.btn-warning.disabled:focus,
.btn-warning[disabled]:focus,
fieldset[disabled] .btn-warning:focus,
.btn-warning.disabled:active,
.btn-warning[disabled]:active,
fieldset[disabled] .btn-warning:active,
.btn-warning.disabled.active,
.btn-warning[disabled].active,
fieldset[disabled] .btn-warning.active {
  background-color: #f1c40f;
  border-color: #f1c40f;
}
.btn-warning.btn-icon i,
.btn-warning.btn-icon-left i {
  background-color: #cba50c;
}
.btn-warning .badge {
  color: #f1c40f;
  background-color: #fff;
}
.btn-info {
  color: #ffffff;
  background-color: #3498db;
  border-color: #3498db;
}
.btn-info:hover,
.btn-info:focus,
.btn-info:active,
.btn-info.active,
.open .dropdown-toggle.btn-info {
  color: #ffffff;
  background-color: #2383c4;
  border-color: #2077b2;
}
.btn-info:active,
.btn-info.active,
.open .dropdown-toggle.btn-info {
  background-image: none;
}
.btn-info.disabled,
.btn-info[disabled],
fieldset[disabled] .btn-info,
.btn-info.disabled:hover,
.btn-info[disabled]:hover,
fieldset[disabled] .btn-info:hover,
.btn-info.disabled:focus,
.btn-info[disabled]:focus,
fieldset[disabled] .btn-info:focus,
.btn-info.disabled:active,
.btn-info[disabled]:active,
fieldset[disabled] .btn-info:active,
.btn-info.disabled.active,
.btn-info[disabled].active,
fieldset[disabled] .btn-info.active {
  background-color: #3498db;
  border-color: #3498db;
}
.btn-info .badge {
  color: #3498db;
  background-color: #fff;
}
.btn-info.btn-3d {
  border-bottom: 3px solid #2383c4 !important;
}
.btn-info:hover,
.btn-info:focus,
.btn-info:active,
.btn-info.active,
.open .dropdown-toggle.btn-info {
  color: #ffffff;
  background-color: #2383c4;
  border-color: #2383c4;
}
.btn-info:hover.btn-3d,
.btn-info:focus.btn-3d,
.btn-info:active.btn-3d,
.btn-info.active.btn-3d,
.open .dropdown-toggle.btn-info.btn-3d {
  border-bottom-color: #1d6fa5 !important;
}
.btn-info:active,
.btn-info.active,
.open .dropdown-toggle.btn-info {
  background-image: none;
}
.btn-info:active.btn-3d,
.btn-info.active.btn-3d,
.open .dropdown-toggle.btn-info.btn-3d {
  border-bottom-width: 0 !important;
}
.btn-info.disabled,
.btn-info[disabled],
fieldset[disabled] .btn-info,
.btn-info.disabled:hover,
.btn-info[disabled]:hover,
fieldset[disabled] .btn-info:hover,
.btn-info.disabled:focus,
.btn-info[disabled]:focus,
fieldset[disabled] .btn-info:focus,
.btn-info.disabled:active,
.btn-info[disabled]:active,
fieldset[disabled] .btn-info:active,
.btn-info.disabled.active,
.btn-info[disabled].active,
fieldset[disabled] .btn-info.active {
  background-color: #3498db;
  border-color: #3498db;
}
.btn-info.btn-icon i,
.btn-info.btn-icon-left i {
  background-color: #2383c4;
}
.btn-info .badge {
  color: #3498db;
  background-color: #fff;
}
.btn-danger {
  color: #ffffff;
  background-color: #e74c3c;
  border-color: #e74c3c;
}
.btn-danger:hover,
.btn-danger:focus,
.btn-danger:active,
.btn-danger.active,
.open .dropdown-toggle.btn-danger {
  color: #ffffff;
  background-color: #df2e1b;
  border-color: #cd2a19;
}
.btn-danger:active,
.btn-danger.active,
.open .dropdown-toggle.btn-danger {
  background-image: none;
}
.btn-danger.disabled,
.btn-danger[disabled],
fieldset[disabled] .btn-danger,
.btn-danger.disabled:hover,
.btn-danger[disabled]:hover,
fieldset[disabled] .btn-danger:hover,
.btn-danger.disabled:focus,
.btn-danger[disabled]:focus,
fieldset[disabled] .btn-danger:focus,
.btn-danger.disabled:active,
.btn-danger[disabled]:active,
fieldset[disabled] .btn-danger:active,
.btn-danger.disabled.active,
.btn-danger[disabled].active,
fieldset[disabled] .btn-danger.active {
  background-color: #e74c3c;
  border-color: #e74c3c;
}
.btn-danger .badge {
  color: #e74c3c;
  background-color: #fff;
}
.btn-danger.btn-3d {
  border-bottom: 3px solid #df2e1b !important;
}
.btn-danger:hover,
.btn-danger:focus,
.btn-danger:active,
.btn-danger.active,
.open .dropdown-toggle.btn-danger {
  color: #ffffff;
  background-color: #df2e1b;
  border-color: #df2e1b;
}
.btn-danger:hover.btn-3d,
.btn-danger:focus.btn-3d,
.btn-danger:active.btn-3d,
.btn-danger.active.btn-3d,
.open .dropdown-toggle.btn-danger.btn-3d {
  border-bottom-color: #bf2718 !important;
}
.btn-danger:active,
.btn-danger.active,
.open .dropdown-toggle.btn-danger {
  background-image: none;
}
.btn-danger:active.btn-3d,
.btn-danger.active.btn-3d,
.open .dropdown-toggle.btn-danger.btn-3d {
  border-bottom-width: 0 !important;
}
.btn-danger.disabled,
.btn-danger[disabled],
fieldset[disabled] .btn-danger,
.btn-danger.disabled:hover,
.btn-danger[disabled]:hover,
fieldset[disabled] .btn-danger:hover,
.btn-danger.disabled:focus,
.btn-danger[disabled]:focus,
fieldset[disabled] .btn-danger:focus,
.btn-danger.disabled:active,
.btn-danger[disabled]:active,
fieldset[disabled] .btn-danger:active,
.btn-danger.disabled.active,
.btn-danger[disabled].active,
fieldset[disabled] .btn-danger.active {
  background-color: #e74c3c;
  border-color: #e74c3c;
}
.btn-danger.btn-icon i,
.btn-danger.btn-icon-left i {
  background-color: #df2e1b;
}
.btn-danger .badge {
  color: #e74c3c;
  background-color: #fff;
}
.btn-pink {
  color: #ffffff;
  background-color: #f09ff2;
  border-color: #f09ff2;
}
.btn-pink:hover,
.btn-pink:focus,
.btn-pink:active,
.btn-pink.active,
.open .dropdown-toggle.btn-pink {
  color: #ffffff;
  background-color: #ea7bed;
  border-color: #e869eb;
}
.btn-pink:active,
.btn-pink.active,
.open .dropdown-toggle.btn-pink {
  background-image: none;
}
.btn-pink.disabled,
.btn-pink[disabled],
fieldset[disabled] .btn-pink,
.btn-pink.disabled:hover,
.btn-pink[disabled]:hover,
fieldset[disabled] .btn-pink:hover,
.btn-pink.disabled:focus,
.btn-pink[disabled]:focus,
fieldset[disabled] .btn-pink:focus,
.btn-pink.disabled:active,
.btn-pink[disabled]:active,
fieldset[disabled] .btn-pink:active,
.btn-pink.disabled.active,
.btn-pink[disabled].active,
fieldset[disabled] .btn-pink.active {
  background-color: #f09ff2;
  border-color: #f09ff2;
}
.btn-pink .badge {
  color: #f09ff2;
  background-color: #fff;
}
.btn-pink.btn-3d {
  border-bottom: 3px solid #ea7bed !important;
}
.btn-pink:hover,
.btn-pink:focus,
.btn-pink:active,
.btn-pink.active,
.open .dropdown-toggle.btn-pink {
  color: #ffffff;
  background-color: #ea7bed;
  border-color: #ea7bed;
}
.btn-pink:hover.btn-3d,
.btn-pink:focus.btn-3d,
.btn-pink:active.btn-3d,
.btn-pink.active.btn-3d,
.open .dropdown-toggle.btn-pink.btn-3d {
  border-bottom-color: #e55ce9 !important;
}
.btn-pink:active,
.btn-pink.active,
.open .dropdown-toggle.btn-pink {
  background-image: none;
}
.btn-pink:active.btn-3d,
.btn-pink.active.btn-3d,
.open .dropdown-toggle.btn-pink.btn-3d {
  border-bottom-width: 0 !important;
}
.btn-pink.disabled,
.btn-pink[disabled],
fieldset[disabled] .btn-pink,
.btn-pink.disabled:hover,
.btn-pink[disabled]:hover,
fieldset[disabled] .btn-pink:hover,
.btn-pink.disabled:focus,
.btn-pink[disabled]:focus,
fieldset[disabled] .btn-pink:focus,
.btn-pink.disabled:active,
.btn-pink[disabled]:active,
fieldset[disabled] .btn-pink:active,
.btn-pink.disabled.active,
.btn-pink[disabled].active,
fieldset[disabled] .btn-pink.active {
  background-color: #f09ff2;
  border-color: #f09ff2;
}
.btn-pink.btn-icon i,
.btn-pink.btn-icon-left i {
  background-color: #ea7bed;
}
.btn-pink .badge {
  color: #f09ff2;
  background-color: #fff;
}
.btn-purple {
  color: #ffffff;
  background-color: #9b59b6;
  border-color: #9b59b6;
}
.btn-purple:hover,
.btn-purple:focus,
.btn-purple:active,
.btn-purple.active,
.open .dropdown-toggle.btn-purple {
  color: #ffffff;
  background-color: #8646a0;
  border-color: #7a4092;
}
.btn-purple:active,
.btn-purple.active,
.open .dropdown-toggle.btn-purple {
  background-image: none;
}
.btn-purple.disabled,
.btn-purple[disabled],
fieldset[disabled] .btn-purple,
.btn-purple.disabled:hover,
.btn-purple[disabled]:hover,
fieldset[disabled] .btn-purple:hover,
.btn-purple.disabled:focus,
.btn-purple[disabled]:focus,
fieldset[disabled] .btn-purple:focus,
.btn-purple.disabled:active,
.btn-purple[disabled]:active,
fieldset[disabled] .btn-purple:active,
.btn-purple.disabled.active,
.btn-purple[disabled].active,
fieldset[disabled] .btn-purple.active {
  background-color: #9b59b6;
  border-color: #9b59b6;
}
.btn-purple .badge {
  color: #9b59b6;
  background-color: #fff;
}
.btn-purple.btn-3d {
  border-bottom: 3px solid #8646a0 !important;
}
.btn-purple:hover,
.btn-purple:focus,
.btn-purple:active,
.btn-purple.active,
.open .dropdown-toggle.btn-purple {
  color: #ffffff;
  background-color: #8646a0;
  border-color: #8646a0;
}
.btn-purple:hover.btn-3d,
.btn-purple:focus.btn-3d,
.btn-purple:active.btn-3d,
.btn-purple.active.btn-3d,
.open .dropdown-toggle.btn-purple.btn-3d {
  border-bottom-color: #713b87 !important;
}
.btn-purple:active,
.btn-purple.active,
.open .dropdown-toggle.btn-purple {
  background-image: none;
}
.btn-purple:active.btn-3d,
.btn-purple.active.btn-3d,
.open .dropdown-toggle.btn-purple.btn-3d {
  border-bottom-width: 0 !important;
}
.btn-purple.disabled,
.btn-purple[disabled],
fieldset[disabled] .btn-purple,
.btn-purple.disabled:hover,
.btn-purple[disabled]:hover,
fieldset[disabled] .btn-purple:hover,
.btn-purple.disabled:focus,
.btn-purple[disabled]:focus,
fieldset[disabled] .btn-purple:focus,
.btn-purple.disabled:active,
.btn-purple[disabled]:active,
fieldset[disabled] .btn-purple:active,
.btn-purple.disabled.active,
.btn-purple[disabled].active,
fieldset[disabled] .btn-purple.active {
  background-color: #9b59b6;
  border-color: #9b59b6;
}
.btn-purple.btn-icon i,
.btn-purple.btn-icon-left i {
  background-color: #8646a0;
}
.btn-purple .badge {
  color: #9b59b6;
  background-color: #fff;
}
.btn-inverse {
  color: #ffffff;
  background-color: #34495e;
  border-color: #34495e;
}
.btn-inverse:hover,
.btn-inverse:focus,
.btn-inverse:active,
.btn-inverse.active,
.open .dropdown-toggle.btn-inverse {
  color: #ffffff;
  background-color: #253544;
  border-color: #1e2a37;
}
.btn-inverse:active,
.btn-inverse.active,
.open .dropdown-toggle.btn-inverse {
  background-image: none;
}
.btn-inverse.disabled,
.btn-inverse[disabled],
fieldset[disabled] .btn-inverse,
.btn-inverse.disabled:hover,
.btn-inverse[disabled]:hover,
fieldset[disabled] .btn-inverse:hover,
.btn-inverse.disabled:focus,
.btn-inverse[disabled]:focus,
fieldset[disabled] .btn-inverse:focus,
.btn-inverse.disabled:active,
.btn-inverse[disabled]:active,
fieldset[disabled] .btn-inverse:active,
.btn-inverse.disabled.active,
.btn-inverse[disabled].active,
fieldset[disabled] .btn-inverse.active {
  background-color: #34495e;
  border-color: #34495e;
}
.btn-inverse .badge {
  color: #34495e;
  background-color: #fff;
}
.btn-inverse.btn-3d {
  border-bottom: 3px solid #253544 !important;
}
.btn-inverse:hover,
.btn-inverse:focus,
.btn-inverse:active,
.btn-inverse.active,
.open .dropdown-toggle.btn-inverse {
  color: #ffffff;
  background-color: #253544;
  border-color: #253544;
}
.btn-inverse:hover.btn-3d,
.btn-inverse:focus.btn-3d,
.btn-inverse:active.btn-3d,
.btn-inverse.active.btn-3d,
.open .dropdown-toggle.btn-inverse.btn-3d {
  border-bottom-color: #19232d !important;
}
.btn-inverse:active,
.btn-inverse.active,
.open .dropdown-toggle.btn-inverse {
  background-image: none;
}
.btn-inverse:active.btn-3d,
.btn-inverse.active.btn-3d,
.open .dropdown-toggle.btn-inverse.btn-3d {
  border-bottom-width: 0 !important;
}
.btn-inverse.disabled,
.btn-inverse[disabled],
fieldset[disabled] .btn-inverse,
.btn-inverse.disabled:hover,
.btn-inverse[disabled]:hover,
fieldset[disabled] .btn-inverse:hover,
.btn-inverse.disabled:focus,
.btn-inverse[disabled]:focus,
fieldset[disabled] .btn-inverse:focus,
.btn-inverse.disabled:active,
.btn-inverse[disabled]:active,
fieldset[disabled] .btn-inverse:active,
.btn-inverse.disabled.active,
.btn-inverse[disabled].active,
fieldset[disabled] .btn-inverse.active {
  background-color: #34495e;
  border-color: #34495e;
}
.btn-inverse.btn-icon i,
.btn-inverse.btn-icon-left i {
  background-color: #253544;
}
.btn-inverse .badge {
  color: #34495e;
  background-color: #fff;
}
.btn-yellow {
  color: #f39c12;
  background-color: #f1c40f;
  border-color: #f1c40f;
}
.btn-yellow:hover,
.btn-yellow:focus,
.btn-yellow:active,
.btn-yellow.active,
.open .dropdown-toggle.btn-yellow {
  color: #f39c12;
  background-color: #cba50c;
  border-color: #b8960b;
}
.btn-yellow:active,
.btn-yellow.active,
.open .dropdown-toggle.btn-yellow {
  background-image: none;
}
.btn-yellow.disabled,
.btn-yellow[disabled],
fieldset[disabled] .btn-yellow,
.btn-yellow.disabled:hover,
.btn-yellow[disabled]:hover,
fieldset[disabled] .btn-yellow:hover,
.btn-yellow.disabled:focus,
.btn-yellow[disabled]:focus,
fieldset[disabled] .btn-yellow:focus,
.btn-yellow.disabled:active,
.btn-yellow[disabled]:active,
fieldset[disabled] .btn-yellow:active,
.btn-yellow.disabled.active,
.btn-yellow[disabled].active,
fieldset[disabled] .btn-yellow.active {
  background-color: #f1c40f;
  border-color: #f1c40f;
}
.btn-yellow .badge {
  color: #f1c40f;
  background-color: #fff;
}
.btn-yellow.btn-3d {
  border-bottom: 3px solid #cba50c !important;
}
.btn-yellow:hover,
.btn-yellow:focus,
.btn-yellow:active,
.btn-yellow.active,
.open .dropdown-toggle.btn-yellow {
  color: #f39c12;
  background-color: #cba50c;
  border-color: #cba50c;
}
.btn-yellow:hover.btn-3d,
.btn-yellow:focus.btn-3d,
.btn-yellow:active.btn-3d,
.btn-yellow.active.btn-3d,
.open .dropdown-toggle.btn-yellow.btn-3d {
  border-bottom-color: #aa8a0a !important;
}
.btn-yellow:active,
.btn-yellow.active,
.open .dropdown-toggle.btn-yellow {
  background-image: none;
}
.btn-yellow:active.btn-3d,
.btn-yellow.active.btn-3d,
.open .dropdown-toggle.btn-yellow.btn-3d {
  border-bottom-width: 0 !important;
}
.btn-yellow.disabled,
.btn-yellow[disabled],
fieldset[disabled] .btn-yellow,
.btn-yellow.disabled:hover,
.btn-yellow[disabled]:hover,
fieldset[disabled] .btn-yellow:hover,
.btn-yellow.disabled:focus,
.btn-yellow[disabled]:focus,
fieldset[disabled] .btn-yellow:focus,
.btn-yellow.disabled:active,
.btn-yellow[disabled]:active,
fieldset[disabled] .btn-yellow:active,
.btn-yellow.disabled.active,
.btn-yellow[disabled].active,
fieldset[disabled] .btn-yellow.active {
  background-color: #f1c40f;
  border-color: #f1c40f;
}
.btn-yellow.btn-icon i,
.btn-yellow.btn-icon-left i {
  background-color: #cba50c;
}
.btn-yellow .badge {
  color: #f1c40f;
  background-color: #fff;
}
.btn-success-alt {
  color: #2ecc71;
  background-color: transparent;
  border: 1px solid #2ecc71 !important;
}
.btn-success-alt:hover,
.btn-success-alt:focus,
.btn-success-alt:active,
.btn-success-alt.active,
.open .dropdown-toggle.btn-success-alt {
  color: #1b7943;
  border-color: #1b7943;
}
.btn-success-alt:active,
.btn-success-alt.active,
.open .dropdown-toggle.btn-success-alt {
  background-image: none;
}
.btn-success-alt.disabled,
.btn-success-alt[disabled],
fieldset[disabled] .btn-success-alt,
.btn-success-alt.disabled:hover,
.btn-success-alt[disabled]:hover,
fieldset[disabled] .btn-success-alt:hover,
.btn-success-alt.disabled:focus,
.btn-success-alt[disabled]:focus,
fieldset[disabled] .btn-success-alt:focus,
.btn-success-alt.disabled:active,
.btn-success-alt[disabled]:active,
fieldset[disabled] .btn-success-alt:active,
.btn-success-alt.disabled.active,
.btn-success-alt[disabled].active,
fieldset[disabled] .btn-success-alt.active {
  background-color: transparent;
  border-color: #2ecc71;
}
.btn-info-alt {
  color: #3498db;
  background-color: transparent;
  border: 1px solid #3498db !important;
}
.btn-info-alt:hover,
.btn-info-alt:focus,
.btn-info-alt:active,
.btn-info-alt.active,
.open .dropdown-toggle.btn-info-alt {
  color: #196090;
  border-color: #196090;
}
.btn-info-alt:active,
.btn-info-alt.active,
.open .dropdown-toggle.btn-info-alt {
  background-image: none;
}
.btn-info-alt.disabled,
.btn-info-alt[disabled],
fieldset[disabled] .btn-info-alt,
.btn-info-alt.disabled:hover,
.btn-info-alt[disabled]:hover,
fieldset[disabled] .btn-info-alt:hover,
.btn-info-alt.disabled:focus,
.btn-info-alt[disabled]:focus,
fieldset[disabled] .btn-info-alt:focus,
.btn-info-alt.disabled:active,
.btn-info-alt[disabled]:active,
fieldset[disabled] .btn-info-alt:active,
.btn-info-alt.disabled.active,
.btn-info-alt[disabled].active,
fieldset[disabled] .btn-info-alt.active {
  background-color: transparent;
  border-color: #3498db;
}
.btn-warning-alt {
  color: #f0ad4e;
  background-color: transparent;
  border: 1px solid #f0ad4e !important;
}
.btn-warning-alt:hover,
.btn-warning-alt:focus,
.btn-warning-alt:active,
.btn-warning-alt.active,
.open .dropdown-toggle.btn-warning-alt {
  color: #c77c11;
  border-color: #c77c11;
}
.btn-warning-alt:active,
.btn-warning-alt.active,
.open .dropdown-toggle.btn-warning-alt {
  background-image: none;
}
.btn-warning-alt.disabled,
.btn-warning-alt[disabled],
fieldset[disabled] .btn-warning-alt,
.btn-warning-alt.disabled:hover,
.btn-warning-alt[disabled]:hover,
fieldset[disabled] .btn-warning-alt:hover,
.btn-warning-alt.disabled:focus,
.btn-warning-alt[disabled]:focus,
fieldset[disabled] .btn-warning-alt:focus,
.btn-warning-alt.disabled:active,
.btn-warning-alt[disabled]:active,
fieldset[disabled] .btn-warning-alt:active,
.btn-warning-alt.disabled.active,
.btn-warning-alt[disabled].active,
fieldset[disabled] .btn-warning-alt.active {
  background-color: transparent;
  border-color: #f0ad4e;
}
.btn-danger-alt {
  color: #e74c3c;
  background-color: transparent;
  border: 1px solid #e74c3c !important;
}
.btn-danger-alt:hover,
.btn-danger-alt:focus,
.btn-danger-alt:active,
.btn-danger-alt.active,
.open .dropdown-toggle.btn-danger-alt {
  color: #a82315;
  border-color: #a82315;
}
.btn-danger-alt:active,
.btn-danger-alt.active,
.open .dropdown-toggle.btn-danger-alt {
  background-image: none;
}
.btn-danger-alt.disabled,
.btn-danger-alt[disabled],
fieldset[disabled] .btn-danger-alt,
.btn-danger-alt.disabled:hover,
.btn-danger-alt[disabled]:hover,
fieldset[disabled] .btn-danger-alt:hover,
.btn-danger-alt.disabled:focus,
.btn-danger-alt[disabled]:focus,
fieldset[disabled] .btn-danger-alt:focus,
.btn-danger-alt.disabled:active,
.btn-danger-alt[disabled]:active,
fieldset[disabled] .btn-danger-alt:active,
.btn-danger-alt.disabled.active,
.btn-danger-alt[disabled].active,
fieldset[disabled] .btn-danger-alt.active {
  background-color: transparent;
  border-color: #e74c3c;
}
.btn-purple-alt {
  color: #9b59b6;
  background-color: transparent;
  border: 1px solid #9b59b6 !important;
}
.btn-purple-alt:hover,
.btn-purple-alt:focus,
.btn-purple-alt:active,
.btn-purple-alt.active,
.open .dropdown-toggle.btn-purple-alt {
  color: #623475;
  border-color: #623475;
}
.btn-purple-alt:active,
.btn-purple-alt.active,
.open .dropdown-toggle.btn-purple-alt {
  background-image: none;
}
.btn-purple-alt.disabled,
.btn-purple-alt[disabled],
fieldset[disabled] .btn-purple-alt,
.btn-purple-alt.disabled:hover,
.btn-purple-alt[disabled]:hover,
fieldset[disabled] .btn-purple-alt:hover,
.btn-purple-alt.disabled:focus,
.btn-purple-alt[disabled]:focus,
fieldset[disabled] .btn-purple-alt:focus,
.btn-purple-alt.disabled:active,
.btn-purple-alt[disabled]:active,
fieldset[disabled] .btn-purple-alt:active,
.btn-purple-alt.disabled.active,
.btn-purple-alt[disabled].active,
fieldset[disabled] .btn-purple-alt.active {
  background-color: transparent;
  border-color: #9b59b6;
}
.btn-inverse-alt {
  color: #34495e;
  background-color: transparent;
  border: 1px solid #34495e !important;
}
.btn-inverse-alt:hover,
.btn-inverse-alt:focus,
.btn-inverse-alt:active,
.btn-inverse-alt.active,
.open .dropdown-toggle.btn-inverse-alt {
  color: #10161c;
  border-color: #10161c;
}
.btn-inverse-alt:active,
.btn-inverse-alt.active,
.open .dropdown-toggle.btn-inverse-alt {
  background-image: none;
}
.btn-inverse-alt.disabled,
.btn-inverse-alt[disabled],
fieldset[disabled] .btn-inverse-alt,
.btn-inverse-alt.disabled:hover,
.btn-inverse-alt[disabled]:hover,
fieldset[disabled] .btn-inverse-alt:hover,
.btn-inverse-alt.disabled:focus,
.btn-inverse-alt[disabled]:focus,
fieldset[disabled] .btn-inverse-alt:focus,
.btn-inverse-alt.disabled:active,
.btn-inverse-alt[disabled]:active,
fieldset[disabled] .btn-inverse-alt:active,
.btn-inverse-alt.disabled.active,
.btn-inverse-alt[disabled].active,
fieldset[disabled] .btn-inverse-alt.active {
  background-color: transparent;
  border-color: #34495e;
}
.btn-white-alt {
  color: #ffffff;
  background-color: transparent;
  border: 1px solid #ffffff !important;
}
.btn-white-alt:hover,
.btn-white-alt:focus,
.btn-white-alt:active,
.btn-white-alt.active,
.open .dropdown-toggle.btn-white-alt {
  color: #cccccc;
  border-color: #cccccc;
}
.btn-white-alt:active,
.btn-white-alt.active,
.open .dropdown-toggle.btn-white-alt {
  background-image: none;
}
.btn-white-alt.disabled,
.btn-white-alt[disabled],
fieldset[disabled] .btn-white-alt,
.btn-white-alt.disabled:hover,
.btn-white-alt[disabled]:hover,
fieldset[disabled] .btn-white-alt:hover,
.btn-white-alt.disabled:focus,
.btn-white-alt[disabled]:focus,
fieldset[disabled] .btn-white-alt:focus,
.btn-white-alt.disabled:active,
.btn-white-alt[disabled]:active,
fieldset[disabled] .btn-white-alt:active,
.btn-white-alt.disabled.active,
.btn-white-alt[disabled].active,
fieldset[disabled] .btn-white-alt.active {
  background-color: transparent;
  border-color: #ffffff;
}
.btn-pink .caret,
.btn-purple .caret,
.btn-inverse .caret {
  border-top-color: #ffffff;
}
.dropup .btn-pink .caret,
.dropup .btn-purple .caret,
.dropup .btn-inverse .caret {
  border-bottom-color: #ffffff;
}
.btn-yellow .caret {
  border-top-color: #ab3326;
}
.dropup .btn-yellow .caret {
  border-bottom-color: #ab3326;
}
.thumbnail {
  border-color: #eeeeee;
}
.modal-open > #app {
  -webkit-filter: blur(3px);
  filter: blur(3px);
}
.modal-backdrop {
  opacity: 0.5 !important;
  overflow: hidden !important;
  background-color: rgba(240, 240, 240, 0.8);
}
.ie8 .modal-backdrop {
  background-color: transparent;
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#99ffffff, endColorstr=#99ffffff)";
}
.ie8 .modal-dialog {
  border: 1px solid #dddddd;
}
.modal-header {
  height: auto;
  padding: 15px 15px;
  border-radius: 6px 6px 0 0;
  border-bottom-color: #eeeeee;
}
.modal-header h1,
.modal-header h2,
.modal-header h3,
.modal-header h4,
.modal-header h5 {
  margin: 5px 0;
  font-size: 16px;
  font-weight: bold;
  color: #666666;
}
.modal-header .close {
  margin-top: 2px;
}
.modal-footer {
  padding: 15px 25px;
  border-top-color: #eeeeee;
  margin-top: 0;
}
.input-group {
  padding: 10px 0;
}
.input-group-addon {
  border-color: #eeeeee;
  background-color: #f8f8f8;
}
.table-bordered > thead > tr > th,
.table-bordered > thead > tr > td {
  border-bottom-width: 1px;
}
.table {
  thead > tr > th {
    border-bottom-width: 1px;
    background-color: #f9f9f9;
    color: #888888;
    padding: 10px;
  }
  thead,
  tbody {
    tr {
      th,
      td {
        border-color: #e6e6e6;
        font-size: 14px;
      }
    }
  }
}

.custom-form .btn,
.custom-form .btn:hover,
.custom-form .btn:active {
  //border-width:1px !important;
  //padding: 7px 12px ;
}
.custom-form .form-group .input-group-btn:last-child > .btn:hover,
.custom-form .form-group .input-group-btn:last-child > .btn-group:hover {
  // border:1px solid #A2B624 !important;
}
