@import "../../../base/colors";
@import "../../../utils/mixins";

.pt-index-trend-values {
  &__container {
    @include shadow-3dp;
    position: relative;
    border: 1px solid $gray-border;
    border-radius: 5px;
    background: $white;
    margin-top: 75px;
    padding: 25px;
    min-height: 50px;
  }

  &__icon {
    @include pt-size-mixin(100px);
    display: block;
    border-radius: 50%;
    margin: 0 auto;
    margin-top: -75px;
    font-size: 40px;
    text-align: center;
    vertical-align: middle;
    background-color: #57a544;

    .fa {
      display: inline-block;
      vertical-align: middle;
      height: 100px;
      line-height: 100px;
      color: $white;
    }
  }

  &__title {
    letter-spacing: 2px;
    text-transform: uppercase;
    text-align: center;
    margin: 10px 0 !important;
  }

  &__variance {
    margin-right: 10px;
  }

  &__values-table {
    border: 1px solid $gray-border;
    border-radius: 3px;

    & table {
      width: 100%;
    }

    & tr {
      border-bottom: 1px solid $gray-border;

      &:last-child {
        border-bottom: 0;
      }
    }

    & td {
      padding: 10px;
      vertical-align: middle;
      font-size: 14px;

      &:first-child {
        white-space: nowrap;
      }
      &:last-child {
        text-align: right;

        & b {
          padding: 0 7px;
          background-color: #777;
          border-radius: 20px;
          color: $white;
        }
      }
    }
  }

  .list-group {
    margin-bottom: 0;
  }
  .list-group-item {
    margin-bottom: 0;
    line-height: 23px;
    border-bottom-width: 0;

    &:last-child {
      border-bottom-width: 1px;
    }
  }
}
