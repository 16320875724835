@import "../../base/colors";

.pt-enum-filter {
  & > * {
    margin-top: 10px;
    margin-left: 10px;

    &:first-child {
      margin-left: 0;
    }
  }

  &__shortcuts-block {
    & > * {
      display: inline-block;
      cursor: pointer;
      color: lighten($gray-font, 20%);

      &:nth-child(1) .svg-inline--fa {
        color: lighten($green-bright, 20%);
        margin-right: 5px;
      }
      &:nth-child(1):hover {
        color: $gray-font;

        & .svg-inline--fa {
          color: $green-bright;
        }
      }

      &:nth-child(2) .svg-inline--fa {
        color: lighten($red-bright, 20%);
        margin-left: 10px;
        margin-right: 5px;
      }
      &:nth-child(2):hover {
        color: $gray-font;

        .svg-inline--fa {
          color: $red-bright;
        }
      }

      &:nth-child(1):active,
      &:nth-child(2):active {
        transform: scale(1.02);
      }
    }
  }
}
