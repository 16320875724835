@use "sass:math";

@import "../../../base/colors";
@import "../../../base/labor_index/typography";
@import "../../../utils/mixins";

$icon-height: 12px;

.pt-panel {
  clear: both;
  margin-bottom: 16px;
  position: relative;
  @include pt-raised-shadow();

  &__heading {
    padding: 20px 15px 10px;
    background: $gray-font;
    box-shadow: inset 0 -4px 0 0 $lime-dark;
  }

  &__heading-title {
    margin: 0;
    color: $white;
    font-size: 24px;
    line-height: 30px;
    font-family: $ff-source-semibold;
  }
}

.pt-news-list {
  list-style: none;
  padding: 0;
  margin: 0;

  &__item {
    padding: 9px 20px 9px 15px;
    background: #f5f5f5;
    box-shadow: inset 0 1px 3px 0 $white, inset 0 -1px 0 0 #c9c9c9;
    font-family: $ff-source-regular;
    font-size: 16px;
    color: $gray-font;
    line-height: 20px;
    position: relative;

    a {
      color: $gray-font !important;
      text-decoration: none;
    }

    &__icon {
      display: none;
      font-size: 12px;
      line-height: $icon-height;

      position: absolute;
      top: 50%;
      right: 7px;
      height: $icon-height;
      margin-top: -(math.div($icon-height, 2));
    }

    &:hover {
      background: $gray-light;
      box-shadow: inset 5px 0 0 0 $lime-dark, inset 0 1px 3px 0 $white,
        inset 0 -1px 0 0 #c9c9c9;

      a {
        color: $lime-dark !important;
      }

      .pt-news-list__item__icon {
        display: block;
        color: $lime-darker;
      }
    }
  }
}

.pt-list-more-btn {
  background: $gray-light;
  box-shadow: inset 0 1px 0 0 $white;
  text-align: center;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  font-family: $ff-source-regular;
  font-size: 16px;
  color: #9e9e9e;

  &__icon {
    font-size: 12px;
    color: #9e9e9e;
    line-height: $icon-height;
  }
}
