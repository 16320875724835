$pt-store-base-color: #3d6deb;

.ps-pack-pdf-upsell-page {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;

  &__custom-lib-title {
    flex: 0 0 10%;
    display: flex;
    align-items: flex-end;
    padding: 0 10px;
    font-size: 46px;
  }

  &__custom-lib-image {
    flex: 0 0 35%;
    display: flex;
    align-items: center;
    position: relative;

    img {
      display: block;
      width: 100%;
      max-height: 100%;
    }
  }
  &__top-email-to {
    display: block;
    position: absolute;
    bottom: 3px;
    left: 38px;
    font-size: 16px;
    color: $pt-store-base-color !important;
  }

  &__private-index-title {
    flex: 0 0 10%;
    display: flex;
    align-items: center;
    padding: 0 10px;
    font-size: 46px;
  }

  &__private-index-description {
    flex: 0 0 10%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 0 10px;
    font-size: 18px;

    a {
      font-size: 18px;
      color: $pt-store-base-color !important;
    }
  }

  &__private-index-image {
    flex: 0 0 35%;
    display: flex;
    align-items: center;

    img {
      display: block;
      width: 100%;
      max-width: 100%;
    }
  }
}
