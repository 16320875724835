@import "../ratecards";
@import "../pt_ui";
@import "../variables";

@import "./components/multi_select";
@import "./components/a4_page_container";
@import "./components/pack_pdf_cover_page";
@import "./components/pack_pdf_summary_page";
@import "./components/pack_pdf_results_page";
@import "./components/exp_levels_legend";
@import "./components/pack_pdf_branding_page";
@import "./components/pack_pdf_upsell_page";
@import "./components/pack_jobs_list";

@import "./partials/pack_pdf_view";
@import "./partials/pack_details_page";

.ps-searches-modal {
  font-size: initial;
  margin: -20px;

  &__top-controls {
    display: flex;
    padding: 15px 20px;
    border-bottom: 1px solid $rc-element-border-color;

    & > * {
      &:first-child {
        flex-grow: 1;
      }
      &:last-child {
        flex-grow: 0;
        flex-shrink: 0;
        align-self: flex-end;
      }
    }
  }
  &__counters {
    color: $rc-green6;
  }

  &__searches-list {
    min-height: 200px;
    max-height: 400px;
    overflow: hidden;
    overflow-y: scroll;
  }

  &__search-item {
    padding: 0 10px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $rc-element-border-color;

    &:last-child {
      border-bottom: 0;
    }
    & > * {
      padding: 10px;
      flex-basis: 0;
    }
  }
  &__search-checkbox {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 50px;

    .rc-checkbox-component {
      width: 16px;
      text-align: center;
    }
  }
  &__search-title {
    flex-grow: 3;
  }
  &__search-description {
    flex-grow: 3;
  }
  &__search-ratetype {
    flex-grow: 1;
  }
  &__search-location {
    flex-grow: 3;
  }
}

.ps-ratecard-select {
  display: block;
  background: $white !important;

  & .select2-choice,
  & .select2-arrow {
    background: $white !important;
  }

  & .select2-choices {
    padding: 4px 1px 6px 1px;
    background: $white !important;
  }

  & .select2-search-choice {
    padding: 7px 7px 7px 20px !important;
    font-size: 15px !important;
    font-size: 14px;
  }

  & .select2-search-choice-close {
    top: 7px;
  }

  & .select2-search-field input {
    background: $white;
    font-size: 15px !important;
    margin: 4px 0 !important;
  }

  & .select2-chosen {
    overflow-x: auto;
    vertical-align: middle;
    line-height: inherit;
  }

  .select2-drop .select2-search {
    background: $white !important;
  }
}

//-------------------------
// GENERAL SECTION PAGE
//-------------------------

.ps-store {
  padding: 15px;
  /*font-size: inherit;*/

  .ps-container:first-child {
    margin-top: 0;
  }
  .ps-container:last-child {
    margin-bottom: 0;
  }

  .btn .fa {
    margin-right: 4px;
  }
}

//-------------------------
// PACKS LIST PAGE
//-------------------------

.ps-inventory {
  width: 100%;

  .ps-container-section {
    padding: 0;
  }
}

.ps-packs-list {
  font-size: 16px;

  &__error-message,
  &__empty-message {
    padding: 15px;
  }
  &__header {
    padding: 15px 20px;
    border-bottom: 1px solid $rc-element-border-color;
  }
}

.ps-packs-list-item {
  display: flex;
  align-items: center;
  padding: 15px 20px;
  cursor: pointer;
  border-bottom: 1px solid $rc-element-border-color;

  & > * {
    flex: 1 1 0;
    padding: 0 10px;
  }

  &__checkbox {
    flex: 0 0;
  }
  &__image {
    flex: 0 0;

    img {
      border: 1px solid $gray-border;
      border-radius: 2px;
      max-width: 90px;
      height: auto;
      max-height: 30px;
    }

    &-tooltip {
      .tooltip-inner {
        padding: 1px;
        border-radius: 2px;
      }

      img {
        max-width: 180px;
        height: auto;
        max-height: 60px;
      }
    }
  }
  &__title-container {
    flex-grow: 3;
  }
  &__rate-type {
    flex-grow: 1;
    text-align: center;
  }
  &__category {
    flex-grow: 2;
    text-align: center;
  }
  &__price {
    flex-grow: 4;
    text-align: center;
  }
  &__status {
    flex: 0 0 auto;
    text-align: center;
  }
  &__actions {
    flex: 0 0 auto;
    text-align: right;

    & > * {
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__title {
    font-size: 22px;
    color: $rc-green4;
  }

  // list item hover

  &.active:hover {
    background-color: $rc-green8;
  }
  &.active:hover &__title {
    color: $rc-green5;
  }
  &.inactive {
    background: $rc-gray7;
  }
  &.failed {
    background: $rc-red2;
  }
  &.warning {
    background: $rc-yellow3;
  }
}

//-------------------------
// CREATE A PACK PAGE
//-------------------------

.ps-create-pack {
  width: 100%;

  .ps-container-section {
    padding: 30px;
  }

  &__ratecard-select-container {
    width: 100%;
    display: flex;
    align-items: center;

    & > * {
      &:first-child {
        margin-right: 10px;
        flex-grow: 1;
        flex-basis: 0;
      }
      &:last-child {
        flex-grow: 0;
        flex-shrink: 0;
      }
    }

    .btn-toggle {
      height: 32px;
    }
  }

  &__ratecard-preview-container {
    display: flex;
    width: 100%;
    margin-top: 15px;

    & > * {
      flex-grow: 1;
      margin-right: 10px;

      &:first-child {
        flex-grow: 0;
        flex-shrink: 0;
      }
      &:last-child {
        flex-grow: 0;
        flex-shrink: 0;
        align-self: flex-end;
        margin-right: 0;
      }
    }

    .fa-id-card-o {
      display: inline-block;
      flex-grow: 0;
      flex-shrink: 0;
      font-size: 80px;
      text-align: center;
      color: $rc-green1;
    }
  }
  &__ratecard-preview-text {
    flex-grow: 1;
    color: $rc-green6;
  }

  .pt-base-text-area,
  .pt-base-text-input {
    padding: 5px 10px;
  }

  &__hint {
    display: inline-block;
    margin-right: 20px !important;
    color: $rc-gray1;
  }

  &__image-select .pt-image-select__image {
    width: 100%;
    text-align: center;

    & img {
      max-height: 180px;
      max-width: 180px;
    }
  }
}

//-------------------------
// PACK REUSABLE FORM
//-------------------------

.ps-pack-form {
  position: relative;

  &-field {
    margin-top: 30px;

    &:first-child {
      margin-top: 0;
    }

    & > label {
      display: block;
      margin-bottom: 15px;
      font-size: 20px;
    }
    & input {
      font-size: 16px;
    }
    & textarea {
      font-size: 18px;
    }

    & .ps-multi-select {
      font-size: 16px;
    }

    table td {
      border-bottom: 6px solid $white;
      min-width: 300px;
      padding-left: 10px;

      &:first-child {
        width: 300px;
        text-align: center;
        background-color: $rc-green3;
        color: $white;
      }
      &:nth-child(2) > * {
        display: block;
        width: 100%;
      }
    }
  }
}
