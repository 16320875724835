@import "../base/colors";

.nopadding {
  padding: 0 !important;
  .table {
    margin-bottom: 0;
  }
  .table-bordered {
    border: 0 !important;
  }
}
.line {
  //  background: url("../img/line.png") repeat-x scroll 0 0 transparent;
  display: block;
  height: 8px;
}
.css_right {
  float: right;
}
.disabled {
  color: #bbbbbb;
}
.gray {
  background-color: #f6f6f6;
}
.turquoise {
  background-color: #1abc9c;
}
.turquoise-dark {
  background-color: #16a085;
}
.red {
  background-color: #e74c3c;
}
.red-dark {
  background-color: #c0392b;
}
.yellow {
  background-color: #f1c40f;
}
.yellow-dark {
  background-color: #f39c12;
}
.blue {
  background-color: #3498db;
}
.blue-dark {
  background-color: #2980b9;
}
.green {
  background-color: #2ecc71;
}
.green-dark {
  background-color: #27ae60;
}
.purple {
  background-color: #9b59b6;
}
.purple-dark {
  background-color: #8e44ad;
}
.inverse {
  background-color: #34495e;
}
/** Helper: No bottom margin **/
.nbm {
  margin-bottom: 0 !important;
}

.tdp {
  padding: 10px 15px;
}
.tb {
  border-right: 1px solid #e6e6e6;
}
.w20 {
  width: 20%;
}
.w60 {
  width: 60%;
}
.w1p {
  width: 1%;
}

.spinner {
  width: 32px;
  height: 32px;
  background-image: url("../../assets/img/select2-spinner.gif");
  background-repeat: no-repeat;
  background-position: center right;
  display: none;
  position: absolute;
  left: 0;
  top: 0;
}

.normal {
  font-weight: normal;
}
.bold {
  font-weight: bold;
}

.select2-drop.bigdrop .select2-search input {
  line-height: 20px;
}
.select2-drop .select2-results {
  overflow-x: auto;
  overflow-y: auto;
}

.no-gutter > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.positive {
  color: $green-bright;
}

.negative {
  color: $red-bright;
}

.margin-top-20 {
  margin-top: 20px !important;
}

.font-p-14,
.font-p-14 p {
  font-size: 14px;
  line-height: 20px;
}

.margin-right-neg-20 {
  margin-right: -20px !important;
}

.toast-cover {
  .fa-times {
    font-size: 15px;
    margin: 0 0 0 5px;
  }
  .fa-tag {
    margin-right: 4px;
  }
}
.margin-neg-20 {
  margin: 0 -20px;
}
