@import "variables", "mixins", "animations";

// all declarations in this file should be inside .pt-ui{} scope.
// declarations outside .pt-ui{} go in the file _pt-ui.scss
.pt-ui {
  .fa.sc-status {
    color: $rc-default-color !important;
    font-size: 28px !important;
    line-height: 32px;
    padding-top: 2px;

    &.running {
      color: $rc-primary-color !important;
      animation: fadePulse 1s infinite;

      &:hover {
        animation: none;
      }
    }

    &.finished {
      color: $rc-success-color !important;
    }

    &.finished-errors {
      color: $rc-warning-color !important;
    }

    &.failed {
      color: $rc-danger-color !important;
    }
  }

  &.ss-tag {
    display: inline-block;
    font-size: 12px;
    border-radius: 4px;
    padding: 2px 6px;
    margin: 0 0 0 5px;
    background-color: #f1f0ec;
    color: #333333;
    opacity: 0.65;

    transition: opacity 250ms;

    &:hover {
      opacity: 1;
    }

    &.tag-red {
      background-color: #fde6e6;
      color: #e81111;
    }

    &.tag-green {
      background-color: #e0e7d9;
      color: #657d4f;
    }

    &.tag-yellow {
      background-color: #f9f2cc;
      color: $rc-gray3;
    }
  }

  .control-info-text {
    font-size: 14px;
    padding-top: 5px;
  }

  .table-action-button {
    cursor: pointer;
    margin: 0 5px;
  }

  .search-table.ss-job-title-table {
    .search-list-item-container {
      .search-list-item {
        padding: 10px;
        font-size: 16px;

        .search-label {
          font-size: 16px;
        }
        .search-title {
          font-size: 14px;
          color: $rc-gray6;
        }
        .batch-search.search-expand-state {
          font-size: 16px;
          height: 100%;
        }
        .search-info {
          font-size: 16px;
          header {
            font-size: 14px;
            color: $rc-gray6;
          }
        }
      }
    }
  }

  .batch-search-job-list-item {
    cursor: pointer;
    transition: all 200ms ease;
    &:hover,
    &.selected {
      background-color: $rc-green8 !important;
      td {
        background-color: $rc-green8 !important;
      }
    }
  }

  .batch-search-job-description {
    font-size: 14px;
    border: none;
    padding: 0;
    font-family: "Source Sans Pro", sans-serif;
  }

  #batch-search-titles-selection-dropdown {
    &.btn-success {
      border: 2px solid $rc-green4 !important;
      background-color: $rc-green4;
      color: $rc-white1 !important;

      &:hover {
        border: 2px solid $rc-green6 !important;
        background-color: $rc-green6;
      }
    }

    &.btn-warning {
      border: 2px solid $rc-yellow !important;
      background-color: $rc-yellow;
      color: $rc-white1 !important;

      &:hover {
        border: 2px solid $rc-yellow2 !important;
        background-color: $rc-yellow2;
      }
    }
  }
}

.pt-ui-tooltip.tooltip {
  opacity: 1 !important;
  .tooltip-inner {
    font-size: 14px;
    //color: $rc-white1;
    //background-color: $rc-success-color;
    border-radius: 2px;
  }
  .tooltip-arrow {
    visibility: hidden;
  }
}
