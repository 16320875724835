@font-face {
  font-family: "Source Sans Pro";
  src: url("../../fonts/SourceSansPro-ExtraLight.eot");
  src: url("../../fonts/SourceSansPro-ExtraLight.eot?#iefix") format("embedded-opentype"),
    url("../../fonts/SourceSansPro-ExtraLight.ttf.woff") format("woff"),
    url("../../fonts/SourceSansPro-ExtraLight.ttf") format("truetype"),
    url("../../fonts/SourceSansPro-ExtraLight.svg") format("svg");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url("../../fonts/SourceSansPro-Light.eot");
  src: url("../../fonts/SourceSansPro-Light.eot?#iefix") format("embedded-opentype"),
    url("../../fonts/SourceSansPro-Light.ttf.woff") format("woff"),
    url("../../fonts/SourceSansPro-Light.ttf") format("truetype"),
    url("../../fonts/SourceSansPro-Light.svg") format("svg");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url("../../fonts/SourceSansPro-Regular.eot");
  src: url("../../fonts/SourceSansPro-Regular.eot?#iefix") format("embedded-opentype"),
    url("../../fonts/SourceSansPro-Regular.ttf.woff") format("woff"),
    url("../../fonts/SourceSansPro-Regular.ttf") format("truetype"),
    url("../../fonts/SourceSansPro-Regular.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url("../../fonts/SourceSansPro-Semibold.eot");
  src: url("../../fonts/SourceSansPro-Semibold.eot?#iefix") format("embedded-opentype"),
    url("../../fonts/SourceSansPro-Semibold.ttf.woff") format("woff"),
    url("../../fonts/SourceSansPro-Semibold.ttf") format("truetype"),
    url("../../fonts/SourceSansPro-Semibold.svg") format("svg");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url("../../fonts/SourceSansPro-Bold.eot");
  src: url("../../fonts/SourceSansPro-Bold.eot?#iefix") format("embedded-opentype"),
    url("../../fonts/SourceSansPro-Bold.ttf.woff") format("woff"),
    url("../../fonts/SourceSansPro-Bold.ttf") format("truetype"),
    url("../../fonts/SourceSansPro-Bold.svg") format("svg");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url("../../fonts/SourceSansPro-Black.eot");
  src: url("../../fonts/SourceSansPro-Black.eot?#iefix") format("embedded-opentype"),
    url("../../fonts/SourceSansPro-Black.ttf.woff") format("woff"),
    url("../../fonts/SourceSansPro-Black.ttf") format("truetype"),
    url("../../fonts/SourceSansPro-Black.svg") format("svg");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url("../../fonts/SourceSansPro-ExtraLightIt.eot");
  src: url("../../fonts/SourceSansPro-ExtraLightIt.eot?#iefix") format("embedded-opentype"),
    url("../../fonts/SourceSansPro-ExtraLightIt.ttf.woff") format("woff"),
    url("../../fonts/SourceSansPro-ExtraLightIt.ttf") format("truetype"),
    url("../../fonts/SourceSansPro-ExtraLightIt.svg") format("svg");
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url("../../fonts/SourceSansPro-LightIt.eot");
  src: url("../../fonts/SourceSansPro-LightIt.eot?#iefix") format("embedded-opentype"),
    url("../../fonts/SourceSansPro-LightIt.ttf.woff") format("woff"),
    url("../../fonts/SourceSansPro-LightIt.ttf") format("truetype"),
    url("../../fonts/SourceSansPro-LightIt.svg") format("svg");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url("../../fonts/SourceSansPro-It.eot");
  src: url("../../fonts/SourceSansPro-It.eot?#iefix") format("embedded-opentype"),
    url("../../fonts/SourceSansPro-It.ttf.woff") format("woff"),
    url("../../fonts/SourceSansPro-It.ttf") format("truetype"), url("../../fonts/SourceSansPro-It.svg") format("svg");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url("../../fonts/SourceSansPro-SemiboldIt.eot");
  src: url("../../fonts/SourceSansPro-SemiboldIt.eot?#iefix") format("embedded-opentype"),
    url("../../fonts/SourceSansPro-SemiboldIt.ttf.woff") format("woff"),
    url("../../fonts/SourceSansPro-SemiboldIt.ttf") format("truetype"),
    url("../../fonts/SourceSansPro-SemiboldIt.svg") format("svg");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url("../../fonts/SourceSansPro-BoldIt.eot");
  src: url("../../fonts/SourceSansPro-BoldIt.eot?#iefix") format("embedded-opentype"),
    url("../../fonts/SourceSansPro-BoldIt.ttf.woff") format("woff"),
    url("../../fonts/SourceSansPro-BoldIt.ttf") format("truetype"),
    url("../../fonts/SourceSansPro-BoldIt.svg") format("svg");
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url("../../fonts/SourceSansPro-BlackIt.eot");
  src: url("../../fonts/SourceSansPro-BlackIt.eot?#iefix") format("embedded-opentype"),
    url("../../fonts/SourceSansPro-BlackIt.ttf.woff") format("woff"),
    url("../../fonts/SourceSansPro-BlackIt.ttf") format("truetype"),
    url("../../fonts/SourceSansPro-BlackIt.svg") format("svg");
  font-weight: 900;
  font-style: italic;
}
body {
  background-color: #333333;
  font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.blur {
  visibility: hidden;
  width: 2px;
  height: 2px;
  filter: url("../../img/blur.svg#blur");
}
a:not(.btn) {
  color: #c2d646;
}
a:hover:not(.btn),
a.dark:not(.btn) {
  color: #97aa26;
  text-decoration: none;
}
a.dark:hover:not(.btn) {
  color: #72801c;
}
a.text-muted {
  color: #bbbbbb;
}
a.text-muted:hover {
  color: #999999;
}
.text-primary,
h1.primary,
h2.primary,
h3.primary,
h4.primary,
h5.primary {
  color: #c2d646 !important;
}
h1 {
  font-weight: 600;
  font-size: 54px;
  line-height: 54px;
}
h2 {
  font-weight: 600;
  font-size: 36px;
  line-height: 36px;
}
h3 {
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
}
h4,
p {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
}
h5,
p.small {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}
h1.special,
h2.special,
h3.special {
  border-top: 1px dotted #dddddd;
  border-bottom: 1px dotted #dddddd;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
}
hr.dotted {
  border-top-style: dashed;
}
.gray hr.dotted {
  border-top-color: #dddddd;
}
.bold {
  font-weight: bold;
}
.semibold {
  font-weight: 600;
}
.normal {
  font-weight: normal;
}
.light {
  font-weight: 300;
}
.btn {
  border: 0;
}
.btn-primary {
  color: #ffffff;
  background-color: #c2d646;
  border-color: #c2d646;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
  color: #ffffff;
  background-color: #b2c72c;
  border-color: #a3b628;
}
.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
  background-image: none;
}
.btn-primary.disabled,
.btn-primary[disabled],
fieldset[disabled] .btn-primary,
.btn-primary.disabled:hover,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary:hover,
.btn-primary.disabled:focus,
.btn-primary[disabled]:focus,
fieldset[disabled] .btn-primary:focus,
.btn-primary.disabled:active,
.btn-primary[disabled]:active,
fieldset[disabled] .btn-primary:active,
.btn-primary.disabled.active,
.btn-primary[disabled].active,
fieldset[disabled] .btn-primary.active {
  background-color: #c2d646;
  border-color: #c2d646;
}
.btn-primary .badge {
  color: #c2d646;
  background-color: #ffffff;
}
.btn-gray {
  color: #ffffff;
  background-color: #666666;
  border-color: #666666;
}
.btn-gray:hover,
.btn-gray:focus,
.btn-gray:active,
.btn-gray.active,
.open .dropdown-toggle.btn-gray {
  color: #ffffff;
  background-color: #525252;
  border-color: #474747;
}
.btn-gray:active,
.btn-gray.active,
.open .dropdown-toggle.btn-gray {
  background-image: none;
}
.btn-gray.disabled,
.btn-gray[disabled],
fieldset[disabled] .btn-gray,
.btn-gray.disabled:hover,
.btn-gray[disabled]:hover,
fieldset[disabled] .btn-gray:hover,
.btn-gray.disabled:focus,
.btn-gray[disabled]:focus,
fieldset[disabled] .btn-gray:focus,
.btn-gray.disabled:active,
.btn-gray[disabled]:active,
fieldset[disabled] .btn-gray:active,
.btn-gray.disabled.active,
.btn-gray[disabled].active,
fieldset[disabled] .btn-gray.active {
  background-color: #666666;
  border-color: #666666;
}
.btn-gray .badge {
  color: #666666;
  background-color: #ffffff;
}
.btn-white {
  color: #444444;
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn-white:hover,
.btn-white:focus,
.btn-white:active,
.btn-white.active,
.open .dropdown-toggle.btn-white {
  color: #444444;
  background-color: #ebebeb;
  border-color: #e0e0e0;
}
.btn-white:active,
.btn-white.active,
.open .dropdown-toggle.btn-white {
  background-image: none;
}
.btn-white.disabled,
.btn-white[disabled],
fieldset[disabled] .btn-white,
.btn-white.disabled:hover,
.btn-white[disabled]:hover,
fieldset[disabled] .btn-white:hover,
.btn-white.disabled:focus,
.btn-white[disabled]:focus,
fieldset[disabled] .btn-white:focus,
.btn-white.disabled:active,
.btn-white[disabled]:active,
fieldset[disabled] .btn-white:active,
.btn-white.disabled.active,
.btn-white[disabled].active,
fieldset[disabled] .btn-white.active {
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn-white .badge {
  color: #ffffff;
  background-color: #444444;
}
.main-wrap {
  background-color: #ffffff;
  overflow: hidden;
}
.form-control {
  /*box-shadow: none !important;*/
  /*border-color: #d4d4d4;*/
  color: #aaaaaa;
  border-radius: 3px;
}
.form-control::-webkit-placeholder {
  color: #c1c1c1;
}
.form-control::-moz-placeholder {
  color: #c1c1c1;
}
.form-control::placeholder {
  color: #c1c1c1;
}
.form-control.placeholder {
  color: #c1c1c1;
}
.form-control:focus,
.form-control:active {
  border-color: #A2B624;
}
.text-white {
  color: #ffffff;
}
.panel {
  box-shadow: none;
}
.panel .panel-heading h4 {
  font-size: 14px;
}
.panel .panel-heading a {
  color: #888888;
}
.panel-group .panel + .panel {
  margin-top: 15px;
}
.modal-open #top,
.modal-open .main-wrap,
.modal-open #footer {
  -webkit-filter: blur(3px);
  filter: url("../../img/blur.svg#blur");
}
.mt15 {
  margin-top: 15px;
}
.mt20 {
  margin-top: 20px;
}
.mt25 {
  margin-top: 25px;
}
.mt30 {
  margin-top: 30px;
}
.mt35 {
  margin-top: 35px;
}
.mt40 {
  margin-top: 40px;
}
.mt45 {
  margin-top: 45px;
}
.mt50 {
  margin-top: 50px;
}
.mb15 {
  margin-bottom: 15px;
}
.mb20 {
  margin-bottom: 20px;
}
.mb25 {
  margin-bottom: 25px;
}
.mb30 {
  margin-bottom: 30px;
}
.mb35 {
  margin-bottom: 35px;
}
.mb40 {
  margin-bottom: 40px;
}
.mb45 {
  margin-bottom: 45px;
}
.mb50 {
  margin-bottom: 50px;
}
.mb60 {
  margin-bottom: 60px;
}
.mb70 {
  margin-bottom: 70px;
}
.carousel-fade .carousel-inner .item {
  opacity: 0;
  transition-property: opacity;
}
.carousel-fade .carousel-inner .active {
  opacity: 1;
}
.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  left: 0;
  opacity: 0;
  z-index: 1;
}
.carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right {
  opacity: 1;
}
.carousel-fade .carousel-control {
  z-index: 2;
}
ol {
  font-weight: 600;
}
.relative {
  position: relative;
}
.tooltip.left .tooltip-arrow {
  background-color: transparent;
  border-top-color: transparent;
  border-bottom-color: transparent;
  border-left-color: green;
}
.mw780 {
  max-width: 780px;
}
.centered {
  margin-left: auto;
  margin-right: auto;
}
/* Animations */
.animate {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;
  -webkit-transform: translateZ(0) !important;
}
.fade-in {
  -webkit-animation-name: fadein;
  animation-name: fadein;
  opacity: 1;
  filter: alpha(opacity=100);
}
.fade-in-top {
  -webkit-animation-name: fadeintop;
  animation-name: fadeintop;
  opacity: 1;
  filter: alpha(opacity=100);
}
.fade-in-bottom {
  -webkit-animation-name: fadeinbottom;
  animation-name: fadeinbottom;
  opacity: 1;
  filter: alpha(opacity=100);
}
.fade-in-left {
  -webkit-animation-name: fadeinleft;
  animation-name: fadeinleft;
  opacity: 1;
  filter: alpha(opacity=100);
}
.fade-in-right {
  -webkit-animation-name: fadeinright;
  animation-name: fadeinright;
  opacity: 1;
  filter: alpha(opacity=100);
}
.scale-in {
  -webkit-animation-name: scalein;
  animation-name: scalein;
  opacity: 1;
  filter: alpha(opacity=100);
}
.bounce-in {
  -webkit-animation-name: bounce;
  animation-name: bounce;
  animation-timing-function: ease;
  opacity: 1;
  filter: alpha(opacity=100);
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeintop {
  from {
    opacity: 0;
    transform: translateY(-40px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}
@-moz-keyframes fadeintop {
  from {
    opacity: 0;
    -moz-transform: translateY(-40px);
  }
  to {
    opacity: 1;
    -moz-transform: translateY(0px);
  }
}
@-webkit-keyframes fadeintop {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -40px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0, 0, 0);
  }
}
@keyframes fadeinbottom {
  from {
    opacity: 0;
    transform: translateY(40px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}
@-moz-keyframes fadeinbottom {
  from {
    opacity: 0;
    -moz-transform: translateY(40px);
  }
  to {
    opacity: 1;
    -moz-transform: translateY(0px);
  }
}
@-webkit-keyframes fadeinbottom {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 40px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeinleft {
  from {
    opacity: 0;
    transform: translateX(-35px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}
@-moz-keyframes fadeinleft {
  from {
    opacity: 0;
    -moz-transform: translateX(-35px);
  }
  to {
    opacity: 1;
    -moz-transform: translateX(0px);
  }
}
@-webkit-keyframes fadeinleft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-35px, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeinright {
  from {
    opacity: 0;
    transform: translateX(35px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}
@-moz-keyframes fadeinright {
  from {
    opacity: 0;
    -moz-transform: translateX(35px);
  }
  to {
    opacity: 1;
    -moz-transform: translateX(0px);
  }
}
@-webkit-keyframes fadeinright {
  from {
    opacity: 0;
    -webkit-transform: translate3d(35px, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
  }
}
@keyframes scalein {
  0% {
    opacity: 0;
    transform: scale(0.7);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@-moz-keyframes scalein {
  0% {
    opacity: 0;
    -moz-transform: scale(0.7);
  }
  100% {
    opacity: 1;
    -moz-transform: scale(1);
  }
}
@-webkit-keyframes scalein {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.7);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
  }
}
@keyframes bouncein {
  0% {
    opacity: 0;
    transform: scale(0.7);
  }
  33% {
    transform: scale(1.1);
  }
  66% {
    opacity: 1;
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}
@-moz-keyframes bouncein {
  0% {
    opacity: 0;
    -moz-transform: scale(0.7);
  }
  33% {
    -moz-transform: scale(1.1);
  }
  66% {
    opacity: 1;
    -moz-transform: scale(0.95);
  }
  100% {
    -moz-transform: scale(1);
  }
}
@-webkit-keyframes bouncein {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.7);
  }
  33% {
    -webkit-transform: scale(1.1);
  }
  66% {
    opacity: 1;
    -webkit-transform: scale(0.95);
  }
  100% {
    -webkit-transform: scale(1);
  }
}
.custom-skill-report .custom-skill-report-text .list,
.rate-preview .rate-preview-text .list,
#consulting-list .list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.custom-skill-report .custom-skill-report-text .list li,
.rate-preview .rate-preview-text .list li,
.subscription-features .text,
#consulting-list li {
  font-size: 18px;
  padding: 10px 0 10px 35px;
  position: relative;
}
#consulting-list li {
  float: left;
  width: 50%;
}
.subscription-features .text {
  display: block;
  padding-left: 20px;
}
.custom-skill-report .custom-skill-report-text .list li .icon,
.rate-preview .rate-preview-text .list li .icon,
.subscription-features .icon,
#consulting-list li .icon {
  display: block;
  position: absolute;
  left: 0;
  top: 13px;
  width: 20px;
  height: 20px;
  background-image: url("../../img/check_sign.png");
}
@media (min-width: 480px) and (max-width: 767px) {
  .subscription-features h3 {
    text-align: left !important;
  }
}
@media (max-width: 767px) {
  .subscription-features .text {
    padding-left: 45px;
  }
  .subscription-features .icon {
    left: 25px;
  }
  #consulting-list li {
    float: none;
    width: 100%;
    margin-left: 10px;
  }
}
#top {
  background-color: #333333;
  border-bottom: 1px solid #c5d850;
}
#logo {
  margin-top: 20px;
  display: inline-block;
}
.show-menu {
  float: right;
  font-size: 16px;
  padding: 13.5px 15px;
  margin-right: -15px;
}
.show-menu .fa {
  color: #ffffff;
}
nav#main {
  float: right;
}
nav#main > ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
nav#main > ul > li {
  float: left;
  position: relative;
}
nav#main > ul > li.special {
  margin-left: 10px;
}
nav#main > ul > li > a {
  font-size: 17px;
  color: #eeeeee;
  display: block;
  padding: 25px 20px;
  text-decoration: none !important;
}
nav#main > ul > li.dropdown {
  margin-right: 15px;
}
nav#main > ul > li.dropdown:before {
  content: "";
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #ffffff;
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -2px;
}
nav#main > ul > li.active > a,
nav#main > ul > li:hover > a {
  color: #c2d646 !important;
}
nav#main > ul > li.dropdown.active:before,
nav#main > ul > li.dropdown:hover:before {
  border-top-color: #c2d646;
}
nav#main > ul > li.special > a {
  color: #ffffff !important;
  margin-top: 15px;
  padding: 10px 20px;
}
nav#main .dropdown-menu {
  min-width: 160px;
  max-width: 160px;
  width: 160px;
  left: 50%;
  margin-left: -80px;
}
nav#main .dropdown-menu:before {
  content: "";
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #ffffff;
  position: absolute;
  top: -8px;
  left: 50%;
  margin-left: -8px;
}
nav#main .dropdown-menu li {
  float: none;
}
nav#main .dropdown-menu li a {
  color: #333333;
}
nav#main .dropdown-menu li.active a {
  background-color: #323232;
  color: #ffffff;
}
.main-wrap {
  position: relative;
  z-index: 2;
  margin-bottom: 240px;
  border-bottom: 3px solid #c5d850;
  border: none;
  /*box-shadow: 0 2px 15px rgba(0, 0, 0, 0.6);*/
}
/** Page Heading **/
#page-heading {
  background-color: #9eae32;
  padding: 40px 0 44px;
  background-image: url("../../img/page_head_bg.jpg");
  background-position: top center;
  background-repeat: repeat-y;
}
#page-heading.only-heading {
  padding: 53px 0 57px;
}
#page-heading h1,
#page-heading h4 {
  text-align: center;
  color: #ffffff;
  margin: 0;
  padding: 0;
}
#page-heading h1 {
  font-size: 48px;
}
#page-heading h4 {
  font-size: 18px;
  line-height: 26px;
}
/** Page Content **/
/*.content {
  padding-top: 40px;
}*/
.content.gray {
  background-color: #f4f4f4;
}
.content.primary {
  background-color: #c2d646;
}
.content.posts {
  margin-top: 0px;
}
/*****/
.ie8 .input-placeholder {
  position: absolute;
  top: 11px;
  left: 29px;
  font-size: 18px;
  color: #888888;
}
.ie8 .relative > .input-placeholder {
  left: 17px !important;
}
/*****/
.feature-img-box {
  margin-top: 45px;
}
.feature-img-box img {
  width: 100%;
}
.feature-img-box img.icon {
  width: auto;
  height: 59px;
  margin: 0 auto;
  display: block;
}
.feature-img-box img.icon.large {
  height: 95px;
}
.feature-img-box h3,
.feature-img-box p {
  text-align: center;
}
.feature-img-box h3 {
  margin-top: 30px;
}
.feature-img-box p {
  font-weight: 400;
}
.dark-bg,
.dark-pattern {
  background-color: #333333;
}
.dark-bg h2,
.dark-pattern h2 {
  color: #ffffff;
}
.dark-bg .feature-img-box h3,
.dark-pattern .feature-img-box h3,
.dark-bg .feature-img-box p,
.dark-pattern .feature-img-box p {
  color: #ffffff;
}
.dark-bg .learn-more,
.dark-pattern .learn-more {
  margin-top: 30px;
}
.dark-pattern {
  background-image: url("../../img/pattern_black.png");
  background-repeat: repeat-y;
  background-position: top center;
  padding-bottom: 65px;
}
.col-xs-15,
.col-sm-15,
.col-md-15,
.col-lg-15 {
  position: relative;
  min-height: 1px;
  padding-right: 7px;
  padding-left: 7px;
}
.col-xs-15 {
  width: 20%;
  float: left;
}
/* 404 Error page */
.error-404 {
  padding-top: 100px;
  padding-bottom: 250px;
  text-align: center;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.73);
  text-shadow: 1px 4px 6px rgba(255, 255, 255, 0.73), 0 0 0 rgba(0, 0, 0, 0.7), 1px 1px 0 #ffffff;
}
.error-404 h1 {
  line-height: 200px;
  font-size: 200px;
}
.error-404 h3 {
  font-size: 50px;
}
.error-404 p {
  padding-top: 35px;
  color: #cccccc;
  font-size: 16px;
}
.error-404 code {
  background-color: #f3f3f3;
  color: #aaaaaa;
}
.show-ie8,
.show-ie9 {
  display: none !important;
}
.hide-ie8,
.hide-ie9 {
  display: block !important;
}
.ie8 .show-ie8 {
  display: block !important;
}
.ie9 .show-ie9 {
  display: block !important;
}
.ie8 .hide-ie8 {
  display: none !important;
}
.ie9 .hide-ie9 {
  display: none !important;
}
/** Sidebar **/
#sidebar section {
  margin-bottom: 35px;
}
#sidebar section .title {
  text-transform: uppercase;
  color: #333333;
}
#sidebar section .list {
  list-style: none;
  margin: 0;
  padding: 10px 0 0;
}
#sidebar section .list li {
  padding-bottom: 15px;
}
#sidebar section .list li a,
#sidebar section .list li .counter {
  font-size: 17px;
}
#sidebar section .list li .counter {
  color: #999999;
  padding-left: 5px;
}
/** Blog posts **/
.post {
  color: #666666;
  border-bottom: 1px dotted #dddddd;
  padding-bottom: 50px;
  margin-bottom: 65px;
}
.post .post-title {
  margin: 0;
}
.post .post-img {
  width: 100%;
  margin-top: 20px;
}
.post .post-data {
  margin-top: 20px;
}
.post .post-data .post-meta {
  list-style: none;
  margin: 0;
  padding: 0;
  float: left;
  width: 200px;
}
.post .post-data .post-meta li {
  line-height: 32px;
}
.post .post-data .post-content {
  margin-left: 205px;
}
.post .post-data .post-content .read-more {
  display: inline-block;
  margin-top: 25px;
}
/** Comments **/
.commentlist {
  list-style: none;
  margin: 25px 0;
  padding: 0;
}
.commentlist li {
  margin: 20px 0;
}
.commentlist li .wrap {
  background-color: #fdfdfd;
  border: 1px solid #f4f4f4;
  padding: 15px 20px;
  position: relative;
}
.commentlist li .wrap h3 {
  margin: 0;
  font-weight: bold;
}
.commentlist li .wrap p {
  position: absolute;
  right: 20px;
  top: 15px;
  color: #aaaaaa;
}
.commentlist li .wrap .content {
  padding: 10px 0 0;
  font-size: 16px;
}
.comments .notifications .success {
  background-color: rgba(194, 214, 70, 0.15);
  color: #91a327;
  border: 1px solid #c2d646;
  padding: 5px 15px;
  margin-top: 20px;
}
/** Pagination **/
nav.pagination {
  display: block;
  text-align: center;
}
nav.pagination a,
nav.pagination strong {
  display: inline-block;
  background-color: #aaaaaa;
  color: #ffffff;
  padding: 5px 10px;
  margin-left: -3px;
}
nav.pagination a:hover {
  background-color: #5c5c5c;
  color: #ffffff;
}
nav.pagination a:first-child {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
nav.pagination a:last-child {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
nav.pagination strong {
  background-color: #c2d646;
}
nav.pagination a.disabled,
nav.pagination a.disabled:hover,
nav.pagination a.disabled:active {
  background-color: #d4d4d4;
  color: #ffffff;
  cursor: default;
  text-decoration: none;
}
.bill-rate .pay-bill .amount {
  margin-bottom: 20px;
}
.bill-rate .info-box p {
  padding-left: 5px;
  padding-right: 5px;
}
.bill-rate .separator {
  margin-top: 40px;
  margin-bottom: 20px;
  text-align: center;
  position: relative;
}
.bill-rate .separator:before {
  content: "";
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed #999;
  position: absolute;
  top: 23px;
  left: 0;
  z-index: 1;
}
.bill-rate .separator h4 {
  position: relative;
  background-color: #fff;
  z-index: 2;
  padding: 0 30px;
  display: inline-block;
}
.bill-rate .content.gray {
  margin-top: 60px;
  padding-top: 50px;
  padding-bottom: 60px;
}
.bill-rate .content.gray .misconceptions {
  margin-top: 35px;
}
.bill-rate .content.gray .misconceptions .col-md-6 {
  margin-bottom: 20px;
}
.bill-rate .content.gray .misconceptions .col-md-6 span {
  color: #f61c18;
  font-weight: bold;
  text-transform: uppercase;
}
.bill-rate .img {
  margin-top: 40px;
  height: 510px;
  background-image: url("../../img/pbimg.jpg");
  background-position: top center;
}
.home_banner {
  height: 500px;
  background-image: url("../../img/home_banner.jpg");
  background-position: center -60px;
  background-repeat: no-repeat;
}
.home_banner--open {
  height: 1472px;
}
.home_banner .banner_box {
  padding: 30px 20px 35px;
  border-radius: 4px;
  text-align: center;
  width: 700px;
  color: #ffffff;
  background-color: #111111;
  background-color: rgba(55, 55, 55, 0.9);
  margin: 150px auto 0;
  overflow: hidden;
  position: relative;
  /*
		.watch-demo {
			display: block;
			text-align: center;
			margin-top: 20px;
			.icon, .text {
				display: inline-block;
				vertical-align: middle;
			}
			.icon {
				width: 27px;
				height: 26px;
				background-image: url('../img/icons/play_circle.png');
				background-repeat: no-repeat;
			}
			.text {
				margin-left: 10px;
				font-size: 18px;
				color: #ffffff;
			}
		}*/
}
.home_banner .banner_box .pr-sub-heading {
  font-style: italic;
}
.home_banner .banner_box .pr-h4 {
  text-align: left;
  text-decoration: underline;
  font-style: italic;
}
.home_banner .banner_box .pr-text {
  text-align: justify;
}
.home_banner .banner_box .pr-see-more {
  position: absolute;
  bottom: 15px;
  right: 20px;
}
.home_banner .banner_box .pr-kelly-logo {
  width: 160px;
  border-radius: 2px;
}
.home_banner .banner_box .pr-pt-logo {
  margin-left: 30px;
}
.home_banner .banner_box .pr-wrap {
  height: 285px;
  overflow: hidden;
}
.home_banner .banner_box .pr-wrap--open {
  height: 100%;
}
.home_banner .banner_box--open {
  height: 1320px;
}
.ie8 .home_banner .banner_box {
  margin-top: 200px;
  background: transparent;
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#E5373737,endColorstr=#E5373737)";
  /* IE8 */
  zoom: 1;
}
.home_banner .banner_box h2 {
  margin-top: 0;
  font-size: 40px;
}
.home_banner .banner_box p.light {
  padding: 0 15px;
}
.home_banner .banner_box .try-now,
.home_banner .banner_box .watch-demo {
  margin-top: 30px;
  display: inline-block;
  padding: 10px 0;
  border-radius: 3px;
  width: 130px;
}
.home_banner .banner_box .watch-demo {
  margin-left: 20px;
}
.home-features .learn-more {
  margin-top: 40px;
  margin-bottom: 65px;
}
.map-title h2 {
  margin: 0;
  padding: 30px 0;
  font-weight: 400;
  color: #aaaaaa;
}
.quotes,
.news-feed {
  padding-bottom: 65px;
}
.ie8 .quotes,
.ie8 .news-feed {
  font-family: "Arial" !important;
}
.quotes-items {
  margin: 30px 0 0;
  padding: 0;
  list-style: none;
}
.quotes-items li {
  margin: 0 auto;
  text-align: center;
  width: 48%;
}
.quotes-items li .quote {
  font-weight: 300;
  color: #666666;
  font-style: italic;
}
.quotes-items li .quote:before,
.quotes-items li .quote:after {
  content: '"';
}
.quotes-items li .name {
  margin-top: 15px;
  margin-bottom: 0;
  color: #444444;
}
.quotes-items li .meta {
  color: #888888;
}
.data-counter {
  padding-bottom: 80px;
}
.data-counter > .container {
  position: relative;
}
.data-counter > .container:before,
.data-counter > .container:after {
  position: absolute;
  top: 0;
  width: 100px;
  height: 100%;
  display: block;
  content: "";
  z-index: 160;
}
.data-counter > .container:before {
  left: 15px;
  background-image: linear-gradient(to right, #f4f4f4 25%, rgba(244, 244, 244, 0) 100%);
}
.data-counter > .container:after {
  right: 15px;
  background-image: linear-gradient(to left, #f4f4f4 0%, rgba(244, 244, 244, 0.5) 50%, rgba(244, 244, 244, 0) 100%);
}
.data-counter .counter {
  text-align: center;
  margin: 30px 0 15px;
}
.data-counter .counter .counter-number,
.data-counter .counter .counter-comma {
  display: inline-block;
}
.data-counter .counter .counter-number:hover,
.data-counter .counter .counter-comma:hover {
  cursor: default;
}
.data-counter .counter .counter-number {
  position: relative;
  width: 70px;
  height: 97px;
  background-image: url("../../img/number_bg.png");
  background-repeat: no-repeat;
  color: #ffffff;
  font-size: 80px;
  line-height: 92px;
  text-align: center;
  font-weight: bold;
  vertical-align: bottom;
}
.data-counter .counter .counter-number:before {
  height: 3px;
  content: "";
  width: 50px;
  position: absolute;
  left: 10px;
  top: 50%;
  margin-top: -2px;
  background-color: #000000;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.4);
}
.data-counter .counter .counter-comma {
  font-size: 80px;
  height: 97px;
  color: #999999;
}
.data-counter #newsticker_1 li {
  margin: 0;
  white-space: nowrap;
  color: #888888;
  display: inline-block;
  font-size: 18px;
}
.data-counter #newsticker_1 li i.fa {
  font-size: 28px;
  display: inline-block;
  vertical-align: middle;
  padding: 0;
  margin-top: -2px;
}
.data-counter #newsticker_1 li i.fa.fa-caret-up {
  margin-top: -3px;
}
.data-counter #newsticker_1 li i.fa.fa-minus {
  margin-top: 0;
}
.data-counter #newsticker_1 li .good {
  color: #c2d646;
}
.data-counter #newsticker_1 li .bad {
  color: #f61c18;
}
#news-feed {
  margin-top: 35px;
}
.ie8 #news-feed {
  font-family: "Arial" !important;
}
#demo-video .close {
  position: absolute;
  top: -15px;
  right: -15px;
  background-color: #000;
  z-index: 1000;
  opacity: 1;
  padding: 2.5px 7px 3.5px 8px;
  border: 2px solid #ffffff;
  border-radius: 50%;
  color: #ffffff;
  text-shadow: none;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.5);
}
#demo-video .modal-dialog {
  width: 867px;
  margin-top: 80px;
}
#demo-video .modal-dialog .modal-content,
#demo-video .modal-dialog .modal-body {
  padding: 3px;
}
/** Pricing Tables **/
.pricing-table {
  overflow: hidden;
  position: relative;
  background-color: #f4f4f4;
  text-align: center;
  padding: 25px 15px;
  border-radius: 4px;
  margin: 15px 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: transform 0.15s, box-shadow 0.15s;
  -moz-transition: transform 0.15s, box-shadow 0.15s;
  -ms-transition: transform 0.15s, box-shadow 0.15s;
  transition: transform 0.15s, box-shadow 0.15s;
  /*&:hover {
		-webkit-transform: scale(1.06);
		-moz-transform: scale(1.06);
		-ms-transform: scale(1.06);
		transform: scale(1.06);
		.box-shadow(0 0 10px rgba(0,0,0,0.2));
	}*/
}
.pricing-table.featured {
  padding: 40px 15px;
  margin: 0;
}
.pricing-table .price {
  font-size: 18px;
  line-height: 40px;
  color: #c2d646;
}
.pricing-table .price .amount {
  line-height: 40px;
  font-size: 34px;
  vertical-align: top;
}
.pricing-table .price .desc {
  display: block;
  margin-top: -10px;
  color: #aaaaaa;
}
.pricing-table hr {
  border-top-color: #e4e4e4;
  margin-top: 15px;
}
.pricing-table .list {
  list-style: none;
  margin: 15px 0 0;
  padding: 0;
}
.pricing-table .list li {
  font-size: 18px;
  color: #5c5c5c;
}
.pricing-table .list li.featured {
  font-weight: 600;
}
.pricing-table .list li.disabled {
  color: #d4d4d4;
}
.pricing-table .ribbon {
  background-color: #c2d646;
  color: #ffffff;
  font-size: 12px;
  padding: 5px 0 6px;
  position: absolute;
  right: -50px;
  top: 35px;
  width: 200px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(SizingMethod='auto expand', M11=0.7071067811865476, M12=-0.7071067811865475, M21=0.7071067811865475, M22=0.7071067811865476)";
  /* IE8 */
}
.ie8 .pricing-table .ribbon {
  top: -25px;
  right: -65px;
}
.pricing-table .icon img {
  width: 90px;
}
.popover-ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.popover-ul li {
  font-size: 13px !important;
}
.popover-ul li:before {
  content: "\2713";
  font-weight: bold;
}
.question-list {
  margin-top: 35px;
  margin-bottom: 90px;
}
.question-list dd {
  margin-bottom: 35px;
}
/*Custom Skill Report Section **/
.custom-skill-report {
  margin-top: 50px;
  padding-bottom: 100px;
  clear: both;
}
.custom-skill-report .custom-skill-report-img {
  width: 370px;
  height: 367px;
  float: left;
  position: relative;
}
.custom-skill-report .custom-skill-report-img .zoom {
  position: absolute;
  top: -15px;
  left: -15px;
}
.custom-skill-report .custom-skill-report-text {
  margin-left: 400px;
}
.custom-skill-report .custom-skill-report-text p {
  padding-top: 10px;
}
.custom-skill-report .custom-skill-report-text .learn-more,
.rate-preview .rate-preview-text .learn-more {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 40px;
}
#signup-modal .modal-dialog {
  overflow: hidden;
  width: 500px;
}
#signup-modal .modal-dialog .modal-content {
  background-image: -webkit-linear-gradient(top, #ffffff 0%, #f6f6f6 100%);
  background-image: linear-gradient(to bottom, #ffffff 0%, #f6f6f6 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#fff6f6f6', GradientType=0);
}
#signup-modal .modal-dialog .stepc {
  position: relative;
}
#signup-modal .modal-dialog .close,
#signup-modal .modal-dialog .add-new,
#signup-modal .modal-dialog .remove-new {
  width: 22px;
  height: 22px;
  float: none;
  position: absolute;
  top: -30px;
  left: -10px;
  text-align: center;
  padding-left: 1px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
  box-shadow: inset 1px 0.5px 2px rgba(0, 0, 0, 0.2);
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  color: #ffffff;
  border-radius: 50%;
  background-color: #d7d7d7;
}
#signup-modal .modal-dialog .close .fa,
#signup-modal .modal-dialog .add-new .fa,
#signup-modal .modal-dialog .remove-new .fa {
  position: absolute;
  font-size: 14px;
  top: 4px;
  left: 5px;
}
#signup-modal .modal-dialog .close:hover,
#signup-modal .modal-dialog .add-new:hover,
#signup-modal .modal-dialog .remove-new:hover {
  background-color: #cccccc;
}
#signup-modal .modal-dialog .add-new,
#signup-modal .modal-dialog .remove-new {
  top: 12px;
  left: 19px;
  border: 0;
}
#signup-modal .modal-dialog .add-new .fa,
#signup-modal .modal-dialog .remove-new .fa {
  top: 5px;
  left: 5.5px;
}
#signup-modal .modal-dialog input[type="text"],
#signup-modal .modal-dialog input[type="password"] {
  margin-bottom: 15px;
}
#signup-modal .modal-dialog input[type="text"]::placeholder,
#signup-modal .modal-dialog input[type="password"]::placeholder {
  font-weight: 300;
}
#signup-modal .modal-dialog input[type="text"]::-moz-placeholder,
#signup-modal .modal-dialog input[type="password"]::-moz-placeholder {
  font-weight: 300;
}
#signup-modal .modal-dialog input[type="text"]::-webkit-placeholder,
#signup-modal .modal-dialog input[type="password"]::-webkit-placeholder {
  font-weight: 300;
}
#signup-modal .modal-dialog input[type="text"].placeholder,
#signup-modal .modal-dialog input[type="password"].placeholder {
  font-weight: 300;
}
#signup-modal .modal-dialog input[type="text"].has-error,
#signup-modal .modal-dialog input[type="password"].has-error {
  border-color: #f41323;
}
.ie8 #signup-modal .modal-dialog input[type="text"],
.ie8 #signup-modal .modal-dialog input[type="password"] {
  font-family: Arial !important;
}
#signup-modal .modal-dialog #go-login {
  display: inline-block;
  padding: 9.5px 45px;
  border-radius: 3px;
  margin-top: 10px;
}
#signup-modal .modal-dialog .steps {
  position: relative;
  width: 400px;
  margin: 25px auto 0;
  text-align: center;
}
#signup-modal .modal-dialog .steps:after {
  position: absolute;
  z-index: 2;
  top: 16.5px;
  left: 60px;
  width: 280px;
  content: "";
  height: 1px;
  border-bottom: 2px solid #dddddd;
}
#signup-modal .modal-dialog .steps .step {
  position: relative;
  z-index: 3;
  width: 100px;
  margin: 0 15px;
  display: inline-block;
  text-align: center;
  vertical-align: top;
}
#signup-modal .modal-dialog .steps .step .number {
  border-radius: 50%;
  width: 35px;
  height: 35px;
  text-align: center;
  color: #cccccc;
  padding-left: 1px;
  text-shadow: 1px 2px 2px rgba(0, 0, 0, 0.3);
  line-height: 33px;
  font-size: 20px;
  display: inline-block;
  background-image: -webkit-linear-gradient(top, #999999 0%, #555555 100%);
  background-image: linear-gradient(to bottom, #999999 0%, #555555 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff999999', endColorstr='#ff555555', GradientType=0);
  box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.3);
}
#signup-modal .modal-dialog .steps .step .text {
  margin-top: 10px;
  font-size: 17px;
  line-height: 22px;
  color: #bbbbbb;
}
#signup-modal .modal-dialog .steps .step.active .number {
  background-image: -webkit-linear-gradient(top, #c2d646 0%, #aabf2a 100%);
  background-image: linear-gradient(to bottom, #c2d646 0%, #aabf2a 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffc2d646', endColorstr='#ffaabf2a', GradientType=0);
  color: #ffffff;
}
#signup-modal .modal-dialog .steps .step.active .text {
  color: #777777;
}
#signup-modal .modal-dialog .form {
  width: 400px;
  margin: 15px auto;
  text-align: center;
}
#signup-modal .modal-dialog .form .required-text {
  display: block;
  color: #bbbbbb;
  padding-bottom: 10px;
  padding-left: 10px;
  text-align: left;
}
#signup-modal .modal-dialog .form .select2-container {
  width: 100%;
  margin-bottom: 15px;
}
#signup-modal .modal-dialog .form .select2-container .select2-choice {
  height: 46.5px;
}
#signup-modal .modal-dialog .form .select2-container .select2-chosen {
  text-align: left;
  font-size: 18px;
  padding-top: 7px;
  padding-left: 5px;
  color: #aaaaaa;
}
#signup-modal .modal-dialog .form .select2-container.has-error .select2-choice {
  border-color: #f41323;
}
#signup-modal .modal-dialog .form input[type="button"],
#signup-modal .modal-dialog .form button[type="button"] {
  display: inline-block;
  padding: 10px 45px;
  border-radius: 3px;
  margin-top: 10px;
}
#signup-modal .modal-dialog .package-info {
  margin-top: 25px;
}
#signup-modal .modal-dialog .package-info > div {
  margin-top: 10px;
  font-size: 24px;
}
#signup-modal .modal-dialog .package-info .icon > img {
  width: 120px;
}
#signup-modal .modal-dialog .package-info .desc {
  font-size: 18px;
  color: #999999;
}
#signup-modal .modal-dialog .total-charges {
  border-top: 1px dashed #cccccc;
  border-bottom: 1px dashed #cccccc;
  margin-bottom: 15px;
  font-size: 26px;
}
#signup-modal .modal-dialog .total-charges:before,
#signup-modal .modal-dialog .total-charges:after {
  content: " ";
  display: table;
}
#signup-modal .modal-dialog .total-charges:after {
  clear: both;
}
#signup-modal .modal-dialog .total-charges .text {
  float: left;
  padding-top: 14px;
}
#signup-modal .modal-dialog .total-charges .price {
  float: right;
  font-size: 38px;
  color: #c2d646;
}
#signup-modal .modal-dialog .total-charges .price .currency {
  font-size: 20px;
  vertical-align: top;
  display: inline-block;
  padding-top: 8px;
}
#signup-modal .modal-dialog .instant-box {
  margin-top: 25px;
  border: 1px solid #dddddd;
  border-radius: 5px;
}
#signup-modal .modal-dialog .instant-box.ntm {
  margin-top: 0;
}
#signup-modal .modal-dialog .instant-box .top {
  border-bottom: 1px solid #dddddd;
  text-align: left;
  padding: 4px 10px 1px;
}
#signup-modal .modal-dialog .instant-box .top label {
  font-size: 18px;
  font-weight: 400;
  color: #444444;
}
#signup-modal .modal-dialog .instant-box .top label input {
  margin-right: 5px;
}
#signup-modal .modal-dialog .instant-box .bottom {
  padding: 5px 10px;
  text-align: justify;
  font-size: 14px;
  line-height: 18px;
  color: #aaaaaa;
}
#signup-modal .modal-dialog .instant-box .bottom.large {
  font-size: 16px;
  line-height: 20px;
}
#signup-modal .modal-dialog .instant-box .bottom + .input-icon {
  margin-top: 2px;
  margin-bottom: -16px;
  margin-left: -1px;
  margin-right: -1px;
}
#signup-modal .modal-dialog dl {
  text-align: left;
  margin-top: 30px;
}
#signup-modal .modal-dialog dl dt {
  margin-top: 15px;
  color: #666666;
  font-weight: 400;
}
#signup-modal .modal-dialog dl dd {
  font-size: 12px;
  color: #aaaaaa;
  text-align: justify;
}
#signup-modal .modal-dialog .input-icon {
  position: relative;
  display: block;
}
#signup-modal .modal-dialog .input-icon:before,
#signup-modal .modal-dialog .input-icon:after {
  content: " ";
  display: table;
}
#signup-modal .modal-dialog .input-icon:after {
  clear: both;
}
#signup-modal .modal-dialog .input-icon.onleft .first {
  z-index: 20;
  position: absolute;
  left: 3px;
  top: 10px;
  font-size: 18px;
  width: 40px;
  text-align: center;
}
#signup-modal .modal-dialog .input-icon.onleft input[type="text"] {
  padding-left: 40px;
}
#signup-modal .modal-dialog .thankyou {
  margin-top: 45px;
  font-size: 18px;
  color: #aaaaaa;
  text-align: center;
}
#signup-modal .modal-dialog .thankyou h3 {
  color: #444444;
  font-size: 28px;
}
#signup-modal .modal-dialog .thankyou input,
#signup-modal .modal-dialog .thankyou button,
#signup-modal .modal-dialog .thankyou #go-login {
  margin-top: 55px;
}
#report-ex-modal .modal-dialog {
  width: 1005px;
}
#report-ex-modal .modal-dialog .modal-body {
  padding: 10px 0;
}
#report-ex-modal .modal-dialog .modal-body button.close {
  top: 10px;
  position: absolute;
  opacity: 1;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #eaeaea;
  font-weight: 300;
  font-size: 48px;
  text-align: center;
  line-height: 38px;
  color: #d6d6d6;
  right: 10px;
  background-image: -webkit-linear-gradient(top, #f0f0f0 0%, #fcfcfc 100%);
  background-image: linear-gradient(to bottom, #f0f0f0 0%, #fcfcfc 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff0f0f0', endColorstr='#fffcfcfc', GradientType=0);
  outline: none !important;
}
#cc-info {
  margin-top: 20px;
  margin-bottom: -10px;
  text-align: right;
  font-size: 12px;
  color: #777777;
  vertical-align: middle;
}
#cc-info .cc-icons {
  margin: 0;
  padding: 0;
  vertical-align: middle;
  display: inline-block;
  list-style: none;
}
#cc-info .cc-icons li {
  float: left;
  margin-left: 10px;
}
[data-provide="tooltip"]:hover {
  cursor: default;
}
.tooltip .tooltip-inner {
  border-radius: 0;
  background-color: #333333;
  padding: 4px 7px 3px;
}
.tooltip .tooltip-arrow {
  border-top-color: #333333 !important;
}
.terms-agree.has-error {
  color: #f2042b;
}
#special-offer-modal .modal-dialog {
  width: 700px;
}
#special-offer-modal .modal-dialog ul {
  list-style: none;
  margin: 25px 0 0;
  padding: 0;
}
#special-offer-modal .modal-dialog ul:before,
#special-offer-modal .modal-dialog ul:after {
  content: " ";
  display: table;
}
#special-offer-modal .modal-dialog ul:after {
  clear: both;
}
#special-offer-modal .modal-dialog ul li {
  width: 33%;
  float: left;
}
#special-offer-modal .modal-dialog ul li .icon {
  text-align: center;
  margin: 10px 0 15px;
}
#special-offer-modal .modal-dialog ul li .icon img {
  display: inline-block;
  width: 90px;
  height: 90px;
}
#special-offer-modal .modal-dialog ul li h3 {
  padding: 0;
  margin: 0;
  font-size: 20px;
}
#special-offer-modal .modal-dialog ul li h3:first-of-type {
  font-size: 24px;
  margin-bottom: 5px;
}
#special-offer-modal .modal-dialog ul li h3.primary {
  font-style: italic;
}
#where-available .container {
  border-top: 1px dashed #dddddd;
}
#where-available .container:before,
#where-available .container:after {
  content: " ";
  display: table;
}
#where-available .container:after {
  clear: both;
}
#where-available .container .left {
  width: 35%;
  float: left;
  margin-top: 55px;
}
#where-available .container .left .dark i {
  vertical-align: middle;
  margin-top: -1px;
  margin-left: 5px;
  font-size: 10px;
}
#where-available .container .left ul {
  margin: 5px 0 10px;
  padding: 0;
  list-style: none;
}
#where-available .container .left ul:before,
#where-available .container .left ul:after {
  content: " ";
  display: table;
}
#where-available .container .left ul:after {
  clear: both;
}
#where-available .container .left ul li {
  margin: 5px 0px;
  width: 50%;
  float: left;
  font-size: 18px;
}
#where-available .container .left ul li i {
  color: #97aa26;
  margin-right: 10px;
}
#where-available .container .right {
  width: 65%;
  float: right;
  position: relative;
  height: auto;
}
#where-available .container .right img {
  width: 100%;
  height: auto;
}
#where-available .container .right .pins {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
#where-available .container .right .pins li {
  position: absolute;
  width: 22px;
  height: 28px;
  display: block;
  background-image: url("../../img/world_map_marker.png");
}
#where-available .container .right .pins li.usa {
  top: 160px;
  left: 130px;
}
#where-available .container .right .pins li.canada {
  top: 100px;
  left: 105px;
}
#where-available .container .right .pins li.uk {
  top: 110px;
  left: 330px;
}
#where-available .container .right .pins li.india {
  top: 205px;
  left: 497px;
}
#coming-countries-modal .modal-dialog {
  width: 420px;
}
#coming-countries-modal .modal-content {
  background-image: -webkit-linear-gradient(top, #ffffff 0%, #f6f6f6 100%);
  background-image: linear-gradient(to bottom, #ffffff 0%, #f6f6f6 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#fff6f6f6', GradientType=0);
  padding-bottom: 15px;
}
#coming-countries-modal h3 {
  margin-top: 10px;
}
#coming-countries-modal h4 {
  margin-top: 25px;
  padding-left: 15px;
  padding-right: 15px;
}
#coming-countries-modal .row {
  padding-left: 15px;
  padding-right: 15px;
}
#coming-countries-modal .row > div {
  line-height: 22px;
  position: relative;
  padding-left: 35px;
}
#coming-countries-modal .fa {
  margin-right: 5px;
  color: #c2d646;
  font-size: 16px;
  position: absolute;
  top: 4px;
  left: 15px;
}
#coming-countries-modal button.close {
  top: 10px;
  position: absolute;
  opacity: 1;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #eaeaea;
  font-weight: 300;
  font-size: 48px;
  text-align: center;
  line-height: 38px;
  color: #d6d6d6;
  right: 10px;
  background-image: -webkit-linear-gradient(top, #f0f0f0 0%, #fcfcfc 100%);
  background-image: linear-gradient(to bottom, #f0f0f0 0%, #fcfcfc 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff0f0f0', endColorstr='#fffcfcfc', GradientType=0);
  outline: none !important;
}
#coming-countries-modal button.close:hover {
  border-color: #dadada;
  color: #c6c6c6;
}
@media (max-width: 480px) {
  #where-available .container .left {
    margin-left: 5px !important;
    width: 100% !important;
  }
  #coming-countries-modal .modal-dialog {
    width: 94%;
  }
  #coming-countries-modal h3 {
    text-align: left !important;
  }
  #coming-countries-modal h4 {
    padding-left: 0;
    font-size: 16px !important;
  }
  #coming-countries-modal .row {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (max-width: 767px) {
  #where-available .container .left {
    margin-left: 10px;
    width: 80%;
    float: none;
  }
  #where-available .container .right {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  #where-available .container .left {
    margin-top: 0;
  }
  #where-available .container .left ul li {
    width: 100%;
    float: none;
  }
  #where-available .container .right .pins li.usa {
    top: 93px;
    left: 80px;
  }
  #where-available .container .right .pins li.canada {
    top: 60px;
    left: 65px;
  }
  #where-available .container .right .pins li.uk {
    top: 65px;
    left: 205px;
  }
  #where-available .container .right .pins li.india {
    top: 115px;
    left: 310px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  #where-available .container .right .pins li.usa {
    top: 130px;
    left: 105px;
  }
  #where-available .container .right .pins li.canada {
    top: 85px;
    left: 85px;
  }
  #where-available .container .right .pins li.uk {
    top: 85px;
    left: 270px;
  }
  #where-available .container .right .pins li.india {
    top: 160px;
    left: 410px;
  }
}
/** Features **/
.features-top {
  background-image: url("../../img/pattern_bg.png");
  background-position: bottom center;
  background-repeat: no-repeat;
  border-bottom: 1px solid #e4e4e4;
}
.features-preview {
  width: 841px;
  height: 415px;
  margin: 70px auto 0;
  display: block;
  position: relative;
}
.features-preview img {
  width: 100%;
}
.features-preview .zoom {
  position: absolute;
  bottom: -20px;
  left: 50%;
  margin-left: -20px;
}
.features-base {
  padding: 65px 0;
}
.feature-box {
  margin: 25px 0;
}
.feature-box .icon {
  float: left;
  width: 50px;
  padding-top: 5px;
  height: 50px;
  text-align: center;
  display: table;
}
.feature-box .icon .inner {
  width: 50px;
  height: 50px;
  display: table-cell;
  vertical-align: middle;
}
.feature-box .icon img {
  display: inline;
}
.feature-box .content {
  padding: 0;
  margin-left: 63px;
  color: #ffffff;
}
.feature-box .content h3 {
  margin-top: 0;
}
.rate-preview {
  padding: 25px 0 100px;
}
.rate-preview .rate-preview-img {
  float: left;
  width: 571px;
  position: relative;
  height: 425px;
}
.rate-preview .rate-preview-text {
  margin-left: 600px;
}
#aboutNavCont.fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 5px;
  z-index: 50;
  background-color: #fff;
  border-bottom: 1px solid #eeeeee;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1);
}
#aboutNavCont.fixed + section {
  margin-top: 92px;
}
.about .img {
  height: 510px;
  background-image: url("../../img/about_us_banner.jpg");
  background-position: top center;
}
.about-section,
.meet-team {
  padding-bottom: 65px;
}
.map {
  overflow: hidden;
  margin: 0 auto;
  position: relative;
}
.map #map {
  height: 530px;
}
.map:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  box-shadow: 0 0 5px #000;
  z-index: 100000;
}
.map:before {
  top: -1px;
}
.map iframe {
  width: 102%;
  height: 530px;
}
.gm-style a > div,
.gm-style .gm-style-cc {
  display: none;
}
.indicator {
  position: absolute;
}
.indicator .indicator-icon {
  z-index: 2;
  position: relative;
  width: 34px;
  height: 34px;
  display: block;
}
/* .indicator .indicator-icon.female {
  background-image: url('../../img/icons/map_indicator_female.png');
}
.indicator .indicator-icon.male {
  background-image: url('../../img/icons/map_indicator_male.png');
} */
.indicator .indicator-popup {
  -webkit-transition: opacity 0.2s ease 0.2s;
  -moz-transition: opacity 0.2s ease 0.2s;
  -ms-transition: opacity 0.2s ease 0.2s;
  transition: opacity 0.2s ease 0.2s;
  visibility: hidden;
  opacity: 0;
  z-index: 3;
  background-color: #ffffff;
  border: 3px solid #555555;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  position: absolute;
  top: 20px;
  left: 20px;
}
.indicator .indicator-popup table {
  font-size: 16px;
}
.indicator .indicator-popup table th,
.indicator .indicator-popup table td {
  border-bottom: 1px solid #dddddd;
  padding: 2px 10px;
  white-space: nowrap;
}
.indicator .indicator-popup table tbody tr:last-child td {
  border-bottom: 0;
}
.indicator .indicator-popup table th {
  font-weight: 600;
}
.indicator .indicator-popup table td:first-child {
  color: #aaaaaa;
  text-align: right;
  border-right: 1px solid #dddddd;
  width: 90px;
}
.indicator .indicator-popup table td .good,
.indicator .indicator-popup table td .bad {
  display: inline-block;
}
.indicator .indicator-popup table td .good .chevron,
.indicator .indicator-popup table td .bad .chevron {
  display: inline-block;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0 2px;
}
.indicator .indicator-popup table td .good {
  color: #00b80c;
}
.indicator .indicator-popup table td .good .chevron:before {
  content: "\f0d8";
}
.indicator .indicator-popup table td .bad {
  color: #c70010;
}
.indicator .indicator-popup table td .bad .chevron:before {
  content: "\f0d7";
}
.indicator:hover .indicator-icon {
  z-index: 4;
}
.indicator:hover .indicator-popup {
  visibility: visible;
  opacity: 1;
}
.team-sortable {
  margin: 35px 0 20px;
  text-align: center;
}
.nav {
  display: inline-block;
}
.nav li {
  margin: 0 5px;
}
.nav li a {
  color: #999999;
  font-size: 20px;
}
.nav li:hover a {
  background-color: #f9f9f9;
}
.nav li.active a,
.nav li.active a:hover,
.nav li.active a:focus {
  background-color: #c2d646;
  color: #ffffff;
}
.skills-village .glyphicon-share-alt {
  font-size: 12px;
  top: -10px;
  left: 6px;
}
#team-members {
  margin: 0;
}
#team-members .item {
  height: 250px;
}
.team-member {
  height: 90%;
  border-radius: 5px;
  overflow: hidden;
  background-color: #f4f4f4;
  margin: 15px 0;
}
.team-member .team-member-img {
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
  padding-left: 0;
  padding-right: 0;
  background-position: top center;
  overflow: hidden;
}
.team-member .team-member-img img {
  width: 100%;
  border-radius: 9px;
  border: none;
  cursor: pointer;
}
.team-member .team-member-info {
  text-align: left;
  padding-bottom: 20px;
  height: 100%;
  position: relative;
}
.team-member .team-member-info .team-member-name {
  margin-top: 15px;
  margin-bottom: 0;
  font-weight: 600;
  font-size: 18px;
}
.team-member .team-member-info .team-member-who,
.team-member .team-member-info .team-member-description {
  font-size: 17px;
  font-weight: 300;
  padding-right: 15px;
}
.team-member .team-member-info .team-member-description {
  font-size: 14px;
}
.team-member .team-member-info .team-member-who {
  font-style: italic;
  font-size: 16px;
}
.team-member .team-member-info .team-member-company {
  font-weight: bold;
  font-size: 110%;
}
.team-member .team-member-info .read-more {
  margin-top: 10px;
  padding-left: 25px;
  padding-right: 25px;
  position: absolute;
  bottom: 10px;
  right: 0;
}
#about-modal {
  overflow: hidden !important;
}
#about-modal .modal-dialog {
  position: fixed;
  top: 50% !important;
  left: 50%;
  margin-left: -450px;
  margin-top: -200px;
  width: 900px;
}
#about-modal .modal-dialog .modal-content {
  background-color: #fcfcfc;
}
#about-modal .modal-dialog .modal-content .modal-header {
  border-bottom: 0;
  padding: 10px 10px 40px 0;
}
#about-modal .modal-dialog .modal-content .modal-header button.close {
  opacity: 1;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #eaeaea;
  font-weight: 300;
  font-size: 48px;
  text-align: center;
  line-height: 38px;
  color: #d6d6d6;
  background-image: -webkit-linear-gradient(top, #f0f0f0 0%, #fcfcfc 100%);
  background-image: linear-gradient(to bottom, #f0f0f0 0%, #fcfcfc 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff0f0f0', endColorstr='#fffcfcfc', GradientType=0);
  outline: none !important;
}
#about-modal .modal-dialog .modal-content .modal-body {
  padding: 15px 70px 45px;
}
#about-modal .modal-dialog .modal-content .modal-body:before,
#about-modal .modal-dialog .modal-content .modal-body:after {
  content: " ";
  display: table;
}
#about-modal .modal-dialog .modal-content .modal-body:after {
  clear: both;
}
#about-modal .left {
  float: left;
  width: 25%;
}
#about-modal .left .timg {
  position: relative;
  width: 100%;
  max-height: 200px;
  overflow: hidden;
  text-align: center;
  border-radius: 5px;
  margin-bottom: 20px;
}
#about-modal .left .timg img {
  z-index: 2;
  position: relative;
  left: 100%;
  margin-left: -200%;
  width: 100%;
  height: auto;
}
#about-modal .left .timg:before {
  z-index: 3;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
}
#about-modal .right {
  float: left;
  width: 70%;
  margin-left: 5%;
}
#about-modal .right .tname {
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 0;
  font-size: 26px;
}
#about-modal .right .twho {
  margin-top: 5px;
  padding-top: 0;
  font-weight: 300;
  font-style: italic;
  padding-bottom: 0;
  margin-bottom: 5px;
  font-size: 20px;
}
#about-modal .right .tdesc {
  font-size: 16px;
}
.isotope,
.isotope .item {
  -webkit-transition-duration: 0.8s;
  -moz-transition-duration: 0.8s;
  -ms-transition-duration: 0.8s;
  -o-transition-duration: 0.8s;
  transition-duration: 0.8s;
}
.isotope {
  -webkit-transition-property: height, width;
  -moz-transition-property: height, width;
  -ms-transition-property: height, width;
  -o-transition-property: height, width;
  transition-property: height, width;
}
.isotope .item {
  -webkit-transition-property: -webkit-transform, opacity;
  -moz-transition-property: -moz-transform, opacity;
  -ms-transition-property: -ms-transform, opacity;
  -o-transition-property: top, left, opacity;
  transition-property: transform, opacity;
}
.values {
  height: 500px;
}
.values .circles,
.values .values-content {
  list-style: none;
  margin: 50px 0 0;
  padding: 0;
}
.values .circles:before,
.values .circles:after {
  content: " ";
  display: table;
}
.values .circles:after {
  clear: both;
}
.values .circles li {
  width: 20%;
  text-align: center;
  display: block;
  float: left;
}
.values .circles li a {
  border-radius: 50%;
  display: inline-block;
  border: 2px solid #e2e2e2;
  color: #e2e2e2;
  width: 150px;
  height: 150px;
  text-align: center;
  line-height: 145px;
  font-size: 42px;
  -webkit-transition: border 0.3s, color 0.3s;
  transition: border 0.3s, color 0.3s;
}
.values .circles li.active a,
.values .circles li a:hover {
  border-color: #c2d646;
  color: #c2d646;
}
.values .values-content {
  position: relative;
  width: 100%;
}
.values .values-content li {
  position: absolute;
  width: 100%;
  left: 0;
  opacity: 0;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}
.ie8 .values .values-content li {
  display: none;
}
.values .values-content li.active {
  opacity: 1;
}
.ie8 .values .values-content li.active {
  display: block;
}
.values .values-content li h3,
.values .values-content li p {
  text-align: center;
}
.values .values-content li h3 {
  margin-bottom: 20px;
}
.job-openings {
  padding-bottom: 60px;
}
.job-openings ul.nav {
  margin-top: 25px;
  margin-bottom: 35px;
  text-align: center;
}
.job-openings ul.nav li {
  display: inline-block;
  float: none;
}
.suppliers-resellers h2 + h2 {
  margin-top: 0;
}
.suppliers-resellers ul {
  list-style: none;
  margin: 50px auto 70px;
  padding: 0;
  width: 960px;
}
.suppliers-resellers ul li {
  width: 48%;
}
.suppliers-resellers ul li a {
  display: block;
  padding: 10px 20px 20px;
  opacity: 0.3;
  -webkit-transition: background-color 0.2s, opacity 0.2s;
  transition: background-color 0.2s, opacity 0.2s;
}
.ie8 .suppliers-resellers ul li a {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
}
.suppliers-resellers ul li a:before,
.suppliers-resellers ul li a:after {
  content: "";
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  position: absolute;
  left: 50%;
  margin-left: -15px;
  opacity: 0;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
}
.suppliers-resellers ul li a:before {
  bottom: -28px;
  z-index: 1;
  border-top: 25px solid #222222;
}
.suppliers-resellers ul li a:after {
  bottom: -25px;
  z-index: 2;
  border-top: 25px solid #4f4f4f;
}
.suppliers-resellers ul li a p {
  color: #ffffff;
}
.suppliers-resellers ul li.active a {
  position: relative;
  background-color: #4f4f4f;
  opacity: 1;
  border-bottom: 2px solid #222222;
}
.suppliers-resellers ul li.active a:before,
.suppliers-resellers ul li.active a:after {
  opacity: 1;
}
.ie8 .suppliers-resellers ul li.active a {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Enabled=false)" !important;
}
.ie8 .suppliers-resellers ul li.active a:hover {
  text-decoration: none !important;
}
.suppliers-resellers img {
  display: block;
  margin: 0 auto -65px;
}
.became-partner {
  padding-bottom: 60px;
}
.became-partner h3 {
  font-size: 32px;
}
.became-partner .btn {
  margin-top: 25px;
  width: 150px;
}
.rbox-opening-list,
.rbox-opening-detail {
  background-color: #fff;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  margin-top: 45px;
}
.rbox-opening-list .rbox-opening-li {
  border-top: 1px solid #e6e6e6;
  border-bottom: 0;
  padding: 10px 15px;
}
.rbox-opening-list .rbox-opening-li:first-child {
  border-top: 0;
}
.rbox-opening-detail .rbox-top-links,
.rbox-opening-detail .rbox-bottom-links {
  font-size: 16px;
  padding: 10px 15px;
}
.rbox-opening-detail .rbox-top-links a,
.rbox-opening-detail .rbox-bottom-links a {
  color: #888888;
}
.rbox-opening-detail .rbox-top-links a:hover,
.rbox-opening-detail .rbox-bottom-links a:hover {
  color: #555555;
}
.rbox-opening-detail .rbox-top-links {
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 5px;
}
.rbox-opening-detail .rbox-bottom-links {
  border-top: 1px solid #e6e6e6;
  margin-top: 15px;
}
.rbox-opening-detail .rbox-job-title,
.rbox-opening-detail .rbox-job-shortdesc,
.rbox-opening-detail .rbox-job-longdesc,
.rbox-opening-detail .rbox-buttons,
.rbox-opening-detail .rbox-application {
  padding: 0 15px;
}
.rbox-opening-detail .rbox-job-shortdesc {
  padding-bottom: 10px;
}
.rbox-opening-detail .rbox-job-longdesc {
  padding-top: 10px;
}
.rbox-opening-detail .rbox-btn {
  background-color: #c2d646 !important;
  color: #ffffff !important;
  border-color: #c2d646 !important;
  box-shadow: none !important;
  background-image: none !important;
  text-shadow: none !important;
}
.contact .main-wrap .container > .row:first-child {
  margin-top: 35px;
}
.contact .img-responsive {
  display: inline-block;
  width: 100%;
}
.contact .main-details h3 {
  margin-top: 0;
}
.contact .list {
  margin: 15px 0 0;
  padding: 0;
  list-style: none;
}
.contact .list li,
.contact .list li a {
  font-size: 18px;
  line-height: 30px;
  color: #5c5c5c;
}
.contact .list li img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  vertical-align: bottom;
}
.contact .list li.contact-info {
  margin-top: 15px;
}
.contact .list li.contact-info:hover a {
  color: #c2d646;
}
.contact .support-box {
  margin: 80px 0;
  background-color: #f4f4f4;
  border: 1px solid #f4f4f4;
  padding: 35px 10px;
  border-radius: 3px;
  -webkit-transition: border 0.15s ease-in-out;
  transition: border 0.15s ease-in-out;
}
.contact .support-box img {
  display: block;
  width: 70px;
  margin: 0 auto;
}
.contact .support-box h3 {
  margin-bottom: 5px;
}
.contact .support-box p {
  margin-bottom: 0;
}
.contact .support-box.sales,
.contact .support-box.press {
  padding-bottom: 42px;
}
.contact .support-box.sales img,
.contact .support-box.press img {
  margin-top: 15px;
}
.contact .support-box:hover {
  cursor: pointer;
  border-color: #c2d646;
}
#apply-job-modal .modal-dialog,
#partner-with-us-modal .modal-dialog,
#request-modal .modal-dialog,
#support-modal .modal-dialog,
#press-modal .modal-dialog,
#sales-modal .modal-dialog,
#contact-me-modal .modal-dialog {
  overflow: hidden;
  width: 500px;
}
#apply-job-modal .modal-dialog .modal-content,
#partner-with-us-modal .modal-dialog .modal-content,
#request-modal .modal-dialog .modal-content,
#support-modal .modal-dialog .modal-content,
#press-modal .modal-dialog .modal-content,
#sales-modal .modal-dialog .modal-content,
#contact-me-modal .modal-dialog .modal-content {
  background-image: -webkit-linear-gradient(top, #ffffff 0%, #f6f6f6 100%);
  background-image: linear-gradient(to bottom, #ffffff 0%, #f6f6f6 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#fff6f6f6', GradientType=0);
}
#apply-job-modal .modal-dialog h2,
#partner-with-us-modal .modal-dialog h2,
#request-modal .modal-dialog h2,
#support-modal .modal-dialog h2,
#press-modal .modal-dialog h2,
#sales-modal .modal-dialog h2,
#contact-me-modal .modal-dialog h2 {
  margin-top: 5px;
}
#apply-job-modal .modal-dialog button.close,
#partner-with-us-modal .modal-dialog button.close,
#request-modal .modal-dialog button.close,
#support-modal .modal-dialog button.close,
#press-modal .modal-dialog button.close,
#sales-modal .modal-dialog button.close,
#contact-me-modal .modal-dialog button.close {
  top: 10px;
  position: absolute;
  opacity: 1;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #eaeaea;
  font-weight: 300;
  font-size: 48px;
  text-align: center;
  line-height: 38px;
  color: #d6d6d6;
  right: 10px;
  background-image: -webkit-linear-gradient(top, #f0f0f0 0%, #fcfcfc 100%);
  background-image: linear-gradient(to bottom, #f0f0f0 0%, #fcfcfc 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff0f0f0', endColorstr='#fffcfcfc', GradientType=0);
  outline: none !important;
}
.ie8 #apply-job-modal .modal-dialog button.close,
.ie8 #partner-with-us-modal .modal-dialog button.close,
.ie8 #request-modal .modal-dialog button.close,
.ie8 #support-modal .modal-dialog button.close,
.ie8 #press-modal .modal-dialog button.close,
.ie8 #sales-modal .modal-dialog button.close,
.ie8 #contact-me-modal .modal-dialog button.close {
  width: 30px;
  height: 30px;
  line-height: 30px;
  padding-top: 0;
  font-size: 36px;
  margin-top: 0;
}
#apply-job-modal .modal-dialog button.close:hover,
#partner-with-us-modal .modal-dialog button.close:hover,
#request-modal .modal-dialog button.close:hover,
#support-modal .modal-dialog button.close:hover,
#press-modal .modal-dialog button.close:hover,
#sales-modal .modal-dialog button.close:hover,
#contact-me-modal .modal-dialog button.close:hover {
  border-color: #ccc;
  color: #bbb;
}
#apply-job-modal .modal-dialog input[type="text"],
#partner-with-us-modal .modal-dialog input[type="text"],
#request-modal .modal-dialog input[type="text"],
#support-modal .modal-dialog input[type="text"],
#press-modal .modal-dialog input[type="text"],
#sales-modal .modal-dialog input[type="text"],
#contact-me-modal .modal-dialog input[type="text"],
#apply-job-modal .modal-dialog input[type="password"],
#partner-with-us-modal .modal-dialog input[type="password"],
#request-modal .modal-dialog input[type="password"],
#support-modal .modal-dialog input[type="password"],
#press-modal .modal-dialog input[type="password"],
#sales-modal .modal-dialog input[type="password"],
#contact-me-modal .modal-dialog input[type="password"] {
  margin-bottom: 15px;
}
#apply-job-modal .modal-dialog input[type="text"]::placeholder,
#partner-with-us-modal .modal-dialog input[type="text"]::placeholder,
#request-modal .modal-dialog input[type="text"]::placeholder,
#support-modal .modal-dialog input[type="text"]::placeholder,
#press-modal .modal-dialog input[type="text"]::placeholder,
#sales-modal .modal-dialog input[type="text"]::placeholder,
#contact-me-modal .modal-dialog input[type="text"]::placeholder,
#apply-job-modal .modal-dialog input[type="password"]::placeholder,
#partner-with-us-modal .modal-dialog input[type="password"]::placeholder,
#request-modal .modal-dialog input[type="password"]::placeholder,
#support-modal .modal-dialog input[type="password"]::placeholder,
#press-modal .modal-dialog input[type="password"]::placeholder,
#sales-modal .modal-dialog input[type="password"]::placeholder,
#contact-me-modal .modal-dialog input[type="password"]::placeholder {
  font-weight: 300;
}
#apply-job-modal .modal-dialog input[type="text"]::-moz-placeholder,
#partner-with-us-modal .modal-dialog input[type="text"]::-moz-placeholder,
#request-modal .modal-dialog input[type="text"]::-moz-placeholder,
#support-modal .modal-dialog input[type="text"]::-moz-placeholder,
#press-modal .modal-dialog input[type="text"]::-moz-placeholder,
#sales-modal .modal-dialog input[type="text"]::-moz-placeholder,
#contact-me-modal .modal-dialog input[type="text"]::-moz-placeholder,
#apply-job-modal .modal-dialog input[type="password"]::-moz-placeholder,
#partner-with-us-modal .modal-dialog input[type="password"]::-moz-placeholder,
#request-modal .modal-dialog input[type="password"]::-moz-placeholder,
#support-modal .modal-dialog input[type="password"]::-moz-placeholder,
#press-modal .modal-dialog input[type="password"]::-moz-placeholder,
#sales-modal .modal-dialog input[type="password"]::-moz-placeholder,
#contact-me-modal .modal-dialog input[type="password"]::-moz-placeholder {
  font-weight: 300;
}
#apply-job-modal .modal-dialog input[type="text"]::-webkit-placeholder,
#partner-with-us-modal .modal-dialog input[type="text"]::-webkit-placeholder,
#request-modal .modal-dialog input[type="text"]::-webkit-placeholder,
#support-modal .modal-dialog input[type="text"]::-webkit-placeholder,
#press-modal .modal-dialog input[type="text"]::-webkit-placeholder,
#sales-modal .modal-dialog input[type="text"]::-webkit-placeholder,
#contact-me-modal .modal-dialog input[type="text"]::-webkit-placeholder,
#apply-job-modal .modal-dialog input[type="password"]::-webkit-placeholder,
#partner-with-us-modal .modal-dialog input[type="password"]::-webkit-placeholder,
#request-modal .modal-dialog input[type="password"]::-webkit-placeholder,
#support-modal .modal-dialog input[type="password"]::-webkit-placeholder,
#press-modal .modal-dialog input[type="password"]::-webkit-placeholder,
#sales-modal .modal-dialog input[type="password"]::-webkit-placeholder,
#contact-me-modal .modal-dialog input[type="password"]::-webkit-placeholder {
  font-weight: 300;
}
#apply-job-modal .modal-dialog input[type="text"].placeholder,
#partner-with-us-modal .modal-dialog input[type="text"].placeholder,
#request-modal .modal-dialog input[type="text"].placeholder,
#support-modal .modal-dialog input[type="text"].placeholder,
#press-modal .modal-dialog input[type="text"].placeholder,
#sales-modal .modal-dialog input[type="text"].placeholder,
#contact-me-modal .modal-dialog input[type="text"].placeholder,
#apply-job-modal .modal-dialog input[type="password"].placeholder,
#partner-with-us-modal .modal-dialog input[type="password"].placeholder,
#request-modal .modal-dialog input[type="password"].placeholder,
#support-modal .modal-dialog input[type="password"].placeholder,
#press-modal .modal-dialog input[type="password"].placeholder,
#sales-modal .modal-dialog input[type="password"].placeholder,
#contact-me-modal .modal-dialog input[type="password"].placeholder {
  font-weight: 300;
}
#apply-job-modal .modal-dialog input[type="text"].has-error,
#partner-with-us-modal .modal-dialog input[type="text"].has-error,
#request-modal .modal-dialog input[type="text"].has-error,
#support-modal .modal-dialog input[type="text"].has-error,
#press-modal .modal-dialog input[type="text"].has-error,
#sales-modal .modal-dialog input[type="text"].has-error,
#contact-me-modal .modal-dialog input[type="text"].has-error,
#apply-job-modal .modal-dialog input[type="password"].has-error,
#partner-with-us-modal .modal-dialog input[type="password"].has-error,
#request-modal .modal-dialog input[type="password"].has-error,
#support-modal .modal-dialog input[type="password"].has-error,
#press-modal .modal-dialog input[type="password"].has-error,
#sales-modal .modal-dialog input[type="password"].has-error,
#contact-me-modal .modal-dialog input[type="password"].has-error {
  border-color: #f41323;
}
#apply-job-modal .modal-dialog input[type="button"],
#partner-with-us-modal .modal-dialog input[type="button"],
#request-modal .modal-dialog input[type="button"],
#support-modal .modal-dialog input[type="button"],
#press-modal .modal-dialog input[type="button"],
#sales-modal .modal-dialog input[type="button"],
#contact-me-modal .modal-dialog input[type="button"],
#apply-job-modal .modal-dialog input[type="submit"],
#partner-with-us-modal .modal-dialog input[type="submit"],
#request-modal .modal-dialog input[type="submit"],
#support-modal .modal-dialog input[type="submit"],
#press-modal .modal-dialog input[type="submit"],
#sales-modal .modal-dialog input[type="submit"],
#contact-me-modal .modal-dialog input[type="submit"] {
  display: inline-block;
  padding: 10px 0;
  width: 150px;
  text-align: center;
  border-radius: 3px;
  margin-top: 10px;
}
#apply-job-modal .modal-dialog button[type="submit"],
#partner-with-us-modal .modal-dialog button[type="submit"],
#request-modal .modal-dialog button[type="submit"],
#support-modal .modal-dialog button[type="submit"],
#press-modal .modal-dialog button[type="submit"],
#sales-modal .modal-dialog button[type="submit"],
#contact-me-modal .modal-dialog button[type="submit"] {
  display: inline-block;
  padding: 10px 0;
  width: 150px;
  text-align: center;
  border-radius: 3px;
  margin-top: 10px;
}
#apply-job-modal .modal-dialog form,
#partner-with-us-modal .modal-dialog form,
#request-modal .modal-dialog form,
#support-modal .modal-dialog form,
#press-modal .modal-dialog form,
#sales-modal .modal-dialog form,
#contact-me-modal .modal-dialog form {
  width: 400px;
  margin: 35px auto 15px;
  text-align: center;
}
#apply-job-modal .modal-dialog a,
#partner-with-us-modal .modal-dialog a,
#request-modal .modal-dialog a,
#support-modal .modal-dialog a,
#press-modal .modal-dialog a,
#sales-modal .modal-dialog a,
#contact-me-modal .modal-dialog a {
  color: #999999;
}
.field-container {
  position: relative;
}
.field-container .error {
  position: absolute;
  right: 15px;
  top: 13px;
  color: #f41323;
  opacity: 0;
}
.field-container .error + .tooltip .tooltip-inner {
  background-color: #f41323;
}
.field-container .error:hover {
  cursor: default;
}
.field-container.file .error {
  top: 5px;
}
.solutions .img {
  margin-top: 50px;
  height: 510px;
  background-image: url("../../img/solutions_banner.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #575a5f;
}
.solutions .procurement h2.primary,
.solutions .human-res h2.primary,
.solutions .sow h2.primary {
  margin-top: 0;
  margin-bottom: 0;
}
.solutions .procurement h2.light,
.solutions .human-res h2.light,
.solutions .sow h2.light {
  margin-top: 5px;
  margin-bottom: 20px;
}
.solutions .procurement p.light,
.solutions .human-res p.light,
.solutions .sow p.light {
  font-weight: 400;
  margin-bottom: 40px;
  color: #666666;
}
.solutions .procurement a.btn,
.solutions .human-res a.btn,
.solutions .sow a.btn {
  width: 160px;
  padding-left: 0;
  padding-right: 0;
  border-radius: 5px;
  margin-bottom: 35px;
}
.solutions .procurement a.btn + a.btn,
.solutions .human-res a.btn + a.btn,
.solutions .sow a.btn + a.btn {
  margin-left: 15px;
}
.solutions .procurement .model {
  width: 432px;
  margin-left: -100px;
}
.solutions .sow .model {
  width: 398px;
  margin-left: -65px;
}
.solutions .main-wrap > section:last-child {
  position: absolute;
}
#nw-ex-modal .modal-dialog {
  width: 800px;
}
#nw-ex-modal .modal-dialog .modal-body {
  padding: 10px 0;
}
#nw-ex-modal .modal-dialog .modal-body h3 {
  margin: 0;
  padding: 5px 0 0 25px;
}
#nw-ex-modal .modal-dialog .modal-body button.close {
  top: 10px;
  position: absolute;
  opacity: 1;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #eaeaea;
  font-weight: 300;
  font-size: 48px;
  text-align: center;
  line-height: 38px;
  color: #d6d6d6;
  right: 10px;
  background-image: -webkit-linear-gradient(top, #f0f0f0 0%, #fcfcfc 100%);
  background-image: linear-gradient(to bottom, #f0f0f0 0%, #fcfcfc 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff0f0f0', endColorstr='#fffcfcfc', GradientType=0);
  outline: none !important;
}
#nw-ex-modal .modal-dialog .modal-body img {
  margin-left: 10px;
  margin-top: 15px;
}
@media (max-width: 767px) {
  .solutions .img {
    height: 250px;
    -webkit-background-size: cover;
    background-size: cover;
  }
}
@media (max-width: 991px) {
  .solutions img.model {
    display: none;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .solutions .procurement img.model {
    width: 500px;
    margin-left: -40px;
  }
  .solutions .human-res img.model {
    width: 600px;
    margin-top: 55px;
  }
  .solutions .sow img.model {
    width: 430px;
    margin-top: 5px;
    margin-left: -50px;
  }
}
.data h2 {
  margin-bottom: 5px;
}
.data h2 + h2 {
  margin-top: 0;
}
.data .compare,
.data .acquisition,
.data .process {
  padding-bottom: 60px;
}
.data .img {
  margin-top: 50px;
  height: 510px;
  background-image: url("../../img/data_banner.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #575a5f;
}
.data table thead tr th {
  background-color: #cccccc;
  color: #797979;
  text-align: center;
  font-size: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-weight: 400;
  border: 0;
}
.data table tbody tr td {
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  padding: 20px 35px;
  color: #666666;
}
.data table tbody tr.odd td {
  background-color: #f2f2f2;
}
.data .list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.data .list li {
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 10px;
  padding-right: 50px;
}
.data .list li i {
  float: left;
  margin-top: 2px;
  margin-right: 20px;
  padding-bottom: 20px;
  color: #c2d646;
}
.data .crops {
  background-image: url("../../img/crops.png");
  margin: 50px 0 0;
  width: 1170px;
  height: 350px;
}
.data .crops .text {
  margin-top: -3px;
  margin-left: 10px;
  display: inline-block;
  font-size: 20px;
  line-height: 40px;
}
.data .logo-grid {
  margin-top: 25px;
}
.data .logo-grid img {
  margin: 20px 0;
  width: 100%;
}
.data .logo-grid .amazon,
.data .logo-grid .apache_solr,
.data .logo-grid .hadoop,
.data .logo-grid .scikits,
.data .logo-grid .python,
.data .logo-grid .nodejs {
  border-bottom: 1px solid #bbbbbb;
}
.data .logo-grid .amazon,
.data .logo-grid .apache_solr,
.data .logo-grid .hadoop,
.data .logo-grid .scikits,
.data .logo-grid .python,
.data .logo-grid .mongodb,
.data .logo-grid .github,
.data .logo-grid .stanford_nlp,
.data .logo-grid .hive,
.data .logo-grid .weka {
  border-right: 1px solid #bbbbbb;
}
.data .process table {
  width: 100%;
}
.data .process table tr td {
  width: 50%;
  font-size: 18px;
  color: #555555;
  text-align: left;
  padding: 5px 10px;
}
.data .process table tr.even td {
  background-color: #ccc;
}
.data .process img {
  width: 100%;
}
.data .process .levels {
  display: block;
  width: 100%;
  margin: 20px 0;
  padding: 0;
  list-style: none;
}
.data .process .levels:before,
.data .process .levels:after {
  content: " ";
  display: table;
}
.data .process .levels:after {
  clear: both;
}
.data .process .levels li {
  margin: 0;
  padding: 0;
  width: 20%;
  display: block;
  float: left;
  font-size: 20px;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
  border: 1px solid #f4f4f4;
  margin-left: -1px;
}
.data .process .levels li.j {
  margin-left: 0;
  background-color: #66cd66;
}
.data .process .levels li.m {
  background-color: #ccff66;
}
.data .process .levels li.s {
  background-color: #ffff65;
}
.data .process .levels li.l {
  background-color: #ffcc66;
}
.data .process .levels li.g {
  background-color: #ff9999;
}
.data .desktop-slider {
  position: relative;
}
.data .desktop-slider > img {
  position: relative;
  z-index: 3;
}
.data .desktop-slider .carousel {
  position: absolute;
  top: 22px;
  left: 4%;
  z-index: 2;
  width: 92%;
  height: 300px;
  overflow: hidden;
}
@media (max-width: 767px) {
  .data .desktop-slider .carousel {
    top: 5%;
    height: 70%;
  }
}
@media (max-width: 991px) {
  .data .logo-grid .hadoop,
  .data .logo-grid .stanford_nlp {
    border-right: 0;
  }
  .data .logo-grid .mongodb,
  .data .logo-grid .github,
  .data .logo-grid .stanford_nlp {
    border-bottom: 1px solid #bbbbbb;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .data .desktop-slider .carousel {
    top: 28px;
    height: 380px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .data .desktop-slider {
    margin-top: 70px;
  }
  .data .desktop-slider .carousel {
    top: 18px;
    width: 92.1%;
    height: 250px;
  }
}
.dna #page-heading {
  background: url("../../img/page_head_dna_bg.jpg") no-repeat top center;
}
.dna #page-heading .container h1 {
  position: relative;
}
.dna #page-heading .container h1:before {
  position: absolute;
  top: -53px;
  left: 80px;
  content: "";
  background: url("../../img/dna_small.png") no-repeat top left;
  width: 65px;
  height: 164px;
}
.dna h2 {
  margin-bottom: 5px;
}
.dna h2 + h3 {
  margin-top: 0;
}
.dna .dna-data {
  padding-top: 0;
}
.dna .dna-data h3 {
  margin-top: 40px;
}
.dna .img {
  margin-top: 50px;
  height: 513px;
  background-image: url("../../img/dna_banner.jpg");
  background-position: top center;
  background-repeat: no-repeat;
  background-color: #243246;
  border-bottom: 3px solid #c2d646;
}
.dna .dna-details {
  width: 85%;
  margin: 20px auto 0;
  list-style: none;
  padding: 0;
}
.dna .dna-details li {
  border-radius: 25px;
  padding: 15px 30px;
  margin-bottom: 20px;
  position: relative;
}
.dna .dna-details li:before,
.dna .dna-details li:after {
  content: " ";
  display: table;
}
.dna .dna-details li:after {
  clear: both;
}
.dna .dna-details li .icon {
  width: 78px;
  position: absolute;
  top: 0;
  left: 30px;
  bottom: 0;
  background-image: url("../../img/dna_data_icons.png");
  background-repeat: no-repeat;
}
.dna .dna-details li .data {
  padding-left: 110px;
}
.dna .dna-details li h5,
.dna .dna-details li p {
  font-size: 18px;
  padding: 0;
  margin: 0;
  line-height: 22px;
  color: #fff;
}
.dna .dna-details li h5 {
  font-weight: 600;
}
.dna .dna-details li p {
  font-weight: 300;
  text-align: justify;
}
.dna .dna-details li.engagement-data {
  background-color: #a1b323;
}
.dna .dna-details li.engagement-data .icon {
  background-position: 0 center;
}
.dna .dna-details li.pay-rates {
  background-color: #bd1e2d;
}
.dna .dna-details li.pay-rates .icon {
  background-position: -78px center;
}
.dna .dna-details li.markups {
  background-color: #e88b00;
}
.dna .dna-details li.markups .icon {
  background-position: -158px center;
}
.dna .dna-details li.bill-rates {
  background-color: #427962;
}
.dna .dna-details li.bill-rates .icon {
  background-position: -240px center;
}
.dna .dna-details li.market-data {
  background-color: #c85323;
}
.dna .dna-details li.market-data .icon {
  background-position: -320px center;
}
.dna .dna-data-info p {
  font-size: 18px;
  line-height: 36px;
  text-align: justify;
  margin: 0 0 40px;
  padding: 0;
}
.dna .dna-data-info h2 {
  margin-bottom: 20px;
}
.dna .dna-data-info ul {
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
  position: relative;
}
.dna .dna-data-info ul li {
  width: 95%;
  position: relative;
}
.dna .dna-data-info ul li i {
  font-size: 23px;
  color: #c2d646;
  float: left;
  line-height: 38px;
  margin-right: 10px;
}
.login-form,
.recover-form,
.register-form,
.request-info-form,
.change-password-form {
  width: 430px;
  margin: 30px 0 15px -215px;
  background-color: #f4f4f4;
  padding: 15px 30px 25px;
  text-align: center;
  border-radius: 4px;
  position: absolute;
  left: 50%;
}
.login-form h3,
.recover-form h3,
.register-form h3,
.request-info-form h3,
.change-password-form h3 {
  margin-bottom: 20px;
}
.login-form input[type="text"],
.recover-form input[type="text"],
.register-form input[type="text"],
.request-info-form input[type="text"],
.change-password-form input[type="text"],
.login-form input[type="password"],
.recover-form input[type="password"],
.register-form input[type="password"],
.request-info-form input[type="password"],
.change-password-form input[type="password"] {
  margin-bottom: 15px;
}
.login-form input[type="text"]::placeholder,
.recover-form input[type="text"]::placeholder,
.register-form input[type="text"]::placeholder,
.request-info-form input[type="text"]::placeholder,
.change-password-form input[type="text"]::placeholder,
.login-form input[type="password"]::placeholder,
.recover-form input[type="password"]::placeholder,
.register-form input[type="password"]::placeholder,
.request-info-form input[type="password"]::placeholder,
.change-password-form input[type="password"]::placeholder {
  font-weight: 300;
}
.login-form input[type="text"]::-moz-placeholder,
.recover-form input[type="text"]::-moz-placeholder,
.register-form input[type="text"]::-moz-placeholder,
.request-info-form input[type="text"]::-moz-placeholder,
.change-password-form input[type="text"]::-moz-placeholder,
.login-form input[type="password"]::-moz-placeholder,
.recover-form input[type="password"]::-moz-placeholder,
.register-form input[type="password"]::-moz-placeholder,
.request-info-form input[type="password"]::-moz-placeholder,
.change-password-form input[type="password"]::-moz-placeholder {
  font-weight: 300;
}
.login-form input[type="text"]::-webkit-placeholder,
.recover-form input[type="text"]::-webkit-placeholder,
.register-form input[type="text"]::-webkit-placeholder,
.request-info-form input[type="text"]::-webkit-placeholder,
.change-password-form input[type="text"]::-webkit-placeholder,
.login-form input[type="password"]::-webkit-placeholder,
.recover-form input[type="password"]::-webkit-placeholder,
.register-form input[type="password"]::-webkit-placeholder,
.request-info-form input[type="password"]::-webkit-placeholder,
.change-password-form input[type="password"]::-webkit-placeholder {
  font-weight: 300;
}
.login-form input[type="text"].has-error,
.recover-form input[type="text"].has-error,
.register-form input[type="text"].has-error,
.request-info-form input[type="text"].has-error,
.change-password-form input[type="text"].has-error,
.login-form input[type="password"].has-error,
.recover-form input[type="password"].has-error,
.register-form input[type="password"].has-error,
.request-info-form input[type="password"].has-error,
.change-password-form input[type="password"].has-error {
  border-color: #f41323;
}
.login-form button[type="submit"],
.recover-form button[type="submit"],
.register-form button[type="submit"],
.request-info-form button[type="submit"],
.change-password-form button[type="submit"] {
  padding: 10px 45px;
  border-radius: 3px;
}
.login-form form,
.recover-form form,
.register-form form,
.request-info-form form,
.change-password-form form {
  margin-bottom: 15px;
}
.login-form form + a,
.recover-form form + a,
.register-form form + a,
.request-info-form form + a,
.change-password-form form + a {
  display: inline-block;
  margin-bottom: 10px;
}
.login-form + .text-center,
.recover-form + .text-center,
.register-form + .text-center,
.request-info-form + .text-center,
.change-password-form + .text-center {
  margin-bottom: 80px;
}
.login-form + .text-center a,
.recover-form + .text-center a,
.register-form + .text-center a,
.request-info-form + .text-center a,
.change-password-form + .text-center a {
  font-weight: 300;
  color: #aaaaaa;
}
.login-form + .text-center a:hover,
.recover-form + .text-center a:hover,
.register-form + .text-center a:hover,
.request-info-form + .text-center a:hover,
.change-password-form + .text-center a:hover,
.login-form + .text-center a:active,
.recover-form + .text-center a:active,
.register-form + .text-center a:active,
.request-info-form + .text-center a:active,
.change-password-form + .text-center a:active,
.login-form + .text-center a:focus,
.recover-form + .text-center a:focus,
.register-form + .text-center a:focus,
.request-info-form + .text-center a:focus,
.change-password-form + .text-center a:focus {
  color: #777777;
}
.register-form,
.request-info-form {
  width: 500px;
  margin-left: auto;
  margin-right: auto;
  left: auto;
  position: relative;
  padding-bottom: 1px;
}
.register-form input[type="submit"] {
  padding: 10px 15px;
}
.request-info-form {
  width: 430px;
}
.recover-form {
  -webkit-transform: translate(450px, 0);
  -ms-transform: translate(450px, 0);
  transform: translate(450px, 0);
  opacity: 0;
  filter: alpha(opacity=0);
  z-index: -100;
}
.ie8 .recover-form {
  opacity: 1;
  filter: alpha(opacity=100);
  display: none;
}
.login-form,
.recover-form {
  -webkit-transition: transform 0.4s, opacity 0.4s;
  transition: transform 0.4s, opacity 0.4s;
}
.holder {
  height: 420px;
  overflow: hidden;
  -webkit-transition: height 0.4s;
  transition: height 0.4s;
}
.holder + .text-center {
  margin-bottom: 80px;
}
.holder + .text-center a {
  font-weight: 300;
  color: #aaaaaa;
}
.holder + .text-center a:hover,
.holder + .text-center a:active,
.holder + .text-center a:focus {
  color: #777777;
}
.holder.recovervis {
  height: 330px;
}
.holder.recovervis .login-form {
  -webkit-transform: translate(-450px, 0);
  -ms-transform: translate(-450px, 0);
  transform: translate(-450px, 0);
  opacity: 0;
  filter: alpha(opacity=0);
}
.ie8 .holder.recovervis .login-form {
  opacity: 1;
  filter: alpha(opacity=100);
}
.holder.recovervis .recover-form {
  -webkit-transform: translate(0px, 0);
  -ms-transform: translate(0px, 0);
  transform: translate(0px, 0);
  opacity: 1;
  filter: alpha(opacity=100);
}
.holder.loginvis .login-form {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  opacity: 1;
  filter: alpha(opacity=100);
}
.holder.loginvis .recover-form {
  -webkit-transform: translate(450px, 0);
  -ms-transform: translate(450px, 0);
  transform: translate(450px, 0);
  opacity: 0;
  filter: alpha(opacity=0);
}
.ie8 .holder.loginvis .recover-form {
  opacity: 1;
  filter: alpha(opacity=100);
}
.ie8 .holder a {
  color: #c2d646;
}
.ie8 .holder a:hover {
  color: #91a327;
}
.placeholder-ws {
  font-weight: 300;
}
.ladda-button {
  position: relative;
}
.ladda-button .ladda-spinner {
  position: absolute;
  z-index: 2;
  display: inline-block;
  width: 32px;
  height: 32px;
  top: 50%;
  margin-top: -16px;
  opacity: 0;
  pointer-events: none;
}
/* Button label */
.ladda-button .ladda-label {
  position: relative;
  z-index: 3;
}
.ladda-button[data-style="zoom-in"],
.ladda-button[data-style="zoom-in"] .ladda-spinner,
.ladda-button[data-style="zoom-in"] .ladda-label,
.ladda-button[data-style="zoom-out"],
.ladda-button[data-style="zoom-out"] .ladda-spinner,
.ladda-button[data-style="zoom-out"] .ladda-label {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.ladda-button[data-style="zoom-in"] {
  overflow: hidden;
}
.ladda-button[data-style="zoom-in"] .ladda-spinner {
  left: 50%;
  margin-left: -16px;
  -webkit-transform: scale(0.1);
  -moz-transform: scale(0.1);
  -ms-tranform: scale(0.1);
  transform: scale(0.1);
}
.ladda-button[data-style="zoom-in"] .ladda-label {
  position: relative;
  display: inline-block;
}
.ladda-button[data-style="zoom-in"][data-loading] .ladda-label {
  opacity: 0;
  -webkit-transform: scale(2.2);
  -moz-transform: scale(2.2);
  -ms-tranform: scale(2.2);
  transform: scale(2.2);
}
.ladda-button[data-style="zoom-in"][data-loading] .ladda-spinner {
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-tranform: scale(1);
  transform: scale(1);
}
#maintenance {
  margin-top: 60px;
  margin-bottom: 120px;
  background-color: #f6f6f6;
  border-radius: 5px;
}
#maintenance h2 {
  margin-top: 50px;
  margin-bottom: 30px;
}
#maintenance .fa {
  margin-top: 55px;
  font-size: 144px;
}
#maintenance p {
  margin-top: 15px;
  margin-bottom: 60px;
  font-size: 24px;
  line-height: 32px;
}
.privacy ul {
  font-size: 16px;
  list-style: none;
}
.privacy ul i {
  font-size: 12px;
  margin-right: 5px;
  vertical-align: middle;
  margin-top: -2px;
  display: inline-block;
  color: #999999;
}
#privacyTabs li {
  display: block;
  float: none;
}
#privacyTabs li a {
  font-size: 18px;
  color: #bbbbbb;
  padding: 5px 10px;
  outline: none;
}
#privacyTabs li:hover a {
  background-color: transparent;
  color: #888888;
}
#privacyTabs li.active a {
  background-color: transparent;
  color: #c2d646;
}
#consulting-header {
  background-image: url("../../img/consulting_header.jpg");
  background-size: cover;
  background-position: top center;
  height: 500px;
}
#consulting-header .text {
  margin-top: 45px;
  float: left;
  width: 420px;
}
#consulting-header .text h1 {
  font-size: 50px;
}
#consulting-header .text p {
  margin-top: 20px;
  font-size: 20px;
  line-height: 24px;
}
#consulting-header .text .btn {
  margin-top: 30px;
  width: 160px;
  border-radius: 4px;
}
#consulting-list {
  margin-bottom: 45px;
}
#consulting-list p {
  margin-top: 20px;
}
#consulting-list img {
  width: 100%;
  margin-top: 25px;
}
#consulting-call-to {
  padding-bottom: 50px;
}
#consulting-call-to h2 {
  font-size: 30px;
}
#consulting-call-to .btn {
  margin-top: 20px;
  width: 200px;
  border-radius: 4px;
}
/** Footer **/
#footer {
  background-color: #333333;
  padding: 30px 0;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 250px;
  z-index: 1;
}
#footer h4.footer-title {
  margin: 0;
  padding-top: 0;
  padding-bottom: 8px;
  border-bottom: 1px solid #777777;
}
#footer h4.footer-title,
h2.contact-phone {
  color: #999999;
}
#footer h4.contact-text {
  color: #777777;
  margin-bottom: 0;
  padding-bottom: 0;
}
#footer h2.contact-phone {
  margin-top: 0;
  padding-top: 0;
}
#footer ul.footer-list {
  list-style: none;
  margin: 10px 0 0;
  padding: 0;
}
#footer ul.footer-list li a {
  color: #777777;
  font-size: 17px;
  display: inline-block;
  padding: 2px 0;
}
#footer ul.footer-list li a:hover {
  text-decoration: none;
  color: #aaaaaa;
}
#footer .social {
  margin: 15px 0 0;
  padding: 0;
  list-style: none;
}
#footer .social li {
  float: left;
  margin-left: 20px;
}
#footer .social li:first-child {
  margin-left: 0;
}
#footer .social li a {
  font-size: 30px;
  background-color: #5c5c5c;
  color: #333333;
  display: block;
  text-align: center;
  line-height: 50px;
  border-radius: 50%;
  width: 50px;
  -webkit-transition: background-color 0.3s;
  -ms-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
#footer .social li a:hover {
  background-color: #777777;
}
#footer .copyright {
  color: #777777;
  font-size: 12px;
  padding: 10px 0;
}
.select2-result-label {
  white-space: nowrap;
}
.select2-container .select2-choice {
  -webkit-box-shadow: none;
  box-shadow: none;
  min-width: 50px;
  background-image: none;
  border: 1px solid #d4d4d4;
  height: 32px;
  line-height: 30px;
  padding-left: 10px;
}
.register-form .select2-container .select2-choice {
  padding-left: 15px;
}
.dataTables_length .select2-container .select2-choice {
  min-width: 50px;
  width: 50px;
}
.select2-container .select2-choice .select2-chosen {
  color: #555555;
  font-size: 12px;
}
.register-form .select2-container {
  display: block;
  margin-bottom: 15px;
}
.register-form .select2-container .select2-choice {
  line-height: 44px;
  text-align: left;
  height: 46px;
}
.register-form .select2-container .select2-choice .select2-chosen {
  color: #aaaaaa;
  font-size: 18px;
}
.select2-container .select2-choice .select2-arrow {
  background-color: #ffffff;
  background-image: none;
  border-left: 0;
}
.select2-container .select2-choice abbr {
  background: url("../../img/select2.png") right top no-repeat;
}
.select2-container.select2-container-active .select2-choice {
  background-color: #f9f9f9;
  border-color: #d6d6d6;
}
.select2-container.select2-container-active .select2-choice .select2-arrow {
  background-color: #f9f9f9;
}
.select2-dropdown-open .select2-choice,
.select2-dropdown-open .select2-choice .select2-arrow,
.select2-container-active .select2-choice .select2-arrow {
  background-color: #ffffff;
  box-shadow: none;
}
.select2-search {
  padding: 10px 10px 5px;
  background-color: #f9f9f9;
  border-bottom: 1px solid #d6d6d6;
}
.select2-search input {
  padding: 5px 10px;
  border-color: #d6d6d6;
  background: #ffffff url("../../img/select2.png") no-repeat 100% -22px;
}
.select2-search input.select2-active {
  background: #ffffff url("../../img/select2-spinner.gif") no-repeat 100% -22px;
}
.select2-results {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  padding-left: 0;
}
.select2-results .select2-no-results,
.select2-results .select2-searching {
  padding: 5px 10px;
  background-color: #fff;
}
.select2-more-results.select2-active {
  background: #f4f4f4 url("../../img/select2-spinner.gif") no-repeat 100%;
}
.select2-search-choice-close {
  background: url("../../img/select2.png") right top no-repeat;
  top: 3px;
}
.select2-container-multi .select2-choices {
  border: 1px solid #dddddd;
  padding: 2px 5px;
  background-image: none;
  border-radius: 3px;
}
.select2-container-multi .select2-choices .select2-search-field input {
  min-width: 120px;
}
.select2-container-multi .select2-choices .select2-search-field input.select2-active {
  background: #ffffff url("../../img/select2-spinner.gif") no-repeat 100% !important;
}
.select2-container-multi .select2-choices .select2-search-choice {
  background-color: #eeeeee;
  background-image: none;
  filter: none;
  border-color: #eeeeee;
  box-shadow: none;
  font-size: 12px;
  color: #555555;
}
.select2-container-multi .select2-choices .select2-search-choice.select2-search-choice-focus {
  background-color: #2a609d;
  border-color: #2a609d;
  color: rgba(255, 255, 255, 0.5);
}
.select2-container-multi.select2-container-active .select2-choices {
  background-color: #f9f9f9;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: #d6d6d6;
}
.select2-drop-active {
  border-left: 1px solid #d6d6d6;
  border-right: 1px solid #d6d6d6;
  border-bottom: 1px solid #d6d6d6;
}
.select2-drop.select2-drop-above.select2-drop-active {
  border-top: 1px solid #d6d6d6;
}
.select2-dropdown-open.select2-drop-above .select2-choice,
.select2-dropdown-open.select2-drop-above .select2-choices {
  background-image: none !important;
}
.select2-results .select2-highlighted {
  background-color: #c2d646;
}
@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  .select2-search input,
  .select2-search-choice-close,
  .select2-container .select2-choice abbr,
  .select2-container .select2-choice .select2-arrow b {
    background-image: url("../../img/select2x2.png") !important;
  }
}
.datepicker {
  z-index: 1060 !important;
}
.datepicker td span {
  width: 31%;
  height: 30px;
  line-height: 30px;
}
.datepicker td span.active:hover,
.datepicker td span.active:focus,
.datepicker td span.active:active,
.datepicker td span.active.active,
.datepicker td span.active.disabled,
.datepicker td span.active[disabled] {
  background-color: #c2d646 !important;
  background-image: none !important;
  text-shadow: none;
}
.datepicker td span.disabled,
.datepicker td span.disabled:hover {
  color: #cccccc !important;
}
@media (max-width: 397px) {
  .solutions .procurement a.btn,
  .solutions .human-res a.btn,
  .solutions .sow a.btn {
    width: 120px;
    font-size: 14px;
  }
  .solutions .procurement a.btn + a.btn,
  .solutions .human-res a.btn + a.btn,
  .solutions .sow a.btn + a.btn {
    margin-left: 5px;
  }
}
@media (max-width: 480px) {
  h1 {
    line-height: 38px !important;
  }
  header#top {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 25;
  }
  .main-wrap {
    margin-top: 50px;
  }
  .home_banner {
    height: 305px;
    margin-bottom: 50px;
  }
  .home_banner .banner_box {
    width: 100%;
    margin-top: 118px;
    padding: 20px 0px;
  }
  .home_banner .banner_box h2 {
    font-size: 24px !important;
    line-height: 26px !important;
  }
  .home_banner .banner_box p {
    font-size: 12px !important;
    line-height: 20px !important;
  }
  .home_banner .banner_box a.btn {
    margin-top: 17px;
    width: 27%;
    margin-left: 3%;
    font-size: 12px;
  }
  .home_banner .banner_box a.btn:first-of-type {
    margin-left: 0;
  }
  .data-counter h1 {
    font-size: 26px;
    margin-top: 0;
    padding-top: 0;
  }
  .quotes-items li {
    width: 90%;
  }
  .lcnb_contents {
    padding-top: 20px !important;
  }
  .team-sortable li {
    width: 46%;
  }
  .login-form,
  .recover-form {
    width: 94%;
    margin-left: 0;
    left: auto;
  }
  .feature-img-box {
    margin-bottom: 30px;
  }
  .feature-img-box h3 {
    margin-top: 15px;
  }
  .values .circles li a i {
    line-height: 24px !important;
    width: 24px !important;
    height: 24px !important;
    font-size: 24px !important;
    margin: -12px 0 0 -12px !important;
  }
  .about .img,
  .bill-rate .img {
    height: 300px;
  }
  .data .img {
    margin-top: 20px;
    height: 300px;
    background-size: cover;
  }
  .data .crops {
    background-image: none;
    width: 100%;
    margin-bottom: 25px;
  }
  .data .crops .text {
    position: relative;
  }
  .data .crops .text div {
    position: relative;
    z-index: 2;
    padding: 5px 0;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    background-color: #c2d646;
    margin-bottom: 5px;
  }
  .data .crops .text div:last-child {
    margin-bottom: 0;
  }
  .data .crops .text:after {
    z-index: 1;
    position: absolute;
    content: "";
    background-color: #c2d646;
    width: 3px;
    top: 0;
    left: 50%;
    height: 100%;
    margin-left: -1.5px;
  }
  .data .table {
    min-width: 300px !important;
    width: 300px !important;
    max-width: 300px !important;
  }
  #footer .col-xs-6 {
    width: 100%;
    float: none;
  }
  #page-heading h1 {
    font-size: 30px !important;
  }
  #page-heading h4 {
    margin-top: 5px;
    line-height: 18px !important;
    font-size: 16px !important;
  }
  #consulting-header p {
    font-size: 16px !important;
    line-height: 20px !important;
  }
  #consulting-list .row {
    margin-bottom: 50px;
  }
  #consulting-list .row:last-child {
    margin-bottom: 0;
  }
  #consulting-call-to h2 {
    font-size: 24px !important;
    line-height: 28px !important;
  }
  .suppliers-resellers ul,
  .suppliers-resellers ul li {
    width: 100%;
  }
  .suppliers-resellers ul li {
    float: none !important;
  }
  .suppliers-resellers img {
    width: 100%;
  }
  .became-partner {
    padding: 20px 0 35px;
  }
  .became-partner h3 {
    font-size: 24px !important;
    line-height: 28px !important;
  }
  .pay-bill .col-xs-12:nth-child(2) h3,
  .pay-bill .col-xs-12:nth-child(4) h3,
  .pay-bill .col-xs-12:nth-child(2) h4,
  .pay-bill .col-xs-12:nth-child(4) h4 {
    display: none;
  }
  .contact .ready-to-talk {
    margin-bottom: 20px;
  }
  .contact .support-box {
    margin: 15px 0;
  }
}
@media (max-width: 520px) {
  #signup-modal .modal-dialog {
    margin: 2%;
    width: 96%;
  }
  #signup-modal .modal-dialog .form {
    width: 100%;
  }
  #signup-modal .modal-dialog .steps {
    width: 100%;
  }
  #signup-modal .modal-dialog .steps:after {
    width: 75%;
    left: 12.5%;
  }
  #signup-modal .modal-dialog .steps .step {
    width: 32%;
    margin: 0;
  }
  #apply-job-modal .modal-dialog,
  #partner-with-us-modal .modal-dialog,
  #request-modal .modal-dialog,
  #support-modal .modal-dialog,
  #press-modal .modal-dialog,
  #sales-modal .modal-dialog,
  #contact-me-modal .modal-dialog {
    margin: 2%;
    width: 96%;
  }
  #apply-job-modal .modal-dialog form,
  #partner-with-us-modal .modal-dialog form,
  #request-modal .modal-dialog form,
  #support-modal .modal-dialog form,
  #press-modal .modal-dialog form,
  #sales-modal .modal-dialog form,
  #contact-me-modal .modal-dialog form {
    width: 100%;
  }
}
@media (max-width: 700px) {
  .map #map,
  .map #map iframe {
    height: 350px;
  }
  .select2-input {
    min-width: auto;
    width: auto;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .cl:before,
  .cl:after {
    content: " ";
    display: table;
  }
  .cl:after {
    clear: both;
  }
}
@media (max-width: 767px) {
  h1 {
    font-size: 30px;
    line-height: 50px;
  }
  h2 {
    font-size: 24px;
    line-height: 28px;
  }
  h3 {
    font-size: 20px;
    line-height: 24px;
  }
  h4,
  p {
    font-size: 14px;
    line-height: 18px;
  }
  p.small {
    font-size: 12px !important;
    line-height: 14px !important;
  }
  #logo {
    margin-top: 12px;
    margin-bottom: 5px;
  }
  #logo img {
    height: 30px;
  }
  nav#main {
    border-top: 1px solid #494949;
    float: none;
    display: none;
    margin-top: 15px;
    width: 100%;
  }
  nav#main > ul {
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
  }
  nav#main > ul > li {
    float: none;
    display: block;
    width: 100%;
  }
  nav#main > ul > li:not(.special) > a {
    padding: 15px 10px !important;
    text-align: center;
  }
  nav#main > ul > li.special {
    display: inline-block;
    width: 45%;
  }
  nav#main > ul > li.special a {
    width: 100%;
  }
  nav#main > ul > li.dropdown {
    margin-right: 0;
  }
  nav#main > ul > li.dropdown:before {
    display: none;
  }
  nav#main > ul > li .dropdown-menu {
    display: block;
    position: relative !important;
    top: auto;
    float: none;
    left: auto;
    background-color: #282828;
    min-width: 100%;
    max-width: 100%;
    width: 100%;
    margin: 0 auto 10px;
    box-shadow: none;
    border: 0;
  }
  nav#main > ul > li .dropdown-menu:before {
    border-bottom-color: #282828 !important;
  }
  nav#main > ul > li .dropdown-menu li a {
    color: #ffffff;
    background-color: transparent !important;
  }
  nav#main > ul > li .dropdown-menu li a:hover {
    background-color: #242424 !important;
  }
  nav#main > ul > li .dropdown-menu li.active a {
    color: #c2d646;
  }
  nav#main > ul > li a {
    width: 100%;
    display: block;
  }
  #about-modal {
    overflow-x: none !important;
    overflow-y: scroll !important;
  }
  #about-modal .modal-dialog {
    width: 96%;
    margin-left: 0;
    left: 2%;
    position: relative;
    margin-top: 10px;
    top: auto !important;
  }
  #about-modal .modal-dialog .modal-body {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  #about-modal .left,
  #about-modal .right {
    float: none;
    width: auto;
  }
  #about-modal .right {
    margin-top: 20px;
    margin-left: 0;
  }
  #about-modal .tname,
  #about-modal .twho {
    text-align: center;
  }
  #about-modal .tdesc {
    text-align: justify;
  }
  #about-modal .timg img {
    border-radius: 4px;
    overflow: hidden;
  }
  .values {
    height: 350px;
  }
  .values:before,
  .values:after {
    content: " ";
    display: table;
  }
  .values:after {
    clear: both;
  }
  .values .circles {
    margin-top: 20px;
  }
  .values .circles li {
    position: relative;
    width: 18%;
    margin-left: 2.5%;
  }
  .values .circles li:first-child {
    margin-left: 0;
  }
  .values .circles li a {
    width: 100%;
    height: auto;
    line-height: 1;
  }
  .values .circles li a:before {
    content: "";
    float: left;
    padding-bottom: 100%;
  }
  .values .circles li a:after {
    content: "";
    display: table;
    clear: both;
  }
  .values .circles li a i {
    position: absolute;
    text-align: center;
    line-height: 42px;
    width: 42px;
    height: 42px;
    top: 50%;
    left: 50%;
    margin: -21px 0 0 -21px;
  }
  .values .values-content {
    margin-top: 20px;
  }
  .values .values-content:before,
  .values .values-content:after {
    content: " ";
    display: table;
  }
  .values .values-content:after {
    clear: both;
  }
  #page-heading {
    padding: 5px 0px 17px;
  }
  #page-heading.only-heading {
    padding: 5px 0 10px;
  }
  #page-heading h4 {
    padding-left: 20px;
    padding-right: 20px;
  }
  .features-top {
    background-image: none;
  }
  .features-top h2 {
    margin-top: -10px;
  }
  .features-top .features-preview {
    margin-top: 30px;
    width: 100%;
    height: auto;
  }
  .features-top .features-preview img {
    width: 100% !important;
  }
  #privacyTabs {
    width: auto;
    display: block;
  }
  #privacyTabs li {
    display: inline-block;
    float: none;
    width: 48%;
    margin: 0;
  }
  #privacyTabs li a {
    text-align: center;
  }
  #consulting-header {
    height: 340px;
  }
  #consulting-header .text {
    margin-top: 25px;
    float: none;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 4px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 25px;
  }
  #consulting-header .text h1 {
    font-size: 24px !important;
    line-height: 28px !important;
  }
  #consulting-header .text .btn {
    margin-top: 10px;
  }
  .dna #page-heading h1 {
    font-size: 26px !important;
  }
  .dna .dna-details li .icon {
    position: relative;
    top: auto;
    left: auto;
    bottom: auto;
    height: 96px;
    margin: 0 auto 10px;
  }
  .dna .dna-details li .data {
    padding-left: 0;
  }
  .dna .dna-data-info ul li {
    padding-left: 34px;
  }
  .dna .dna-data-info ul li i {
    position: absolute;
    left: 0;
    top: 0;
  }
}
@media (min-width: 768px) {
  .col-sm-15 {
    width: 20%;
    float: left;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  #about-modal .modal-dialog {
    width: 760px;
    margin-left: -380px;
    margin-top: -300px;
  }
  /*
	#logo {
		width: 250px;
		display: block;
		margin-left: auto;
		margin-right: auto;
	}*/
  .dna .dna-data-info ul li i {
    left: -60px;
  }
}
@media (max-width: 991px) {
  .main-wrap {
    margin-bottom: 0;
  }
  .rate-preview .rate-preview-img,
  .custom-skill-report .custom-skill-report-img {
    width: 100%;
    height: auto;
    float: none;
  }
  .rate-preview .rate-preview-img,
  .custom-skill-report .custom-skill-report-img,
  .rate-preview .rate-preview-img img,
  .custom-skill-report .custom-skill-report-img img {
    width: 100%;
  }
  .rate-preview .rate-preview-text,
  .custom-skill-report .custom-skill-report-text {
    margin-left: 0;
    margin-top: 20px;
  }
  .data-counter {
    overflow: hidden;
  }
  .data-counter .container:before,
  .data-counter .container:after {
    top: 220px;
  }
  #demo-video .modal-dialog {
    width: 94%;
    margin-left: 3%;
    margin-right: 3%;
  }
  #footer {
    height: auto;
    position: relative;
  }
  #footer .col-md-3 {
    margin-bottom: 20px;
  }
  #consulting-list h2,
  #consulting-list p {
    text-align: center !important;
  }
  .dna #page-heading h1 {
    font-size: 48px;
  }
  .dna #page-heading h1:before {
    display: none;
  }
}
@media (min-width: 992px) {
  .col-md-15 {
    width: 20%;
    float: left;
  }
  .dna #page-heading .container h1:before {
    left: -5px;
  }
}
@media (max-width: 1199px) {
  .contact .main-details h3 {
    margin-top: 20px;
  }
  .dna .col-md-4 > img {
    height: 966px;
  }
}
@media (min-width: 1200px) {
  .col-lg-15 {
    width: 20%;
    float: left;
  }
}
@media (min-width: 751px) and (max-width: 990px) {
  nav#main > ul > li > a {
    padidng-left: 15px;
    padding-right: 15px;
  }
}
