.panel {
  background: none repeat scroll 0 0 #ffffff;
  border: 1px solid #e6e6e6;
  clear: both;
  margin-top: 16px;
  margin-bottom: 16px;
  position: relative;
  border-radius: 4px;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.panel .widget-refresh-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.7);
  filter: inherit;
  zoom: 1;
  z-index: 5;
  opacity: 0;
}
.panel .widget-refresh-overlay .inner {
  -ms-filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#B2ffffff,endColorstr=#B2ffffff);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#B2ffffff,endColorstr=#B2ffffff);
  display: table;
  width: 100%;
  height: 100%;
}
.panel .widget-refresh-overlay .inner .icon {
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  color: #666666;
  font-size: 18px;
}
.panel.widget-plain {
  background: transparent;
  border: 0;
  margin-top: 0;
  margin-bottom: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.panel.widget-plain .widget-content {
  padding: 12px 0 0 !important;
}
.panel .panel-heading {
  position: relative;
  z-index: 3;
  padding: 7px 15px;
}
.panel .panel-heading:before,
.panel .panel-heading:after {
  content: " ";
  display: table;
}
.panel .panel-heading:after {
  clear: both;
}
.panel .panel-heading a {
  color: #555555;
}
.panel .panel-heading span.icon {
  border-right: 1px solid #cdcdcd;
  padding: 8px 10px 8px 11px;
  float: left;
  opacity: 0.7;
  filter: alpha(opacity=70);
  -webkit-box-shadow: 1px 0 0 0 #ffffff;
  box-shadow: 1px 0 0 0 #ffffff;
}
.panel .panel-heading span.icon.pull-right {
  border-right: 0;
  border-left: 1px solid #ffffff;
  -webkit-box-shadow: -1px 0 0 #cdcdcd;
  box-shadow: -1px 0 0 #cdcdcd;
}
.panel .panel-heading span.icon.with-checkbox {
  padding-bottom: 2px;
}
.panel .panel-heading h4,
.panel .panel-heading h4 > a {
  color: #666666 !important;
  text-shadow: 0 1px 0 #ffffff;
  float: left;
  font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: normal;
  padding: 8px 12px 8px 0;
  line-height: 16px;
  margin: 0;
}
.panel .panel-heading h4 > a {
  padding: 0;
}
.panel .panel-heading .nav-tabs {
  border: 0;
  float: left;
  margin-top: 4px;
  margin-bottom: -9px;
}
.panel .panel-heading .nav-tabs li {
  margin-bottom: 0;
}
.panel .panel-heading .nav-tabs li a {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  color: #aaaaaa;
  margin: 0;
  outline: medium none;
  padding: 9px 15px 8px;
  font-size: 12px;
}
.panel .panel-heading .nav-tabs li a:hover {
  background-color: transparent;
  color: #666666;
  border-color: transparent;
}
.panel .panel-heading .nav-tabs li a .fa {
  margin-right: 3px;
}
.panel .panel-heading .nav-tabs li.active,
.panel .panel-heading .nav-tabs li.active:hover {
  margin-bottom: -1px;
}
.panel .panel-heading .nav-tabs li.active a,
.panel .panel-heading .nav-tabs li.active:hover a,
.panel .panel-heading .nav-tabs li.active a:hover,
.panel .panel-heading .nav-tabs li.active:hover a:hover,
.panel .panel-heading .nav-tabs li.active a:focus,
.panel .panel-heading .nav-tabs li.active:hover a:focus {
  background-color: #ffffff;
  color: #444444;
  border-top: 1px solid #e6e6e6 !important;
  border-left: 1px solid #e6e6e6 !important;
  border-right: 1px solid #e6e6e6 !important;
  border-bottom: 1px solid #ffffff !important;
}
.panel .panel-heading .nav-tabs li:first-child a {
  border-left: none;
}
.panel .panel-heading .nav-tabs.pull-right {
  margin-top: 5px;
  margin-bottom: -9px;
}
.panel .panel-heading .buttons {
  float: right;
  margin: -7px -15px -8px;
}
.panel .panel-heading .buttons .btn {
  padding: 15px;
  color: #555555;
  border-radius: 0;
  border-left: 1px solid #eeeeee !important;
  font-size: 12px;
  line-height: 17px;
  margin: 0;
}
.panel .panel-heading .buttons .btn .fa {
  vertical-align: middle;
  margin-right: 5px;
  font-size: 14px;
}
.panel .panel-heading .buttons .btn:hover,
.panel .panel-heading .buttons .btn:active {
  background-color: #f9f9f9;
}
.panel .panel-heading .buttons .btn:active {
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1) inset;
}
.panel .panel-heading .label,
.panel .panel-heading .badge {
  padding: 3px 5px 2px;
  float: right;
  margin: 9px 15px 0 0;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3) inset, 0 1px 0 #ffffff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3) inset, 0 1px 0 #ffffff;
}
.widget-calendar .panel .panel-heading .label,
.widget-calendar .panel .panel-heading .badge {
  margin-right: 190px;
}
.panel .panel-heading .widget-options {
  float: right;
  padding-top: 4px;
}
.panel .panel-heading .widget-options .widget-color-chooser {
  display: inline-block;
}
.panel .panel-heading .widget-options .widget-color-chooser > a {
  background-color: rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.6) inset;
  display: block;
  height: 24px;
  margin: 0 0 0 5px;
  width: 24px;
}
.panel .panel-heading .widget-options .widget-color-chooser .dropdown-menu {
  min-width: 0;
  width: 148px;
}
.panel .panel-heading .widget-options .widget-color-chooser .dropdown-menu li {
  display: inline-block;
  height: 24px;
  margin: 0;
  padding: 0;
  width: 24px;
}
.panel .panel-heading .widget-options .widget-color-chooser .dropdown-menu li a {
  border: 1px solid #eeeeee;
  border-radius: 0;
  box-shadow: 0 0 0 1px #ffffff inset;
  display: block;
  height: 100% !important;
  margin: 0;
  padding: 0;
  width: 100% !important;
}
.panel .panel-heading .widget-options .widget-color-chooser + .widget-minimize,
.panel .panel-heading .widget-options .widget-color-chooser + .widget-refresh,
.panel .panel-heading .widget-options .widget-color-chooser + .widget-close {
  margin-left: 10px;
}
.panel .panel-heading .widget-options .widget-minimize,
.panel .panel-heading .widget-options .widget-refresh,
.panel .panel-heading .widget-options .widget-close {
  display: inline-block;
  margin: 1px 0 0 5px;
  padding: 4px 6px;
  margin-top: 2px;
  border-radius: 3px;
  vertical-align: top;
  color: #666666;
  font-size: 12px;
  background-color: rgba(0, 0, 0, 0.04);
}
.panel .panel-heading .widget-options .widget-minimize i,
.panel .panel-heading .widget-options .widget-refresh i,
.panel .panel-heading .widget-options .widget-close i {
  display: block;
  margin-top: 1px;
}
.panel .panel-heading .widget-options .widget-minimize:hover,
.panel .panel-heading .widget-options .widget-refresh:hover,
.panel .panel-heading .widget-options .widget-close:hover {
  background-color: rgba(0, 0, 0, 0.1);
  color: #222222;
}
.panel .panel-heading .widget-options .widget-minimize {
  padding: 4.5px 6px 3.5px;
}
.panel .panel-heading .widget-options + .nav-tabs {
  margin-right: 10px;
}
.panel .panel-heading.turquoise,
.panel .panel-heading.turquoise-dark,
.panel .panel-heading.red,
.panel .panel-heading.red-dark,
.panel .panel-heading.yellow,
.panel .panel-heading.yellow-dark,
.panel .panel-heading.green,
.panel .panel-heading.green-dark,
.panel .panel-heading.blue,
.panel .panel-heading.blue-dark,
.panel .panel-heading.purple,
.panel .panel-heading.purple-dark,
.panel .panel-heading.inverse {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.panel .panel-heading.turquoise h4,
.panel .panel-heading.turquoise-dark h4,
.panel .panel-heading.red h4,
.panel .panel-heading.red-dark h4,
.panel .panel-heading.yellow h4,
.panel .panel-heading.yellow-dark h4,
.panel .panel-heading.green h4,
.panel .panel-heading.green-dark h4,
.panel .panel-heading.blue h4,
.panel .panel-heading.blue-dark h4,
.panel .panel-heading.purple h4,
.panel .panel-heading.purple-dark h4,
.panel .panel-heading.inverse h4,
.panel .panel-heading.turquoise h4 > a,
.panel .panel-heading.turquoise-dark h4 > a,
.panel .panel-heading.red h4 > a,
.panel .panel-heading.red-dark h4 > a,
.panel .panel-heading.yellow h4 > a,
.panel .panel-heading.yellow-dark h4 > a,
.panel .panel-heading.green h4 > a,
.panel .panel-heading.green-dark h4 > a,
.panel .panel-heading.blue h4 > a,
.panel .panel-heading.blue-dark h4 > a,
.panel .panel-heading.purple h4 > a,
.panel .panel-heading.purple-dark h4 > a,
.panel .panel-heading.inverse h4 > a,
.panel .panel-heading.turquoise .widget-options .widget-minimize,
.panel .panel-heading.turquoise-dark .widget-options .widget-minimize,
.panel .panel-heading.red .widget-options .widget-minimize,
.panel .panel-heading.red-dark .widget-options .widget-minimize,
.panel .panel-heading.yellow .widget-options .widget-minimize,
.panel .panel-heading.yellow-dark .widget-options .widget-minimize,
.panel .panel-heading.green .widget-options .widget-minimize,
.panel .panel-heading.green-dark .widget-options .widget-minimize,
.panel .panel-heading.blue .widget-options .widget-minimize,
.panel .panel-heading.blue-dark .widget-options .widget-minimize,
.panel .panel-heading.purple .widget-options .widget-minimize,
.panel .panel-heading.purple-dark .widget-options .widget-minimize,
.panel .panel-heading.inverse .widget-options .widget-minimize,
.panel .panel-heading.turquoise .widget-options .widget-refresh,
.panel .panel-heading.turquoise-dark .widget-options .widget-refresh,
.panel .panel-heading.red .widget-options .widget-refresh,
.panel .panel-heading.red-dark .widget-options .widget-refresh,
.panel .panel-heading.yellow .widget-options .widget-refresh,
.panel .panel-heading.yellow-dark .widget-options .widget-refresh,
.panel .panel-heading.green .widget-options .widget-refresh,
.panel .panel-heading.green-dark .widget-options .widget-refresh,
.panel .panel-heading.blue .widget-options .widget-refresh,
.panel .panel-heading.blue-dark .widget-options .widget-refresh,
.panel .panel-heading.purple .widget-options .widget-refresh,
.panel .panel-heading.purple-dark .widget-options .widget-refresh,
.panel .panel-heading.inverse .widget-options .widget-refresh,
.panel .panel-heading.turquoise .widget-options .widget-close,
.panel .panel-heading.turquoise-dark .widget-options .widget-close,
.panel .panel-heading.red .widget-options .widget-close,
.panel .panel-heading.red-dark .widget-options .widget-close,
.panel .panel-heading.yellow .widget-options .widget-close,
.panel .panel-heading.yellow-dark .widget-options .widget-close,
.panel .panel-heading.green .widget-options .widget-close,
.panel .panel-heading.green-dark .widget-options .widget-close,
.panel .panel-heading.blue .widget-options .widget-close,
.panel .panel-heading.blue-dark .widget-options .widget-close,
.panel .panel-heading.purple .widget-options .widget-close,
.panel .panel-heading.purple-dark .widget-options .widget-close,
.panel .panel-heading.inverse .widget-options .widget-close {
  color: #ffffff !important;
  text-shadow: none;
}
.panel .panel-heading.turquoise .widget-options .widget-minimize,
.panel .panel-heading.turquoise-dark .widget-options .widget-minimize,
.panel .panel-heading.red .widget-options .widget-minimize,
.panel .panel-heading.red-dark .widget-options .widget-minimize,
.panel .panel-heading.yellow .widget-options .widget-minimize,
.panel .panel-heading.yellow-dark .widget-options .widget-minimize,
.panel .panel-heading.green .widget-options .widget-minimize,
.panel .panel-heading.green-dark .widget-options .widget-minimize,
.panel .panel-heading.blue .widget-options .widget-minimize,
.panel .panel-heading.blue-dark .widget-options .widget-minimize,
.panel .panel-heading.purple .widget-options .widget-minimize,
.panel .panel-heading.purple-dark .widget-options .widget-minimize,
.panel .panel-heading.inverse .widget-options .widget-minimize,
.panel .panel-heading.turquoise .widget-options .widget-refresh,
.panel .panel-heading.turquoise-dark .widget-options .widget-refresh,
.panel .panel-heading.red .widget-options .widget-refresh,
.panel .panel-heading.red-dark .widget-options .widget-refresh,
.panel .panel-heading.yellow .widget-options .widget-refresh,
.panel .panel-heading.yellow-dark .widget-options .widget-refresh,
.panel .panel-heading.green .widget-options .widget-refresh,
.panel .panel-heading.green-dark .widget-options .widget-refresh,
.panel .panel-heading.blue .widget-options .widget-refresh,
.panel .panel-heading.blue-dark .widget-options .widget-refresh,
.panel .panel-heading.purple .widget-options .widget-refresh,
.panel .panel-heading.purple-dark .widget-options .widget-refresh,
.panel .panel-heading.inverse .widget-options .widget-refresh,
.panel .panel-heading.turquoise .widget-options .widget-close,
.panel .panel-heading.turquoise-dark .widget-options .widget-close,
.panel .panel-heading.red .widget-options .widget-close,
.panel .panel-heading.red-dark .widget-options .widget-close,
.panel .panel-heading.yellow .widget-options .widget-close,
.panel .panel-heading.yellow-dark .widget-options .widget-close,
.panel .panel-heading.green .widget-options .widget-close,
.panel .panel-heading.green-dark .widget-options .widget-close,
.panel .panel-heading.blue .widget-options .widget-close,
.panel .panel-heading.blue-dark .widget-options .widget-close,
.panel .panel-heading.purple .widget-options .widget-close,
.panel .panel-heading.purple-dark .widget-options .widget-close,
.panel .panel-heading.inverse .widget-options .widget-close {
  background-color: rgba(0, 0, 0, 0.1);
}
.panel .panel-heading.turquoise .widget-options .widget-minimize:hover,
.panel .panel-heading.turquoise-dark .widget-options .widget-minimize:hover,
.panel .panel-heading.red .widget-options .widget-minimize:hover,
.panel .panel-heading.red-dark .widget-options .widget-minimize:hover,
.panel .panel-heading.yellow .widget-options .widget-minimize:hover,
.panel .panel-heading.yellow-dark .widget-options .widget-minimize:hover,
.panel .panel-heading.green .widget-options .widget-minimize:hover,
.panel .panel-heading.green-dark .widget-options .widget-minimize:hover,
.panel .panel-heading.blue .widget-options .widget-minimize:hover,
.panel .panel-heading.blue-dark .widget-options .widget-minimize:hover,
.panel .panel-heading.purple .widget-options .widget-minimize:hover,
.panel .panel-heading.purple-dark .widget-options .widget-minimize:hover,
.panel .panel-heading.inverse .widget-options .widget-minimize:hover,
.panel .panel-heading.turquoise .widget-options .widget-refresh:hover,
.panel .panel-heading.turquoise-dark .widget-options .widget-refresh:hover,
.panel .panel-heading.red .widget-options .widget-refresh:hover,
.panel .panel-heading.red-dark .widget-options .widget-refresh:hover,
.panel .panel-heading.yellow .widget-options .widget-refresh:hover,
.panel .panel-heading.yellow-dark .widget-options .widget-refresh:hover,
.panel .panel-heading.green .widget-options .widget-refresh:hover,
.panel .panel-heading.green-dark .widget-options .widget-refresh:hover,
.panel .panel-heading.blue .widget-options .widget-refresh:hover,
.panel .panel-heading.blue-dark .widget-options .widget-refresh:hover,
.panel .panel-heading.purple .widget-options .widget-refresh:hover,
.panel .panel-heading.purple-dark .widget-options .widget-refresh:hover,
.panel .panel-heading.inverse .widget-options .widget-refresh:hover,
.panel .panel-heading.turquoise .widget-options .widget-close:hover,
.panel .panel-heading.turquoise-dark .widget-options .widget-close:hover,
.panel .panel-heading.red .widget-options .widget-close:hover,
.panel .panel-heading.red-dark .widget-options .widget-close:hover,
.panel .panel-heading.yellow .widget-options .widget-close:hover,
.panel .panel-heading.yellow-dark .widget-options .widget-close:hover,
.panel .panel-heading.green .widget-options .widget-close:hover,
.panel .panel-heading.green-dark .widget-options .widget-close:hover,
.panel .panel-heading.blue .widget-options .widget-close:hover,
.panel .panel-heading.blue-dark .widget-options .widget-close:hover,
.panel .panel-heading.purple .widget-options .widget-close:hover,
.panel .panel-heading.purple-dark .widget-options .widget-close:hover,
.panel .panel-heading.inverse .widget-options .widget-close:hover {
  background-color: rgba(0, 0, 0, 0.2);
  color: #eeeeee;
}
.panel .panel-heading.turquoise .nav-tabs li a,
.panel .panel-heading.turquoise-dark .nav-tabs li a,
.panel .panel-heading.red .nav-tabs li a,
.panel .panel-heading.red-dark .nav-tabs li a,
.panel .panel-heading.yellow .nav-tabs li a,
.panel .panel-heading.yellow-dark .nav-tabs li a,
.panel .panel-heading.green .nav-tabs li a,
.panel .panel-heading.green-dark .nav-tabs li a,
.panel .panel-heading.blue .nav-tabs li a,
.panel .panel-heading.blue-dark .nav-tabs li a,
.panel .panel-heading.purple .nav-tabs li a,
.panel .panel-heading.purple-dark .nav-tabs li a,
.panel .panel-heading.inverse .nav-tabs li a {
  color: #ffffff;
}
.panel .panel-heading.turquoise .nav-tabs li:hover a,
.panel .panel-heading.turquoise-dark .nav-tabs li:hover a,
.panel .panel-heading.red .nav-tabs li:hover a,
.panel .panel-heading.red-dark .nav-tabs li:hover a,
.panel .panel-heading.yellow .nav-tabs li:hover a,
.panel .panel-heading.yellow-dark .nav-tabs li:hover a,
.panel .panel-heading.green .nav-tabs li:hover a,
.panel .panel-heading.green-dark .nav-tabs li:hover a,
.panel .panel-heading.blue .nav-tabs li:hover a,
.panel .panel-heading.blue-dark .nav-tabs li:hover a,
.panel .panel-heading.purple .nav-tabs li:hover a,
.panel .panel-heading.purple-dark .nav-tabs li:hover a,
.panel .panel-heading.inverse .nav-tabs li:hover a {
  color: #eeeeee;
  color: rgba(255, 255, 255, 0.9);
}
.panel .panel-heading.turquoise .nav-tabs li.active a,
.panel .panel-heading.turquoise-dark .nav-tabs li.active a,
.panel .panel-heading.red .nav-tabs li.active a,
.panel .panel-heading.red-dark .nav-tabs li.active a,
.panel .panel-heading.yellow .nav-tabs li.active a,
.panel .panel-heading.yellow-dark .nav-tabs li.active a,
.panel .panel-heading.green .nav-tabs li.active a,
.panel .panel-heading.green-dark .nav-tabs li.active a,
.panel .panel-heading.blue .nav-tabs li.active a,
.panel .panel-heading.blue-dark .nav-tabs li.active a,
.panel .panel-heading.purple .nav-tabs li.active a,
.panel .panel-heading.purple-dark .nav-tabs li.active a,
.panel .panel-heading.inverse .nav-tabs li.active a {
  color: #444444;
}
.panel .panel-heading + .panel-body,
.panel .panel-heading + .table {
  border-top: 1px solid #eeeeee !important;
}
.panel .panel-heading + .table > thead > tr > th {
  border-top: 0 !important;
}
.panel .panel-body {
  position: relative;
  padding: 12px 15px;
  font-size: 13px;
  color: #555555;
}
.panel .panel-body > hr {
  margin: 20px -15px;
  height: 0px;
  border-width: 1px 0;
  border-style: solid none;
  border-color: #eeeeee transparent #fff;
}
.panel .panel-body.tabs {
  position: relative;
}
.panel .panel-body.tabs:before,
.panel .panel-body.tabs:after {
  content: " ";
  display: table;
}
.panel .panel-body.tabs:after {
  clear: both;
}
.panel .panel-body.tabs > .tab-pane {
  margin-left: 120px;
}
.panel .panel-body.tabs > .nav-tabs {
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  background-color: #fbfbfb;
  border-right: 1px solid #e6e6e6;
  border-bottom: 0;
  width: 120px;
}
.panel .panel-body.tabs > .nav-tabs li {
  float: none;
}
.panel .panel-body.tabs > .nav-tabs li a {
  border: 0;
  margin-right: 0;
  color: #888888;
  border-radius: 0;
}
.panel .panel-body.tabs > .nav-tabs li:hover a {
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  background-color: #f6f6f6;
}
.panel .panel-body.tabs > .nav-tabs li.active a {
  color: #666666;
  position: relative;
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  background-color: #ffffff;
}
.panel .panel-body.tabs > .nav-tabs li.active a:before,
.panel .panel-body.tabs > .nav-tabs li.active a:after {
  position: absolute;
  top: 0;
  content: "";
  width: 10px;
  height: 10px;
  border-top: 19px solid transparent;
  border-bottom: 19px solid transparent;
}
.panel .panel-body.tabs > .nav-tabs li.active a:before {
  right: -10px;
  border-left: 10px solid #ffffff;
  z-index: 2;
}
.panel .panel-body.tabs > .nav-tabs li.active a:after {
  right: -11px;
  border-left: 10px solid #e6e6e6;
  z-index: 1;
}
.panel .panel-body.tabs > .nav-tabs li:first-child a {
  border-top: 0 !important;
}
.panel .panel-body.tabs.tabs-right > .tab-pane {
  margin-left: 0;
  margin-right: 120px;
}
.panel .panel-body.tabs.tabs-right > .nav-tabs {
  left: auto;
  right: 0;
  border-right: 0;
  border-left: 1px solid #e6e6e6;
}
.panel .panel-body.tabs.tabs-right > .nav-tabs li.active a:before,
.panel .panel-body.tabs.tabs-right > .nav-tabs li.active a:after {
  border-left: 0;
  right: auto;
}
.panel .panel-body.tabs.tabs-right > .nav-tabs li.active a:before {
  left: -10px;
  border-right: 10px solid #ffffff;
  z-index: 2;
}
.panel .panel-body.tabs.tabs-right > .nav-tabs li.active a:after {
  left: -11px;
  border-right: 10px solid #e6e6e6;
  z-index: 1;
}
.panel-group:not(.separate) .panel {
  border-radius: 0;
}
.panel-group:not(.separate) .panel + .panel {
  margin-top: -2px;
}
.panel-group:not(.separate) .panel .panel-heading {
  border-bottom: 1px solid #eee;
}
.panel-group:not(.separate) .panel .panel-heading + .panel-collapse {
  border-top: 0;
}
.panel-group:not(.separate) .panel:first-child {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}
.panel-group:not(.separate) .panel:last-child {
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}
.panel-group:not(.separate) .panel:last-child .panel-heading {
  border-bottom: 0;
}
.panel-group.separate .panel + .panel {
  margin-top: 3px;
}
.panel.inverse {
  background-color: #333;
  border-color: #333;
}
.panel.inverse .widget-refresh-overlay {
  background-color: rgba(51, 51, 51, 0.8);
}
.panel.inverse .widget-refresh-overlay .inner {
  -ms-filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#CC333333,endColorstr=#CC333333);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#CC333333,endColorstr=#CC333333);
}
.panel.inverse .widget-refresh-overlay .inner .icon {
  color: #ffffff;
}
.panel.inverse .panel-heading h4 {
  color: #ffffff !important;
  text-shadow: none;
}
.panel.inverse .panel-heading .widget-options .widget-minimize,
.panel.inverse .panel-heading .widget-options .widget-refresh,
.panel.inverse .panel-heading .widget-options .widget-close {
  color: #bbbbbb;
}
.panel.inverse .panel-heading .widget-options .widget-minimize:hover,
.panel.inverse .panel-heading .widget-options .widget-refresh:hover,
.panel.inverse .panel-heading .widget-options .widget-close:hover {
  color: #dddddd;
}
.panel.inverse .panel-body {
  border-top-color: #444444;
  color: #cccccc;
}
.panel.minimized .panel-heading {
  border-bottom: 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.panel.collapsible .collapse.in .widget-content {
  border-bottom: 1px solid #cdcdcd;
}
.panel.graphs .panel-heading {
  border-bottom: 1px solid #eeeeee;
}
.panel.graphs .panel-heading h4 {
  float: none;
  display: block;
  font-size: 16px;
  text-transform: uppercase;
  padding-bottom: 3px;
}
.panel.graphs .panel-heading h4.subhead {
  padding-top: 3px;
  padding-bottom: 10px;
  text-transform: none;
  font-weight: 300;
}
.panel.graphs .panel-heading h4.subhead .good,
.panel.graphs .panel-heading h4.subhead .neutral,
.panel.graphs .panel-heading h4.subhead .bad {
  display: inline-block;
  position: relative;
  padding: 0 0 0 15px;
  margin-right: 5px;
  font-weight: bold;
}
.panel.graphs .panel-heading h4.subhead .good:before,
.panel.graphs .panel-heading h4.subhead .neutral:before,
.panel.graphs .panel-heading h4.subhead .bad:before {
  font-family: "FontAwesome";
  position: absolute;
  top: 0;
  left: 0;
}
.panel.graphs .panel-heading h4.subhead .good {
  color: #1da699;
}
.panel.graphs .panel-heading h4.subhead .good:before {
  content: "\f0d8";
}
.panel.graphs .panel-heading h4.subhead .neutral {
  color: #666666;
}
.panel.graphs .panel-heading h4.subhead .neutral:before {
  top: 1px;
  font-size: 12px;
  content: "\f068";
}
.panel.graphs .panel-heading h4.subhead .bad {
  color: #dd494a;
}
.panel.graphs .panel-heading h4.subhead .bad:before {
  content: "\f0d7";
}
.panel.graphs .panel-heading h4 .bigger {
  font-size: 26px;
  margin-right: 5px;
}
.panel.graphs .panel-heading .widget-options {
  float: none;
  position: absolute;
  padding: 0;
  top: 20px;
  right: 30px;
}
.panel.graphs .panel-heading .widget-options .widget-minimize,
.panel.graphs .panel-heading .widget-options .widget-refresh,
.panel.graphs .panel-heading .widget-options .widget-close {
  font-size: 18px;
  margin-left: 10px;
}
.panel.pie-graph .panel-heading {
  border-bottom: 1px solid #eeeeee;
}
.panel.pie-graph .panel-heading h4 {
  font-size: 18px;
  line-height: 18px;
  padding-top: 21px;
  padding-bottom: 21px;
}
@media (max-width: 767px) {
  .panel .widget-title .buttons {
    float: left;
    border-right: 1px solid rgba(255, 255, 255, 0.7);
  }
  .panel .widget-title .buttons > .btn {
    white-space: nowrap;
    overflow: hidden;
    padding: 10px 3px 6px 10px !important;
  }
  .panel .widget-title .buttons > .btn:last-child {
    padding: 9px 6px 6px 10px !important;
    border-right: 1px solid #dddddd;
  }
  .panel .widget-title .buttons .text,
  .panel .widget-title .buttons .go-full-screen {
    display: none;
  }
}
.panel.widget-full-screen {
  position: fixed;
  left: 0;
  top: -16px;
  z-index: 1020;
  width: 100%;
  height: 100%;
}
.panel.widget-full-screen,
.panel.widget-full-screen .widget-title {
  border-radius: 0;
}
.panel-left {
  margin-right: 249px;
}
@media (max-width: 700px) {
  .panel-left {
    margin-right: 0;
  }
}
.panel-right {
  width: 249px;
  background-color: #f2f2f2;
  border-left: 1px solid #dddddd;
  position: absolute;
  right: 0;
  top: 36px;
  bottom: 0;
}
.panel-right .panel-title {
  width: 100%;
  background-color: #ececec;
  border-bottom: 1px solid #dddddd;
}
.panel-right .panel-title h5 {
  font-size: 12px;
  color: #777777;
  text-shadow: 0 1px 0 #ffffff;
  padding: 6px 10px 5px;
  margin: 0;
}
.panel-right .panel-title h5 .panel-content {
  padding: 10px;
}
@media (max-width: 700px) {
  .panel-right {
    border-top: 1px solid #dddddd;
    border-left: none;
    position: relative;
    top: auto;
    right: auto;
    height: auto;
    width: auto;
  }
}
.panel .panel-heading span.icon {
  margin-top: -7px;
  margin-bottom: -8px;
  margin-left: -14px;
  margin-right: 10px;
  padding: 14px 0 13px;
  width: 40px;
  text-align: center;
}
.panel .panel-heading span.icon.border {
  border-right: 1px solid #e2e2e2;
}

.panel-body > .nav-tabs {
  border-bottom-color: #eeeeee;
}
.panel-body > .nav-tabs > li > a {
  border-color: #eeeeee;
  margin-right: -1px;
  border-radius: 0;
  background-color: #fbfbfb;
  color: #999999;
}
.panel-body > .nav-tabs > li.active > a,
.panel-body > .nav-tabs > li.active > a:hover,
.panel-body > .nav-tabs > li.active > a:focus {
  border-color: #eeeeee #eeeeee #ffffff;
  background-color: #fff;
  color: #666666;
}
.panel-body > .tab-content {
  border-left: 1px solid #eeeeee;
  border-right: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
  padding: 10px;
}
.panel-group .panel-heading + .panel-collapse .panel-body {
  border-top: 0;
}
