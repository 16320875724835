@import "../base/variables";

#footer {
  text-align: center;
  color: #999999;
  font-size: 14px;
  padding: 10px 15px 25px;
  left: 0;
  -webkit-transition: left 0.5s;
  transition: left 0.5s;

  a {
    color: rgba(255, 255, 255, 0.5);
    &:hover {
      color: rgba(255, 255, 255, 0.8);
    }
  }
  .pull-left {
    width: 100%;
    text-align: center;
  }
  .pull-right {
    margin-top: -20px;
  }
}
@media (max-width: 991px) {
  #footer {
    position: relative !important;
    bottom: auto !important;
    z-index: 1;
    .menu-open & {
      left: 180px;
    }
  }
}

.pt-app-footer-container {
  position: relative;
}

.pt-app-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  height: $footer-height;
}

.pt-app-footer__links a {
  color: #859521 !important;
}
