.search_counter {
  margin: 0;
  vertical-align: middle;

  .searches_left {
  }
  .searches_cost {
    font-weight: bold;
  }

  //button {
  //  display: inline-block;
  //  color: white;
  //  margin-left: 10px;
  //  &:focus, &:active {
  //    outline: none;
  //  }
  //}
}
.width-90 {
  width: 90%;
  .texts {
    max-width: 100%;
  }
}
