@import "../base/colors";

.pt-messager {
  display: inline-block;
  opacity: 0;

  -webkit-transition: opacity 0.5s ease;
  -moz-transition: opacity 0.5s ease;
  -o-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;

  &--visible {
    opacity: 1;
  }
  &--success {
    color: $green-bright;
  }
  &--warning {
    color: $yellow;
  }
  &--failure {
    color: $red-bright;
  }

  & .fa {
    margin-left: 10px;
  }
}
