@import "../../base/colors";
@import "../../utils/mixins";

.pt-table-config {
  &__modal-panel {
    @include pt-no-select;

    .pt-button {
      margin-left: 10px;

      &:first-child {
        margin-left: 0;
      }
    }

    .pt-base-checkbox {
      margin-right: 10px;
      vertical-align: middle;
    }

    a {
      display: block;
      margin-top: 5px;
      margin-left: 20px;
      cursor: pointer;

      &:first-child {
        margin-top: 0;
      }
    }
  }

  &__shortcut {
    cursor: pointer;
    margin-left: 10px;

    &:first-child {
      margin-left: 0;
    }
  }
}
