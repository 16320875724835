.ps-exp-levels-legend {
  .panel-body {
    padding: 0 !important;
    position: relative;

    table {
      width: 100%;

      th {
        font-size: 10px;
        font-weight: bold;
        padding: 6px 10px;

        &:first-child {
          border-right: 1px solid $gray-border;
          text-align: center;
        }
      }

      td {
        font-size: 10px;
        padding: 4px 10px;
        border-top: 1px solid $gray-border;

        &:first-child {
          text-align: center;
          border-right: 1px solid $gray-border;
        }
      }
    }
  }
}
