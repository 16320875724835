@font-face {
  font-family: "Source Sans Pro";
  src: url("../../assets/fonts/SourceSansPro-ExtraLight.eot");
  src: url("../../assets/fonts/SourceSansPro-ExtraLight.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/fonts/SourceSansPro-ExtraLight.ttf.woff") format("woff"),
    url("../../assets/fonts/SourceSansPro-ExtraLight.ttf") format("truetype"),
    url("../../assets/fonts/SourceSansPro-ExtraLight.svg") format("svg");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url("../../assets/fonts/SourceSansPro-Light.eot");
  src: url("../../assets/fonts/SourceSansPro-Light.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/fonts/SourceSansPro-Light.ttf.woff") format("woff"),
    url("../../assets/fonts/SourceSansPro-Light.ttf") format("truetype"),
    url("../../assets/fonts/SourceSansPro-Light.svg") format("svg");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url("../../assets/fonts/SourceSansPro-Regular.eot");
  src: url("../../assets/fonts/SourceSansPro-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/fonts/SourceSansPro-Regular.ttf.woff") format("woff"),
    url("../../assets/fonts/SourceSansPro-Regular.ttf") format("truetype"),
    url("../../assets/fonts/SourceSansPro-Regular.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url("../../assets/fonts/SourceSansPro-Semibold.eot");
  src: url("../../assets/fonts/SourceSansPro-Semibold.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/fonts/SourceSansPro-Semibold.ttf.woff") format("woff"),
    url("../../assets/fonts/SourceSansPro-Semibold.ttf") format("truetype"),
    url("../../assets/fonts/SourceSansPro-Semibold.svg") format("svg");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url("../../assets/fonts/SourceSansPro-Bold.eot");
  src: url("../../assets/fonts/SourceSansPro-Bold.eot?#iefix") format("embedded-opentype"),
    url("../../assets/fonts/SourceSansPro-Bold.ttf.woff") format("woff"),
    url("../../assets/fonts/SourceSansPro-Bold.ttf") format("truetype"),
    url("../../assets/fonts/SourceSansPro-Bold.svg") format("svg");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url("../../assets/fonts/SourceSansPro-Black.eot");
  src: url("../../assets/fonts/SourceSansPro-Black.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/fonts/SourceSansPro-Black.ttf.woff") format("woff"),
    url("../../assets/fonts/SourceSansPro-Black.ttf") format("truetype"),
    url("../../assets/fonts/SourceSansPro-Black.svg") format("svg");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url("../../assets/fonts/SourceSansPro-ExtraLightIt.eot");
  src: url("../../assets/fonts/SourceSansPro-ExtraLightIt.ttf.woff") format("woff"),
    url("../../assets/fonts/SourceSansPro-ExtraLightIt.ttf") format("truetype"),
    url("../../assets/fonts/SourceSansPro-ExtraLightIt.svg") format("svg");
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url("../../assets/fonts/SourceSansPro-LightIt.eot");
  src: url("../../assets/fonts/SourceSansPro-LightIt.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/fonts/SourceSansPro-LightIt.ttf.woff") format("woff"),
    url("../../assets/fonts/SourceSansPro-LightIt.ttf") format("truetype"),
    url("../../assets/fonts/SourceSansPro-LightIt.svg") format("svg");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url("../../assets/fonts/SourceSansPro-It.eot");
  src: url("../../assets/fonts/SourceSansPro-It.eot?#iefix") format("embedded-opentype"),
    url("../../assets/fonts/SourceSansPro-It.ttf.woff") format("woff"),
    url("../../assets/fonts/SourceSansPro-It.ttf") format("truetype"),
    url("../../assets/fonts/SourceSansPro-It.svg") format("svg");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url("../../assets/fonts/SourceSansPro-SemiboldIt.eot");
  src: url("../../assets/fonts/SourceSansPro-SemiboldIt.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/fonts/SourceSansPro-SemiboldIt.ttf.woff") format("woff"),
    url("../../assets/fonts/SourceSansPro-SemiboldIt.ttf") format("truetype"),
    url("../../assets/fonts/SourceSansPro-SemiboldIt.svg") format("svg");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url("../../assets/fonts/SourceSansPro-BoldIt.eot");
  src: url("../../assets/fonts/SourceSansPro-BoldIt.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/fonts/SourceSansPro-BoldIt.ttf.woff") format("woff"),
    url("../../assets/fonts/SourceSansPro-BoldIt.ttf") format("truetype"),
    url("../../assets/fonts/SourceSansPro-BoldIt.svg") format("svg");
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url("../../assets/fonts/SourceSansPro-BlackIt.eot");
  src: url("../../assets/fonts/SourceSansPro-BlackIt.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/fonts/SourceSansPro-BlackIt.ttf.woff") format("woff"),
    url("../../assets/fonts/SourceSansPro-BlackIt.ttf") format("truetype"),
    url("../../assets/fonts/SourceSansPro-BlackIt.svg") format("svg");
  font-weight: 900;
  font-style: italic;
}

body {
  font-family: $font;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: $font;
}
/* Add a padding to description paragraph, as of panel body content does not have any padding */
p.description {
  padding-left: 20px;
  padding-right: 20px;
  text-align: justify !important;
}
