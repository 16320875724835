.content-header .container {
  background: #eeeeee;
  min-height: 102px;
  border-bottom: 1px solid #cccccc;
}

.content-header h1 {
  font-size: 26px;
  color: #666666;
  font-weight: 600;
  margin-bottom: 6px;
}

.content-header p {
  color: #666666;
}
// NOTE: Not used in latest version of library and messed with reactr-date styling
// ::-webkit-input-placeholder {
//   /* Chrome/Opera/Safari */
//   color: pink;
// }
// ::-moz-placeholder {
//   /* Firefox 19+ */
//   color: pink;
// }
// :-ms-input-placeholder {
//   /* IE 10+ */
//   color: pink;
// }
// :-moz-placeholder {
//   /* Firefox 18- */
//   color: pink;
// }
.content-header .btn {
  border: 0;
  border-radius: 2px;
  font-size: 16px;
  font-weight: 600;
  box-shadow: none;
}

.content-header .btn-primary.active,
.content-header .btn-primary:active,
.content-header .btn:focus,
.content-header .btn:hover,
.open > .content-header .dropdown-toggle.btn-primary {
  outline: none;
}

.content-header .dropdown-menu {
  background: #575656;
  color: #ffffff;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  margin-top: 0;
  box-shadow: none;
  width: 100%;
  border: 0;
}

.content-header .dropdown-menu > li > a {
  color: #ffffff;
}

.content-header .dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
  color: #e2e9b9;
  background-color: transparent;
}

.content-header .action_button .btn {
  min-width: 180px;
  text-align: left;
  margin-top: 30px;
}

.content {
  width: 100%;
}

.user-job-data h2 {
  font-size: 36px;
  //font-weight: 700;
  color: #666666;
  margin-top: 0;
  margin-bottom: 10px;
}

.user-job-data .box {
  min-height: 278px;
  padding: 22px;
}

.user-job-data .box label {
  font-size: 13px;
  color: #999999;
  font-weight: normal;
}

.user-job-data .box input.form-control,
.user-job-data .box input.rc-input-box {
  height: 34px;
}

.input_group {
  position: relative;
}

.user-job-data .box button.search_btn {
  z-index: 9;
  background: $rc-green4;
  height: 100%;
  width: 40px;
  position: absolute;
  top: 0;
  border: none;
  right: 0;
  color: #fff;
  border-radius: 0 4px 4px 0;
}

.box {
  position: relative;
  margin-bottom: 20px;
  width: 100%;
}

#job_description {
  min-height: 118px;
}

.mb-none {
  margin-bottom: 0;
}

.button-grey {
  background: $rc-green4;
  min-width: 162px;
  background: $rc-green4;
  border: 0;
  border-radius: 2px;
  font-size: 16px;
  font-weight: 600;
  box-shadow: none;
}

#upload_job p,
#upload_job p label {
  font-size: 14px;
  color: #999999;
}

.button-grey:focus,
.button-grey:hover {
  background: $rc-yellow !important;
  outline: none;
  border: 0;
  border-radius: 2px;
  font-size: 16px;
  font-weight: 600;
}

.box-filters {
  width: 100%;
}

.box-filter {
  border: 1px solid #cccccc;
  margin-bottom: 14px;
}
@media screen and (min-width: 1200px) {
  .content {
    margin: 45px auto 0;
    min-height: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .box-filter {
    width: 1100;
  }
}

.box .form-control {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  font-size: 14px;
  transition: all 0.2s;
  color: #333;
}

.box-filter-table td:first-child {
  background: #dddddd;
  text-align: center;
  vertical-align: top;
  width: 37px;
  padding: 16px 0;
}

.box-filter-table td:nth-child(2) {
  padding: 16px;
  vertical-align: top;
  width: 767px;
}

.box-filter-table td:nth-child(3) {
  padding: 16px;
  vertical-align: top;
}

.box-filter-table td h4 {
  margin-top: 0;
  color: #666666;
  font-style: normal;
  font-size: 18px;
  margin-bottom: 1px;
}

.box-filter-table td h4 span {
  font-weight: 600;
  font-size: 16px;
}

.box-filter-table td .box-filter-sub-title {
  color: #666666;
  font-weight: 600;
  font-size: 15px;
  margin-bottom: 2px;
}

.box-filter-author-name {
  margin-top: 23px;
  text-align: right;
}

.box-filter-view-more {
  margin-top: 17px;
}

.box-filter-view-more .button-grey {
  padding: 4px;
  min-width: 138px;
}

.box-filter-tagsinput .label-info {
  background: #999999;
  font-size: 12px;
  font-weight: 400;
  border-radius: 0;
  border: #666666 1px solid;
  margin-right: 2px;
  padding: 4px 12px;
}

.modal {
  z-index: 99999;
}

.custom-modal-box .modal-dialog {
  width: 1015px;
}

.custom-modal-box .modal-content {
  border: 10px solid #666666;
  border-radius: 0;
  box-shadow: none;
}

.custom-modal-box .modal-header .close {
  margin-top: -6px;
  color: #666666;
  opacity: 1;
  font-size: 32px;
  font-weight: bold;
  outline: none !important;
}

.btn {
  outline: none !important;
}

.custom-modal-box .modal-title {
  margin: 0;
  line-height: 1.42857143;
  font-size: 22px;
  font-weight: 700;
  font-style: normal;
  color: #666666;
}

.custom-modal-box .modal-header {
  border-bottom: 0;
}

.custom-modal-box {
  color: #666;
}

.custom-modal-box .modal-inner-body {
  border: 1px solid #cccccc;
  min-height: 250px;
  padding: 25px 15px;
}

.custom-modal-box .modal-body {
  padding: 15px 25px;
}

.custom-modal-box .modal-header {
  padding: 25px;
}

.custom-modal-box label {
  font-weight: 500;
  font-size: 14px;
  color: #999999;
}

.custom-modal-box .button-grey {
  font-weight: 400;
  font-size: 15px;
}

.edit-btn {
  padding: 2px;
  min-width: 80px;
  position: relative;
  top: 25px;
}

.custom-modal-box .button-grey.light-white {
  margin-right: 15px;
  background: #eeeeee;
  color: #000000;
  border: 1px solid #666666;
}

.custom-modal-box .button-grey.light-white:hover {
  background: #e3e1e1 !important;
}

.fs-20 {
  font-size: 20px;
  font-weight: 600;
  color: #666;
}

.box {
  border: 1px solid #cccccc;
  background: none;
}
#upload_job .upload-job {
  padding-top: 50px;
}
.upload-job:after {
  content: "OR";
  position: absolute;
  height: 45px;
  top: 8px;
  left: 50%;
  border-left: 1px solid #666666;
  width: 45px;
  background: #666;
  border-radius: 50%;
  text-align: center;
  color: #fff;
  font-size: 17px;
  line-height: 43px;
  margin-left: -23px;
}

.upload-job::before {
  content: "";
  position: absolute;
  height: 1px;
  top: 30px;
  left: 0;
  width: 100%;
  background: #999;
}

.upload-job .box .btn-file {
  border: 1px solid #666666;
  padding: 6px 18px;
  background: #eeeeee;
  color: #000;
  border-radius: 3px;
  text-align: center;
  display: inline-block;
  cursor: pointer;
  font-size: 14px;
  width: 100%;
}

.dropdown {
  display: inline-block;
}

.ratecard_detail div {
  margin-top: 10px;
}

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 240px;
  height: 30px;
  padding: 10px 20px;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: relative;
  top: 20px;
  width: 100%;
  border: 10px solid #666;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  max-height: 100px;
  z-index: 2;
  min-height: 400px;
  max-height: 400px;
  overflow-y: auto;
}

.search-filter {
  background: #dddddd;
  padding-right: 7px;
  padding-left: 7px;
  min-height: 50px;
}

.react-autosuggest__suggestions-container--open:before {
  border: solid;
  border-color: #666 transparent;
  border-width: 0 6px 6px 6px;
  top: -16px;
  content: "";
  left: 50%;
  position: absolute;
  z-index: 123;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  overflow-y: auto;
  min-height: 380px;
  max-height: 380px;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.react-autosuggest__container > input {
  display: block;
  width: 100%;
  height: 50px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857;
  color: #555555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  border: 1px solid #666;
  border-radius: 0;
  box-shadow: none !important;
  border: 1px solid #666;
  color: #aaaaaa;
  position: relative;
  border-radius: 0;
}

.react-autosuggest__container:after {
  content: "\F002";
  background: $rc-green4;
  color: #fff;
  top: 0;
  content: "\F002";
  font-family: FontAwesome;
  font-weight: normal;
  font-style: normal;
  margin: 0 0 0 10px;
  text-decoration: none;
  text-align: center;
  width: 50px;
  height: 50px;
  font-size: 18px;
  position: absolute;
  right: 0;
  line-height: 47px;
}

.DateRangePickerInput {
  background: transparent;
  border: 1px solid #ddd;
}

.DateInput {
  background: transparent;
}

.pull-right.filter-toggle-buttons {
  margin-top: 7px;
}

.content {
  width: 1109px;
  margin: 45px auto 0;
}

.job-id {
  font-weight: 400 !important;
}

button.btn-primary.btn.btn-filters {
  background: $rc-green4;
  min-width: 94px;
  max-width: 100px;
  border-radius: 0;
  margin-right: 7px;
  padding: 6px 5px;
  outline: none !important;
  border: 1px solid $rc-green4;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 13px;
}

.rc-input-box {
  width: 100%;
}

.filter-toggle-buttons button.btn-primary.btn.btn-filters {
  min-width: 94px;
  max-width: 108px;
}

.checkbox-position {
  margin-left: 15px;
  margin-top: 15px;
}

.filter-dropdown {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: bold;
  width: auto;
}

.browse_button {
  position: absolute;
  visibility: hidden;
}

.form-group.upload-btn {
  margin-top: 5px;
}

.action .dropdown-toggle {
  border: 1px solid #999;
  min-width: 155px;
  text-align: left;
  margin-top: 9px;
  margin-bottom: 9px;
  color: #999;
  background: #fff;
  font-weight: 300;
}

.action .btn-default.active,
.action .btn-default:active,
.action .dropdown-toggle:active,
.action .dropdown-toggle:focus,
.action .dropdown-toggle:hover,
.open > .action .btn-default.dropdown-toggle {
  border: 1px solid #999;
  min-width: 155px;
  text-align: left;
  margin-top: 9px;
  margin-bottom: 9px;
  color: #999;
  background: #fff !important;
  font-weight: 300;
  box-shadow: none;
}

.action .dropdown-toggle .caret {
  float: right;
  margin-top: 7px;
}

.action .dropdown-menu {
  margin-top: -9px;
}

.dropdown-menu {
  border-radius: 0;
  margin-top: 0;
}

.mt-39 {
  margin-top: 39px;
  margin-left: -39px;
}

.btn {
  cursor: pointer;
  border-radius: 3px;
  font-size: 14px;
  line-height: normal;
  border: 2px solid $rc-default-color !important;
  background-color: $rc-default-color;
  color: $rc-white1;
  margin: 0 10px 0 0;
  padding: 4px 10px;
  transition: all 0.2s ease;

  .fa.icon {
    margin-left: -2px;

    & + span {
      margin-left: 2px;
    }
  }

  &.btn-lg {
    padding: 10px 20px;
    font-size: 18px;
    line-height: 1.33333;

    .fa.icon {
      margin-left: -4px;

      & + span {
        margin-left: 10px;
      }
    }
  }

  input[type="checkbox"] {
    margin: 0 8px 0 0;
  }

  &:last-child {
    margin: 0;
  }

  &:hover {
    background-color: darken($rc-default-color, 6.8%);
    border: 2px solid darken($rc-default-color, 6.8%) !important;
    //color: $rc-green5 !important;
  }
}

.btn-default {
  border-color: $rc-default-color !important;
  background-color: $rc-default-color;
  color: $rc-white1 !important;

  &:hover {
    background-color: darken($rc-default-color, 6.8%);
    border-color: darken($rc-default-color, 6.8%) !important;
  }
}

.btn-yellow {
  border: 2px solid $rc-yellow !important;
  background-color: $rc-yellow;
  color: $rc-white1 !important;

  &:hover {
    border: 2px solid $rc-yellow2 !important;
    background-color: $rc-yellow2;
  }
}

.btn-green {
  border: 2px solid $rc-green4 !important;
  background-color: $rc-green4;
  color: $rc-white1 !important;

  &:hover {
    border: 2px solid $rc-green6 !important;
    background-color: $rc-green6;
  }
}

.btn-danger {
  border: 2px solid $rc-danger-color !important;
  background-color: $rc-danger-color;
  color: $rc-white1 !important;

  &:hover {
    border: 2px solid darken($rc-danger-color, 6.8%) !important;
    background-color: darken($rc-danger-color, 6.8%);
  }
}

.btn-toggle {
  color: darken($rc-input-border-color, 32.8%);
  border: 2px solid $rc-input-border-color !important;
  background-color: white;
  position: relative;
  //transition: all .2s;
  input[type="checkbox"],
  input[type="radio"] {
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
  }

  &.borderless {
    border: 2px solid transparent !important;
    background-color: transparent;
  }

  &.btn-lg {
    border-radius: 6px;
  }

  &.selected,
  &.selected:hover {
    color: $rc-green6;
    border: 2px solid lighten($rc-yellow, 6.8%) !important;
    background-color: $rc-green8;
  }

  &:hover {
    color: $rc-green6 !important;
    border: 2px solid lighten($rc-green6, 6.8%) !important;
    background-color: $rc-green8;
  }

  &:active {
    box-shadow: none;
  }

  &:last-child,
  &:only-child {
    margin: 0;
  }

  .text-x-small {
    color: darken($rc-input-border-color, 14.2%);

    .fa.icon {
      margin-left: 0;
      font-size: 13px;
      _ra & + span {
        margin-left: 5px;
      }
    }
  }
}

.btn-toggle-group {
  margin: 0;
  padding: 0;
  display: inline;

  &:not(:last-child) {
    margin-right: 20px;
  }

  .btn-toggle {
    margin: 0 0 0 -1px;

    &:not(:first-child):not(:last-child) {
      border-right: 0 !important;
      border-left: 1px solid $rc-element-border-color !important;
      border-radius: 0;
    }

    &:first-child {
      border-right: 0 !important;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:last-child {
      border-left: 1px solid $rc-element-border-color !important;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &:not(:first-child).selected,
    &:not(:first-child).selected:hover,
    &:not(:first-child):hover {
      border-left: 1px solid $rc-element-border-color !important;
    }
  }
}

.rc-input-box {
  @include pt-input;

  &:disabled {
    background-color: $rc-element-border-color;
    opacity: 0.6;
  }
}

.clearfix {
  clear: both;
}

// TODO: Fix style in job libraries
//.ratecards {
//  width: 100%;
//}

.modal-footer .btn {
  margin-right: 0;
  margin-left: 4px;
}

.input-group-btn:last-child > .btn,
.input-group-btn:last-child > .btn-group {
  border-width: 0 !important;
  padding: 7px 12px !important;
}

.btndisabled {
  opacity: 0.65;
  cursor: not-allowed !important;
}

.alignclassalt .header-left-nav.atl-1 {
  float: left;
}

.alignclassalt .header-right-nav.atl-1 {
  float: right;
}

.ratecards-table-header.alignclassalt {
  clear: both;
  width: 100%;
  margin: 10px -20px;
  height: 50px;
}

.ratecards-table {
  width: 100%;
}
/*altclass*/
.greyaltbg {
  background: #f8f8f8;
  margin-top: -20px;

  .user-job-data {
    .box {
      min-height: 260px;
    }
  }
  .input-group {
    padding: 0px;
  }
  .rc-input-box {
    font-size: 14px !important;
  }
  .upload-btn {
    margin-right: -2px;
    .btn-green {
      margin-right: 0px;
    }
  }
  .fileinput-new {
    float: right;
    margin-right: -2px;
  }
  .form-control {
    font-size: 14px;
  }
}
.altuifixtablewidth {
  // .ratecards-table {
  //   width: 103.2% !important;
  // }
}

.info_head {
  &.saved-search-details {
    h2 {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 90%;
    }
  }
}

.altwidthfix {
  .search-list-item-left,
  .search-list-item-right {
    width: 50%;
  }

  .search-list-item-right {
    .search-info {
      max-width: 112px;
      width: 18%;
    }
  }
}
.ratecards .ratecard-list-item-right {
  justify-content: flex-end;
  padding-right: 10px;
}
.alignclassalt {
  padding-right: 0px !important;
  .borderless {
    &:last-child {
      margin-right: -10px;
    }
  }
}
h3 {
  color: #666;
}
.rc-input-box::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #aaa;
}
.rc-input-box::-moz-placeholder {
  /* Firefox 19+ */
  color: #aaa;
}
.rc-input-box:-ms-input-placeholder {
  /* IE 10+ */
  color: #aaa;
}
.rc-input-box:-moz-placeholder {
  /* Firefox 18- */
  color: #aaa;
}
.atl-mar-help {
  height: 40px;
  margin: 0 10px;
}
.user-job-data .Select-control {
  padding: 0px !important;
  height: 35px;
  .Select-input {
    padding: 5px;
    height: 34px;
  }
  .Select-clear {
    top: 5px;
    right: 25px;
  }
}
.form-group.upload-btn {
  margin-top: 10px;
  margin-right: 0px;
  .btn-green {
    min-width: 111px;
    margin-right: 0px;
    width: 100%;
  }
}
.Select-input {
  padding: 5px;
}
.userjob {
  .Select-loading {
    margin-top: -9px !important;
  }
}
.add-job {
  .Select {
    &-loading {
      top: 6px;
    }
  }
}

.ratecards.schedulesearch
  .search-card-content.atlfixformborder
  .regionfix
  .Select-menu-outer {
  position: static;
  margin-top: -11px;
}
.user-job-data .input-box-fix .Select-input {
  margin-top: 3px;
  max-width: 85%;
}
.user-job-data .input-box-fix .Select {
  height: 34px;
}
textarea {
  &.rc {
    &-input {
      &-box {
        resize: none;
      }
    }
  }
}
.alert-red {
  color: #a94442;
}
.alert-green {
  color: limegreen;
}
.country-text {
  padding: 0 5px;
  max-width: 92%;
  display: inline-block;
  width: 100%;
}
.listflex {
  @include flexbox();
  display: flex !important;
  @include justify-content(space-between);
  @include align-items(center);
  span {
    &:first-child {
      width: 90%;
    }
  }
}
.fa.fa-plus.greenbox,
.fa.fa-minus.greenbox {
  background: #a2b624;
  @include flexbox();
  @include justify-content(center);
  @include align-items(center);
  width: 20px;
  height: 20px;
  color: #fff;
  border-radius: 50%;
}
.atlfixformborder .form-group.cross-fix .Select.is-searchable {
  .Select-clear {
    top: 5px;
  }
  .Select-placeholder {
    padding-left: 10px;
  }
}
.modal-container.headrtfix {
  .header-action-bar.marginTopzero {
    padding-top: 0px;
  }
}
.scerate {
  .search-card-content {
    padding-top: 0px !important;
    min-height: 1px;
  }
  br {
    display: none;
  }
}
.marginbottom10 {
  margin-bottom: 10px !important;
}
