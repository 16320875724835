@import "../../../base/colors";
@import "../../../base/labor_index/typography";
@import "../../../utils/mixins";

$content-padding: 15px 15px 10px;

.pt-question-panel {
  padding-bottom: 10px;
  margin-bottom: 16px;
  background-color: $gray-light;
  @include pt-raised-shadow();

  ::selection {
    background-color: lighten($purple, 30%) !important;
  }

  &__title {
    margin: 0;
    padding: 20px 15px 10px;
    color: $purple;
    background: rgba(238, 238, 238, 0);
    box-shadow: 0 1px 0 0 $white, inset 0 1px 0 0 $white, inset 0 -1px 0 0 $gray-darker;
  }

  &__prompt {
    margin: 0;
    @include pt-form-text();
  }

  &__content {
    padding: $content-padding;
  }
}

.pt-question-form {
  margin-top: 25px;

  &__email,
  &__question {
    @include pt-bordered-input();
    border-radius: 0;

    &:focus {
      border-color: lighten($purple, 30%) !important;
    }
  }

  &__submit {
    background: $purple;
    font-family: $ff-source-regular;
    font-size: 16px;
    color: $white;
    line-height: 20px;
    width: 100%;
    border: 0;
    height: 40px;

    &:hover {
      color: $white;
    }
  }

  &--submitted {
    @include pt-form-text();
    padding: $content-padding;
  }

  &__dismiss {
    cursor: pointer;
  }
}

.pt-highlight-text {
  color: $purple !important;
  font-weight: bold;
}
