#industry-header {
  margin-top: 15px;
  margin-right: 15px;
  margin-left: 15px;
  border: 1px solid #dddddd;
  background-color: #fff;
}
#industry-header .left {
  padding-top: 20px;
  padding-bottom: 5px;
  padding-left: 25px;
}
#industry-header .left:hover {
  cursor: pointer;
}
#industry-header .left .label {
  font-weight: normal;
  color: #90a927;
  padding-left: 0;
}
#industry-header ul {
  height: 0;
  opacity: 0;
  z-index: 20;
  position: absolute;
  width: 100%;
  top: 100px;
  left: 0;
  background-color: #fff;
  margin: 0;
  padding: 0;
  list-style: none;
  border: 1px solid #999999;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  overflow: hidden;
}

#industry-header ul li {
  margin-top: 1px;
}
#industry-header ul li:first-child {
  margin-top: 0;
}
#industry-header ul li a {
  display: block;
  padding: 8px 25px 8px;
  font-size: 18px;
  color: #232e35;
}
#industry-header ul li a .fa {
  width: 18px;
  margin-right: 20px;
  text-align: center;
}
#industry-header ul li a .ishortcut {
  float: right;
  text-transform: uppercase;
}
#industry-header ul li a[data-industry="all"] {
  background-color: #232e35;
  color: #ffffff;
}
#industry-header ul li a[data-industry="all"] .ishortcut {
  color: #90a927;
}
#industry-header ul li a[data-industry="academia"] {
  background-color: #fec591;
}
#industry-header ul li a[data-industry="agriculture"] {
  background-color: #fef5a2;
}
#industry-header ul li a[data-industry="aerospace-and-defense"] {
  background-color: #bbdea0;
}
#industry-header ul li a[data-industry="computers-and-technology"] {
  background-color: #8fd3a0;
}
#industry-header ul li a[data-industry="consumer-goods"] {
  background-color: #59ca9f;
}
#industry-header ul li a[data-industry="finance"] {
  background-color: #44cdc8;
}
#industry-header ul li a[data-industry="government"] {
  background-color: #20d2f4;
}
#industry-header ul li a[data-industry="healthcare"] {
  background-color: #6baad7;
}
#industry-header ul li a[data-industry="insurance"] {
  background-color: #7f96c8;
}
#industry-header ul li a[data-industry="manufacturing"] {
  background-color: #8b85bc;
}
#industry-header ul li a[data-industry="oil-and-gas"] {
  background-color: #ab8abe;
}
#industry-header ul li a[data-industry="pharmaceutical"] {
  background-color: #cb8fbf;
}
#industry-header ul li a[data-industry="power-and-utilities"] {
  background-color: #fe9cc3;
}
#industry-header ul li a[data-industry="professional-services"] {
  background-color: #fe98a1;
}
#industry-header ul li a[data-industry="telecommunications"] {
  background-color: #fd9780;
}
#industry-header ul li a[data-industry="conglomerate"] {
  background-color: #fead88;
}

#industry-header .right {
  padding-top: 23px;
}
#industry-header .left.labor-index {
  background-color: #2b3239;
}
#industry-header > .labor-index h1 {
  color: #ffffff;
}
#industry-header > .labor-index .shortcut {
  color: #90a927;
}

#industry-name {
  font-size: 42px;
  font-weight: 600;
  padding: 0;
  margin: 0;
}
#industry-name .shortcut {
  margin-left: 15px;
  font-size: 35px;
  vertical-align: bottom;
  font-weight: normal;
}
#industry-name .fa {
  margin-top: 10px;
  margin-right: 10px;
  float: right;
}
.last-price,
.today-change {
  float: left;
}
.last-price {
  margin-left: 10px;
  margin-right: 20px;
}
.last-price h1,
.today-change h1 {
  margin: 0;
  padding: 0;
}
.last-price h1 {
  color: #666666;
}
.last-price .label,
.today-change .label {
  color: #888888;
  font-weight: normal;
  padding-left: 0;
}
.today-change .fa {
  margin-right: 10px;
}

#performanceTable tbody tr td:first-child > .fa {
  margin-top: 4px;
  float: right;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
#performanceTable tbody tr.parent.open td:first-child > .fa {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
}
#performanceTable tbody tr.data > td {
  padding: 0;
  box-shadow: 0 11px 20px -10px rgba(0, 0, 0, 0.7) inset,
    0 -11px 20px -10px rgba(0, 0, 0, 0.7) inset;
}
#performanceTable tbody tr.data td {
  background-color: #2b3239;
}

#performanceTable tr.parent > td {
  border-bottom: 0 !important;
}
#performanceTable tr.parent:hover {
  cursor: pointer;
}
#performanceTable tr.data > td {
  border-top: 0 !important;
}
#performanceTable tr.data .row {
  display: none;
  padding-top: 20px;
  padding-bottom: 15px;
}
#performanceTable tr.data table {
  border: 0;
  background-color: transparent;
}
#performanceTable tr.data table thead th,
#performanceTable tr.data table td {
  color: #ffffff;
  border-bottom-width: 1px;
  border-bottom-color: #555555;
  border-bottom-color: rgba(255, 255, 255, 0.2);
  padding: 10px 10px;
}
#performanceTable tr.data table thead th {
  box-shadow: none;
  font-weight: normal;
  text-transform: uppercase;
  border-bottom-style: solid;
}
#performanceTable tr.data table td {
  height: 35px;
  vertical-align: middle;
  border-top: 0;
  border-bottom-style: dashed;
}
#performanceTable tr.data table td.vt {
  vertical-align: top;
}
#performanceTable tr.data table.top-industries td {
  height: 58.15px;
}
#analysisBottom {
  margin-top: 10px;
  padding-left: 7px;
  padding-right: 7px;
}
#analysisBottom .last-update h3 {
  margin: 0;
  padding: 0;
  font-size: 20px;
  color: #666666;
}
#analysisBottom .last-update p.label {
  color: #999999;
  font-weight: normal;
  text-transform: uppercase;
  font-size: 12px;
  padding-left: 0;
}
#analysisBottom .btn {
  border-radius: 4px;
  font-size: 14px;
  padding: 10px 20px;
}
#analysisBottom .btn .fa {
  margin-right: 5px;
}
