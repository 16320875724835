@import "../../../../base/colors";

$page-padding-regular: 0.25in;
$page-padding-half: 0.125in;
$pt-store-base-color: #3d6deb;
$pt-store-font-color: #a6a6a6;

$pt-store-page-width: 793.91px;
$pt-store-page-height: 1122.23px;
$pt-store-header-height: 145px;
$pt-store-content-height: 924px;
$pt-store-footer-height: 53.23px;

.ps-a4-page-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: $pt-store-page-width;
  height: $pt-store-page-height;
  background-color: $white;
  font-size: 12px;

  page-break-after: always;
  page-break-inside: avoid;
  &:last-child {
    page-break-after: avoid;
  }

  /* overrides */

  /*h1, h2, h3  {
    font-weight: bold;
  }
  h1 {
    font-size: 24px;
  }
  h2 {
    font-size: 16px;
    border-bottom: 1px solid #ccc;
    padding: 0 0 2px 0;
    margin: 0 0 5px 0;
  }
  h3 {
    font-size: 13px;
    margin: 0 0 2px 0;
  }*/

  /* internals */

  &__header {
    flex: 0 0 $pt-store-header-height;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    overflow: hidden;
    padding: $page-padding-half $page-padding-regular;
    padding-bottom: 0;
  }

  &__content {
    position: relative;
    flex: 0 0 $pt-store-content-height;
    overflow: hidden;
    color: $pt-store-font-color;
    padding: 0 $page-padding-regular;
  }

  &__footer {
    flex: 0 0 $pt-store-footer-height;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    padding: $page-padding-half $page-padding-regular;
    padding-top: 0;
  }

  &__people-bluebook-logo {
    width: 100%;
  }

  &__copyright-container {
    width: 100%;
    height: $pt-store-footer-height - 1 * $page-padding-half;
    line-height: $pt-store-footer-height - 1 * $page-padding-half;
    text-align: center;
    vertical-align: middle;
    background-color: $pt-store-base-color;
    color: #fcfcff;
    font-size: 14px;
  }

  &__peopleticker-logo {
    display: inline-block;
    width: 15%;
  }
}
