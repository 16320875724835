@import "../../../base/colors";

.pt-pi-regular-contractor-editor {
  position: relative;
  margin: 40px 0;

  &__message {
    padding: 0 10px;

    .fa {
      margin-right: 8px;
    }
    .fa.positive,
    .fa.negative {
      font-size: 40px;
      line-height: 40px;
      vertical-align: middle;
    }
    .fa.negative {
      color: rgba($green-bright, 0.1);
    }
    .fa.negative {
      color: rgba($red-bright, 0.4);
    }
  }

  &__field {
    position: relative;
    margin-top: 10px;
    padding: 0 10px;

    &:last-child {
      margin-bottom: 10px;
    }
  }

  &__buttons {
    padding: 15px !important;
    text-align: right;

    .fa {
      margin-right: 5px;
    }

    .pt-button {
      margin-left: 10px;

      &:first-child {
        margin-left: 0;
      }
      &--disabled {
        background-color: #e6e3e3 !important;
      }
    }
  }
  &__back-button {
    background-color: #c3c3c3;
  }
  &__undo-button {
    background-color: #ff7a73;
  }
  &__apply-button,
  &__search-button {
    background-color: #64da8d;
  }

  .pt-messager {
    display: block;
    text-align: center;
  }

  .panel {
    margin-top: 0;
    margin-bottom: 0;

    .panel-title {
      font-size: 24px;
    }
    .panel-heading,
    .list-group-item {
      padding: 10px 20px;
    }
  }

  // overrides

  .panel-danger {
    border-color: #ebccd1 !important;

    .panel-heading {
      background-color: #f2dede;
      border-bottom: 1px solid #ebccd1;
    }

    .list-group-item {
      border-color: #ebccd1;
    }
  }
  .panel-success {
    border-color: #d6e9c6 !important;

    .panel-heading {
      background-color: #dff0d8;
      border-bottom: 1px solid #d6e9c6;
    }

    .list-group-item {
      border-color: #d6e9c6;
    }
  }
}
