@import "../base/colors";

.bar-slider {
  padding: 44px 10px 50px;
  font-size: 14px;

  @media (min-width: 992px) {
    padding: 45px 10px 50px;
  }

  &-header {
    width: 340px;
    text-align: center;
    margin: 0 auto;
    white-space: nowrap;
    font-size: 18px;
  }

  &-base {
    margin: 2rem 0;
    width: 100%;
    height: 6rem;
    background-color: #dddddd;
  }

  &-marks {
    height: 100%;
    width: calc(50% + 4px);
    border-right: 4px solid #dddddd;
  }

  &-marks-annual {
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;

    li {
      display: inline-block;
      list-style: none;
      height: 8rem;
      width: 10%;
      margin-top: -10px;
      text-align: center;

      @media (max-width: 400px) {
        font-size: 10px;
      }
    }

    li:first-child {
      width: 5%;
      text-align: left;

      div:first-child {
        border-right: none;
        border-left: 6px solid #dddddd;
      }
    }

    li:last-child {
      width: 5%;
      text-align: right;

      div:first-child {
        width: 100%;
        border-right: 6px solid #dddddd;
      }
    }
  }

  &-marks-hourly {
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;

    li {
      display: inline-block;
      list-style: none;
      height: 8rem;
      width: 14%;
      margin-top: -10px;
      text-align: center;

      @media (max-width: 400px) {
        font-size: 10px;
      }
    }

    li:first-child {
      width: 8%;
      text-align: left;

      div:first-child {
        border-right: none;
        border-left: 6px solid #dddddd;
      }
    }

    li:last-child {
      width: 8%;
      text-align: right;

      div:first-child {
        width: 100%;
        border-right: 6px solid #dddddd;
      }
    }
  }

  &-green {
    background: $lime;
    height: 6rem;
    margin-top: -8rem !important;
    position: relative;
    z-index: 100;
  }

  &-arrow:before {
    content: "";
    width: 0;
    height: 0;
    border-bottom: 4rem solid $lime;
    border-left: 1rem solid transparent;
    border-right: 1rem solid transparent;
  }

  &-your-rate {
    padding: 1rem 1.2rem;
    background: $lime;
    width: 200px;
    margin: 3rem 0 0;
    text-align: center;

    @media (max-width: 400px) {
      font-size: 12px;
    }
  }
}
