/* Datepicker */
.datepicker th.dow,
.datepicker td.day,
.datepicker span.year,
.datepicker span.month {
  font-size: 12px;
}
.datepicker th {
  font-weight: normal;
  font-family: $font;
  color: #000000;
}
.datepicker th.prev,
.datepicker th.switch,
.datepicker th.next {
  padding-top: 0;
}
.datepicker th.dow {
  font-weight: bold;
}
.datepicker td.day,
.datepicker span.year,
.datepicker span.month {
  color: #777777;
}
.datepicker td.new,
.datepicker td.old,
.datepicker span.year.new,
.datepicker span.year.old {
  color: #cccccc;
}
.datepicker td.active,
.datepicker span.year.active,
.datepicker span.month.active,
.datepicker td.active:hover,
.datepicker span.year.active:hover,
.datepicker span.month.active:hover {
  color: #ffffff;
  text-shadow: none;
  background-image: none;
}
.datepicker span.year,
.datepicker span.month {
  height: 47.7px;
  width: 32%;
  margin: 0 0.5%;
  line-height: 47.7px;
}
.datepicker .datepicker-years td,
.datepicker .datepicker-months td {
  padding: 0;
}
/* Daterange picker */
.daterangepicker .calendar thead tr:first-child th {
  font-weight: normal;
}
.daterangepicker .calendar th,
.daterangepicker .calendar td {
  font-size: 12px;
}
.daterangepicker .calendar th {
  font-family: $font;
  color: #000000;
}
.daterangepicker .calendar td {
  color: #777777;
}
.daterangepicker .calendar td.off {
  color: #cccccc;
}
.daterangepicker .ranges li {
  color: #888888;
}
.datetimepicker th.dow,
.datetimepicker td.day,
.datetimepicker span.year,
.datetimepicker span.month,
.datetimepicker span.hour,
.datetimepicker span.minute {
  font-size: 12px;
}
.datetimepicker th,
.datetimepicker fieldset.hour legend {
  font-weight: normal;
  font-family: $font;
  color: #000000;
}
.datetimepicker th.prev,
.datetimepicker fieldset.hour legend.prev,
.datetimepicker th.switch,
.datetimepicker fieldset.hour legend.switch,
.datetimepicker th.next,
.datetimepicker fieldset.hour legend.next {
  padding-top: 0;
}
.datetimepicker th.dow,
.datetimepicker fieldset.hour legend.dow {
  font-weight: bold;
}
.datetimepicker fieldset.hour legend {
  padding-bottom: 5px;
}
.datetimepicker td.day,
.datetimepicker span.year,
.datetimepicker span.month {
  color: #777777;
}
.datetimepicker td.new,
.datetimepicker td.old,
.datetimepicker span.year.new,
.datetimepicker span.year.old {
  color: #cccccc !important;
}
.datetimepicker td.active,
.datetimepicker span.year.active,
.datetimepicker span.month.active,
.datetimepicker span.hour.active,
.datetimepicker span.minute.active,
.datetimepicker td.active:hover,
.datetimepicker span.year.active:hover,
.datetimepicker span.month.active:hover,
.datetimepicker span.hour.active:hover,
.datetimepicker span.minute.active:hover {
  color: #ffffff !important;
  text-shadow: none !important;
  background-image: none !important;
}
