@import "../utils/mixins";

.pt-pagination-container {
  .search-input {
    .input-group-addon {
      cursor: pointer;
      background-color: #f8f8f8;

      &:hover {
        color: #000;
        background-color: #f4f4f4;
      }
      &:active {
        color: inherit;
      }
    }
  }

  .pagination {
    li a {
      @include pt-no-select();
      color: #888888 !important;
    }
    li a:hover {
      color: #000 !important;
    }
    li.active a {
      color: #fff !important;
    }
    li.disabled a {
      color: #888888 !important;
    }
  }
}
